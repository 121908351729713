import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Paper, Tab, Box } from '@mui/material'
import { useState, useEffect } from 'react'
import Base from '../../Base/views/Base'
import DashboardIcon from '@mui/icons-material/Dashboard';
import ManageHistoryIcon from '@mui/icons-material/ManageHistory';
import AssessmentIcon from '@mui/icons-material/Assessment';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import Employee from './Employee/Employee';
import Dashboard from './Dashboard/Dashboard';
import "../css/style.css";
import { useLocation } from "react-router-dom";
import Leave from './Leave/Leave';
import Others from './Others/Others';
import { getUserData } from '../../Base/helper/baseFunctions';
import { useSelector, useDispatch } from "react-redux";
import { GetWeekOffConfigureAction, GetWorkHoursAction } from '../../../../store/Leave/WeekOff/Action';
import { GetLeaveTypeAction } from '../../../../store/Leave/LeaveType/Action';
import { GetBankAction } from "../../../../store/Ancillary/Bank/Action";
import { GetRelationshipAction } from "../../../../store/Ancillary/Relationship/Action";
import { GetCasteAction } from "../../../../store/Ancillary/Caste/Action";
import { GetPaygradeAction } from "../../../../store/Ancillary/Paygrade/Action";
import { GetMyTimeConfigureAction } from "../../../../store/Leave/MyTimeConfigure/Action";
import { GetDesignationAction } from '../../../../store/Ancillary/Designation/Action';
import { GetLeavingReasonAction } from '../../../../store/Ancillary/LeavingReason/Action';
import { GetAtsJobPostAction } from '../../../../store/ATS/JobPost/Action';
import { GetBusinessUnitAction } from '../../../../store/Ancillary/BusinessUnit/Action';
import { GetSubBusinessUnitAction } from '../../../../store/Ancillary/SubBusinessUnit/Action';
import { GetStudyLevelAction } from '../../../../store/Ancillary/StudyLevel/Action';
import { GetStudyProgramAction } from '../../../../store/Ancillary/StudyProgram/Action';
import { GetEmploymentTypeAction } from '../../../../store/Ancillary/EmploymentType/Action';
import { GetCostCenterAction } from '../../../../store/Ancillary/CostCenter/Action';
import { GetWorkLocationAction } from '../../../../store/Ancillary/WorkLocation/Action';
import { GetYearlyHolidayCalendarAction } from '../../../../store/Leave/YearlyHolidayCalendar/Action';
import { GetProofTypeAction } from '../../../../store/Ancillary/ProofType/Action';
import { GetLeaveReasonAction } from '../../../../store/Leave/LeaveReason/Action';
import { GetShiftTimeConfigureAction } from '../../../../store/Leave/ShiftConfigure/Action';


const HRSettings = () => {
    const dispatch = useDispatch();
    const userData = getUserData();
    const location = useLocation();
    const defaultValue = location.state?.click_event || ""
    const [value, setValue] = useState(defaultValue ? defaultValue : '1');
    const [accordian, setAccordian] = useState('1');
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    useEffect(() => {
        dispatch(GetDesignationAction());
        dispatch(GetBankAction());
        dispatch(GetLeavingReasonAction());
        dispatch(GetRelationshipAction());
        dispatch(GetAtsJobPostAction());
        dispatch(GetBusinessUnitAction());
        dispatch(GetSubBusinessUnitAction());
        dispatch(GetCasteAction());
        dispatch(GetLeaveTypeAction());
        dispatch(GetStudyLevelAction());
        dispatch(GetStudyProgramAction({ studylevel_id: 0 }));
        dispatch(GetPaygradeAction());
        dispatch(GetEmploymentTypeAction());
        dispatch(GetCostCenterAction());
        dispatch(GetWorkLocationAction());
        dispatch(GetYearlyHolidayCalendarAction({ year: 0 }));
        dispatch(GetProofTypeAction());
        dispatch(GetLeaveReasonAction());
        dispatch(GetMyTimeConfigureAction());
        dispatch(GetWeekOffConfigureAction());
        dispatch(GetWorkHoursAction());
        dispatch(GetShiftTimeConfigureAction());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <Base>
            <Paper className='admin-setting' sx={{ borderRadius: "10px", minHeight: "87vh", maxHeight: "87vh", overflowY: "auto" }}>
                <TabContext value={value}>
                    <Box>
                        <TabList sx={{
                            minHeight: "40px", ".MuiTab-root": { minHeight: "20px" },
                            '& .MuiTabs-indicator': {
                                width: '100%',
                                display: 'flex',
                                justifyContent: "center !important",
                            },
                        }} onChange={handleChange} aria-label="lab API tabs example">
                            <Tab icon={<DashboardIcon fontSize='small' />} iconPosition="start" label="Dashboard" value="1" />
                            <Tab icon={<ManageHistoryIcon fontSize='small' />} iconPosition="start" label="Employee" value="2" />
                            {(userData?.f_country_code === "IND") && <Tab icon={<AssessmentIcon fontSize='small' />} iconPosition="start" label="Leave" value="3" />}
                            <Tab icon={<AutoAwesomeIcon fontSize='small' />} iconPosition="start" label="Organization" value="4" />
                        </TabList>
                    </Box>
                    <TabPanel value="1" sx={{ padding: "0px", minHeight: "45px" }}>
                        <Dashboard setValue={setValue} setAccordian={setAccordian} />
                    </TabPanel>
                    <TabPanel value="2" sx={{ padding: "0px" }}>
                        <Employee accordian={accordian} />
                    </TabPanel>
                    <TabPanel value="3" sx={{ padding: "0px" }}>
                        <Leave accordian={accordian} />
                    </TabPanel>
                    <TabPanel value="4" sx={{ padding: "0px" }}>
                        <Others accordian={accordian} />
                    </TabPanel>
                </TabContext>
            </Paper>
        </Base>

    )
}

export default HRSettings;