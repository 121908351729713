
import { Collapse, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'
import GroupIcon from '@mui/icons-material/Group';
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { grey } from '@mui/material/colors';

const EmployeeMenu = ({ EmployeeCollapse, setEmployeeCollapse, open, employeeOptions, selectedMenu, handleMenuClick, isMobile, theme }) => {
    return (
        <>
            <ListItem disablePadding sx={{ display: 'block', fontSize: "12px", marginTop:"2px"}}>
                <ListItemButton onClick={() => setEmployeeCollapse()} disableRipple selected={EmployeeCollapse} sx={{ "&.Mui-selected:hover": { backgroundColor: "rgb(254 255 187 / 48%)" }, ":hover": { backgroundColor: "rgb(254 255 187 / 48%)" },px: 1.5, borderRadius: "8px", fontWeight: 600 }}>
                    <ListItemIcon sx={{ minWidth: "35px" }}>
                        <span style={{ color: EmployeeCollapse && `${theme?.palette?.primary?.main}` }}><GroupIcon fontSize='small' /></span>
                    </ListItemIcon>
                    <ListItemText primaryTypographyProps={{
                        variant: "body2",
                        fontWeight: EmployeeCollapse ? 600 : 550,
                        fontSize: "0.8rem",
                        color: EmployeeCollapse ? "primary" : grey[600]
                    }} primary={"My Employee"} sx={{ opacity: open ? 1 : 0 }} />

                    {open && EmployeeCollapse ? <ExpandLess /> : open && <ExpandMore />}
                </ListItemButton>
            </ListItem>
            <Collapse in={EmployeeCollapse} timeout="auto" unmountOnExit>
                <ListItem disablePadding sx={{ display: 'block', fontSize: "12px", pl: 0, pt: 1, pb: 1 }}>
                    
                    <List sx={{ padding: "0px" }}>
                        {
                            employeeOptions.map((res, i) => {
                                return (
                                    <Link key={i} to={res?.route} style={{ color: theme.palette.mode === "dark" ? "white" : "black" }}>
                                      {res?.show && 
                                        <ListItem
                                            disablePadding
                                            sx={{ display: 'block', fontSize: "12px", marginLeft: `${isMobile ? "-4px" : ''}` }}
                                            onClick={(e) => (handleMenuClick(res?.label))}

                                        >
                                            <ListItemButton sx={{ borderRadius: "8px", fontWeight: 600, minHeight: 10, justifyContent: open ? 'initial' : 'center', px: 1.5, }} selected={selectedMenu === res?.label}>
                                                <ListItemIcon sx={{ minWidth: 0, mr: open ? 2 : 'auto', justifyContent: 'center', }} >
                                                    <span style={{ color: selectedMenu === res?.label && `${theme?.palette?.primary?.main}` }}>{res?.icon}</span>
                                                </ListItemIcon>
                                                <ListItemText primaryTypographyProps={{
                                                    variant: "body2",
                                                    fontWeight: selectedMenu === res?.label ? 600 : 550,
                                                    fontSize: "0.8rem",
                                                    color: selectedMenu === res?.label ? "primary" : grey[600]
                                                }} primary={res?.label} sx={{ opacity: open ? 1 : 0 }} />
                                            </ListItemButton>
                                        </ListItem>
                                      }
                                    </Link>
                                )
                            })
                        }
                    </List>
                    </ListItem>
            </Collapse>
        </>
    )
}

export default EmployeeMenu