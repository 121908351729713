import { useState, useEffect, useRef } from "react";
import { DragDropContext } from "react-beautiful-dnd";
import DraggableElement from "./DraggableElement";
import { InputAdornment, IconButton } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Skeleton,
  TextField,
  Typography,
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import Popup from "../../../../../Base/views/Popup";
import AtsCandidatePreview from "../../AtsCandidatePreview";
import {
  AddAtsJobKanbanHelper,
  AddAtsJobKanbanOfferHelper,
  GetAtsJobKanbanHelper,
  GetAtsJobKanbanOfferHelper,
  UpdateAtsJobKanbanHelper,
  UpdateAtsJobKanbanOfferHelper,
} from "../../../../helper/AtsJobKanbanHelper";
import {
  AddAtsJobStageHelper,
  DeleteAtsJobStageHelper,
  GetAtsJobStageHelper,
  UpdateAtsJobStageHelper,
} from "../../../../helper/AtsJobStageHelper";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import {
  GetAtsCandidateAction,
  ProposeCandidateConfirmationAction,
} from "../../../../../../../store/ATS/Candidate/Action";
import { matchSorter } from "match-sorter";
import CommonDatePicker from "../../../../../Base/views/CommonDatePicker";
import AtsCandidateValidate from "../../../ATSCandidate/AtsCandidateValidate";
import RejectPopup from "../RejectPopup";
import AcceptPopup from "../AcceptPopup";
import { AtsJobPostModeAction } from "../../../../../../../store/ATS/JobPost/Action";
import ArrowBackButton from "../../../../../Base/views/ArrowBackButton";
import { EnableAddRequestAction } from "../../../../../../../store/Onboarding/Action";
import { AddMenuHeadingAction } from "../../../../../../../store/Menu/Action";
import history from "../../../../../../../history";
import { addSessionData } from "../../../../../Base/helper/baseHelper";
import OfferLetterResponseStepper from "../../../../../ATSSettings/views/OfferLetter/OfferLetterResponseStepper";
import { getUserData } from "../../../../../Base/helper/baseFunctions";
import "../../../../css/styles.css"
import Draggable from 'react-draggable';

const useStyles = makeStyles((theme) =>
  createStyles({
    ListGrid: {
      display: "flex",
      flexDirection: "row",
      alignItems: "flex-start",
      overflow: "auto",
      marginBottom: "5px",
    },
  })
);
const Loader = () => {
  const classes = useStyles();
  return (
    <div className={classes?.ListGrid}>
      {Array(5)
        .fill()
        .map((data, index) => (
          <div key={index} style={{ padding: "10px" }}>
            <Skeleton variant="text" width={210} sx={{ fontSize: "1rem" }} />
            {Array(5)
              .fill()
              .map((d, i) => (
                <div key={i} style={{ paddingTop: "10px" }}>
                  <Skeleton variant="rounded" width={210} height={70} />
                </div>
              ))}
          </div>
        ))}
    </div>
  );
};
function KanbanBoard({
  setArchived,
  currentMode,
  setCurrentMode,
  currentJobData,
  candidatevalueData,
  setCandidateUpdate,
  candidateUpdate,
  setCandidatePreview,
  candidatePreview,
  toggleDrawer,
  setAnchor,
  anchor,
  setCurrentData,
  currentData,
  filters,
  sortValue,
  searchVisible,
  tabValue
}) {
  const classes = useStyles();
  const userData = getUserData();
  const dispatch = useDispatch();
  const { fixedMenu, isMobile } = useSelector((state) => state?.MenuReducer);
  const { atsCandidateData } = useSelector(
    (state) => state.AtsReducer?.AtsCandidateReducer
  );
  const [searchTerm, setSearchTerm] = useState("");
  const date = new Date();
  const default_date = `${date?.getFullYear() +
    "-" +
    ("0" + (date?.getMonth() + 1)).slice(-2) +
    "-" +
    ("0" + date?.getDate()).slice(-2)
    }`;
  const [elements, setElements] = useState([]);
  const [atsJobStageData, setAtsJobStageData] = useState([]);
  const [atsJobStageData1, setAtsJobStageData1] = useState([]);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [offerWarning, setOfferWarning] = useState(false);
  const [stageDeleteConfirm, setStageDeleteConfirm] = useState({ show: false });
  const [inputData, setInputData] = useState({ user: "", note: "" });
  const [dragResult, setDragResult] = useState(false);
  const [load, setLoad] = useState(true);
  const [indexValue, setIndexValue] = useState("");
  const [errors, setErrors] = useState({});
  const [reject, setReject] = useState(false);
  const [accept, setAccept] = useState(false);
  const [stagesData, setStagesData] = useState({ load: true, values: [] });
  const [isverified, setIsVerified] = useState(false);
  const [showValidate, setShowValidate] = useState(false);
  const [popMsg, setPopMsg] = useState("");
  const [isonboard, setIsOnboard] = useState(false);
  const [isoffer, setIsOffer] = useState(false);
  const [isofferform, setIsOfferForm] = useState(false);
  const [message, setMessage] = useState(
    "Do you want to onboard candidate now?"
  );
  const [show, setShow] = useState("1");
  const [offerdata] = useState([]);
  const boardRef = useRef(null);
  useEffect(() => {
    GetAtsJobStageHelper({
      ats_jobpost_id: currentJobData?.ats_jobpost_id,
    }).then((res) => {
      setAtsJobStageData(res?.data?.data);
      setAtsJobStageData1(res?.data?.data);
    });
    dispatch(GetAtsCandidateAction());
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (candidateUpdate === true) {
      GetAtsJobStageHelper({
        ats_jobpost_id: currentJobData?.ats_jobpost_id,
      }).then((res) => {
        setAtsJobStageData(res?.data?.data);
        setAtsJobStageData1(res?.data?.data);
      });
      getKanbanData();
      dispatch(GetAtsCandidateAction());
      setCurrentData(candidatevalueData);
      setCandidateUpdate(false);
    }
    // eslint-disable-next-line
  }, [candidateUpdate]);
  useEffect(() => {
    if (
      filters?.assignee_filter?.length > 0 ||
      filters?.proposed_filter?.length > 0 ||
      (filters?.stage_filter && filters.stage_filter.ats_job_stages_id) ||
      sortValue?.name === "priority"
    ) {
      let precruiter = [];
      let propose = [];
      filters?.assignee_filter?.forEach((res) =>
        precruiter.push(res?.recruiter_id)
      );
      filters?.proposed_filter?.forEach((res) =>
        propose.push(res?.recruiter_id)
      );
      const assigned_to_id =
        filters?.assignee_filter?.length > 0
          ? construcData(
            filters?.assignee_filter,
            "assigned_to_id",
            "recruiter_id"
          )
          : null;
      const proposed_by_name =
        filters?.proposed_filter?.length > 0
          ? construcData(
            filters?.proposed_filter,
            "proposed_by_name",
            "proposed_by_name"
          )
          : null;
      const stage_id = 
        filters?.stage_filter && filters.stage_filter.ats_job_stages_id
          ? JSON.stringify(
            [{ stage_id: filters.stage_filter.ats_job_stages_id }])
          : null;

      if (currentJobData?.offer_letter) {
        GetAtsJobKanbanOfferHelper({
          ats_jobpost_id: currentJobData?.ats_jobpost_id,
          assigned_to_id: assigned_to_id,
          proposed_by_name: proposed_by_name,
          ats_job_stages_id: stage_id,
          sort_order: sortValue?.order,
        }).then((res) => {
          res?.data?.data && setElements(res?.data?.data);
          setLoad(false);
        });
      } else {
        GetAtsJobKanbanHelper({
          ats_jobpost_id: currentJobData?.ats_jobpost_id,
          assigned_to_id: assigned_to_id,
          proposed_by_name: proposed_by_name,
          ats_job_stages_id: stage_id,
          sort_order: sortValue?.order,
        }).then((res) => {
          res?.data?.data && setElements(res?.data?.data);
          setLoad(false);
        });
      }
    } else {
      getKanbanData();
    }
    // eslint-disable-next-line
  }, [filters, sortValue]);

  const construcData = (value, label, key) => {
    var temp = [];
    value?.forEach((res) => {
      temp.push({ [label]: res[key] });
    });
    return JSON.stringify(temp);
  };
  const getKanbanData = () => {
    if (currentJobData?.offer_letter) {
      GetAtsJobKanbanOfferHelper({
        ats_jobpost_id: currentJobData?.ats_jobpost_id,
      }).then((res) => {
        res?.data?.data && setElements(res?.data?.data);
        setLoad(false);
      });
    } else {
      GetAtsJobKanbanHelper({
        ats_jobpost_id: currentJobData?.ats_jobpost_id,
      }).then((res) => {
        res?.data?.data && setElements(res?.data?.data);
        setLoad(false);
      });
    }
  };

  const onDragEnd = (result) => {
    if (inputData?.proposeCandidate) {
      if (currentJobData?.offer_letter) {
        AddAtsJobKanbanOfferHelper({
          kanban_data: JSON.stringify([
            {
              proposed_by:userData?.ats_user_id,
              candidate_id: inputData?.user?.candidate_id,
              ats_jobpost_id: currentJobData?.ats_jobpost_id,
              comment: inputData?.note,
              ats_job_stages_id: inputData?.jobStage_id || null,
              priority: 5,
              ats_status_id: null,
            },
          ]),
        }).then((res) => {
          if (res?.hasError) {
            setInputData({});
          } else {
            res?.data?.data && setElements(res?.data?.data);
            setInputData({});
            if (res?.data?.statuscode === 200) {
              dispatch(
                ProposeCandidateConfirmationAction({
                  candidate_data: inputData?.user,
                  job_data: currentJobData,
                  kanban_data: res?.data?.data?.[0],
                })
              );
            }
          }
        });
      } else {
        AddAtsJobKanbanHelper({
          kanban_data: JSON.stringify([
            {
              proposed_by:userData?.ats_user_id,
              candidate_id: inputData?.user?.candidate_id,
              ats_jobpost_id: currentJobData?.ats_jobpost_id,
              comment: inputData?.note,
              ats_job_stages_id: inputData?.jobStage_id || null,
              priority: 5,
              ats_status_id: null,
            },
          ]),
        }).then((res) => {
          if (res?.hasError) {
            setInputData({});
          } else {
            res?.data?.data && setElements(res?.data?.data);
            setInputData({});
            if (res?.data?.statuscode === 200) {
              dispatch(
                ProposeCandidateConfirmationAction({
                  candidate_data: inputData?.user,
                  job_data: currentJobData,
                  kanban_data: res?.data?.data?.[0],
                })
              );
            }
          }
        });
      }
    } else {
      if (!result.destination) {
        return;
      }
      if (currentJobData?.offer_letter) {
        UpdateAtsJobKanbanOfferHelper({
          kanban_data: JSON.stringify([
            {
              proposed_by: +currentData?.proposed_by,
              candidate_job_id: +result?.draggableId,
              ats_jobpost_id: currentJobData?.ats_jobpost_id,
              interview_feedback: inputData?.note,
              ats_job_stages_id: result?.destination?.droppableId,
              priority: currentData?.priority || 5,
              ats_status_id: null,
              selected: currentJobData?.selected || null,
              candidate_id: elements?.filter(
                (a) => +a?.candidate_job_id === +result?.draggableId
              )?.[0]?.candidate_id,
              accepted_ctc: inputData?.accepted_ctc
                ? inputData?.accepted_ctc
                : 0,
              assignee_id : elements?.filter(
                  (a) => +a?.candidate_job_id === +result?.draggableId
                )?.[0]?.assignee_id,
              tentative_joining_date: inputData?.tentative_joining_date || null,
              joined_ctc: inputData?.joined_ctc ? inputData?.joined_ctc : 0,
              joined_date: inputData?.joined_date || null,
              offer_released_date: inputData?.offer_released_date || default_date,
              rejected_reason: inputData?.reason || null,
              reverting_reason: inputData?.revert_reason || null,
            },
          ]),
        }).then((res) => {
          setAccept(false);
          setErrors([]);
          res?.data?.data && setElements(res?.data?.data);
          setOpenConfirm(false);
          setInputData({ user: "", note: "" });
          if (indexValue === "joined") {
            if (currentData?.access_onboard_candidate) {
              setIsOnboard(true);
            } else if (indexValue === "Offer Preparation") {
              setIsOffer(true);
            }
          }
        });
      } else {
        UpdateAtsJobKanbanHelper({
          kanban_data: JSON.stringify([
            {
              proposed_by: +currentData?.proposed_by,
              candidate_job_id: +result?.draggableId,
              ats_jobpost_id: currentJobData?.ats_jobpost_id,
              interview_feedback: inputData?.note,
              ats_job_stages_id: result?.destination?.droppableId,
              priority: currentData?.priority || 5,
              ats_status_id: null,
              selected: currentJobData?.selected || null,
              candidate_id: elements?.filter(
                (a) => +a?.candidate_job_id === +result?.draggableId
              )?.[0]?.candidate_id,
              accepted_ctc: inputData?.accepted_ctc
                ? inputData?.accepted_ctc
                : 0,
              assignee_id : elements?.filter(
                  (a) => +a?.candidate_job_id === +result?.draggableId
                )?.[0]?.assignee_id,
              tentative_joining_date: inputData?.tentative_joining_date || null,
              joined_ctc: inputData?.joined_ctc ? inputData?.joined_ctc : 0,
              joined_date: inputData?.joined_date || null,
              offer_released_date: inputData?.offer_released_date || default_date,
              rejected_reason: inputData?.reason || null,
              reverting_reason: inputData?.revert_reason || null,
            },
          ]),
        }).then((res) => {
          setAccept(false);
          setErrors([]);
          res?.data?.data && setElements(res?.data?.data);
          setOpenConfirm(false);
          setInputData({ user: "", note: "" });
          if (indexValue === "joined") {
            if (currentData?.access_onboard_candidate) {
              setIsOnboard(true);
            } else if (indexValue === "Offer Preparation") {
              setIsOffer(true);
            }

          }
        });
      }
      const listCopy = elements;
      var foundIndex = listCopy?.findIndex(
        (x) => +x.candidate_job_id === +result?.draggableId
      );
      listCopy[foundIndex]["ats_job_stages_id"] =
        +result?.destination?.droppableId;
      setElements([...listCopy]);
    }
  };

  const val = (result, currentData) => {
    var i = result?.destination?.droppableId;
    let index = atsJobStageData.findIndex((obj) => obj.ats_job_stages_id === i);
    if (index === atsJobStageData.length - 1) {
      setInputData({
        ...inputData,
        joined_ctc: currentData?.accepted_ctc,
        joined_date: "dd/mm/yyyy",
      });
      setIndexValue(() => "joined");
    } else if (index === atsJobStageData.length - 2) {
      setInputData({
        ...inputData,
        accepted_ctc: "",
        tentative_joining_date: "dd/mm/yyyy",
      });
      setIndexValue("offerAccepted");
    } else if (index === atsJobStageData.length - 4) {
      setInputData({
        ...inputData,
        offer_released_date: default_date,
      });
      setIndexValue("offerReleased");
    }
  };

  const onDragEndConfirm = (result) => {
    var ele = elements.find((x) => x?.candidate_job_id === result?.draggableId);
    val(result, ele);
    if (
      elements.find(
        (x) =>
          x?.candidate_job_id === result?.draggableId &&
          x?.is_verified === false
      )
    ) {
      setCurrentData(
        elements.find((x) => x?.candidate_job_id === result?.draggableId)
      );
    } else {
      setDragResult(result);
      setCurrentData(
        elements.find((x) => x?.candidate_job_id === result?.draggableId)
      );
      var rejId = atsJobStageData.find((obj) => obj.name === "Rejected");
      if (result?.destination?.droppableId === rejId.ats_job_stages_id) {
        setReject(true);
        setInputData({ ...inputData, reason: "" });
      } else if (
        result?.destination?.droppableId !== rejId.ats_job_stages_id &&
        result?.source?.droppableId === rejId.ats_job_stages_id
      ) {
        const relevantStages = [
          "Offer Released",
          "Offer Rejected",
          "Offer Accepted",
          "Joined",
        ];
        const newStage = atsJobStageData.find(
          (obj) => obj.ats_job_stages_id === result?.destination?.droppableId
        );
        if (
          ele?.offer_letter &&
          !ele?.offer_preparation_completed
        ) {
          if (newStage && relevantStages.includes(newStage.name)) {
            setOfferWarning(true);
          }
          else {
            setAccept(true);
          }
        }
        else {
          setAccept(true);
        }
        setInputData({ ...inputData, revert_reason: "" });
      } else {
        if (result?.destination?.droppableId !== result.source.droppableId) {
          setPopMsg("");
          const relevantStages = [
            "Offer Released",
            "Offer Rejected",
            "Offer Accepted",
            "Joined",
          ];
          const newStage = atsJobStageData.find(
            (obj) => obj.ats_job_stages_id === result?.destination?.droppableId
          );
          if (
            ele?.offer_letter &&
            !ele?.offer_preparation_completed
          ) {
            if (newStage && relevantStages.includes(newStage.name)) {
              setOfferWarning(true);
            } else {
              setOpenConfirm(true);
            }
          } else {
            setOpenConfirm(true);
          }
        }
      }
    }
  };
  const handleInputChange = (e, value) => {
    if (value) {
      setInputData({ ...inputData, user: value });
    } else {
      setInputData({ ...inputData, user: "" });
    }
  };

  const validate = (inputData) => {
    let temp = { ...errors };
    if ("joined_date" in inputData) {
      temp.joined_date =
        inputData.joined_date !== "dd/mm/yyyy" ? "" : "Join date is required";
    }
    if ("tentative_joining_date" in inputData) {
      temp.tentative_joining_date =
        inputData.tentative_joining_date !== "dd/mm/yyyy"
          ? ""
          : "Tentative joning date is required";
    }
    if ("reason" in inputData) {
      temp.reason = inputData?.reason === "" ? "Reason is required" : "";
    }
    if ("revert_reason" in inputData) {
      temp.revert_reason =
        inputData?.revert_reason === "" ? "Revert Reason is required" : "";
    }
    if ("stage_id" in inputData) {
      temp.stage_id = ["", null].includes(inputData?.stage_id)
        ? "Moving Stage is required"
        : "";
    }
    setErrors({
      ...temp,
    });
    return Object.values(temp).every((x) => x === "");
  };

  const handleSubmit = () => {
    let err = validate(inputData);
    if (err) {
      setReject(false);
      onDragEnd(dragResult);
      setInputData({ user: "", note: "" });
      setIndexValue("");
      setErrors({});
      setOpenConfirm(false);
    }
  };
  const handleStageChange = (event, id) => {
    let dt = atsJobStageData.findIndex((res) => res?.ats_job_stages_id === id);
    let data = [...atsJobStageData];
    data[dt] = { ...data[dt], name: event.target.value, focus: true };
    setAtsJobStageData(data);
  };
  const handleFieldChange = (event, value) => {
    if (["stage_id"].includes(event)) {
      setInputData({ ...inputData, [event]: value });
      Object.values?.(errors)?.find((res) => res !== "")?.length > 0 &&
        validate({ ...value, [event]: value });
    } else if (event.target.name === "accepted_ctc") {
      setInputData({ ...inputData, [event.target.name]: event.target.value });
    } else {
      setInputData({ ...inputData, [event.target.name]: event.target.value });
      Object.values?.(errors)?.find((res) => res !== "")?.length > 0 &&
        validate({ ...inputData, [event.target.name]: event.target.value });
    }
  };

  const handleStoreStage = (e, id) => {
    let oldStage = atsJobStageData1.findIndex(
      (res) => res?.ats_job_stages_id === id
    );
    let dt = atsJobStageData.findIndex((res) => res?.ats_job_stages_id === id);
    let name = atsJobStageData[dt]?.name;
    let sort_key = atsJobStageData[+dt - 1]?.sort_key;
    if ([0].includes(id)) {
      AddAtsJobStageHelper({
        ats_jobpost_id: currentJobData?.ats_jobpost_id,
        name: name,
        indexKey: sort_key,
      })
        .then((res) => {
          toast.dismiss();
          toast.success("Stage added successfully");
          setAtsJobStageData(res?.data?.data);
          setAtsJobStageData1(res?.data?.data);
        })
        .catch((err) => {
          toast.dismiss();
          toast.error(err?.response?.data?.error?.[0]?.message);
          let data = [...atsJobStageData];
          data.splice(dt, 1);
          setAtsJobStageData(data);
          setAtsJobStageData1(data);
        });
    } else {
      if (name !== atsJobStageData1[oldStage]?.name) {
        UpdateAtsJobStageHelper({
          ats_job_stages_id: id,
          ats_jobpost_id: currentJobData?.ats_jobpost_id,
          name: name,
        }).then((res) => {
          setAtsJobStageData1(res?.data?.data);
          setAtsJobStageData(res?.data?.data);
        });
      } else {
        let dt = atsJobStageData.findIndex(
          (res) => res?.ats_job_stages_id === id
        );
        let data = [...atsJobStageData];
        data[dt] = { ...data[dt], name: name, focus: false };
        setAtsJobStageData(data);
      }
    }
  };

  const handleNewStage = (index, sortKey) => {
    let data = [...atsJobStageData];
    data.splice(index + 1, 0, {
      ats_job_stages_id: 0,
      name: "New Stage",
      sort_key: +sortKey + 0.1,
      focus: true,
    });
    setAtsJobStageData(data);
  };

  const handleProposeCandidate = (value) => {
    if (value?.sort_key === "1") setPopMsg("Select Candidate to Propose");
    setOpenConfirm(true);
    setInputData({
      ...inputData,
      proposeCandidate: true,
      jobStage_id: value?.ats_job_stages_id,
    });
  };

  const handleDeleteStage = (id) => {
    let children = elements?.filter(
      (element) => element.ats_job_stages_id === +id
    );
    if (children?.length > 0) {
      setStageDeleteConfirm({ show: true, jobStage_id: id, stage: "" });
    } else {
      DeleteAtsJobStageHelper({ jobStage_id: id, del_candidates: "N" }).then(
        (res) => {
          if (res?.data?.statuscode === 500) {
            toast.dismiss();
            toast.error(res?.data?.error?.[0]?.message);
          } else {
            setAtsJobStageData(res?.data?.data);
            setAtsJobStageData1(res?.data?.data);
            if (currentJobData?.offer_letter) {
              GetAtsJobKanbanOfferHelper({
                ats_jobpost_id: currentJobData?.ats_jobpost_id,
              }).then((res) => {
                res?.data?.data && setElements(res?.data?.data);
                setLoad(false);
              });
            } else {
              GetAtsJobKanbanHelper({
                ats_jobpost_id: currentJobData?.ats_jobpost_id,
              }).then((res) => {
                res?.data?.data && setElements(res?.data?.data);
              });
            }
          }
        }
      );
    }
  };

  const handleStageDelete = () => {
    setStageDeleteConfirm({ show: false, stage: "" });
    DeleteAtsJobStageHelper({
      jobStage_id: stageDeleteConfirm?.jobStage_id,
      del_candidates: stageDeleteConfirm?.stage,
    }).then((res) => {
      if (res?.data?.statuscode === 500) {
        toast.dismiss();
        toast.error(res?.data?.error?.[0]?.message);
      } else {
        setAtsJobStageData(res?.data?.data);
        setAtsJobStageData1(res?.data?.data);
        if (currentJobData?.offer_letter) {
          GetAtsJobKanbanOfferHelper({
            ats_jobpost_id: currentJobData?.ats_jobpost_id,
          }).then((res) => {
            res?.data?.data && setElements(res?.data?.data);
            setLoad(false);
          });
        } else {
          GetAtsJobKanbanHelper({
            ats_jobpost_id: currentJobData?.ats_jobpost_id,
          }).then((res) => {
            res?.data?.data && setElements(res?.data?.data);
          });
        }
      }
    });
  };

  const filterOptions = (options, { inputValue }) => {
    let vals = matchSorter(options, inputValue, {
      keys: ["first_name", "email_id"],
    });
    vals = vals.map((item) => ({
      ...item,
      full_name: `${item.first_name} ${item.last_name}`,
    }));

    return vals || [];
  };
  const handleVerify = () => {
    setIsVerified(false);
    setShowValidate(true);
  };
  const handleStageDataLoading = () => {
    let filteredData = atsJobStageData;
    if (currentJobData?.offer_letter) {
      if (
        currentData?.job_stage_name === "Offer Preparation" &&
        !currentData?.offer_preparation_completed
      ) {
        // If in "Offer Preparation" stage and not completed, load all stages
        const excludedStages = ["6", "7", "8", "9"];
        filteredData = atsJobStageData.filter((stage) => {
          return !excludedStages.includes(stage.sort_key);
        });
      } else if (
        currentData?.job_stage_name === "Offer Preparation" ||
        currentData?.job_stage_name === "Offer Released" ||
        currentData?.job_stage_name === "Offer Rejected" ||
        currentData?.job_stage_name === "Offer Accepted" ||
        currentData?.job_stage_name === "Joined"
      ) {
        filteredData = atsJobStageData;
      } else {
        const excludedStages = ["6", "7", "8", "9"];
        filteredData = atsJobStageData.filter((stage) => {
          return !excludedStages.includes(stage.sort_key);
        });
      }
    }

    setStagesData({ load: false, values: filteredData });
  };
  const handleOnboard = () => {
    dispatch(EnableAddRequestAction({ mode: "add", data: currentData }));
    addSessionData({ key: "selectedMenu", value: "Employee Onboarding" });
    addSessionData({ key: "popup", value: "true" });
    dispatch(AddMenuHeadingAction({ heading: "Employee Onboarding" }));
    history.push("/onboarding");
  };
  const handleLater = () => {
    addSessionData({ key: "popup", value: "true" });
    setShow("2");
    setMessage(
      "You may continue the onboarding process for this candidate by clicking on the same card."
    );
  };
  const handleOffer = () => {
    setIsOffer(false);
    setIsOfferForm(true);
  };
  const [originalElements, setOriginalElements] = useState([]);
  useEffect(() => {
    if (Object.keys(elements).length > 0 && Object.keys(elements).length > Object.keys(originalElements).length) {
      setOriginalElements(elements)
    }
  }, [elements])

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
    if (value === "") {
      setElements([...originalElements]);
    } else {
      setElements(
        elements?.filter(
          (element) =>
            element?.full_name?.toLowerCase().includes(value.toLowerCase()) ||
            element?.email_id?.toLowerCase().includes(value.toLowerCase())
        )
      );
    }
  };
  const handleClearSearch = () => {
    setSearchTerm("")
    setElements([...originalElements])
  };

  // dynamic stage column height calculation
  const [maxColumnHeight, setMaxColumnHeight]= useState(0)
  
  useEffect(() => {
    const maxElementsLength = Math.max(
      0,
      ...atsJobStageData?.map((stage) =>
        elements?.filter(
          (element) => +element?.ats_job_stages_id === +stage?.ats_job_stages_id
        )?.length
      )
    );
    const calculatedHeight = maxElementsLength * 110 + 110;
    setMaxColumnHeight(calculatedHeight);
  }, [atsJobStageData, elements]);
 

// Scroll Icon behaviour like jira 
  const handleDrag = (e, data) => {
    if (boardRef.current) {
    const { scrollLeft, scrollWidth, clientWidth } = boardRef.current;
   // Maximum scrollable width
    const maxScrollLeft = scrollWidth - clientWidth;
    const scaleFactor = maxScrollLeft / 50;
    const newScrollLeft = Math.min(Math.max(scrollLeft + data.deltaX * scaleFactor, 0), maxScrollLeft);
    boardRef.current.scrollLeft = newScrollLeft;
  }
};


  return (
    <>
      {showValidate && (
        <AtsCandidateValidate
          setOpenValidatePopup={setShowValidate}
          openValidatePopup={showValidate}
          currentData={currentData}
          getKanbanData={getKanbanData}
          kanban={true}
        />
      )}
      {searchVisible && (
        <Box sx={{ display: "flex", color: "rgb(99, 115, 129)", justifyContent: "end", alignItems: "center", padding: "0px 16px" }}>
          <TextField
            variant="outlined"
            size="small"
            placeholder="Search by Name or Email"
            value={searchTerm}
            onChange={handleSearchChange}
            sx={{ width: "300px", justifyContent: "end" }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="clear search"
                    onClick={handleClearSearch}
                    edge="end"
                    disabled={!searchTerm}
                  >
                    <ClearIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>)}
      <Box
        sx={{
          width: isMobile 
          ? "100vw" 
          : !fixedMenu 
            ? "calc(100vw - 65px)" 
            : "calc(100vw - 245px)",
          maxWidth: "100%",
          marginRight: !isMobile && "-282px",
          padding:"10px 0px 10px 0px",
          position: "relative",
        }}
      >
        {load ? (
          <Loader />
        ) : (
          <>
          <DragDropContext onDragEnd={onDragEndConfirm} >
            <div className={classes?.ListGrid} id="droppable-div"  ref={boardRef}>
              {atsJobStageData?.map((listKey, index) => {
                return (
                  <DraggableElement
                    handleDeleteStage={handleDeleteStage}
                    handleProposeCandidate={handleProposeCandidate}
                    handleStoreStage={handleStoreStage}
                    handleChange={handleStageChange}
                    setCurrentData={setCurrentData}
                    currentData={currentData}
                    setAnchor={setAnchor}
                    setIsVerified={setIsVerified}
                    elements={elements
                      ?.filter((element) => +element?.ats_job_stages_id === +listKey?.ats_job_stages_id)}
                    key={listKey?.ats_job_stages_id}
                    prefix={listKey}
                    getKanbanData={getKanbanData}
                    JobData={currentJobData}
                    stageData={atsJobStageData}
                    handleNewStage={handleNewStage}
                    index={index}
                    stageName={listKey.name}
                    sortKey={listKey.sort_key}
                    currentMode={currentMode}
                    offerdata={offerdata}
                    setElements={setElements}
                    maxColumnHeight={maxColumnHeight}
                  />
                );
              })}
            </div>
          </DragDropContext>
          <div
            id= "scroll-box-icon-container"
          >
            <div
              style={{
                display: "flex",
                position: "absolute",
                width: "100px",
                gap: "4px",
              }}
            >
              {Array.from({ length: 5 }).map((_, index) => (
                <div
                  key={index}
                  id="scroll-box-icon"
                />
              ))}
            </div>
            <div
              style={{
                position: "relative",
                width: "100px",
              }}
            >
              <Draggable
                axis="x"
                bounds="parent"
                onDrag={handleDrag}
                position={null}
              >
                <div
                  id="lens-icon-div"
                />
              </Draggable>
            </div>
          </div>
          </>
        )}
        {candidatePreview ? (
          <></>
        ) : (
          <div
          >
          <ArrowBackButton
            onClick={() => {
              setCurrentMode("grid");
              dispatch(AtsJobPostModeAction({ job_mode: "grid" }));
              currentMode === "archive"
                ? setArchived(true)
                : setArchived(false);
            }}
          />
            </div>
        )}
      </Box>
      <Popup
        fullWidth={true}
        maxWidth="md"
        close={false}
        openPopup={anchor}
        hideDividers={true}
      >
        <AtsCandidatePreview
          setIsOnboard={setIsOnboard}
          setIsOffer={setIsOffer}
          setIsOfferForm={setIsOfferForm}
          setCandidateUpdate={setCandidateUpdate}
          setCandidatePreview={setCandidatePreview}
          setElements={setElements}
          toggleDrawer={toggleDrawer}
          stageData={atsJobStageData}
          data={currentData}
          currentJobData={currentJobData}
          offerdata={offerdata}
          setCurrentData={setCurrentData}
          tabValue={tabValue}
        />
      </Popup>
      <Popup
        maxWidth="lg"
        title={popMsg || "Are you sure want to move candidate?"}
        isMasterProduct={false}
        setOpenPopup={setOpenConfirm}
        openPopup={openConfirm}
        setIndex={setIndexValue}
        setInputData={setInputData}
        setErrors={setErrors}
      >
        <Box minWidth="500px">
          {inputData?.proposeCandidate && (
            <FormControl fullWidth sx={{ pr: 1, mb: 2 }}>
              <Autocomplete
                options={atsCandidateData || []}
                value={inputData?.user}
                size="small"
                name="user"
                filterOptions={filterOptions}
                getOptionLabel={(option) => {
                  if (option && option["first_name"]) {
                    // Concatenate first_name and last_name
                    const fullName = `${option.first_name} ${option.last_name}`;
                    return fullName.trim(); // Trim to remove extra spaces
                  } else {
                    return "";
                  }
                }}
                onChange={handleInputChange}
                renderInput={(params) => (
                  <TextField
                    placeholder="Search candidates by E-mail"
                    {...params}
                    required={true}
                    label="Candidate"
                  />
                )}
              />
            </FormControl>
          )}
          {indexValue === "offerAccepted" && (
            <div>
              <TextField
                sx={{ mb: "15px" }}
                fullWidth
                size="small"
                value={inputData?.accepted_ctc}
                onChange={handleFieldChange}
                variant="outlined"
                id="accepted_ctc"
                label="Accepted CTC"
                name="accepted_ctc"
              ></TextField>
              {
                <FormControl fullWidth sx={{ mb: 3 }}>
                  <CommonDatePicker
                    size="small"
                    required
                    name="tentative_joining_date"
                    label="Tentative Join Date"
                    pickerType="date"
                    onKeyDown={(e) => e.preventDefault()}
                    onKeyPress={(e) => e.preventDefault()}
                    pickerFormat="dd/MM/yyyy"
                    DefaultDTvalue={inputData?.tentative_joining_date}
                    handleChange={(value) =>
                      handleFieldChange({
                        target: {
                          name: "tentative_joining_date",
                          value: value
                            ? `${value?.getFullYear() +
                            "-" +
                            ("0" + (value?.getMonth() + 1)).slice(-2) +
                            "-" +
                            ("0" + value?.getDate()).slice(-2)
                            }`
                            : "",
                        },
                      })
                    }
                    {...(errors.tentative_joining_date && {
                      error: true,
                      helperText: errors.tentative_joining_date,
                    })}
                  />
                </FormControl>
              }
            </div>
          )}
          {indexValue === "joined" && (
            <>
              <TextField
                sx={{ mb: "15px" }}
                fullWidth
                size="small"
                value={currentData?.accepted_ctc}
                disabled={currentData?.accepted_ctc}
                variant="outlined"
                id="accepted_ctc"
                label="Accepted CTC"
                name="accepted_ctc"
              ></TextField>
              <TextField
                sx={{ mb: "15px" }}
                fullWidth
                size="small"
                value={inputData?.joined_ctc}
                variant="outlined"
                id="joined_ctc"
                label="Joined CTC"
                name="joined_ctc"
                onChange={handleFieldChange}
              ></TextField>
              <FormControl fullWidth sx={{ mb: 3 }}>
                <CommonDatePicker
                  size="small"
                  required
                  name="joinDate"
                  label="Join Date"
                  pickerType="date"
                  onKeyDown={(e) => e.preventDefault()}
                  onKeyPress={(e) => e.preventDefault()}
                  pickerFormat="dd/MM/yyyy"
                  DefaultDTvalue={inputData?.joined_date}
                  handleChange={(value) =>
                    handleFieldChange({
                      target: {
                        name: "joined_date",
                        value: value
                          ? `${value?.getFullYear() +
                          "-" +
                          ("0" + (value?.getMonth() + 1)).slice(-2) +
                          "-" +
                          ("0" + value?.getDate()).slice(-2)
                          }`
                          : "",
                      },
                    })
                  }
                  {...(errors.joined_date && {
                    error: true,
                    helperText: errors.joined_date,
                  })}
                />
              </FormControl>
            </>
          )}
          {indexValue === "offerReleased" && (
            <>
              <FormControl fullWidth sx={{ mb: 3 }}>
                <CommonDatePicker
                  size="small"
                  name="offer_released_date"
                  label="Offer Released Date"
                  pickerType="date"
                  onKeyDown={(e) => e.preventDefault()}
                  onKeyPress={(e) => e.preventDefault()}
                  pickerFormat="dd/MM/yyyy"
                  DefaultDTvalue={inputData?.offer_released_date}
                  handleChange={(value) =>
                    handleFieldChange({
                      target: {
                        name: "offer_released_date",
                        value: value
                          ? `${value?.getFullYear() +
                          "-" +
                          ("0" + (value?.getMonth() + 1)).slice(-2) +
                          "-" +
                          ("0" + value?.getDate()).slice(-2)
                          }`
                          : "",
                      },
                    })
                  }
                />
              </FormControl>
            </>
          )}
          <TextField
            size="small"
            multiline
            rows={4}
            value={inputData?.note}
            onChange={(e) =>
              setInputData({ ...inputData, [e.target.name]: e.target.value })
            }
            variant="outlined"
            fullWidth
            id="note"
            label="Comment"
            name="note"
          />
          <Box sx={{ mt: 2, display: "flex", justifyContent: "flex-end" }}>
            <Button
              sx={{ ml: 2 }}
              disabled={inputData?.proposeCandidate && !inputData?.user}
              variant="outlined"
              className="mpl-primary-btn"
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </Box>
        </Box>
      </Popup>
      <Popup
        maxWidth="lg"
        title="Are you sure to delete the Stage?"
        isMasterProduct={false}
        setOpenPopup={setStageDeleteConfirm}
        openPopup={stageDeleteConfirm?.show}
      >
        <Box minWidth="400px">
          <FormControl>
            <FormLabel id="gender-label">
              Do you want to delete the candidates
            </FormLabel>
            <FormLabel sx={{ fontSize: "10px" }}>
              (If no candidates will be moved to Default stage)
            </FormLabel>
            <RadioGroup
              onChange={(e) =>
                setStageDeleteConfirm({
                  ...stageDeleteConfirm,
                  stage: e.target.value,
                })
              }
              row
              aria-labelledby="gender-label"
              name="stage"
              value={stageDeleteConfirm?.stage}
            >
              <FormControlLabel value="Y" control={<Radio />} label="Yes" />
              <FormControlLabel value="N" control={<Radio />} label="No" />
            </RadioGroup>
          </FormControl>
          <Box sx={{ mt: 2, display: "flex", justifyContent: "flex-end" }}>
            <Button
              disabled={!stageDeleteConfirm?.stage}
              variant="contained"
              className="mpl-primary-btn"
              onClick={() => handleStageDelete()}
            >
              Delete
            </Button>
            <Button
              sx={{ ml: 2 }}
              variant="outlined"
              className="mpl-secondary-btn"
              onClick={() => setStageDeleteConfirm({ show: false, stage: "" })}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Popup>
      <Popup
        maxWidth="lg"
        title="Candidate is not Verified"
        isMasterProduct={false}
        setOpenPopup={setIsVerified}
        openPopup={isverified}
      >
        <Box minWidth="400px">
          <FormControl>
            <FormLabel id="gender-label">
              The Selected Candidate is not Verified
            </FormLabel>
          </FormControl>
          <Box sx={{ mt: 2, display: "flex", justifyContent: "flex-end" }}>
            <Button
              variant="outlined"
              className="mpl-secondary-btn"
              onClick={() => setIsVerified(false)}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              sx={{ ml: 2 }}
              className="mpl-primary-btn"
              onClick={() => handleVerify()}
            >
              Verify
            </Button>
          </Box>
        </Box>
      </Popup>

      <RejectPopup
        openPopup={reject}
        setOpenPopup={setReject}
        handleRejectClick={handleSubmit}
        errors={errors}
        setErrors={setErrors}
        setValues={setInputData}
        values={inputData}
      />
      <AcceptPopup
        hide={true}
        accept={accept}
        setAccept={setAccept}
        handleStageDataLoading={handleStageDataLoading}
        handleValues={handleFieldChange}
        errors={errors}
        values={inputData}
        setErrors={setErrors}
        setValues={setInputData}
        handleRejectClick={handleSubmit}
        stagesData={stagesData}
      />
      <Popup
        maxWidth="lg"
        title="Onboard Candidate"
        isMasterProduct={false}
        setOpenPopup={setIsOnboard}
        openPopup={isonboard}
      >
        <Box minWidth="400px">
          <FormControl>
            <FormLabel id="gender-label">{message}</FormLabel>
          </FormControl>
          <Box sx={{ mt: 2, display: "flex", justifyContent: "center" }}>
            {show === "1" && (
              <>
                <Button
                  variant="outlined"
                  className="mpl-secondary-btn"
                  onClick={handleLater}
                >
                  Later
                </Button>
                <Button
                  variant="contained"
                  sx={{ ml: 2 }}
                  className="mpl-primary-btn"
                  onClick={handleOnboard}
                >
                  Yes
                </Button>
              </>
            )}
            {show === "2" && (
              <Button
                variant="contained"
                className="mpl-primary-btn"
                onClick={() => {
                  setIsOnboard(false);
                  setShow("1");
                  setMessage("Do you want to onboard candidate now?");
                }}
              >
                Ok
              </Button>
            )}
          </Box>
        </Box>
      </Popup>

      <Popup
        maxWidth="lg"
        title="Prepare Offer Letter"
        isMasterProduct={false}
        setOpenPopup={setIsOffer}
        openPopup={isoffer}
      >
        <Box minWidth="400px">
          <FormControl>
            <FormLabel id="gender-label">
              Do you want to Prepare the Offer Letter now?
            </FormLabel>
          </FormControl>
          <Box sx={{ mt: 2, display: "flex", justifyContent: "space-between" }}>
            <span
              style={{
                color: "#1155cc",
                cursor: "pointer",
                textDecoration: "underline",
              }}
              onClick={() => {
                setIsOffer(false);
              }}
            >
              I will do later
            </span>
            <Button
              variant="contained"
              className="mpl-primary-btn"
              onClick={handleOffer}
            // onClick={() => { setIsOffer(false);addSessionData({ key: "offerpopup", value: "true"});}}
            >
              Yes
            </Button>
          </Box>
        </Box>
      </Popup>

      <Popup
        maxWidth="xl"
        title="Prepare Offer Letter"
        fullWidth
        isMasterProduct={true}
        setOpenPopup={setIsOfferForm}
        openPopup={isofferform}
        close={false}
      >
        <OfferLetterResponseStepper
          currentData={currentData}
          currentJobData={currentJobData}
          setIsOfferForm={setIsOfferForm}
          setElements={setElements}
          setCurrentData={setCurrentData}
        />
      </Popup>
      <Popup
        maxWidth="xs"
        title="⚠️ Alert"
        isMasterProduct={true}
        setOpenPopup={setOfferWarning}
        openPopup={offerWarning}
      >
        <Typography sx={{ textAlign: "center", fontSize: "18px" }}>
          Please ensure that you complete the offer generation step to proceed to the Offer Release stage
        </Typography>
      </Popup>
    </>
  );
}
export default KanbanBoard;