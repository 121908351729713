import { Typography, Grid, FormControl, Button, Table, TableHead, TableRow, TableCell, TableBody, Tooltip, TableContainer, Backdrop, CircularProgress } from "@mui/material";
import React, { useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import "../../css/styles.css";
import SelectableSearch from "../../../Base/views/SelectableSearch";
import { GetShiftTimeConfigureAction } from "../../../../../store/Leave/ShiftConfigure/Action";
import { GetWeekDaysAction } from "../../../../../store/Ancillary/WeekDays/Action";
import { GetWeekdatesHelper } from "../../helper/TimeSheet/MyTimeSheetHelper";
import { AddAssignShiftTimeHelper, DeleteAssignShiftTimeHelper, GetAssignShiftTimeHelper, GetWeekHelper, UpdateAssignShiftTimeHelper } from "../../helper/Team/TeamHelper";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import { toast } from "react-toastify";
import { GetReportingEmployeeAction } from "../../../../../store/Leave/ReportingEmployees/Action";
const AssignShift = () => {

    const dispatch = useDispatch();
    const [input, setInput] = useState({ month: "", week: "" });
    const [assignShiftData, setAssignShiftData] = useState([]);
    const [errors, setErrors] = useState({});
    const [disabledShift, setDisabledShift] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [months, setMonths] = useState({ load: true, values: [] });
    const [weekDays, setWeekDays] = useState([]);
    const [employeesData, setEmployeesData] = useState({
        load: true,
        values: [],
    });
    const [shiftData, setShiftData] = useState({
        load: true,
        values: [],
    });
    const [weekoffData, setWeekOffData] = useState({
        load: true,
        values: [],
    });
    const [weekData, setWeekData] = useState({
        load: true,
        values: [],
    });
    const dayMapping = {
        Mon: "Monday",
        Tue: "Tuesday",
        Wed: "Wednesday",
        Thu: "Thursday",
        Fri: "Friday",
        Sat: "Saturday",
        Sun: "Sunday"
    };
    const { shift_time_options } = useSelector(state => state?.LeaveReducer?.ShiftTimeConfigureReducer);
    const { week_days_options } = useSelector(
        (state) => state.AncillaryReducer.WeekDaysReducer
    );
    const { reporting_employee_data } = useSelector(
        (state) => state?.LeaveReducer?.ReportingEmployeeReducer
      );
    const handleEmployees = () => {
        reporting_employee_data &&
            setEmployeesData({ load: false, values: reporting_employee_data });
    };
    const handleShift = () => {
        shift_time_options &&
            setShiftData({ load: false, values: shift_time_options });
    };
    const handleWeekoff = () => {
        week_days_options &&
            setWeekOffData({ load: false, values: week_days_options });
    };
    const getMonths = () => {
        let monthList = Array.from({ length: 12 }, (_, i) => ({
            id: i + 1,
            name: new Date(2000, i).toLocaleString("en-US", { month: "long" })
        }));
        setMonths({ load: false, values: monthList });
    };
    const formatDataStructure = (data) =>{
        return data.map(employee => ({
            employee_id: { employee_id: employee.employee_fk, employee_name: employee.employee_name },
            shift_roster_configuration_id: { shift_roster_configuration_id: employee.shift_configuration_id, shift_name: employee.shift_name },
            week_off_id: Array.isArray(employee.week_off_custom_json)
                ? employee.week_off_custom_json
                : employee.week_off_custom_json
                    ? JSON.parse(employee.week_off_custom_json)
                    : [],
            days: employee.day_shift_mapping_json.reduce((acc, day) => {
                acc[day.day_name] = {
                    shift_roster_configuration_id: day.shift_roster_configuration_id || "",
                    shift_name: day.shift_name || "",
                    in_time: day.in_time || "",
                    out_time: day.out_time || "",
                    pay: day.pay || "",
                    date: day.date || ""
                };
                return acc;
            }, {}),
            shift_roster_id: employee.shift_roster_id
        }));
    }
    useEffect(() => {
        getMonths();
        dispatch(GetReportingEmployeeAction());
        dispatch(GetShiftTimeConfigureAction());
        dispatch(GetWeekDaysAction());

    }, []);
    useEffect(() => {
        if (input?.month?.id) {
            GetWeekHelper({
                month_id: input?.month?.id || null,
                year: new Date().getFullYear()
            }).then((res) => {
                setWeekData({ load: false, values: res?.data?.data });
            });
        }
        else {
            setWeekData({ load: false, values: [] });
        }
        if (input?.month?.id && input?.week?.week_no_id) {
            setIsLoading(true);
            const [start_year, start_month, start_day] = input?.week?.week_start_date.split("-");
            const [end_year, end_month, end_day] = input?.week?.week_end_date.split("-");
            GetWeekdatesHelper({
                start_date_day: start_day,
                start_date_month: start_month,
                start_date_year: start_year,
                end_date_day: end_day,
                end_date_month: end_month,
                end_date_year: end_year,
            }).then((res) => {
                setIsLoading(false);
                const newWeekDays = res?.data?.data?.[0]?.fn_map_days_to_dates || [];
                setWeekDays(newWeekDays);
                setAssignShiftData(prevData =>
                    prevData.map(row => ({
                        ...row,
                        days: Object.fromEntries(
                            newWeekDays.map(day => [
                                day,
                                row.days[day] || { shift_roster_configuration_id: "", shift_name: "", in_time: "", out_time: "", pay: "" }
                            ])
                        )
                    }))
                );
            });
            GetAssignShiftTimeHelper({
                month_id: input?.month?.id || null,
                week_id: input?.week?.week_no_id || null,
                year: new Date().getFullYear()
            }).then((res) => {
                const responseData = res?.data?.data || [];
                const formattedData = formatDataStructure(responseData)
                setAssignShiftData(formattedData);
            });
        }
    }, [input]);
    useEffect(() => {
        const allFieldsEmpty = assignShiftData?.some(
            (shift) =>
                shift?.tempId
        ) 
        setDisabledShift(allFieldsEmpty);
    }, [assignShiftData]);
    const handleInput = (event, value) => {
        const newInput = { ...input };
        if (event === "month") {
            newInput.month = value;
            newInput.week = "";
        } else if (event === "week") {
            newInput.week = value;
        } else {
            newInput[event.target.name] = event.target.value;
        }
        setInput(newInput);
        setAssignShiftData([]);
        if (Object.values(errors).some((res) => res !== "")) {
            validate({ ...input, [event]: value || "" });
        }

        setErrors((prevErrors) => ({
            ...prevErrors,
        }));
    };
    const handleRowChange = (id, field, value) => {
        setAssignShiftData((prev) =>
            prev.map((row, index) => {
                if (index === id) {
                    if (field === "shift_roster_configuration_id") {
                        const selectedShift = shiftData?.values?.find(
                            shift => shift.shift_roster_configuration_id === value?.shift_roster_configuration_id
                        );
                        return {
                            ...row,
                            [field]: value,
                            shift_name: selectedShift?.shift_name || "",
                            days: Object.fromEntries(
                                Object.keys(row.days).map((day) => [
                                    day,
                                    {
                                        shift_roster_configuration_id: selectedShift?.shift_roster_configuration_id || "",
                                        shift_name: selectedShift?.shift_name || "",
                                        in_time: selectedShift?.in_time || "",
                                        out_time: selectedShift?.out_time || "",
                                        pay: selectedShift?.pay || "",
                                    }
                                ])
                            )
                        };
                    }
                    if (field === "week_off_id") {
                        const newWeekOff = Array.isArray(value) ? value : [];
                        return {
                            ...row,
                            [field]: newWeekOff
                        };
                    }
                    return { ...row, [field]: value };
                }
                return row;
            })
        );
    };
    const handleDayChange = (rowId, day, value) => {
        setAssignShiftData((prev) => prev.map((row, index) =>
            index === rowId ? { ...row, days: { ...row.days, [day]: value } } : row
        ));
    };
    const handleMonth = () => { }
    const handleDelete = async (rowIndex) => {
        const row = assignShiftData[rowIndex];
        if (!row) return;

        try {
            if (row.shift_roster_id) {
                await DeleteAssignShiftTimeHelper({
                    shift_roster_id: row.shift_roster_id,
                    month_id: input?.month?.id || null,
                    week_id: input?.week?.week_no_id || null,
                    year: new Date().getFullYear()
                });
            }
            setAssignShiftData(prev => prev.filter((_, index) => index !== rowIndex));

        } catch (error) {
            toast.error("Delete failed");
        }
    };
    const handleAdd = () => {
        if (validate(input)) {
            setAssignShiftData(prevData => [
                ...prevData,
                {
                    tempId: Date.now(),
                    employee_id: "",
                    shift_roster_configuration_id: "",
                    week_off_id: "",
                    days: Object.fromEntries(
                        weekDays.map(day => [
                            day,
                            { shift_roster_configuration_id: "", shift_name: "", in_time: "", out_time: "", pay: "" }
                        ])
                    )
                }
            ]);
        }
    };
    const validate = (fieldValues) => {
        let temp = { ...errors };
        if ("month" in fieldValues) {
            temp.month =
                fieldValues.month === "" ? "Month is required" : "";
        }
        if ("week" in fieldValues) {
            temp.week =
                fieldValues.week === "" ? "Week is required" : "";
        }
        setErrors({
            ...temp,
        });

        return Object.values(temp).every((x) => x === "");
    };
    const handleSubmit = async (rowIndex) => {
        const row = assignShiftData[rowIndex];
        if (!row) return console.error("Row not found!");
        const weekDayMap = {
            "Sunday": "Sun", "Monday": "Mon", "Tuesday": "Tue",
            "Wednesday": "Wed", "Thursday": "Thu", "Friday": "Fri", "Saturday": "Sat"
        };
        const weekOffDays = Array.isArray(row.week_off_id) && row.week_off_id.length > 0
            ? row.week_off_id.map(wo => weekDayMap[wo.week_day_name])
            : [];
        const shiftRosterId = row.shift_roster_id || null;
        const dayShiftData = Object.keys(row.days).map(day => {
            const dayName = day.split(" ")[0];
            const isWeekOff = weekOffDays.includes(dayName);
            return isWeekOff
                ? { day_name: day, shift_roster_configuration_id: "", shift_name: "", in_time: "", out_time: "", pay: "", date: "" }
                : {
                    day_name: day,
                    shift_roster_configuration_id: row.days[day]?.shift_roster_configuration_id || "",
                    shift_name: row.days[day]?.shift_name || "",
                    in_time: row.days[day]?.in_time || "",
                    out_time: row.days[day]?.out_time || "",
                    pay: row.days[day]?.pay || "",
                    date: day.split(" ")[1] ? String(Number(day.split(" ")[1])) : ""
                };
        });
        const formattedRow = {
            shift_roster_id: shiftRosterId,
            employee_id: row.employee_id?.employee_id || null,
            shift_roster_configuration_id: row.shift_roster_configuration_id?.shift_roster_configuration_id || null,
            week_off_custom_json: weekOffDays.length > 0 ? JSON.stringify(
                row.week_off_id.map(wo => ({
                    week_day_pk: wo.week_day_pk,
                    week_day_name: wo.week_day_name
                }))
            ) : null,
            day_shift_mapping_json: JSON.stringify(dayShiftData)
        };
        if (!formattedRow.employee_id) {
            toast.error("Employee ID is required!");
            return;
        }
        const hasValidShift = dayShiftData.some(shift =>
            shift.day_name && (shift.shift_name || shift.in_time || shift.out_time)
        );
        if (!hasValidShift) {
            toast.error("At least one valid shift must be assigned!");
            return;
        }
        try {
            let response;
            if (shiftRosterId) {
                response = await UpdateAssignShiftTimeHelper({
                    shift_roster_id: shiftRosterId,
                    month_id: input?.month?.id || null,
                    week_id: input?.week?.week_no_id || null,
                    year: new Date().getFullYear(),
                    employee_id: formattedRow.employee_id,
                    shift_configuration_id: formattedRow.shift_roster_configuration_id,
                    week_off_custom_json: formattedRow.week_off_custom_json,
                    day_shift_mapping_json: formattedRow.day_shift_mapping_json
                });
            } else {
                response = await AddAssignShiftTimeHelper({
                    month_id: input?.month?.id || null,
                    week_id: input?.week?.week_no_id || null,
                    year: new Date().getFullYear(),
                    employee_id: formattedRow.employee_id,
                    shift_configuration_id: formattedRow.shift_roster_configuration_id,
                    week_off_custom_json: formattedRow.week_off_custom_json,
                    day_shift_mapping_json: formattedRow.day_shift_mapping_json
                });
            }
            const responseData = response?.data?.data || [];
            const formattedData = formatDataStructure(responseData)
            if (shiftRosterId) {
                const filtTempData = assignShiftData?.filter(el=> el?.tempId)
                setAssignShiftData([...formattedData, ...filtTempData]);
            }
            else {
                setAssignShiftData(formattedData);
            }
        } catch (error) {
            toast.error("Failed to save shift!");
        }
    };

    return (
        <>
            {
                isLoading && <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={true}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            }
            <Grid sx={{ display: "flex", flexDirection: { xs: "column", sm: "row" }, gap: 1 }} pt={3} pb={2} container>
                <Grid pr={1} item xs={12} sm={3.5}>
                    <FormControl fullWidth>
                        <SelectableSearch
                            label="Month"
                            value={input?.month}
                            fieldLabel="name"
                            loading={() => handleMonth()}
                            required
                            name="month"
                            size="small"
                            onChangeEvent={handleInput}
                            data={months}
                            {...(errors.month && {
                                error: true,
                                helperText: errors.month,
                            })}
                        />
                    </FormControl>
                </Grid>
                <Grid pr={1} item xs={12} sm={3.5}>
                    <FormControl fullWidth>
                        <SelectableSearch
                            isOptionEqualToValue={(option, value) =>
                                +option.week_no_id ===
                                +value.week_no_id
                            }
                            label="Week"
                            value={input?.week}
                            loading={() =>
                                setWeekData({
                                    load: false,
                                    values: [],
                                })
                            }
                            required
                            fieldLabel="label"
                            variant={"outlined"}
                            name="week"
                            size="small"
                            onChangeEvent={handleInput}
                            data={weekData}
                            {...(errors.week && {
                                error: true,
                                helperText: errors.week,
                            })}
                        />
                    </FormControl>
                </Grid>
                <Grid pr={1} item xs={12} sm={3.5}>
                    <Button
                        disabled={disabledShift}
                        onClick={handleAdd}
                        className="mpl-primary-btn"
                    >
                        + Assign Shift
                    </Button>
                </Grid>
                {assignShiftData.length > 0 && (
                    <TableContainer sx={{
                        maxHeight: "70vh",
                        overflowY: "auto",
                        overflowX: "auto",
                        minWidth: "100%",
                        maxWidth: "100px"
                    }}>
                        <Table
                            sx={{
                                border: "1px solid #ddd",
                                marginTop: "20px",
                                width: "100%",
                            }}
                            size="small"
                        >
                            <TableHead>
                                <TableRow>
                                    <TableCell
                                        sx={{
                                            fontWeight: "bold",
                                            border: "1px solid #ddd",
                                            backgroundColor: "#8b44ff",
                                            color: "#fff",
                                            textAlign: "center",
                                            whiteSpace: "nowrap",
                                            overflow: "hidden",
                                            p: 1,
                                            width: "auto",
                                            minWidth: "200px",
                                        }}
                                    >
                                        Employee
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            fontWeight: "bold",
                                            border: "1px solid #ddd",
                                            backgroundColor: "#8b44ff",
                                            color: "#fff",
                                            textAlign: "center",
                                            whiteSpace: "nowrap",
                                            overflow: "hidden",
                                            p: 1,
                                            width: "auto",
                                            minWidth: "200px",
                                        }}
                                    >
                                        Shift Name
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            fontWeight: "bold",
                                            border: "1px solid #ddd",
                                            backgroundColor: "#8b44ff",
                                            color: "#fff",
                                            textAlign: "center",
                                            whiteSpace: "nowrap",
                                            overflow: "hidden",
                                            p: 1,
                                            width: "auto",
                                            minWidth: "200px",
                                        }}
                                    >
                                        Week Off
                                    </TableCell>
                                    {Object.keys(assignShiftData[0]?.days || {}).map((day, index) => (
                                        <TableCell
                                            key={index}
                                            sx={{
                                                fontWeight: "bold",
                                                border: "1px solid #ddd",
                                                backgroundColor: "#8b44ff",
                                                color: "#fff",
                                                textAlign: "center",
                                                whiteSpace: "nowrap",
                                                overflow: "hidden",
                                                p: 1,
                                                width: "auto",
                                                minWidth: "200px",
                                            }}
                                        >
                                            {day}
                                        </TableCell>
                                    ))}
                                    <TableCell
                                        sx={{
                                            fontWeight: "bold",
                                            border: "1px solid #ddd",
                                            backgroundColor: "#8b44ff",
                                            color: "#fff",
                                            textAlign: "center",
                                            whiteSpace: "nowrap",
                                            overflow: "hidden",
                                            width: "5%",
                                        }}
                                    >
                                        Action
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {assignShiftData.map((row, rowIndex) => (
                                    <TableRow key={rowIndex}>
                                        <TableCell sx={{ border: "1px solid #ddd", p: 1, textAlign: "center", whiteSpace: "nowrap", overflow: "hidden" }}>
                                            <SelectableSearch
                                                fullWidth
                                                variantType="standard"
                                                size="small"
                                                loading={() => handleEmployees()}
                                                value={row.employee_id}
                                                fieldLabel="employee_name"
                                                name="employee_id"
                                                onChangeEvent={(e, v) => handleRowChange(rowIndex, "employee_id", v)}
                                                data={employeesData}
                                            />
                                        </TableCell>
                                        <TableCell sx={{ border: "1px solid #ddd", p: 1, textAlign: "center", whiteSpace: "nowrap", overflow: "hidden" }}>
                                            <SelectableSearch
                                                fullWidth
                                                variantType="standard"
                                                loading={() => handleShift()}
                                                size="small"
                                                value={row.shift_roster_configuration_id}
                                                fieldLabel="shift_name"
                                                name="shift_roster_configuration_id"
                                                onChangeEvent={(e, v) => handleRowChange(rowIndex, "shift_roster_configuration_id", v)}
                                                data={shiftData}
                                            />
                                        </TableCell>
                                        <TableCell sx={{ border: "1px solid #ddd", p: 1, textAlign: "center", whiteSpace: "nowrap", overflow: "hidden" }}>
                                            <SelectableSearch
                                                fullWidth
                                                isOptionEqualToValue={(option, value) => +option.week_day_pk === +value.week_day_pk}
                                                variantType="standard"
                                                multiple={true}
                                                loading={handleWeekoff}
                                                size="small"
                                                value={Array.isArray(row.week_off_id) ? row.week_off_id : []}
                                                fieldLabel="week_day_name"
                                                name="week_off_id"
                                                onChangeEvent={(e, v) => handleRowChange(rowIndex, "week_off_id", v)}
                                                data={weekoffData}
                                                limitTags={1}
                                            />
                                        </TableCell>
                                        {Object.keys(row.days).map((day, index) => {
                                            const dayName = day.split(" ")[0];
                                            const fullDayName = dayMapping[dayName] || dayName;
                                            const weekOffArray = Array.isArray(row.week_off_id) ? row.week_off_id : [];
                                            const isWeekOff = weekOffArray.some((wo) => wo.week_day_name === fullDayName);
                                            return (
                                                <TableCell key={index} sx={{ border: "1px solid #ddd", p: 1, textAlign: "center", whiteSpace: "nowrap", overflow: "hidden" }}>
                                                    {isWeekOff ? (
                                                        <Typography sx={{ color: "red", fontWeight: "bold", textAlign: "center" }}>WO</Typography>
                                                    ) : (
                                                        <SelectableSearch
                                                            fullWidth
                                                            loading={() => handleShift()}
                                                            variantType="standard"
                                                            size="small"
                                                            value={row.days[day]}
                                                            fieldLabel="shift_name"
                                                            onChangeEvent={(e, v) => handleDayChange(rowIndex, day, v)}
                                                            data={shiftData}
                                                        />
                                                    )}
                                                </TableCell>
                                            );
                                        })}
                                        <TableCell sx={{ borderTop: "1px solid #ddd", p: 1.5, textAlign: "center", display: "flex", justifyContent: "center", alignItems: "center", gap: "10px" }}>
                                            <Tooltip title={row.shift_roster_id ? "Update" : "Add"} arrow>
                                                {row.shift_roster_id ? (
                                                    <EditIcon
                                                        sx={{
                                                            cursor: "pointer",
                                                            color: "primary.main",
                                                            fontSize: "1.2rem",
                                                        }}
                                                        onClick={() => handleSubmit(rowIndex)}
                                                    />
                                                ) : (
                                                    <CheckCircleOutlineIcon
                                                        sx={{
                                                            cursor: "pointer",
                                                            color: "green",
                                                            fontSize: "1.2rem",
                                                        }}
                                                        onClick={() => handleSubmit(rowIndex)}
                                                    />
                                                )}
                                            </Tooltip>
                                            <Tooltip title="Delete" arrow>

                                                {row.shift_roster_id ? (
                                                    <DeleteIcon
                                                        sx={{
                                                            cursor: "pointer",
                                                            color: "red",
                                                            fontSize: "1.2rem",
                                                        }}
                                                        onClick={() => handleDelete(rowIndex)}
                                                    />
                                                ) : (
                                                    <CloseIcon
                                                        sx={{
                                                            cursor: "pointer",
                                                            color: "red",
                                                            fontSize: "1.2rem",
                                                        }}
                                                        onClick={() => handleDelete(rowIndex)}
                                                    />
                                                )}

                                            </Tooltip>
                                        </TableCell>
                                    </TableRow>
                                ))}
                                <TableRow>
                                    <TableCell colSpan={Object.keys(assignShiftData[0]?.days || {}).length + 3} sx={{ textAlign: "left", border: "1px solid #ddd", p: 1 }}>
                                        <Typography
                                            sx={{
                                                color: disabledShift ? "#ccc" : "#8b44ff",
                                                fontWeight: "bold",
                                                cursor: disabledShift ? "default" : "pointer",
                                                "&:hover": disabledShift ? {} : { textDecoration: "underline" },
                                                pointerEvents: disabledShift ? "none" : "auto",
                                            }}
                                            onClick={disabledShift ? undefined : handleAdd}
                                        >
                                            + Add Row...
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}


            </Grid >
        </>

    )
}

export default AssignShift;