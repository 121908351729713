import { Box, Button, IconButton } from '@mui/material';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import MaterialTable from 'material-table';
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import Popup from "../../../Base/views/Popup"
import LeaveTypesPopup from './LeaveTypesPopup';
import { useState } from 'react';
import ConfirmationDialog from '../../../Base/views/ConfirmationDialog';
import { DeleteLeaveTypeAction } from '../../../../../store/Leave/LeaveType/Action';
import "../../css/styles.css"

const LeaveTypes = () => {

    const dispatch = useDispatch();
    const { leavetypes_options } = useSelector(state => state.LeaveReducer?.LeaveTypeReducer);

    const [editedRecord, setEditedRecord] = useState(null);
    const [openPopup, setOpenPopup] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [currentData, setCurrentData] = useState({});

    const handleEdit = (rowData) => {
        setEditedRecord(rowData);
        setOpenPopup(true);
    };

    const handleConfirmDelete = (rowData) => {
        setOpenDialog(true);
        setCurrentData(rowData)
    };

    const handleAdd = () => {
        setEditedRecord()
        setOpenPopup(true);
    };

    const handleDelete = () => {
        setOpenDialog(false);
        dispatch(DeleteLeaveTypeAction({ leavetype_id: `{${currentData?.leavetype_id}}` }))
    };

    const addMode = (resetForm) => {
        resetForm();
        setEditedRecord(null);
        setOpenPopup(false);
    };

    const addButton = <Button onClick={handleAdd} variant="contained" className="mpl-primary-btn" startIcon={<AddIcon />} >
        Add
    </Button>

    return (
        <Box>
            <MaterialTable
                columns={[
                    { title: "Name", field: "name", cellStyle: { padding: "0px 10px 0px 0px" } },
                    { title: "Code", field: "code", cellStyle: { padding: "0px 10px 0px 0px" } },
                    { title: "Allotted Days", field: "actual_days", cellStyle: { padding: "0px 10px 0px 0px" } },
                    { title: "Carry Forward Days", field: "noof_cfwd_days", cellStyle: { padding: "0px 10px 0px 0px", textAlign: "center" } },
                    {
                        title: "Carry Forward?", field: "cfwd_leave", cellStyle: { padding: "0px 10px 0px 0px", textAlign: "center" },
                        render: rowData => {
                            return rowData.cfwd_leave ? <TaskAltIcon sx={{ color: "rgb(86, 202, 0)" }} />
                                : <HighlightOffIcon sx={{ color: "rgb(255, 76, 81)" }} />
                        }
                    },
                    {
                        title: "Second Approval", field: "req_second_approval", cellStyle: { padding: "0px 10px 0px 0px", textAlign: "center" },
                        render: rowData => {
                            return rowData.req_second_approval ? <TaskAltIcon sx={{ color: "rgb(86, 202, 0)" }} />
                                : <HighlightOffIcon sx={{ color: "rgb(255, 76, 81)" }} />
                        }
                    },
                    {
                        title: "Unlimited", field: "is_unlimited", cellStyle: { padding: "0px 10px 0px 0px", textAlign: "center" },
                        render: rowData => {
                            return rowData.is_unlimited ? <TaskAltIcon sx={{ color: "rgb(86, 202, 0)" }} />
                                : <HighlightOffIcon sx={{ color: "rgb(255, 76, 81)" }} />
                        }
                    },
                    {
                        title: "Gender Based", field: "gender_based", cellStyle: { padding: "0px 10px 0px 0px", textAlign: "center" },
                        render: rowData => {
                            return rowData.gender_based ? <TaskAltIcon sx={{ color: "rgb(86, 202, 0)" }} />
                                : <HighlightOffIcon sx={{ color: "rgb(255, 76, 81)" }} />
                        }
                    },
                ]}
                data={leavetypes_options || []}
                options={{
                    maxBodyHeight: 385,
                    rowStyle: { fontSize: "13px" },
                    headerStyle: { padding: "0px 10px 0px 0px", fontWeight: "bold" },
                    sorting: true,
                    search: true,
                    searchText: "",
                    searchFieldAlignment: "right",
                    searchAutoFocus: true,
                    searchFieldVariant: "standard",
                    filtering: false,
                    paging: true,
                    pageSizeOptions: [25, 50],
                    pageSize: 25,
                    paginationType: "stepped",
                    showFirstLastPageButtons: false,
                    paginationPosition: "bottom",
                    exportButton: false,
                    exportAllData: true,
                    addRowPosition: "first",
                    selection: false,
                    actionsColumnIndex: -1,
                    showSelectAllCheckbox: true,
                    showTextRowsSelected: true,
                    grouping: false,
                    columnsButton: true,
                }}
                onSelectionChange={(rows) => { return (rows.filter(row => row?.status === "Submitted")) }}
                actions={[
                    {
                        icon: () => <IconButton sx={{ padding: "0px" }}><EditIcon /></IconButton>,
                        tooltip: "Edit",
                        onClick: (_event, rowData) => { handleEdit(rowData) },
                    },
                    {
                        icon: () => <IconButton sx={{ padding: "0px" }}><DeleteIcon /></IconButton>,
                        tooltip: "Delete",
                        onClick: (_event, rowData) => { handleConfirmDelete(rowData) },
                    },
                ]}
                title={addButton}
            />
            <Popup
                title={editedRecord ? "Edit Leave Type" : "Add Leave Type"}
                openPopup={openPopup}
                setOpenPopup={setOpenPopup}
            >
                <LeaveTypesPopup editedRecord={editedRecord} addMode={addMode} setOpenPopup={setOpenPopup} />
            </Popup>
            <ConfirmationDialog
                title="Do you wish to proceed?"
                openDialog={openDialog}
                closeDialog={setOpenDialog}
                popupTitle="Country Management"
                OkButtonText="Delete"
                onSubmit={handleDelete}
            />
        </Box>
    );
}

export default LeaveTypes;
