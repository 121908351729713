import NProgress from "nprogress";
import axiosInstance from "../../../../axiosInstance";
import {
  HRMS_UTILITY_API_URL,
  ADMIN_WORKSPACE,
} from "../../../Constants/baseConstant";
import { toast } from "react-toastify";
import { validateEmailHelper } from "../../SignIn/helper/SigninHelper";

export const GetMeeplAdminHelper = async (params) => {
  NProgress.start();
  return await axiosInstance(`/meepladmin/getRequestDemo`, {
    method: "POST",
    baseURL: HRMS_UTILITY_API_URL,
    data: {
      domain_name: ADMIN_WORKSPACE,
      country_code: params.countryCode
    }
  })
    .then((res) => {
      NProgress.done();
      return { message: "Success", hasError: false, data: res?.data };
    })
    .catch((err) => {
      NProgress.done();
      return { message: "Error", hasError: true, data: err?.response?.data };
    });
};

export const GetSchemaOwnerUser = async (params) => {
  NProgress.start();
  return await axiosInstance(`/meepladmin/getSchemaOwnerUser`, {
    method: "POST",
    baseURL: HRMS_UTILITY_API_URL,
    data: {
      domain_name: params?.domain_name,
    },
  })
    .then((res) => {
      NProgress.done();
      validateEmailHelper({ email: res.data?.data?.[0]?.email, first_name: res.data?.data?.[0]?.first_name, domain: params?.domain_name, message: "Password Reset Mail Sent Successfully" })
      return { message: "Success", hasError: false, data: res?.data };
    })
    .catch((err) => {
      NProgress.done();
      err?.response?.data?.error?.[0]?.message && toast.error(err?.response?.data?.error?.[0]?.message)
      return { message: "Error", hasError: true, data: err?.response?.data };
    });
};


export const GetMeeplAdminUsersHelper = async (params) => {
  NProgress.start();
  return await axiosInstance(`/meepladmin/getMeeplAdminUser`, {
    method: "POST",
    baseURL: HRMS_UTILITY_API_URL,
    data: {
      domain_name: ADMIN_WORKSPACE,
      country_code: params?.country_code,
    },
  })
    .then((res) => {
      NProgress.done();
      return { message: "Success", hasError: false, data: res?.data };
    })
    .catch((err) => {
      NProgress.done();
      return { message: "Error", hasError: true, data: err?.response?.data };
    });
};


export const AddMeeplAdminUsersHelper = async (params) => {
  NProgress.start();
  return await axiosInstance(`/meepladmin/addMeeplAdminUser`, {
    method: "POST",
    baseURL: HRMS_UTILITY_API_URL,
    data: {
      domain_name: ADMIN_WORKSPACE,
      user_type: params?.user_type,
      first_name: params?.first_name,
      last_name: params?.last_name || "",
      email_id: params?.email_id,
      contact_no: params?.contact_no || "",
      country_code: params?.country_code
    },
  })
    .then((res) => {
      NProgress.done();
      validateEmailHelper({ domain: ADMIN_WORKSPACE, email: params?.email_id })
      return { message: "Success", hasError: false, data: res?.data };
    })
    .catch((err) => {
      NProgress.done();
      return { message: "Error", hasError: true, data: err?.response?.data };
    });
};

export const UpdateMeeplAdminUsersHelper = async (params) => {
  NProgress.start();
  return await axiosInstance(`/meepladmin/updateMeeplAdminUser`, {
    method: "POST",
    baseURL: HRMS_UTILITY_API_URL,
    data: {
      domain_name: ADMIN_WORKSPACE,
      user_type: params?.user_type,
      user_id: params?.ats_users_id,
      first_name: params?.first_name,
      last_name: params?.last_name || "",
      email_id: params?.email_id,
      contact_no: params?.contact_no || "",
      phone_code: params?.country_code,
    },
  })
    .then((res) => {
      NProgress.done();
      return { message: "Success", hasError: false, data: res?.data };
    })
    .catch((err) => {
      NProgress.done();
      return { message: "Error", hasError: true, data: err?.response?.data };
    });
};
