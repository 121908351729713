import { useEffect } from "react";
import MaterialTable from "material-table";
import {
    Box,
    CircularProgress,
} from "@mui/material";
import { useSelector } from "react-redux";
import { useState } from "react";
import { useDispatch } from "react-redux";
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { GetEmployeesAction } from "../../../../../store/Employee/Employee/Action";
import EmployeePayroleTemplate from "./EmployeePayroleTemplate";
import Base from "../../../Base/views/Base";
import { ConvertDateTime } from "../../../Base/helper/baseFunctions";
import Popup from "../../../Base/views/Popup";
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { getExportEmployeeDataHelper } from "../../helper/ProfileHelper";

const MyEmployeePayrole = () => {
    const dispatch = useDispatch();
    const [openTemplate, setOpenTemplate] = useState(false);
    const [currentData, setCurrentData] = useState("")
    const [selectedRows, setSelectedRows] = useState([]);
    const [isExporting, setIsExporting] = useState(false);
    const { employees_options } = useSelector(
        (state) => state?.EmployeeReducer.EmployeesReducer
    );
    
    useEffect(() => {
        dispatch(GetEmployeesAction({ is_active: true }));
    }, []);

    const hanldeEdit = (data) => {
        setOpenTemplate(true);
        setCurrentData(data);
    };

    const getSelectedEmployeeIds = async() => {
        try {
            const selectedEmployeeIds = `{${selectedRows?.map((item) => item.employee_id).join(",")}}`;
            const res = await getExportEmployeeDataHelper({ employees_id: selectedEmployeeIds !== '{}' ? selectedEmployeeIds : null });
    
            if (res?.data?.statuscode === 200) {
                return res?.data?.data; 
            } 
        } finally {
            setIsExporting(false);
        }
    }

    const handleExportSelected = async() => {
        setIsExporting(true);
        const selectedEmp =  await getSelectedEmployeeIds();
        const headers = [
            { label: "#", key: "serial_number" },
            { label: "Emp No", key: "emp_no" },
            { label: "First Name", key: "first_name" },
            { label: "Last Name", key: "last_name" },
            { label: "Designation", key: "designation" },
            { label: "Department", key: "department" },
            { label: "Reporting Manager", key: "reporting_manager" },
            { label: "Work Location", key: "work_location" },
            { label: "Date of Joining", key: "doj" },
            { label: "UAN Number", key: "uan" },
            { label: "Overtime Eligibility", key: "overtime_eligibility" },
            { label: "Basic", key: "basic" },
            { label: "Dearness Allowance", key: "Dearness_Allowance" },
            { label: "Variable Dearness Allowance", key: "Variable_Dearness_Allowance" },
            { label: "HRA", key: "hra" },
            { label: "Other Allowances", key: "other_allowances" },
            { label: "Monthly Gross Salary", key: "Monthly_Gross_Salary" },
            { label: "EPF Employer Contribution", key: "EPF_Employer_Contribution" },
            { label: "ESI Employer Contribution", key: "ESI_Employer_Contribution" },
            { label: "LTA", key: "Annual_LTA" },
            { label: "Medical Allowance", key: "Annual_Medical_Allowance" },
            { label: "Variable Pay", key: "Annual_Variable_Pay" },
            { label: "Annual CTC", key: "Annual_CTC" },
            { label: "Gratuity", key: "gratuity" }
        ];
        
        const csvData = [headers.map(header => header.label)];
        
        selectedEmp?.forEach((row, index) => {
          const rowData = headers.map(header => {
            if (header.key === "serial_number") return index + 1; 
            return row?.employee_payroll_report?.[0]?.[header.key] ?? ""; 
        });
          csvData.push(rowData);
        });

        const csv = csvData.map(row => row.join(",")).join("\n");
        const blob = new Blob([csv], { type: "text/csv" });
        const url = URL.createObjectURL(blob);
        const downloadLink = document.createElement("a");
        downloadLink.href = url;
        downloadLink.download = "Employees Salary Master Report.csv";
        downloadLink.setAttribute("target", "_blank");
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
        URL.revokeObjectURL(url);
        setIsExporting(false);
    };
    
    return (
        <>
            <Base>
                <Box sx={{ maxHeight: "87vh", overflowY: "auto" }}>
                    <MaterialTable
                        onRowClick={(event, rowData) => hanldeEdit(rowData)}
                        columns={[
                            {
                                title: "Name",
                                cellStyle: { padding: "10px 10px 10px 13px" },
                                field: "name",
                                grouping: false,
                            },
                            {
                                title: "Email",
                                cellStyle: { padding: "10px 10px 10px 13px" },
                                field: "office_email",
                                grouping: false,
                            },
                            {
                                title: "Joining Date",
                                cellStyle: { padding: "10px 10px 10px 13px" },
                                field: "doj",
                                render: (rowData) => {
                                    return ConvertDateTime(rowData.doj);
                                },
                            },
                            {
                                title: "Payroll Setup", field: "payroll_setup_flag",headerStyle:{ textAlign:"left"}, cellStyle: { padding: "10px 10px 10px 35px", textAlign:"left" },
                                render: rowData => {
                                    return rowData.payroll_setup_flag ? <TaskAltIcon sx={{ color: "rgb(86, 202, 0)" }} />
                                        : <HighlightOffIcon sx={{ color: "rgb(255, 76, 81)" }} />
                                }
                            },
                        ]}
                        data={employees_options}
                        options={{
                            maxBodyHeight: "65vh",
                            minBodyHeight: "65vh",
                            rowStyle: { fontSize: "13px" },
                            headerStyle: { padding: "10px 10px 10px 13px", fontWeight: "bold" },
                            sorting: true,
                            search: true,
                            searchText: "",
                            searchFieldAlignment: "right",
                            searchAutoFocus: true,
                            searchFieldVariant: "standard",
                            filtering: false,
                            paging: true,
                            pageSizeOptions: [25, 50],
                            pageSize: 25,
                            paginationType: "stepped",
                            showFirstLastPageButtons: false,
                            paginationPosition: "bottom",
                            exportButton: false,
                            exportAllData: false,
                            exportFileName: "Employees",
                            addRowPosition: "first",
                            selection: true,
                            actionsColumnIndex: -1,
                            showSelectAllCheckbox: true,
                            showTextRowsSelected: true,
                            grouping: false,
                            columnsButton: false,
                        }}
                        onSelectionChange={(rows) => setSelectedRows(rows)}
                        actions={[
                            {
                                icon: () => (isExporting ? <CircularProgress size={24} /> : <CloudDownloadIcon />),
                                tooltip: 'Export',
                                onClick: () => handleExportSelected(),
                                disabled: isExporting,
                                hidden: selectedRows.length === 0,
                            },
                        ]}
                        title="Employees"
                    />
                    <Popup
                        maxWidth="md"
                        fullWidth={true}
                        title={`Payroll Configuration - ${currentData?.name}`}
                        openPopup={openTemplate}
                        setOpenPopup={setOpenTemplate}
                    >
                        <EmployeePayroleTemplate emp={true} createEmp={false} setOpenTemplate={setOpenTemplate} openTemplate={openTemplate} currentData={currentData} />
                    </Popup>
                </Box>
            </Base>
        </>
    );
};

export default MyEmployeePayrole;