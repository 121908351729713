import { useState, useEffect } from "react";
import Popup from "../../../Base/views/Popup";
import ConfirmationDialog from "../../../Base/views/ConfirmationDialog";
import { useDispatch, useSelector } from "react-redux";
import {
  DeleteEmployeeEducationAction,
  GetEmployeeEducationAction,
} from "../../../../../store/Employee/ProfessionalSummary/Action";
import EducationModalForm from "./EducationModalForm";
import MaterialTable from "material-table";
import AddIcon from "@mui/icons-material/Add";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { getSignedUrl } from "@aws-sdk/s3-request-presigner";
import { GetObjectCommand } from "@aws-sdk/client-s3";
import { toast } from "react-toastify";
import { s3Client } from "../../../Base/helper/baseFunctions";
import {
  Button,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { Box } from "@mui/system";
import { getUserData } from "../../../Base/helper/baseFunctions";

export default function EducationPage({
  disableEdit,
  OnboardingEmpData,
  newdata,
  currentMode,
}) {
  const userData = getUserData();
  const dispatch = useDispatch();
  const { educationData } = useSelector(
    (state) => state?.EmployeeReducer.EmployeeProfessionalSummaryReducer
  );
  const [editedRecord, setEditedRecord] = useState(null);
  const [openPopup, setOpenPopup] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [currentData, setCurrentData] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const initialFValues = {
    studylevel_id: "",
    studyprogram_id: "",
    passingyear: "",
    institution: "",
    board_unv: "",
    mode: "",
    result: "",
    proof: "",
  };
  const [educationModalData, setEducationModalData] = useState(initialFValues);

  const openMoreVertIcon = Boolean(anchorEl);
  const handleMoreVertIconClose = () => {
    setAnchorEl(null);
  };
  const handleMoreVertIconClick = (event, row) => {
    setCurrentData(row);
    setAnchorEl(event.currentTarget);
  };


  const handleDelete = () => {
    setOpenDialog(false);
    var id = [];
    if (Array.isArray(currentData)) {
      currentData.forEach((item) => {
        id.push(item.emp_edu_id);
      });
      if (currentMode === "addemployee") {
        dispatch(
          DeleteEmployeeEducationAction({
            id: `{${id}}`,
            employee_id: newdata?.employee_id,
          })
        );
      } else {
        dispatch(
          DeleteEmployeeEducationAction({
            id: `{${id}}`,
            employee_id: OnboardingEmpData?.employee_id,
          })
        );
      }
    } else if (currentMode === "addemployee") {
      dispatch(
        DeleteEmployeeEducationAction({
          id: `{${currentData?.emp_edu_id}}`,
          employee_id: newdata?.employee_id,
        })
      );
    } else {
      dispatch(
        DeleteEmployeeEducationAction({
          id: `{${currentData?.emp_edu_id}}`,
          employee_id: OnboardingEmpData?.employee_id,
        })
      );
    }
  };

  const handleBulkDelete = (selectedRow) => {
    setCurrentData(selectedRow);
  };

  const addMode = (resetForm) => {
    resetForm();
    setEditedRecord(null);
    setOpenPopup(false);
  };

  const handleEdit = (rowData) => {
    setAnchorEl(false);
    setEditedRecord(rowData);
    if (OnboardingEmpData && currentMode === "editemployee") {
      setEducationModalData({
        ...rowData,
        domain: userData?.domain,
        employee_id: OnboardingEmpData?.employee_id,
        studylevel_id: {
          studylevel_id: rowData?.studylevel_id,
          name: rowData?.studylevel,
        },
        studyprogram_id: {
          studyprogram_id: rowData?.studyprogram_id,
          name: rowData?.subprogram,
        },
        passingyear: { id: "1", year: rowData?.passingyear },
      });
    } else if (currentMode === "addemployee") {
      setEducationModalData({
        ...rowData,
        domain: userData?.domain,
        employee_id: newdata?.employee_id,
        studylevel_id: {
          studylevel_id: rowData?.studylevel_id,
          name: rowData?.studylevel,
        },
        studyprogram_id: {
          studyprogram_id: rowData?.studyprogram_id,
          name: rowData?.subprogram,
        },
        passingyear: { id: "1", year: rowData?.passingyear },
      });
    } else {
      setEducationModalData({
        ...rowData,
        domain: userData?.domain,
        employee_id: userData?.employee_id,
        studylevel_id: {
          studylevel_id: rowData?.studylevel_id,
          name: rowData?.studylevel,
        },
        studyprogram_id: {
          studyprogram_id: rowData?.studyprogram_id,
          name: rowData?.subprogram,
        },
        passingyear: { id: "1", year: rowData?.passingyear },
      });
    }
    setOpenPopup(true);
  };

  const handleConfirmDelete = (rowData) => {
    setAnchorEl(false);
    setOpenDialog(true);
    setCurrentData(rowData);
  };

  const handleView = async (event, rowData) => {
    event.stopPropagation();
    const bucketParams = {
      Bucket: process.env.REACT_APP_DO_BUCKET_NAME,
      Key: rowData?.proof,
    };

    try {
      const url = await getSignedUrl(
        s3Client,
        new GetObjectCommand(bucketParams),
        { expiresIn: 3 * 60 }
      );

      if (url) {
        window.open(url, "_blank");
      }
    } catch (err) {
      toast.error(err.message || "An error occurred while opening the document.");
    }
  };

  const handleAdd = () => {
    setEditedRecord(null);
    if (OnboardingEmpData && currentMode === "editemployee") {
      setEducationModalData({
        ...initialFValues,
        domain: userData?.domain,
        employee_id: OnboardingEmpData?.employee_id,
      });
    } else if (currentMode === "addemployee") {
      setEducationModalData({
        ...initialFValues,
        domain: userData?.domain,
        employee_id: newdata?.employee_id,
      });
    } else {
      setEducationModalData({
        ...initialFValues,
        domain: userData?.domain,
        employee_id: userData?.employee_id,
      });
    }

    setOpenPopup(true);
  };

  useEffect(() => {
    if (OnboardingEmpData && currentMode === "editemployee") {
      dispatch(
        GetEmployeeEducationAction({
          domain: userData?.domain,
          employee_id: OnboardingEmpData?.employee_id,
        })
      );
    } else if (currentMode === "addemployee") {
      dispatch(
        GetEmployeeEducationAction({
          domain: userData?.domain,
          employee_id: newdata?.employee_id,
        })
      );
    } else {
      dispatch(
        GetEmployeeEducationAction({
          domain: userData?.domain,
          employee_id: userData?.employee_id,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const addButton = (
      <Button
        onClick={handleAdd}
        variant="contained"
        startIcon={<AddIcon />}
        className="mpl-primary-btn"
      >
        Add
      </Button>
  );

  return (
    <Box>
      <MaterialTable
        columns={[
          {
            title: "Study Level",
            field: "studylevel",
            cellStyle: { padding: "10px" },
          },
          {
            title: "Study Program",
            field: "subprogram",
            cellStyle: { padding: "10px" },
          },
          {
            title: "Year of Passing",
            field: "passingyear",
            cellStyle: { padding: "10px" },
          },
          {
            title: "Institution",
            field: "institution",
            cellStyle: { padding: "10px" },
          },
          { title: "Result", field: "result", cellStyle: { padding: "10px" } },
          {
            title: "Document",
            render: rowData => (
              <div>
                {rowData.proof ? (
                  <IconButton
                    onClick={(e) => handleView(e, rowData)}
                  >
                    <VisibilityIcon />
                  </IconButton>
                ) : (
                  <IconButton disabled>
                    <VisibilityOffIcon />
                  </IconButton>
                )}
              </div>
            ),
            cellStyle: { padding: "0px" },
          },
        ]}
        data={educationData}
        onSelectionChange={(selectedRow) => handleBulkDelete(selectedRow)}
        options={{
          maxBodyHeight: OnboardingEmpData ? "44vh" : "52vh",
          minBodyHeight: OnboardingEmpData ? "44vh" : "52vh",
          rowStyle: { fontSize: "13px" },
          headerStyle: {
            padding: "0px",
            fontWeight: "bold",
            paddingRight: "10px",
          },
          sorting: true,
          search: true,
          searchText: "",
          searchFieldAlignment: "right",
          searchAutoFocus: true,
          searchFieldVariant: "standard",
          filtering: false,
          paging: true,
          pageSizeOptions: [25, 50],
          pageSize: 25,
          paginationType: "stepped",
          showFirstLastPageButtons: false,
          paginationPosition: "bottom",
          exportButton: true,
          exportAllData: true,
          exportFileName: "Education Details",
          addRowPosition: "first",
          selection: disableEdit ? false : true,
          actionsColumnIndex: -1,
          showSelectAllCheckbox: true,
          showTextRowsSelected: true,
          selectionProps: (rowData) => ({
            disabled: rowData.age < 18 ? true : false,
            color: "secondary",
          }),
          grouping: false,
          columnsButton: true,
        }}
        actions={[
          !disableEdit && {
            icon: () => <MoreVertIcon />,
            position: "row",
            tooltip: "Setting",
            onClick: (_event, rowData) =>
              handleMoreVertIconClick(_event, rowData),
          },
          !disableEdit && {
            icon: () => (
              <IconButton sx={{ padding: "0px" }}>
                <DeleteIcon />
              </IconButton>
            ),
            tooltip: "Delete",
            onClick: (_event, rowData) => {
              handleConfirmDelete(rowData);
            },
          },
        ]}
        title={!disableEdit && addButton}
      />
      <Popup
        title={editedRecord ? "Edit Education" : "Add Education"}
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
        isMasterProduct={true}
      >
        <EducationModalForm
          editedRecord={editedRecord}
          addMode={addMode}
          setOpenPopup={setOpenPopup}
          educationModalData={educationModalData}
          setEducationModalData={setEducationModalData}
          initialFValues={initialFValues}
        />
      </Popup>
      <ConfirmationDialog
        title="Do you wish to proceed?"
        openDialog={openDialog}
        closeDialog={setOpenDialog}
        popupTitle="Education Management"
        OkButtonText="OK"
        onSubmit={handleDelete}
      />
      {
        <Menu
          id="long-menu"
          anchorEl={anchorEl}
          open={openMoreVertIcon}
          onClose={handleMoreVertIconClose}
        >
          {
            <MenuItem key="edit" onClick={() => handleEdit(currentData)}>
              <EditIcon sx={{ fontSize: "15px" }} /> &nbsp;{" "}
              <Typography sx={{ fontSize: "15px" }}>Edit</Typography>
            </MenuItem>
          }
          <MenuItem
            key="delete"
            onClick={() => handleConfirmDelete(currentData)}
          >
            <DeleteIcon sx={{ fontSize: "15px" }} /> &nbsp;{" "}
            <Typography sx={{ fontSize: "15px" }}>Delete</Typography>
          </MenuItem>
        </Menu>
      }
    </Box>
  );
}
