import axiosInstance from "../../../../../axiosInstance";
import { HRMS_FINANCE_API_URL } from "../../../../Constants/baseConstant";
import { getUserData } from "../../../Base/helper/baseFunctions";

export const GetWageReportHelper = async (params) => {
    const data = getUserData();
    return await axiosInstance(`finance/ancillary/getWageReport`, {
        method: "POST",
        baseURL: HRMS_FINANCE_API_URL,
        data: {
            domain_name: data?.domain,
            employee_id: null
        }
    })
        .then((res) => {
            return { message: "successfully", hasError: false, data: res?.data }
        }).catch(err => {
            return { message: "Unable to retrived ", hasError: false, data: err?.response?.data }
        })
}

export const GenerateWageReportHelper = async (params) => {
    const data = getUserData();
    return await axiosInstance(`finance/ancillary/generateWageReport`, {
        method: "POST",
        baseURL: HRMS_FINANCE_API_URL,
        data: {
            domain_name: data?.domain,
            employee_id: params?.employee_id || null,
            month: params?.month,
            year: params?.year,
            user_id: data?.employee_id
        }
    })
        .then((res) => {
            return { message: "successfully", hasError: false, data: res?.data }
        }).catch(err => {
            return { message: "Unable to retrived ", hasError: false, data: err?.response?.data }
        })
}

export const GenerateEPFOReportHelper = async () => {
    const data = getUserData();
    return await axiosInstance(`finance/ancillary/generateEPFOReport`, {
        method: "POST",
        baseURL: HRMS_FINANCE_API_URL,
        data: {
            domain_name: data?.domain,
            employee_id: data?.employee_id
        }
    })
        .then((res) => {
            return { message: "successfully", hasError: false, data: res?.data }
        }).catch(err => {
            return { message: "Unable to retrived ", hasError: false, data: err?.response?.data }
        })
}
export const InEligibleReportHelper = async (params) => {
    const data = getUserData();
    return await axiosInstance(`finance/ancillary/ineligibleReport`, {
        method: "POST",
        baseURL: HRMS_FINANCE_API_URL,
        data: {
            domain_name: data?.domain,
            from_date: params.from_date,
            to_date: params.to_date,
            emp_id: params.emp_id,
            reporting_manager_emp_id: data?.employee_id
        }
    })
        .then((res) => {
            return { message: "successfully", hasError: false, data: res?.data }
        }).catch(err => {
            return { message: "Unable to retrived ", hasError: false, data: err?.response?.data }
        })
}