import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import React from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import IndustryType from '../../ATS/views/Ancillary/IndustryType';
import Skills from '../../ATS/views/Ancillary/Skills';
import JobTemplate from '../../ATS/views/Ancillary/JobTemplate';
import { useTheme } from "@mui/material/styles";

const AtsSettings = ({ accordian }) => {
    const theme = useTheme()
    const [expanded, setExpanded] = useState(accordian)
    const { industryTypeData } = useSelector(state => state.AtsReducer?.IndustryTypeReducer);
    const { skillsData } = useSelector(state => state.AtsReducer?.SkillsReducer);
    const { jobTemplateData } = useSelector(state => state.AtsReducer?.JobTemplateReducer);
    const handleChange = panel => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false)
    }

    const values = [
        { name: "Industry Sector", comp: <IndustryType />, count: industryTypeData?.length || 0 },
        { name: "Skills", comp: <Skills />, count: skillsData?.length || 0 },
        { name: "JD Template", comp: <JobTemplate />, count: jobTemplateData?.length || 0 },
    ]

    const actionSummaryStyle = {
        minHeight: 50,
        maxHeight: 50,
        borderRadius: "10px 10px 0px 0px",
        // backgroundColor: '#a5a5a5',
        '&.Mui-expanded': {
            minHeight: 50,
            maxHeight: 50,
            backgroundColor: theme?.palette?.primary?.dark,
            color: "white",
        }
    }

    return (
        <div style={{ padding: "10px 0px" }}>
            {values.map((value, id) => {
                return (
                    <Accordion key={id} expanded={expanded === `${value.name}`} onChange={handleChange(`${value.name}`)} sx={{ borderRadius: "10px" }}>
                        <AccordionSummary sx={actionSummaryStyle}
                            expandIcon={<KeyboardArrowDownIcon />}
                            id='controlled-panel-header-1'
                            aria-controls='controlled-panel-content-1'
                        >
                            <Typography  sx={{color:theme.palette.primary.contrastText}} variant='body1' fontWeight={600}>{value.name} ({value.count})</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            {value.comp}
                        </AccordionDetails>
                    </Accordion>
                )
            })}
        </div>
    );
}

export default AtsSettings;
