/* eslint-disable no-eval */
import { GetObjectCommand, S3 } from "@aws-sdk/client-s3";
import { getSignedUrl } from "@aws-sdk/s3-request-presigner";
import { toast } from "react-toastify";
const CryptoJS = require("crypto-js");

export const month = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

export const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);
        fileReader.onload = () => {
            resolve(fileReader.result);
        };

        fileReader.onerror = (error) => {
            reject(error);
        }
    })
}

export const ConvertDate = (date) => {
    var con_date = date?.split('-');
    return `${con_date?.[2]} ${month[+con_date?.[1] - 1]} ${con_date?.[0]}`;
}

export const ConvertDateTime = (date) => {
    var con_date = date?.split('T')?.[0]?.split('-');
    return `${con_date?.[2]} ${month[+con_date?.[1] - 1]} ${con_date?.[0]}`;
}
export const ConvertTime = (date) => {
    const time = new Date(date);
    const options = {
        hour: 'numeric',
        minute: 'numeric',
        second:'numeric',
        hour12: true,
    };
    return time.toLocaleTimeString('en-US', {
        ...options,
        timeZone: 'UTC',
    });

};
export const ConvertTimeOnly = (time) => {
    if (!time) return "—";  // Handle empty values
    let [hours, minutes] = time.split(":");
    let ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12; // Convert 24-hour format to 12-hour
    return `${hours}:${minutes} ${ampm}`;
};

export const getUserData = () => {
    if (sessionStorage.getItem("userData")) {
        var userData = CryptoJS.AES.decrypt(sessionStorage.getItem("userData"), 'userData');
        return JSON.parse(userData.toString(CryptoJS.enc.Utf8));
    }
}

export const handleGroupby = (xs, key) => {
    return xs?.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
    }, {});
};

export const handleSearch = (search, data, field) => {
    /**
    * Dynamic Live Search in the array
    * some() executes the function once for each element in the array
    */
    const keyword = (search);
    if (field) {
        const fil = data.filter(res =>
            eval('res.' + field + '.toLowerCase().includes(keyword.toLowerCase())')
        )
        return fil;
    } else {
        const filtered = data.filter(
            entry => Object.values(entry).some(val =>
                typeof val === "string" &&
                (val.toLowerCase()).includes(keyword.toLowerCase())
            )
        );
        return filtered;
    }
};

export const handleSort = (data, field, order) => {
    /**
    * Dynamic Live Sort in the array
    */
    function compareObjects(object1, object2, key) {
        const obj1 = object1[key].toUpperCase()
        const obj2 = object2[key].toUpperCase()

        if (order === "asc") {
            if (obj1 < obj2) return -1
            if (obj1 > obj2) return 1
            return 0
        } else {
            if (obj1 > obj2) return -1
            if (obj1 < obj2) return 1
            return 0
        }
    }

    return data.sort((a, b) => {
        return compareObjects(a, b, field)
    })
};


export const s3Client = new S3({
    // forcePathStyle: false, // Configures to use subdomain/virtual calling format.
    endpoint: process.env.REACT_APP_DO_ENDPOINT,
    region: process.env.REACT_APP_DO_REGION,
    credentials: {
        accessKeyId: process.env.REACT_APP_DO_SPACES_KEY,
        secretAccessKey: process.env.REACT_APP_DO_SPACES_SECRET
    }
});

export const DownloadAttachment = async (filename) => {
    const bucketParams = {
        Bucket: process.env.REACT_APP_DO_BUCKET_NAME,
        Key: filename
    };
    try {
        const url = await getSignedUrl(s3Client, new GetObjectCommand(bucketParams), { expiresIn: 3 * 60 }); // Adjustable expiration.
        window.open(url, "_blank")
        return url;
    } catch (err) {
        toast.error(err)
    }
};

export const formatPayPeriod = (payDay) => {
    if (!payDay || payDay < 1) return ''; // Handle invalid cases
    const suffix = (day) => {
        if (day % 10 === 1 && day !== 11) return 'st';
        if (day % 10 === 2 && day !== 12) return 'nd';
        if (day % 10 === 3 && day !== 13) return 'rd';
        return 'th';
    };
    if (payDay === 1) return `1st to 31st`; // Handle first day case

    return `${payDay}${suffix(payDay)} to ${(payDay - 1)}${suffix(payDay - 1)}`;
};

