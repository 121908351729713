import { useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { Draggable } from "react-beautiful-dnd";
import { createStyles, makeStyles, styled } from "@mui/styles";
import {
  Box,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  IconButton,
  Rating,
  Tooltip,
  Typography,
} from "@mui/material";
import { CheckCircle } from "@mui/icons-material";
import FlagOutlinedIcon from "@mui/icons-material/FlagOutlined";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import UnpublishedIcon from "@mui/icons-material/Unpublished";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import PersonOffOutlinedIcon from "@mui/icons-material/PersonOffOutlined";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import { ConvertDate } from "../../../../../Base/helper/baseFunctions";
import Popup from "../../../../../Base/views/Popup";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import ConfirmationDialog from "../../../../../Base/views/ConfirmationDialog";
import { UpdateStatusHelper } from "../../../../../Ancillary/StatusUpdate/helper/StatusUpdateHelper";
import {
  API_KEY,
  ATS_CANDIDATE,
  ATS_CANDIDATE_JOB,
  CLIENT_ID,
  SCOPES,
} from "../../../../../../Constants/baseConstant";
import RejectPopup from "../RejectPopup";
import AcceptPopup from "../AcceptPopup";
import FlagCandidatePopup from "../FlagCandidatePopup";
import { PowerUserDisabledAction } from "../../../../../../../store/RoleAccess/Permission/Action";
import { UpdateAtsJobKanbanHelper } from "../../../../helper/AtsJobKanbanHelper";
import ContactEmailForm from "../../../ATSCandidate/ContactEmailForm";
import OfferLetterResponseStepper from "../../../../../ATSSettings/views/OfferLetter/OfferLetterResponseStepper";
import LoginForm from "../../../Dashboard/LoginForm";
import { gapi } from "gapi-script";
import InterviewScheduleForm from "../../../Dashboard/InterviewScheduleForm";
import AccessWarning from "../../../Dashboard/AccessWarning";
import SendIcon from "@mui/icons-material/Send";
import ResumeSharingForm from "../../../ATSCandidate/ResumeSharingForm";

const StyledRating = styled(Rating)({
  "& .MuiRating-iconFilled": {
    color: "#00A300",
  },
});
const useStyles = makeStyles((theme) =>
  createStyles({
    CardFooter: {
      width: "100%",
      height: "22px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginTop: "4px",
      marginBottom: "3px"
    },
    DragItem: {
      padding: "1px 10px",
      borderRadius: "6px",
      boxShadow: "0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24)",
      background: theme?.palette?.primary?.lighter,
      margin: "1px 0 8px 0",
      gridGap: "20px",
      width: "250px",
      fontSize:"14px",
      minHeight: "95px",
    },
  })
);
const iconStyle = { fontSize: "18px" };
const getItemStyle = (isDragging, draggableStyle) => ({
  background: isDragging ? "#f1e3f3" : "#f9f9f991",
  ...draggableStyle,
});
const ListItem = ({
  item,
  index,
  currentData,
  setCurrentData,
  setAnchor,
  getKanbanData,
  JobData,
  stageData,
  setIsVerified,
  handleDisabled,
  currentMode,
  offerdata,
  setElements,
}) => {
  const classes = useStyles();
  const today = new Date();
  const options = {
    weekday: 'short',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
    timeZoneName: 'short',
  };
  const formattedDate = new Intl.DateTimeFormat('en-US', options).format(today);
  const dispatch = useDispatch();
  const [errors, setErrors] = useState({});
  const [openLogin, setOpenLogin] = useState(false);
  const [accessgranted, setAccessGranted] = useState(false);
  const [orgname, setOrg] = useState("");
  const [hasAccess, setHasAccess] = useState(false);
  const [openAccess, setOpenAccess] = useState(false);
  const [gapiInitialized, setGapiInitialized] = useState(false);
  const [stagesData, setStagesData] = useState({ load: true, values: [] });
  const [openPopup, setOpenPopup] = useState(false);
  const [openemailPopup, setOpenEmailPopup] = useState(false);
  const [anchorE, setAnchorE] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [reject, setReject] = useState(false);
  const [accept, setAccept] = useState(false);
  const [FlagCandidate, setFlagCandidate] = useState(false);
  const [isofferform, setIsOfferForm] = useState(false);
  const [values, setValues] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const accessTokenforGoogle = localStorage.getItem("accessTokenforGoogle");
  const open = Boolean(anchorEl);
  const [openResumePopup, setOpenResumePopup] = useState(false);

  const openResumeSharePopup = () => {
    setOpenResumePopup(true);
    handleClose();
  };
  const openEmailPopup = (event) => {
    event.stopPropagation();
    setOpenEmailPopup(true);
  };
  const openOfferPopup = (event) => {
    event.stopPropagation();
    setIsOfferForm(true);
  };
  useEffect(() => {
    function start() {
      gapi.client
        .init({
          apiKey: API_KEY,
          clientId: CLIENT_ID,
          discoveryDocs: [
            "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest",
          ],
          scope: SCOPES,
        })
        .then(() => {
          const authInstance = gapi.auth2.getAuthInstance();
          if (authInstance.isSignedIn.get()) {
            const user = authInstance.currentUser.get();
            setOrg(user?.wt?.Ad);
            gapi.client.calendar.events.list({
              'calendarId': 'primary',
              'maxResults': 1,
            }).then(response => {
              setHasAccess(true);
            }).catch(error => {
              setHasAccess(false);
            });
          } else {
            setHasAccess(false);
          }
          setGapiInitialized(true);
        });
    }
    gapi.load("client:auth2", start);
  }, [accessgranted]);

  const handleHorMorevertClick = (event) => {
    event.stopPropagation();
    // setCurrentData(data);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleKanbanCardClick = (event, data) => {
    event.preventDefault();
    if (event.target.name === "priority") {
      event.stopPropagation();
      setCurrentData(data);
    } else {
      if (data?.is_verified === true) {
        setCurrentData(data);
        setAnchor(true);
      } else {
        setCurrentData(data);
        if (handleDisabled("candidate_verify_candidate")) {
          handlePowerDisabled(event);
        } else {
          setIsVerified(true);
        }
      }
    }
  };
  const handleScheduleInterview = (event, data) => {
    if (data?.is_verified === true) {
      event.stopPropagation();
      setCurrentData(data);
      if (accessTokenforGoogle) {
        if (hasAccess) {
          setOpenPopup(true)
        }
        else {
          setOpenAccess(true)
        }
      } else {
        setOpenLogin(true);
      }
    } else {
      setCurrentData(data);
      setIsVerified(true);
    }
  };

  const handleArchiveClick = (event) => {
    event.stopPropagation();
    setAnchorE(null);
    setOpenDialog(true);
  };
  const FlagCandidateClick = (event, data) => {
    event.stopPropagation();
    if (data?.flag_reason) {
      setValues({ flag_reason: data?.flag_reason });
    } else {
      setValues({ flag_reason: "" });
    }
    setFlagCandidate(true);
  };

  const handleConfirmArchive = () => {
    UpdateStatusHelper({
      update_id: item?.candidate_job_id,
      status: "Inactive",
      column_name: ATS_CANDIDATE_JOB,
      successMessage: "Candidate Archived Successfully",
    }).then((res) => {
      if (res?.data?.statuscode === 200) {
        getKanbanData();
        item?.is_external &&
          UpdateStatusHelper({
            update_id: item?.candidate_id,
            status: "Inactive",
            column_name: ATS_CANDIDATE,
            successMessage: "Candidate Archived Successfully",
            hide: true,
          });
      }
    });
    setOpenDialog(false);
  };
  const handleReject = (e, data) => {
    e.stopPropagation();
    if (data?.job_stage_name === "Rejected") {
      setAccept(true);
      setValues({ revert_reason: "", stage_id: "" });
    } else {
      setReject(true);
      let rejectData = stageData?.find((res) => res?.name === "Rejected");
      setValues({ reason: "", stage_id: rejectData?.ats_job_stages_id });
    }
  };
  const handleValues = (event, value) => {
    if (["stage_id"].includes(event)) {
      setValues({ ...values, [event]: value });
      Object.values?.(errors)?.find((res) => res !== "")?.length > 0 &&
        validateRevertReject({ ...value, [event]: value });
    } else {
      setValues({ ...values, [event.target.name]: event.target.value });
      Object.values?.(errors)?.find((res) => res !== "")?.length > 0 &&
        validateRevertReject({
          ...values,
          [event.target.name]: event.target.value,
        });
    }
  };
  const validateRevertReject = (fieldValues) => {
    let temp = { ...errors };
    if ("reason" in fieldValues) {
      temp.reason = fieldValues?.reason === "" ? "Reason is required" : "";
    }
    if ("flag_reason" in fieldValues) {
      temp.flag_reason =
        fieldValues?.flag_reason === "" ? "Reason is required" : "";
    }

    if ("revert_reason" in fieldValues) {
      temp.revert_reason =
        fieldValues?.revert_reason === "" ? "Revert Reason is required" : "";
    }
    if ("stage_id" in fieldValues) {
      temp.stage_id = ["", null].includes(fieldValues?.stage_id)
        ? "Moving Stage is required"
        : "";
    }
    setErrors({
      ...temp,
    });
    return Object.values(temp).every((x) => x === "");
  };
  const handleRejectClick = () => {
    if (validateRevertReject(values)) {
      UpdateAtsJobKanbanHelper({
        reject,
        kanban_data: JSON.stringify([
          {
            proposed_by: +item?.proposed_by,
            candidate_job_id: +item?.candidate_job_id,
            ats_jobpost_id: item?.ats_jobpost_id,
            interview_feedback: item?.interview_feedback,
            ats_job_stages_id:
              typeof values?.stage_id === "object"
                ? values?.stage_id?.ats_job_stages_id
                : values?.stage_id,
            priority: item?.priority || 5,
            ats_status_id: null,
            selected: item?.selected || null,
            candidate_id: item?.candidate_id,
            accepted_ctc: item?.accepted_ctc ? item?.accepted_ctc : 0,
            tentative_joining_date: item?.tentative_joining_date,
            joined_ctc: item?.joined_ctc,
            joined_date: item?.joined_date,
            offer_released_date: item?.offer_released_date,
            rejected_reason: values?.reason || null,
            reverted_reason: values?.revert_reason || null,
          },
        ]),
      }).then((res) => {
        getKanbanData();
      });
      setErrors([]);
      setAccept(false);
      setReject(false);
    }
  };

  const handleFlagCandidateClick = () => {
    if (validateRevertReject(values)) {
      UpdateAtsJobKanbanHelper({
        kanban_data: JSON.stringify([
          {
            proposed_by: +item?.proposed_by,
            candidate_job_id: +item?.candidate_job_id,
            ats_jobpost_id: item?.ats_jobpost_id,
            interview_feedback: item?.interview_feedback,
            ats_job_stages_id: item?.ats_job_stages_id,
            priority: item?.priority || 5,
            ats_status_id: null,
            selected: item?.selected || null,
            candidate_id: item?.candidate_id,
            accepted_ctc: item?.accepted_ctc ? item?.accepted_ctc : 0,
            tentative_joining_date: item?.tentative_joining_date,
            joined_ctc: item?.joined_ctc,
            joined_date: item?.joined_date,
            offer_released_date: item?.offer_released_date,
            rejected_reason: values?.reason || null,
            reverted_reason: values?.revert_reason || null,
            flag_reason: values?.flag_reason || null,
          },
        ]),
      }).then((res) => {
        getKanbanData();
      });
      setErrors([]);
      setFlagCandidate(false);
    }
  };
  const handleStageDataLoading = () => {
    let filteredData = stageData;

    if (currentData?.offer_letter) {
      if (
        currentData?.job_stage_name === "Offer Preparation" &&
        !currentData?.offer_preparation_completed
      ) {
        // If in "Offer Preparation" stage and not completed, load all stages
        const excludedStages = ["6", "7", "8", "9"];
        filteredData = stageData.filter((stage) => {
          return !excludedStages.includes(stage.sort_key);
        });
      } else if (
        currentData?.job_stage_name === "Offer Preparation" ||
        currentData?.job_stage_name === "Offer Released" ||
        currentData?.job_stage_name === "Offer Rejected" ||
        currentData?.job_stage_name === "Offer Accepted" ||
        currentData?.job_stage_name === "Joined"
      ) {
        // Load all stages without exclusion
        filteredData = stageData;
      } else {
        // Exclude stages with sort_key "6", "7", "8", "9"
        const excludedStages = ["6", "7", "8", "9"];
        filteredData = stageData.filter((stage) => {
          return !excludedStages.includes(stage.sort_key);
        });
      }
    }

    setStagesData({ load: false, values: filteredData });
    // setStagesData({ load: false, values: stageData });
  };
  const handlePowerDisabled = (e) => {
    e.stopPropagation();
    dispatch(PowerUserDisabledAction());
  };
  return (
    <div>
      <Draggable
        draggableId={`${item.candidate_job_id}`}
        isDragDisabled={currentMode === "archive" ? true : false}
        index={index}
      >
        {(provided, snapshot) => {
          return (
            <div
              className={classes.DragItem}
              onClick={(e) => handleKanbanCardClick(e, item)}
              ref={provided.innerRef}
              snapshot={snapshot}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              style={getItemStyle(
                snapshot.isDragging,
                provided.draggableProps.style
              )}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: "-1px",
                }}
              >
                <Typography
                  style={{
                    display: "inline-flex",
                    verticalAlign: "text-bottom",
                    boxSizing: "inherit",
                    textAlign: "start",
                    alignItems: "center",
                    fontSize: "14px",
                  }}
                  variant="h6"
                >
                  <span
                    style={{
                      maxWidth: "200px", 
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      display: "inline-block",
                      verticalAlign: "middle",
                    }}
                  >
                  {`${item?.full_name || item?.first_name}`}{" "}
                  </span>
                  {item.job_stage_name === "Joined" && (
                    <span>
                      <StyledRating
                        sx={{ ml: 1, mt: 0.5, ...iconStyle }}
                        name="read-only"
                        emptyIcon={<CheckCircle fontSize="small" />}
                        icon={<CheckCircle fontSize="small" sx={{ color: "#883bd5" }}/>}
                        value={1}
                        max={1}
                        readOnly
                      />
                    </span>
                  )}
                  <Tooltip title="Not Verified">
                    <Box>
                      {item?.is_verified ? null : (
                        <UnpublishedIcon
                          sx={{ ml: 1, color: "#EE0000", ...iconStyle }}
                          fontSize="small"
                        />
                      )}
                    </Box>
                  </Tooltip>
                </Typography>
                {item?.flag_reason && (
                  <Tooltip title={item?.flag_reason}>
                    <FlagOutlinedIcon fontSize="small" color="error" sx={iconStyle}/>
                  </Tooltip>
                )}
                <Box sx={{ ml: "auto" }}>
                  {!item?.offer_preparation_completed &&
                    item?.job_stage_name === "Offer Preparation" && (
                      <Tooltip title="Offer generation Inprogress">
                        <IconButton
                          disabled={currentMode === "archive"}
                          onClick={(e) => {
                            openOfferPopup(e);
                          }}
                        >
                          <BorderColorIcon fontSize="small" sx={iconStyle}/>
                        </IconButton>
                      </Tooltip>
                    )}

                  {item?.offer_preparation_completed &&
                    !item?.managers_approval &&
                    (item?.job_stage_name === "Offer Preparation" ||
                      item?.job_stage_name === "Offer Released") && (
                      <Tooltip title="Offer Preparation Completed">
                        <CheckBoxIcon fontSize="small" color="success" sx={iconStyle} />
                      </Tooltip>
                    )}

                  {item?.managers_approval &&
                    (item?.job_stage_name === "Offer Preparation" ||
                      item?.job_stage_name === "Offer Released" ||
                      item?.job_stage_name === "Offer Accepted" ||
                      item?.job_stage_name === "Joined") && (
                      <Tooltip title="Approver Response Captured">
                        <HowToRegIcon fontSize="small" color="success" sx={iconStyle}/>
                      </Tooltip>
                    )}
                </Box>
              </div>
              <Typography
                sx={{
                  maxWidth: "246px", 
                  whiteSpace: "nowrap", 
                  overflow: "hidden", 
                  textOverflow: "ellipsis",
                  color: "black" 
                }}
              >
                {item?.email_id}
              </Typography>
            <Box sx={{display: "flex", alignItems: "center",  justifyContent: "space-between", mt: 0.5}}>
              <Tooltip title={item?.comp_cons_name}>
                <Typography  sx={{
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    maxWidth: "120px",
                  }}>{item?.comp_cons_name}</Typography>
              </Tooltip>
              <Tooltip title="Assignee">
                <Typography>
                  {item?.assignee_name ? (
                    <Box
                      sx={{
                        padding: "1px 3px",
                        borderRadius: "10%",
                        backgroundImage:
                          "linear-gradient(to left, #facf39, #fbdb6b)",
                        boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.3)", // Optional shadow
                      }}
                    >
                      <Typography  sx={{
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          maxWidth: "120px",
                        color: "black",
                          fontSize: "12px"
                          }}>{item?.assignee_name}
                      </Typography>
                    </Box>
                  ) : (
                    <Typography variant="span">-</Typography>
                  )}
                </Typography>
              </Tooltip>
            </Box>

              <div className={classes.CardFooter}>
                <Tooltip title="Last Modified">
                  <Typography>
                    {ConvertDate(item.modifiedon?.split("T")[0])}
                  </Typography>
                </Tooltip>

                <div>
                  { item?.is_verified && <Tooltip title="Contact Email">
                    <IconButton
                      disabled={currentMode === "archive"  }
                      onClick={(e) => {
                        openEmailPopup(e);
                      }}
                    >
                      <ForwardToInboxIcon fontSize="small" sx={iconStyle}/>
                    </IconButton>
                  </Tooltip>}
                  <Tooltip title="More Options">
                    <IconButton
                      onClick={(event) => handleHorMorevertClick(event)}
                    >
                      <MoreVertIcon fontSize="small" sx={iconStyle}/>
                    </IconButton>
                  </Tooltip>
                </div>
              </div>
            </div>
          );
        }}
      </Draggable>



      <Menu
        id="more-options-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "more-options-button",
        }}
      >
        <MenuItem
          disabled={currentMode === "archive"}
          onClick={(e) =>
            handleDisabled("job_post_kanban_update_candidate")
              ? handlePowerDisabled(e)
              : handleArchiveClick(e)
          }
        >
          <ListItemIcon>
            <PersonRemoveIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Archive</ListItemText>
        </MenuItem>
        { item?.is_verified && (
          <div>
            <MenuItem
              disabled={currentMode === "archive"}
              onClick={(e) => {
                handleDisabled("job_post_kanban_update_candidate")
                  ? handlePowerDisabled(e)
                  : handleScheduleInterview(e, item);
              }}
            >
              <ListItemIcon>
                <CalendarMonthIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Schedule Interview</ListItemText>
            </MenuItem>
            <MenuItem
              disabled={currentMode === "archive" }
              onClick={(e) =>
                handleDisabled("job_post_kanban_update_candidate")
                  ? handlePowerDisabled(e)
                  : handleReject(e, item)
              }
            >
              <ListItemIcon>
                {item?.job_stage_name === "Rejected" ? (
                  <CheckOutlinedIcon color="success" fontSize="small" />
                ) : (
                  <PersonOffOutlinedIcon color="error" fontSize="small" />
                )}
              </ListItemIcon>
              <ListItemText>
                {item?.job_stage_name === "Rejected" ? "Select" : "Reject"}
              </ListItemText>
            </MenuItem>
            <MenuItem
            disabled={currentMode === "archive" }
            onClick={(e) =>
              handleDisabled("job_post_kanban_update_candidate")
                ? handlePowerDisabled(e)
                : FlagCandidateClick(e, item)
            }
          >
            <ListItemIcon>
              <FlagOutlinedIcon fontSize="small" color="error" />
            </ListItemIcon>
            <ListItemText>Flag Candidate</ListItemText>
          </MenuItem>
            </div>
        )}
        {item?.resume ? (
              <MenuItem
                  onClick={openResumeSharePopup}
              >
                <ListItemIcon>
                  <SendIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Share Resume</ListItemText>
                  </MenuItem>
                ) : (
                  <MenuItem>
                    <ListItemText>No Resume Found</ListItemText>
                  </MenuItem>
                )}
       
      </Menu>



      <ConfirmationDialog
        title="Archive"
        content="The candidate will be removed from this job, and you can still propose the candidate to other Jobs. Are you sure want to archive?"
        anchorE={anchorE}
        openDialog={openDialog}
        closeDialog={setOpenDialog}
        popupTitle="Job"
        OkButtonText="Archive"
        onSubmit={handleConfirmArchive}
      />
       <Popup
          fullWidth={true}
          maxWidth="md"
          title="Share Resume"
          openPopup={openResumePopup}
          setOpenPopup={setOpenResumePopup}
        >
          <ResumeSharingForm
            setOpenPopup={setOpenResumePopup}
            currentData={item}
          />
        </Popup>
      <Popup
        fullWidth={true}
        maxWidth="md"
        title="Schedule Interview"
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
      >
        <InterviewScheduleForm
          setOpenPopup={setOpenPopup}
          gapiInitialized={gapiInitialized}
          date={formattedDate}
          jobdata={currentData}
          orgname={orgname}
        />
      </Popup>
      <Popup
        maxWidth="xs"
        title="SignIn Using"
        openPopup={openLogin}
        setOpenPopup={setOpenLogin}
      >
        <LoginForm setOpenPopup={setOpenLogin} setAccessGranted={setAccessGranted} />
      </Popup>
      <Popup
        maxWidth="sm"
        title="Google Access"
        openPopup={openAccess}
        setOpenPopup={setOpenAccess}
      >
        <AccessWarning setOpenPopup={setOpenAccess} setAccessGranted={setAccessGranted} />
      </Popup>
      <FlagCandidatePopup
        openPopup={FlagCandidate}
        setOpenPopup={setFlagCandidate}
        handleFlagCandidateClick={handleFlagCandidateClick}
        errors={errors}
        setValues={setValues}
        setErrors={setErrors}
        values={values}
      />

      <RejectPopup
        openPopup={reject}
        setOpenPopup={setReject}
        handleRejectClick={handleRejectClick}
        errors={errors}
        setValues={setValues}
        setErrors={setErrors}
        values={values}
      />
      <AcceptPopup
        accept={accept}
        setAccept={setAccept}
        handleStageDataLoading={handleStageDataLoading}
        handleValues={handleValues}
        errors={errors}
        values={values}
        setErrors={setErrors}
        setValues={setValues}
        handleRejectClick={handleRejectClick}
        stagesData={stagesData}
      />
      <Popup
        fullWidth={true}
        maxWidth="md"
        title="Contact Email"
        openPopup={openemailPopup}
        setOpenPopup={setOpenEmailPopup}
      >
        <ContactEmailForm setOpenPopup={setOpenEmailPopup} currentData={item} />
      </Popup>
      <Popup
        maxWidth="xl"
        title="Prepare Offer Letter"
        fullWidth
        isMasterProduct={true}
        setOpenPopup={setIsOfferForm}
        openPopup={isofferform}
        close={false}
      >
        <OfferLetterResponseStepper
          currentData={item}
          currentJobData={JobData}
          setIsOfferForm={setIsOfferForm}
          setElements={setElements}
          setCurrentData={setCurrentData}
        />
      </Popup>
    </div>
  );
};
export default ListItem;