import * as React from "react";
import { useState } from "react";
import { ToastContainer } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import NProgress from "nprogress";
// Mui
import MuiDrawer from "@mui/material/Drawer";
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import CssBaseline from "@mui/material/CssBaseline";
import { Box, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Checkbox } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useTheme } from "@mui/material/styles";
import { grey } from "@mui/material/colors";
// Icons
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import AssignmentIcon from '@mui/icons-material/Assignment';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import AdjustIcon from "@mui/icons-material/Adjust";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import MenuIcon from "@mui/icons-material/Menu";
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import AssessmentIcon from '@mui/icons-material/Assessment';
import RuleIcon from '@mui/icons-material/Rule';
import EngineeringIcon from '@mui/icons-material/Engineering';
import GroupWorkIcon from '@mui/icons-material/GroupWork';
import DashboardIcon from "@mui/icons-material/Dashboard";
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import BadgeIcon from "@mui/icons-material/Badge";
import WorkHistoryIcon from '@mui/icons-material/WorkHistory';
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import AccessibilityIcon from "@mui/icons-material/Accessibility";
import DescriptionIcon from '@mui/icons-material/Description';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import SavingsIcon from '@mui/icons-material/Savings';
// Custom
import AdminMenuBar from "./AdminMenuBar";
import { FullLogo, Logo } from "../../../../svg/Logo"
import SettingsMenu from "./SettingsMenu";
import FinanceMenu from "./FinanceMenu";
import AtsMenu from "./AtsMenu";
import AppBar from "./AppBar";
import MobileMenu from "./MobileMenu";
import * as ActionTypes from '../../../../store/Menu/ActionTypes';
import { getUserData } from "../../Base/helper/baseFunctions";
import '../css/style.css';
import Footer from "../../Base/views/Footer";
import { useEffect } from "react";
import WorkIcon from "@mui/icons-material/Work";
import PersonIcon from '@mui/icons-material/Person';
import PowerUserWarningPopup from "../../Ancillary/PowerUserAccess/views/PowerUserWarningPopup";
import { AddMenuHeadingAction } from "../../../../store/Menu/Action";
import ProposeCandidateConfirmation from "../../Base/views/ProposeCandidateConfirmation";
import { AddAtsJobPostFillterAction, AtsJobPostModeAction } from "../../../../store/ATS/JobPost/Action";
import { GetLeaveYearTypeAction } from "../../../../store/Leave/LeaveYearType/Action";
import Popup from "../../Base/views/Popup";
import PlanExpired from "./PlanExpired";
import LmsMenu from "./LmsMenu";
import { GetUserRoleAction } from "../../../../store/RoleAccess/Role/Action";
import EmployeeMenu from "./EmployeeMenu";
import { useLocation } from 'react-router-dom';

const drawerWidth = 245;
const openedMixin = (theme) => ({
  width: drawerWidth,
  zIndex: theme.zIndex.drawer,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden'
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => !["open", "fixMenuVal"].includes(prop) })(
  ({ theme, open, fixMenuVal }) => (
    {
      width: drawerWidth,
      flexShrink: `${fixMenuVal ? 0 : "unset"}`,
      BackdropProps: { invisible: true },
      whiteSpace: 'nowrap',
      zIndex: open ? theme.zIndex.drawer + 1 : 'auto',
      boxSizing: 'border-box',
      ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
      }),
      ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
      }),
    }),
);

export default function MenuLandingPage({ children }) {
  const theme = useTheme()
  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
  const [selectedMenu, setSelectedMenu] = useState();
  const [openMobileMenu, setOpenMobileMenu] = useState(false);
  const [collapse, setCollapse] = useState(false);
  const [show, setShow] = useState(false);
  const [popupContent, setPopupContent] = useState('');
  const [planExpired, setPlanExpired] = useState(false);
  const [planRemain, setPlanRemain] = useState(false);
  const dispatch = useDispatch();
  const { open, fixedMenu, isMobile } = useSelector(state => state?.MenuReducer);
  const { access } = useSelector(state => state.UserAccessReducer)
  const { userInfo, userInfoData } = useSelector(state => state?.SignInReducer)
  const { atsCollapse, atsManageCollapse, atsReportCollapse, lmsCollapse, EmployeeCollapse, financeCollapse, payrollCollapse } = useSelector(state => state?.MenuReducer);
  const { atsModuleData } = useSelector(state => state?.RoleAndAccessReducer?.ATSModuleReducer);

  const { configurestatusData } = useSelector(state => state?.AncillaryReducer?.ConfigureReducer);

  const { Statusdata } = useSelector(
    (state) => state?.MeeplAdminReducer?.WorkspaceReducer
  );
  const RequestStatus = Statusdata?.[0]?.fn_get_request_user_status?.status;
  const userData = getUserData();
  const isGroupOrgFlowTrue = userData?.grouporg_flow === true;
  const location = useLocation();
  useEffect(() => {
    const routeToHeading = {
      '/home': 'Home',
      '/employee': "It's Me",
      '/employee-bulk-upload': 'Employee Bulk Upload',
      '/myemployee-access': 'Employee Access Control',
      '/myemployee': 'Employee',
      '/myemployee-payroll': 'Employee Payroll',
      '/employee-time': 'Employee Time',
      '/myemployee-reports': 'Employee Reports',
      '/payroll-bulk-upload': 'Payroll Bulk Upload',
      '/payroll': 'Payroll Home',
      '/finance-report': 'Finance Reports',
      '/tax-declaration': 'Tax Declaration',
      '/configure-setting': 'Quick Setup',
      '/settings': 'General',
      '/finance-settings': 'Finance',
      '/role-access': 'Roles & Access',
      '/ats/ats-settings/': 'ATS',
      '/ats/dashboard': 'ATS Dashboard',
      '/ats/tasks/': 'Tasks',
      '/ats/manage-job': 'Job Dashboard',
      '/ats/manage-candidate': 'Candidate',
      '/ats/manage-user': 'Add Users',
      '/ats/manage-company': 'Add Client',
      '/ats/manage-group-company': 'Add Group Organisation',
      '/ats/manage-consultancy': 'Add Hiring Partner',
      '/ats/manage-reports/': 'Reports Dashboard',
      '/ats/reports/': 'Operational Reports',
      '/ats/revenue/': 'Revenue',
      '/lms/manage-dashboard': 'LMS Dashboard',
      '/lms/manage-timesheet': 'My Timesheet',
      '/lms/manage-team': 'Team',
      '/time-bulk-upload': 'Time Bulk Upload'
    };
    const currentHeading = routeToHeading[location.pathname];
    if (currentHeading) {
      sessionStorage.setItem("selectedMenu", currentHeading);
      dispatch(AddMenuHeadingAction({ heading: currentHeading }));
    }
  }, [location, dispatch]);
  useEffect(() => {
    dispatch(GetLeaveYearTypeAction());
    dispatch(GetUserRoleAction());
    NProgress.configure({ showSpinner: false });
    setSelectedMenu(sessionStorage.getItem("selectedMenu") || "My Tasks");
    if (parseInt(userInfoData?.expiry_days) === 0) {
      const inputDate = userInfoData?.expiry_date;
      const formattedDate = new Date(inputDate).toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: 'numeric' });
      setShow(true);
      if (userInfoData?.post_req_count === "1") {
        setPopupContent("Thanks for your patience, your request is with us. We will keep you updated.");
      } else {
        setPopupContent(`${userInfoData?.domainname} Workspace Expired on ${formattedDate}`);
      }
      setPlanExpired(true)
    }
    if (RequestStatus === false) {
      if (userInfoData?.pre_req_count === "0") {
        if (parseInt(userInfoData?.expiry_days) > 0 && parseInt(userInfoData?.expiry_days) <= parseInt(userInfoData?.cut_off_no_of_days)) {
          setShow(true);
          setPopupContent(`${userInfoData?.domainname} Workspace will Expire in ${userInfoData?.expiry_days} days`);
          setPlanRemain(true)
        }
      }
    }
    if (window.innerWidth < 850) {
      dispatch({ type: "MOBILE_MENU", payload: true })
    }

    const handleWindowResize = () => {
      if (window.innerWidth > 850) {
        dispatch({ type: "MOBILE_MENU", payload: false })
      } else {
        dispatch({ type: "MOBILE_MENU", payload: true })
      }
    };

    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo, Statusdata]);

  // clearing Job Dashboard applied Filter
  const menu = sessionStorage.getItem("selectedMenu")
  useEffect(() => {
    if (menu !== "Job Dashboard") {
      dispatch(AddAtsJobPostFillterAction({}))
    }
  }, [menu])

  const handleMenuClick = (menu) => {
    if (sessionStorage.getItem("selectedMenu") === "Job Dashboard") {
      dispatch(AtsJobPostModeAction({ job_mode: "grid" }));
    }
    if (menu === "Quick Setup") {
      sessionStorage.setItem("selectedMenu", "");
      dispatch(AddMenuHeadingAction({ heading: "" }));
    }
    else {
      sessionStorage.setItem("selectedMenu", menu);
      dispatch(AddMenuHeadingAction({ heading: menu }));
    }
    if (menu !== "Candidate") {
      sessionStorage.removeItem("tagsSearch");
    }
  }
  const handleDrawerOpen = () => {
    dispatch({ type: ActionTypes.OPEN_MENU })
  };

  const handleDrawerClose = () => {
    dispatch({ type: ActionTypes.CLOSE_MENU })
  };

  const handleDrawerFix = () => {
    fixedMenu ?
      dispatch({ type: ActionTypes.FIXED_MENU_CLOSE })
      : dispatch({ type: ActionTypes.FIXED_MENU_OPEN })
  }

  const handleAtsCollapse = () => {
    dispatch({ type: ActionTypes.ATS_COLLAPSE_MENU })
  }
  const handleLmsCollapse = () => {
    dispatch({ type: ActionTypes.LMS_COLLAPSE_MENU })
  }
  const handleAtsManageCollapse = () => {

    dispatch({ type: ActionTypes.ATS_COLLAPSE_SUB_MENU })
  }

  const handleAtsReportCollapse = () => {
    dispatch({ type: ActionTypes.ATS_REPORT_COLLAPSE_MENU })
  }
  const handleEmployeeCollapse = () => {
    dispatch({ type: ActionTypes.EMPLOYEE_COLLAPSE_MENU })
  }
  const handleFinanceCollapse = () => {
    dispatch({ type: ActionTypes.FINACNE_COLLAPSE_MENU })
  }

  const handlePayrollCollapse = () => {
    dispatch({ type: ActionTypes.PAYROLL_COLLAPSE_MENU })
  }


  const ATSAccess = atsModuleData?.find(
    (item) => item.module_name === "ATS"
  );
  const leaveAccess = atsModuleData?.find(
    (item) => item.module_name === "Leave"
  );
  const CoreHRAccess = atsModuleData?.find(
    (item) => item.module_name === "Core HR"
  );

  const myEmployeeAccess = atsModuleData?.find(
    (item) => item.module_name === "My Employee"
  );
  const settingsAccess = atsModuleData?.find(
    (item) => item.module_name === "Settings"
  );

  const atsDashboarAccess = atsModuleData?.find(
    (item) => item.module_name === "ATS Dashboard"
  );
  const atsCandidateAccess = atsModuleData?.find(
    (item) => item.module_name === "ATS Candidate"
  );

  const atsJobPostAccess = atsModuleData?.find(
    (item) => item.module_name === "ATS Job Post"
  );

  const atsConfigureAccess = atsModuleData?.find(
    (item) => item.module_name === "ATS Configure"
  );

  const atsReportCandidateProgressAccess = atsModuleData?.find(
    (item) => item.module_name === "ATS Candidate Progress"
  );

  const atsReportRecruiterEfficiencyMetricsAccess = atsModuleData?.find(
    (item) => item.module_name === "ATS Recruiter Efficiency Metrics"
  );
  const sideBarData1 = [
    { label: "Home", icon: <DashboardIcon fontSize='small' />, route: "/home", show: true },
    { label: "It's Me", icon: <BadgeIcon fontSize='small' />, route: "/employee", show: CoreHRAccess },
    // { label: "My Employee", icon: <PersonIcon fontSize='small' />, route: "/myemployee",show: myEmployeeAccess,state: "grid"  },
    // { label: "My Leave (Beta)", icon: <EditCalendarIcon fontSize='small' />, route: "/leave", show: leaveAccess},
  ]

  const sideBarData2 = [
    { label: "Quick Setup", icon: <AutoFixHighIcon fontSize='small' />, route: "/configure-setting", show: configurestatusData?.[0]?.onboarding_completed === false ? true : false, state: "Quick Setup" },
    { label: "General", icon: <SettingsSuggestIcon fontSize='small' />, route: "/settings", show: CoreHRAccess, },
    // { label: "Onboarding ", icon: <DepartureBoardIcon fontSize='small' />, route: "/onboarding-settings", show: CoreHRAccess, },
    { label: "Finance ", icon: <SavingsIcon fontSize='small' />, route: "/finance-settings", show: CoreHRAccess && ['cvtmeepl', "xpohrmeepl", "altiio", "adobemeepl", "kmkmeepl", "paycmeepl", "bpmeepl", "nirahameepl", "abmeepl", "uatkmmeepl","sogbimeepl"].includes(userData?.domain) && (userData?.f_country_code === "IND") },
    { label: "Roles & Access", icon: <AccessibilityIcon fontSize='small' />, route: "/role-access", show: false, },
    { label: "ATS", icon: <ContentPasteSearchIcon fontSize='small' />, route: "/ats/ats-settings/", show: atsConfigureAccess },
  ]

  const financeOptions = [
    { label: "Payroll Home", icon: <AccountBalanceIcon fontSize='small' />, route: "/payroll" },
    { label: "Reports", icon: <AssessmentIcon fontSize='small' />, route: "/finance-report" },
    { label: "Tax Declaration", icon: <RuleIcon fontSize='small' />, route: "/tax-declaration" },
  ]
  const payrollOptions = [
    { label: "Employee Payroll", icon: <WorkIcon fontSize='small' />, route: "/myemployee-payroll", show: myEmployeeAccess, state: "grid" },
    { label: "Employee Time", icon: <WorkHistoryIcon fontSize='small' />, route: "/employee-time", show: myEmployeeAccess, state: "grid" },
    { label: "Report", icon: <RequestQuoteIcon fontSize='small' />, route: "/myemployee-reports", show: myEmployeeAccess, state: "grid" },
    { label: "Payroll Bulk Upload", icon: <CloudUploadIcon fontSize='small' />, route: "/payroll-bulk-upload", show: myEmployeeAccess, state: "grid" },
    { label: "Tax Declaration", icon: <RuleIcon fontSize='small' />, route: "/tax-declaration", show: myEmployeeAccess, state: "grid" },
  ]
  const atsOptions = [
    { label: "ATS Dashboard", icon: <DashboardIcon fontSize='small' />, route: "/ats/dashboard", show: atsDashboarAccess },
    { label: "Tasks", icon: <AssignmentIcon fontSize='small' />, route: "/ats/tasks/", show: true },
    { label: "Job Dashboard", icon: <WorkIcon fontSize='small' />, route: "/ats/manage-job", show: atsJobPostAccess },
    { label: "Candidate", icon: <PersonIcon fontSize='small' />, route: "/ats/manage-candidate", show: atsCandidateAccess },
  ]
  const atsSubMenu = [
    { label: "Add Users", icon: <EngineeringIcon fontSize='small' />, route: "/ats/manage-user", show: true, tooltip: "Manage Users" },
    { label: "Add Client", icon: <AccountBalanceIcon fontSize='small' />, route: "/ats/manage-company", show: !isGroupOrgFlowTrue, tooltip: "Manage Clients" },
    { label: "Add Group Organisation", icon: <AccountBalanceIcon fontSize='small' />, route: "/ats/manage-group-company", show: isGroupOrgFlowTrue, tooltip: "Manage Group Organisation" },
    { label: "Add Hiring Partner", icon: <GroupWorkIcon fontSize='small' />, route: "/ats/manage-consultancy", show: true, tooltip: "Manage Hiring partner" },
  ]
  const atsReportMenu = [
    { label: "Dashboard", icon: <DashboardIcon fontSize='small' />, route: "/ats/manage-reports/", show: atsReportCandidateProgressAccess ? true : false, tooltip: "Reports Dashboard", },
    { label: "Operational Reports", icon: <DescriptionIcon fontSize='small' />, route: "/ats/reports/", show: atsReportRecruiterEfficiencyMetricsAccess ? true : false, tooltip: "Quality Reports", },
    { label: "Revenue", icon: <AttachMoneyIcon fontSize='small' />, route: "/ats/revenue/", show: atsReportCandidateProgressAccess ? true : false, tooltip: "Revenue", },
  ]

  //LMS Section
  const lmsSubMenu = [
    { label: "Dashboard", icon: <DashboardIcon fontSize='small' />, route: "/lms/manage-dashboard", show: leaveAccess },
    { label: "My Timesheet", icon: <PendingActionsIcon fontSize='small' />, route: "/lms/manage-timesheet", show: leaveAccess },
    { label: "Team", icon: <Diversity3Icon fontSize='small' />, route: "/lms/manage-team", show: myEmployeeAccess },
    { label: "Time Bulk Upload", icon: <CloudUploadIcon fontSize='small' />, route: "/time-bulk-upload", show: myEmployeeAccess },
  ]


  const employeeOptions = [
    { label: "Employee", icon: <AssignmentIndIcon fontSize='small' />, route: "/myemployee", show: myEmployeeAccess, state: "grid" },
    { label: "Employee Access Control", icon: <ManageAccountsIcon fontSize='small' />, route: "/myemployee-access", show: myEmployeeAccess, state: "grid" },
    { label: "Employee Bulk Upload", icon: <CloudUploadIcon fontSize='small' />, route: "/employee-bulk-upload", show: myEmployeeAccess, state: "grid" },
  ]

  return (
    <>
      <Box sx={{ display: 'flex' }} role="presentation" className={`${isMobile && "mobileview"}`}>
        <Popup
          maxWidth="xs"
          openPopup={show}
          setOpenPopup={setShow}
          close={false}
        >
          <PlanExpired openPopup={show} setOpenPopup={setShow} userInfoData={userInfoData} popupContent={popupContent} setPopupContent={setPopupContent} planExpired={planExpired} planRemain={planRemain} />
        </Popup>
        <CssBaseline />
        <PowerUserWarningPopup />
        <ProposeCandidateConfirmation />
        {
          <MobileMenu
            settingsAccess={settingsAccess}
            open={openMobileMenu}
            close={setOpenMobileMenu}
            sideBarData1={sideBarData1}
            sideBarData2={sideBarData2}
            handleMenuClick={handleMenuClick}
            selectedMenu={selectedMenu}
            isMobile={isMobile}
            theme={theme}
            userData={userData}
            financeCollapse={financeCollapse}
            setFinanceCollapse={handleFinanceCollapse}
            payrollCollapse={payrollCollapse}
            setPayrollCollapse={handlePayrollCollapse}
            access={access}
            financeOptions={financeOptions}
            payrollOptions={payrollOptions}
            atsCollapse={atsCollapse}
            setAtsCollapse={handleAtsCollapse}
            atsOptions={atsOptions}
            atsSubMenu={atsSubMenu}
            atsManageCollapse={atsManageCollapse}
            setAtsManageCollapse={handleAtsManageCollapse}
            atsReportCollapse={atsReportCollapse}
            setReportCollapse={handleAtsReportCollapse}
            atsReportMenu={atsReportMenu}
            lmsCollapse={lmsCollapse}
            setLmsCollapse={handleLmsCollapse}
            lmsSubMenu={lmsSubMenu}
            EmployeeCollapse={EmployeeCollapse}
            setEmployeeCollapse={handleEmployeeCollapse}
            employeeOptions={employeeOptions}
          />
        }
        {
          !isMobile &&
          <Drawer
            PaperProps={{
              sx: {
                borderRight: "1px dashed rgba(145, 158, 171, 0.26)",
                mr: -4,
                backgroundColor: theme.palette.mode === "dark" ? theme?.palette?.dark?.dark1 : theme?.palette?.root?.main,
              }
            }}

            variant="permanent"
            open={open}
            className="menulanding"
            fixMenuVal={fixedMenu}
            onMouseEnter={() => (!fixedMenu && !isMobile) && handleDrawerOpen()}
            onMouseLeave={() => (!fixedMenu && !isMobile) && handleDrawerClose()}
          >
            <DrawerHeader
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between", // Ensures proper spacing
                padding: "0 10px", // Adds some spacing from the edges
              }}
            >
              {/* Logo Container */}
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  width: "140px", // Set your desired width
                  height: "40px",
                }}
              >
                <FullLogo style={{ width: "100%", height: "100%", objectFit: "contain" }} />
              </Box>

              {/* Icon Button */}
              <IconButton
                onClick={handleDrawerFix}
                sx={{ padding: 0, margin: 0 }}
              >
                {open ? (
                  <Checkbox
                    color="secondary"
                    {...label}
                    icon={<CircleOutlinedIcon />}
                    checkedIcon={<AdjustIcon />}
                    checked={fixedMenu}
                  />
                ) : !isMobile ? (
                  <Box sx={{ height: "50px", width: "40px" }}>
                    <Logo />
                  </Box>
                ) : (
                  <MenuIcon />
                )}
              </IconButton>
            </DrawerHeader>

            {
              userData?.domain === "meepl" ?
                <AdminMenuBar
                  handleMenuClick={handleMenuClick}
                  theme={theme}
                  selectedMenu={selectedMenu}
                  open={open}
                  isMobile={isMobile}
                />
                :
                <List sx={{ padding: "0px 3px 0px 3px", overflowY: open ? "auto" : "none", maxHeight: collapse ? "50%" : "80%" }}>
                  {
                    sideBarData1.map((res, id) => {
                      return (
                        <Link key={id} to={{ pathname: res?.route, state: res?.state }}>
                          {res?.show &&
                            <ListItem
                              key={id}
                              disablePadding
                              sx={{ display: 'block', fontSize: "12px", marginLeft: `${isMobile ? "-4px" : ''}` }}
                              onClick={(e) => (handleMenuClick(res?.label))}
                            >
                              <ListItemButton
                                selected={selectedMenu === res?.label}
                                key={id}
                                sx={{
                                  "&.Mui-selected": {
                                    // backgroundColor: 
                                  },
                                  borderRadius: "8px",
                                  fontWeight: 600,
                                  minHeight: 10,
                                  justifyContent: open ? 'initial' : 'center',
                                  px: 1.5,
                                }}
                              >
                                <ListItemIcon
                                  key={id}
                                  sx={{
                                    minWidth: 0,
                                    mr: open ? 2 : 'auto',
                                    justifyContent: 'center',
                                  }}
                                >
                                  <span style={{ color: selectedMenu === res?.label && `${theme?.palette?.primary?.main}` }}>{res?.icon}</span>
                                </ListItemIcon>
                                <ListItemText primaryTypographyProps={{
                                  variant: "body2",
                                  fontWeight: selectedMenu === res?.label ? 600 : 550,
                                  fontSize: "0.8rem",
                                  color: selectedMenu === res?.label ? "primary" : grey[600]
                                }} primary={res?.label} sx={{ opacity: open ? 1 : 0 }} />
                              </ListItemButton>
                            </ListItem>
                          }
                        </Link>
                      )
                    })
                  }

                  {myEmployeeAccess && <EmployeeMenu
                    EmployeeCollapse={EmployeeCollapse}
                    setEmployeeCollapse={handleEmployeeCollapse}
                    open={open}
                    employeeOptions={employeeOptions}
                    selectedMenu={selectedMenu}
                    handleMenuClick={handleMenuClick}
                    isMobile={isMobile}
                    theme={theme}
                  />}
                  {leaveAccess && (userData?.f_country_code === "IND") && <LmsMenu
                    lmsCollapse={lmsCollapse}
                    setLmsCollapse={handleLmsCollapse}
                    open={open}
                    lmsSubMenu={lmsSubMenu}
                    selectedMenu={selectedMenu}
                    handleMenuClick={handleMenuClick}
                    isMobile={isMobile}
                    theme={theme}
                  />}
                  {/* Finance Menu */}
                  {myEmployeeAccess && ['cvtmeepl', "xpohrmeepl", "altiio", "adobemeepl", "kmkmeepl", "paycmeepl", "bpmeepl", "nirahameepl", "abmeepl", "uatkmmeepl","sogbimeepl"].includes(userData?.domain) &&
                    (userData?.f_country_code === "IND") &&
                    <FinanceMenu
                      financeCollapse={financeCollapse}
                      setFinanceCollapse={handleFinanceCollapse}
                      payrollCollapse={payrollCollapse}
                      setPayrollCollapse={handlePayrollCollapse}
                      open={open}
                      financeOptions={financeOptions}
                      payrollOptions={payrollOptions}
                      selectedMenu={selectedMenu}
                      handleMenuClick={handleMenuClick}
                      isMobile={isMobile}
                      theme={theme}
                    />}

                  {/* ATS Menu */}
                  {atsDashboarAccess && ATSAccess && <AtsMenu
                    userInfoData={userInfoData}
                    atsCollapse={atsCollapse}
                    setAtsCollapse={handleAtsCollapse}
                    open={open}
                    sideBarData={atsOptions}
                    userData={userData}
                    selectedMenu={selectedMenu}
                    handleMenuClick={handleMenuClick}
                    theme={theme}
                    isMobile={isMobile}
                    atsSubMenu={atsSubMenu}
                    setAtsManageCollapse={handleAtsManageCollapse}
                    atsManageCollapse={atsManageCollapse}
                    atsReportCollapse={atsReportCollapse}
                    setReportCollapse={handleAtsReportCollapse}
                    atsReportMenu={atsReportMenu}
                  />}
                </List>
            }

            {/* Settings Menu */}
            {settingsAccess && <SettingsMenu
              atsCollapse={atsCollapse}
              setAtsCollapse={handleAtsCollapse}
              collapse={collapse}
              setCollapse={setCollapse}
              open={open}
              sideBarData2={sideBarData2}
              userData={userData}
              selectedMenu={selectedMenu}
              handleMenuClick={handleMenuClick}
              theme={theme}
            />}
            <List sx={{ position: "fixed", bottom: 0, width: "inherit", padding: "0px 0px 15px 0px", }}>
              {
                settingsAccess && !collapse &&
                <>

                  <ListItem disablePadding sx={{ display: 'block', fontSize: "12px", paddingLeft: "5px" }}>
                    <ListItemButton sx={{ px: 1.5, borderRadius: "8px", fontWeight: 600, }} onClick={() => { !collapse && atsCollapse && handleAtsCollapse(); setCollapse(!collapse) }} disableRipple>
                      <ListItemIcon sx={{ minWidth: "35px" }}>
                        <SettingsSuggestIcon />
                      </ListItemIcon>

                      <ListItemText primaryTypographyProps={{
                        variant: "body2",
                        fontWeight: selectedMenu === "Settings" ? 600 : 550,
                        fontSize: "0.8rem",
                        color: selectedMenu === "Settings" ? "primary" : grey[600]
                      }} primary="Settings" sx={{ opacity: open ? 1 : 0 }} />
                      {open && collapse ? <ExpandMore /> : open && <ExpandLess />}
                    </ListItemButton>
                  </ListItem>

                </>
              }
            </List>
          </Drawer >
        }
        <Box component="main" sx={{ flexGrow: 1, width: "100%" }}>
          <AppBar isMobile={isMobile} theme={theme.palette.mode} setOpenMobileMenu={setOpenMobileMenu} />
          <ToastContainer
            position="bottom-left"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          {children}
          <Footer />
        </Box>
      </Box >
    </>
  );
}
