import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MaterialTable from "material-table";

import {
  Typography,
  Box,
  Grid,
  Button,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Tooltip,
  Snackbar,
  Autocomplete,
  Chip,
  FormControl,
  TextField,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import AddIcon from "@mui/icons-material/Add";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import SendIcon from "@mui/icons-material/Send";
import {
  DeleteAtsCandidateAction,
  GetAtsCandidateAction,
} from "../../../../../store/ATS/Candidate/Action";
import { GetQuickSearchAction } from "../../../../../store/ATS/Candidate/Action";
import Popup from "../../../Base/views/Popup";
import ResumeSharingForm from "./ResumeSharingForm";
import FlagOutlinedIcon from "@mui/icons-material/FlagOutlined";
import ContactEmailForm from "./ContactEmailForm";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { ATS_CANDIDATE, BASE_URL } from "../../../../Constants/baseConstant";
import { GetAtsExternalCandidateHelper } from "../../helper/AtsExternalCandidateHelper";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import { getUserData, s3Client } from "../../../Base/helper/baseFunctions";
import { ConvertDateTime } from "../../../Base/helper/baseFunctions";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import NextWeekIcon from '@mui/icons-material/NextWeek';
import ArchiveIcon from "@mui/icons-material/Archive";
import { UpdateStatusHelper } from "../../../Ancillary/StatusUpdate/helper/StatusUpdateHelper";
import ConfirmationDialog from "../../../Base/views/ConfirmationDialog";
import ResumeUploader from "./ResumeUploader";
import FilterListIcon from "@mui/icons-material/FilterList";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import history from "../../../../../history";
import { GetCandidateProposedToJobsHelper } from "../../helper/AtsCandidateHelper";
import { PowerUserDisabledAction } from "../../../../../store/RoleAccess/Permission/Action";
import ComingSoon from "../../../Base/views/ComingSoon";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { toast } from "react-toastify";
import { getSignedUrl } from "@aws-sdk/s3-request-presigner";
import { GetObjectCommand } from "@aws-sdk/client-s3";
import ProposeCandidateForm from "./ProposeCandidateForm";
import { GetReportRecruiterAction } from "../../../../../store/ATS/Report/Action";
import SelectableSearch from "../../../Base/views/SelectableSearch";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Paper sx={{ borderRadius: "10px", overflowY: "auto" }}>
          {children}
        </Paper>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const AtsCandidate = ({
  featuresData,
  currentData,
  setCurrentMode,
  setCurrentData,
  handleValidatePopup,
  openValidatePopup,
  value,
  setValue,
}) => {
  const DownTime = false;
  const theme = useTheme();
  const dispatch = useDispatch();
  const { atsCandidateData, atsQuickSearchCandidateData, isLoading } =
    useSelector((state) => state.AtsReducer?.AtsCandidateReducer);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openPopup, setOpenPopup] = useState(false);
  const [proposePopup, setProposePopup] = useState(false);
  const [openemailPopup, setOpenEmailPopup] = useState(false);
  const [opendeletePopup, setOpenDeletePopup] = useState(false);
  const [atsExternalCandidate, setatsExternalCandidate] = useState([]);
  const [loadingUnverified, setLoadingUnverified] = useState(true);
  const [recruiterData, setRecruiterData] = useState({ load: true, values: [] });
  const [filters, setFilters] = useState({ recruiter_id: [] });
  const { atsRecruiterData } = useSelector(state => state.AtsReducer?.AtsReportReducer);
  const { success } = useSelector(
    (state) => state.AtsReducer?.AtsPythonApiReducer
  );
  const userData = getUserData();
  const [copyLink, setCopyLink] = useState(false);
  const [archiveCandidate, setArchiveCandidate] = useState(false);
  const [searchtags, setSearchTags] = useState(() => JSON.parse(sessionStorage.getItem("tagsSearch")) || []);
  const open = Boolean(anchorEl);
  const [count, setcount] = useState(0);
  useEffect(() => {
    handleQuickSearch();
    dispatch(GetReportRecruiterAction());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const primary_recruiter = `{${filters?.recruiter_id?.map((item) => item.ats_users_id).join(",")}}`;
    if (value === 0) {
      dispatch(GetAtsCandidateAction({ primary_recruiter: primary_recruiter !== "{}" ? primary_recruiter : null }));
    }
    else if (value === 1) {
      handleExternalCandidateGet({ primary_recruiter: primary_recruiter !== "{}" ? primary_recruiter : null });
    }
  }, [filters])



  useEffect(() => {
    handleExternalCandidateGet();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openValidatePopup]);

  useEffect(() => {
    if (count !== 0 && success) {
      handleChange(null, 1);
    } else {
      setcount(count + 1);
    }
  }, [success]);

  const handleFilter = (event, value) => {
    if (["recruiter_id"].includes(event)) {
      setFilters({ ...filters, [event]: value })
    }
  }

  const handleQuickSearch = () => {
    const storedTags = JSON.parse(sessionStorage.getItem("tagsSearch"));
    if (storedTags?.length > 0) {
      setSearchTags(storedTags);
      QuickSearch(storedTags);
    }
    else {
      dispatch(GetQuickSearchAction());
    }
  }

  const QuickSearch = (tags) => {
    let stages = tags.map((tag) => tag);
    let final = stages.length > 0 ? `{${stages.join(",")}}` : null;

    dispatch(
      GetQuickSearchAction({
        search: final,
      })
    );
  };
  const handleTagChange = (event, values) => {
    const formattedValues = values.map((value) => `${value}`);
    setSearchTags(formattedValues);
    sessionStorage.setItem("tagsSearch", JSON.stringify(formattedValues));
    QuickSearch(formattedValues);
  };
  const handleExternalCandidateGet = (params) => {
    GetAtsExternalCandidateHelper(params).then((res) => {
      if (res?.data?.statuscode === 200) {
        setatsExternalCandidate(res?.data?.data);
        setLoadingUnverified(false);
      }
    });
  };

  const handleDisabled = (permission) => {
    const foundPermission = featuresData?.find(
      (item) => item.permission === permission
    );
    return foundPermission ? true : false;
  };

  const handleClick = (event, data) => {
    setCurrentData(data);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const hanldeEdit = (data) => {
    setCurrentMode("edit");
    setCurrentData({ ...data, tabvalue: value });
  };

  const handleAdd = () => {
    setCurrentData(null);
    setCurrentMode("add");
  };

  const openSharePopup = () => {
    setOpenPopup(true);
    handleClose();
  };
  const openEmailPopup = () => {
    setOpenEmailPopup(true);
    handleClose();
  };

  const handleChange = (event, newValue) => {
    if (handleDisabled("candidate_resume_processor") && newValue === 2) {
      dispatch(PowerUserDisabledAction());
      return;
    }
    if (newValue === 1) {
      setLoadingUnverified(true);
      handleExternalCandidateGet();
    } else if (newValue === 0) {
      dispatch(GetAtsCandidateAction());
    }
    if (newValue !== 3 && searchtags?.length > 0) {
      setSearchTags([]);
      sessionStorage.removeItem("tagsSearch");
      dispatch(GetQuickSearchAction({
        search: null,
      }));
    }
    setValue(newValue);
    setFilters({ recruiter_id: [] })
  };

  const handleLinkClick = () => {
    setCopyLink(true);
    const id =
      userData?.user_type === "Consultancy"
        ? userData?.consultancy_id
        : userData?.user_type === "Company"
          ? userData?.ats_company_id
          : "0";
    let candidate_token = `${id}=${userData?.domain}=${userData?.user_type}`;
    navigator.clipboard.writeText(
      `${BASE_URL}/ats/career-portal/candidate/${btoa(candidate_token)}`
    );
  };

  const handleArchiveCandidate = () => {
    setArchiveCandidate(true);
  };

  const handleConfirmArchiveCandidate = () => {
    setArchiveCandidate(false);

    UpdateStatusHelper({
      update_id: currentData?.candidate_id,
      status: "Inactive",
      column_name: ATS_CANDIDATE,
      successMessage: "Candidate Archived Successfully",
    }).then((res) => {
      if (res?.data?.statuscode === 200) handleExternalCandidateGet();
    });
    handleClose();
  };

  const handleConfirmArchiveVerifiedCandidate = () => {
    setOpenDeletePopup(false);

    GetCandidateProposedToJobsHelper({
      candidate_id: currentData?.candidate_id,
    }).then((res) => {
      if (res?.data?.statuscode === 200)
        UpdateStatusHelper({
          update_id: currentData?.candidate_id,
          status: "Inactive",
          column_name: "candidate_pk",
          successMessage: "Candidate Archived Successfully",
        }).then((res) => {
          if (res?.data?.statuscode === 200) dispatch(GetAtsCandidateAction());
        });

      UpdateStatusHelper({
        update_id: res?.data?.data[0]?.f_candidate_job_csv,
        status: "Inactive",
        column_name: "candidate_job_pk",
        hide: "true",
      }).then((res) => {
        if (res?.data?.statuscode === 200) dispatch(GetAtsCandidateAction());
      });
    });

    handleClose();
  };

  const handleConfirmDeleteCandidate = () => {
    setOpenDeletePopup(false);
    deleteCandidate(currentData);
    handleClose();
  };

  const deleteCandidate = (Data) => {
    dispatch(
      DeleteAtsCandidateAction({
        candidate_id: Data?.candidate_id,
      })
    );
  };

  const handleView = async (event, rowData) => {
    event.stopPropagation();
    let filename = rowData?.resume;
    const bucketParams = {
      Bucket: process.env.REACT_APP_DO_BUCKET_NAME,
      Key: filename,
    };
    try {
      const url = await getSignedUrl(
        s3Client,
        new GetObjectCommand(bucketParams),
        { expiresIn: 3 * 60 }
      );
        window.open(`https://docs.google.com/viewer?url=${encodeURIComponent(url)}&embedded=true`, "_blank");
      return url;
    } catch (err) {
      toast.error(err);
    }
  };

  const TableTitle = (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Typography sx={{ fontSize: "15px !important" }} variant="h6">
        {" "}
        Verified Candidates
      </Typography>
      <Chip
        label={`${atsCandidateData?.length ? atsCandidateData.length : "0"
          } Candidates`}
        color="secondary"
        sx={{
          marginLeft: "5px",
          fontSize: "11px !important",
          fontWeight: "600",
        }}
      />
      <Box sx={{ display: "flex", alignItems: "center" }}></Box>
    </Box>
  );
  const NotVerifiedTableTitle = (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Typography sx={{ fontSize: "15px !important" }} variant="h6">
        Not Verified Candidates
      </Typography>
      <Chip
        label={`${atsExternalCandidate?.length ? atsExternalCandidate.length : "0"
          } Candidates`}
        color="secondary"
        sx={{
          marginLeft: "5px",
          fontSize: "11px !important",
          fontWeight: "600",
        }}
      />
      <Box sx={{ display: "flex", alignItems: "center" }}></Box>
    </Box>
  );
  const handleClickPropose = (event, rowData) => {
    event.stopPropagation();
    setCurrentData(rowData);
    setProposePopup(true)
  }
  return (
    <>
      <ConfirmationDialog
        title="Archive"
        content="Are you sure want to Archive?"
        openDialog={archiveCandidate}
        closeDialog={setArchiveCandidate}
        popupTitle="Archive"
        OkButtonText="Archive"
        onSubmit={handleConfirmArchiveCandidate}
      />
      <Snackbar
        open={copyLink}
        onClose={() => setCopyLink(false)}
        autoHideDuration={2000}
        message="Copied to clipboard"
      />
      <Paper
        sx={{
          borderRadius: "10px",
          maxHeight: "87vh",
          overflowY: "auto",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Popup
          fullWidth={true}
          maxWidth="md"
          title="Share Resume"
          openPopup={openPopup}
          setOpenPopup={setOpenPopup}
        >
          <ResumeSharingForm
            setOpenPopup={setOpenPopup}
            currentData={currentData}
          />
        </Popup>
        <Popup
          title="Propose for a Job"
          openPopup={proposePopup}
          setOpenPopup={setProposePopup}
        >
          <ProposeCandidateForm
            currentData={currentData}
            setOpenPopup={setProposePopup}
          />
        </Popup>
        <Popup
          fullWidth={true}
          maxWidth="md"
          title="Contact Email"
          openPopup={openemailPopup}
          setOpenPopup={setOpenEmailPopup}
        >
          <ContactEmailForm
            setOpenPopup={setOpenEmailPopup}
            currentData={currentData}
          />
        </Popup>

        <ConfirmationDialog
          title="Delete Candidate"
          content={
            currentData?.proposed_to_jobs ? (
              <>
                Candidate has already been proposed to some jobs. Do you want to
                archive the candidate from all relevant jobs ?
              </>
            ) : (
              <>
                This action will permanently remove the candidate record from
                Database . Do you want to proceed ?
              </>
            )
          }
          openDialog={opendeletePopup}
          closeDialog={setOpenDeletePopup}
          popupTitle="Delete Candidate"
          OkButtonText="Yes"
          onSubmit={
            currentData?.proposed_to_jobs
              ? handleConfirmArchiveVerifiedCandidate
              : handleConfirmDeleteCandidate
          }
        />
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          sx={{ mb: 1 }}
        >
          <Tab label="Verified" {...a11yProps(0)} />
          <Tab label="Not Verified" {...a11yProps(1)} />
          <Tab label="Resume Processor" {...a11yProps(2)} />
          <Tab label="Quick Search" {...a11yProps(3)} />
        </Tabs>
        <TabPanel value={value} index={0}>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Box>
              <Box sx={{ mt: 1 }}>
                <Grid container columnSpacing={1} mb={1}>
                  <Grid key="recruiter_id" item xs={12} sm={4}>
                    <SelectableSearch
                      isOptionEqualToValue={(option, value) =>
                        +option.ats_users_id === +value.ats_users_id
                      }
                      label="Recruiter"
                      multiple={true}
                      value={filters.recruiter_id}
                      loading={() => setRecruiterData({ load: false, values: atsRecruiterData })}
                      fieldLabel="first_name"
                      required={false}
                      grouping={true}
                      variant={"outlined"}
                      name="recruiter_id"
                      onChangeEvent={handleFilter}
                      data={recruiterData}
                      size="small"
                    />
                  </Grid>

                </Grid>
              </Box>
              <MaterialTable
                onRowClick={(event, rowData) => hanldeEdit(rowData)}
                isLoading={isLoading}
                columns={[
                  {
                    title: "Name",
                    cellStyle: { padding: "10px 10px 10px 13px" },
                    field: "full_name",
                    grouping: false,
                    render: (rowData) => {
                      return (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <span
                            style={{
                              margin: rowData?.flag_reason
                                ? "0px 0px 0px 16px"
                                : "0px 0px 0px 0px",
                            }}
                          >
                            {rowData?.full_name}
                          </span>
                          {rowData?.flag_reason && (
                            <Tooltip title={rowData?.flag_reason}>
                              <FlagOutlinedIcon
                                fontSize="small"
                                color="error"
                              />
                            </Tooltip>
                          )}
                        </div>
                      );
                    },
                  },
                  {
                    title: "Contact",
                    cellStyle: { padding: "10px 10px 10px 13px" },
                    field: "contact_no",
                    grouping: false,
                  },
                  {
                    title: "Email",
                    cellStyle: { padding: "10px 10px 10px 13px" },
                    field: "email_id",
                    grouping: false,
                  },
                  {
                    title: "Location",
                    cellStyle: { padding: "10px 10px 10px 13px" },
                    field: "location",
                    grouping: false,
                  },
                  {
                    title: "Last Modified By",
                    cellStyle: { padding: "10px 10px 10px 13px" },
                    field: "modifiedby",
                    grouping: false,
                  },
                  {
                    title: "Last Modified On",
                    cellStyle: { padding: "10px 10px 10px 13px" },
                    field: "modifiedon",
                    sorting: true,
                    defaultSort: "desc",
                    render: (rowData) => {
                      return ConvertDateTime(rowData.modifiedon);
                    },
                  },
                  {
                    title: "Resume",
                    cellStyle: { padding: "10px 10px 10px 13px" },
                    sorting: false,
                    grouping: false,
                    render: rowData => (
                      <div>
                        {rowData.resume ? (
                          <IconButton
                            onClick={(event) => handleView(event, rowData)}
                          >
                            <VisibilityIcon />
                          </IconButton>
                        ) : (
                          <IconButton disabled>
                            <VisibilityOffIcon />
                          </IconButton>
                        )}
                      </div>
                    ),
                  },
                  {
                    title: "Propose Job",
                    cellStyle: { padding: "10px 10px 10px 13px" },
                    sorting: false,
                    grouping: false,
                    render: rowData => (
                      <div>
                        <IconButton
                          disabled={handleDisabled("candidate_propose_candidate")}
                          onClick={(event) => handleClickPropose(event, rowData)}
                        >
                          <NextWeekIcon />
                        </IconButton>

                      </div>
                    ),
                  },
                ]}
                data={atsCandidateData || []}
                options={{
                  maxBodyHeight: "69vh",
                  minBodyHeight: "69vh",
                  rowStyle: { fontSize: "13px" },
                  thirdSortClick: false,
                  headerStyle: {
                    backgroundColor:
                      theme?.palette?.mode === "light"
                        ? theme.palette.grey[0]
                        : theme.palette.grey[500],
                    color:
                      theme?.palette?.mode === "light"
                        ? theme.palette.primary.contrastText
                        : theme.palette.grey[800],
                    fontWeight: "bold",
                    padding: "10px 10px 10px 13px",
                  },
                  sorting: true,
                  search: true,
                  searchText: "",
                  searchFieldAlignment: "right",
                  searchAutoFocus: true,
                  searchFieldVariant: "standard",
                  filtering: false,
                  paging: true,
                  pageSizeOptions: [25, 50],
                  pageSize: 25,
                  paginationType: "stepped",
                  showFirstLastPageButtons: false,
                  paginationPosition: "bottom",
                  exportButton: false,
                  exportAllData: true,
                  exportFileName: "Candidate",
                  addRowPosition: "first",
                  selection: false,
                  actionsColumnIndex: -1,
                  showSelectAllCheckbox: true,
                  showTextRowsSelected: true,
                  grouping: false,
                  columnsButton: true,
                }}
                actions={[
                  {
                    icon: () => (
                      <Button
                        component="span"
                        disabled={handleDisabled("candidate_add_candidate")}
                        variant="contained"
                        startIcon={<AddIcon />}
                        className="mpl-primary-btn"
                      >
                        Add
                      </Button>
                    ),
                    isFreeAction: true,
                    position: "row",
                    tooltip: handleDisabled("candidate_add_candidate")
                      ? "You Don't have sufficient permission. Please contact Admin."
                      : "Add",
                    disabled: handleDisabled("candidate_add_candidate"),
                    onClick: (_event) => handleAdd(),
                  },
                  {
                    icon: () => (
                      <Button
                        component="span"
                        variant="contained"
                        startIcon={<InsertLinkIcon />}
                        className="mpl-primary-btn"
                      >
                        Form link
                      </Button>
                    ),
                    isFreeAction: true,
                    position: "row",
                    tooltip: "Generate Form link",
                    onClick: (_event) => handleLinkClick(),
                  },
                  {
                    icon: () => (
                      <IconButton
                        disabled={handleDisabled("candidate_filter_candidate")}
                        className="mpl-primary-btn"
                      >
                        {" "}
                        <FilterListIcon />
                      </IconButton>
                    ),
                    isFreeAction: true,
                    position: "row",
                    tooltip: "Filter",
                    disabled: handleDisabled("candidate_filter_candidate"),
                    onClick: (_event) => history.push("/ats/filter-candidate"),
                  },
                  {
                    icon: () => <MoreVertIcon fontSize="small" />,
                    position: "row",
                    tooltip: "Settings",
                    onClick: (_event, rowData) => handleClick(_event, rowData),
                  },
                ]}
                title={TableTitle}
              />

              <Menu
                id="more-options-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "more-options-button",
                }}
              >
                {currentData?.resume ? (
                  <MenuItem
                    onClick={openSharePopup}
                    disabled={handleDisabled("candidate_share_resume")}
                  >
                    <ListItemIcon>
                      <SendIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Share Resume</ListItemText>
                  </MenuItem>
                ) : (
                  <MenuItem>
                    <ListItemText>No Resume Found</ListItemText>
                  </MenuItem>
                )}
                <MenuItem onClick={openEmailPopup}>
                  <ListItemIcon>
                    <ForwardToInboxIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>Contact Email</ListItemText>
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setOpenDeletePopup(true);
                  }}
                >
                  <ListItemIcon>
                    <DeleteOutlineIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>Delete</ListItemText>
                </MenuItem>
              </Menu>
            </Box>
          </Box>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Box sx={{ mt: 1 }}>
            <Grid container columnSpacing={1} mb={1}>
              <Grid key="recruiter_id" item xs={12} sm={4}>
                <SelectableSearch
                  isOptionEqualToValue={(option, value) =>
                    +option.ats_users_id === +value.ats_users_id
                  }
                  label="Recruiter"
                  value={filters.recruiter_id}
                  multiple={true}
                  loading={() => setRecruiterData({ load: false, values: atsRecruiterData })}
                  fieldLabel="first_name"
                  required={false}
                  grouping={true}
                  variant={"outlined"}
                  name="recruiter_id"
                  onChangeEvent={handleFilter}
                  data={recruiterData}
                  size="small"
                />
              </Grid>

            </Grid>
          </Box>
          <MaterialTable
            onRowClick={(event, rowData) => hanldeEdit(rowData)}
            isLoading={loadingUnverified}
            columns={[
              {
                title: "Name",
                cellStyle: { padding: "10px 10px 10px 13px" },
                field: "full_name",
                grouping: false,
                render: (rowData) => {
                  return (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <span
                        style={{
                          margin: rowData?.flag_reason
                            ? "0px 0px 0px 16px"
                            : "0px 0px 0px 0px",
                        }}
                      >
                        {rowData?.full_name}
                      </span>
                      {rowData?.flag_reason && (
                        <Tooltip title={rowData?.flag_reason}>
                          <FlagOutlinedIcon fontSize="small" color="error" />
                        </Tooltip>
                      )}
                    </div>
                  );
                },
              },
              {
                title: "Contact",
                cellStyle: { padding: "10px 10px 10px 13px" },
                field: "contact_no",
                grouping: false,
              },
              {
                title: "Email",
                cellStyle: { padding: "10px 10px 10px 13px" },
                field: "email_id",
                grouping: false,
              },
              {
                title: "Location",
                cellStyle: { padding: "10px 10px 10px 13px" },
                field: "location",
                grouping: false,
              },
              {
                title: "Last Modified By",
                cellStyle: { padding: "10px 10px 10px 13px" },
                field: "modifiedby",
                grouping: false,
              },
              {
                title: "Last Modified On",
                cellStyle: { padding: "10px 10px 10px 13px" },
                field: "modifiedon",
                render: (rowData) => {
                  return ConvertDateTime(rowData.modifiedon);
                },
              },
              {
                title: "Resume",
                cellStyle: { padding: "10px 10px 10px 13px" },
                sorting: false,
                grouping: false,
                render: rowData => (
                  <div>
                    {rowData.resume ? (
                      <IconButton
                        onClick={(event) => handleView(event, rowData)}
                      >
                        <VisibilityIcon />
                      </IconButton>
                    ) : (
                      <IconButton disabled>
                        <VisibilityOffIcon />
                      </IconButton>
                    )}
                  </div>
                ),
              },
            ]}
            data={atsExternalCandidate || []}
            options={{
              maxBodyHeight: "69vh",
              minBodyHeight: "69vh",
              rowStyle: { fontSize: "13px" },
              headerStyle: {
                backgroundColor:
                  theme?.palette?.mode === "light"
                    ? theme.palette.grey[0]
                    : theme.palette.grey[500],
                color:
                  theme?.palette?.mode === "light"
                    ? theme.palette.primary.contrastText
                    : theme.palette.grey[800],
                fontWeight: "bold",
                padding: "10px 10px 10px 13px",
              },
              sorting: true,
              search: true,
              searchText: "",
              searchFieldAlignment: "right",
              searchAutoFocus: true,
              searchFieldVariant: "standard",
              filtering: false,
              paging: true,
              pageSizeOptions: [25, 50],
              pageSize: 25,
              paginationType: "stepped",
              showFirstLastPageButtons: false,
              paginationPosition: "bottom",
              exportButton: false,
              exportAllData: true,
              exportFileName: "Candidate",
              addRowPosition: "first",
              selection: false,
              actionsColumnIndex: -1,
              showSelectAllCheckbox: true,
              showTextRowsSelected: true,
              grouping: false,
              columnsButton: true,
            }}
            actions={[
              {
                icon: () => <MoreVertIcon fontSize="small" />,
                position: "row",
                tooltip: "Settings",
                onClick: (_event, rowData) => handleClick(_event, rowData),
              },
              {
                icon: () => (
                  <Button
                    component="span"
                    variant="contained"
                    startIcon={<InsertLinkIcon />}
                    className="mpl-primary-btn"
                  >
                    Form link
                  </Button>
                ),
                isFreeAction: true,
                position: "row",
                tooltip: "Generate Form link",
                onClick: (_event, rowData) => handleLinkClick(),
              },
            ]}
            title={NotVerifiedTableTitle}
          />

          <Menu
            id="more-options-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "more-options-button",
            }}
          >
            {currentData?.resume && (
              <MenuItem
                onClick={openSharePopup}
                disabled={handleDisabled("candidate_share_resume")}
              >
                <ListItemIcon>
                  <SendIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>Share Resume</ListItemText>
              </MenuItem>
            )}
            <MenuItem
              onClick={() => {
                handleValidatePopup();
                handleClose();
              }}
              disabled={handleDisabled("candidate_verify_candidate")}
            >
              <ListItemIcon>
                <HowToRegIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Verify</ListItemText>
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleArchiveCandidate();
                handleClose();
              }}
            >
              <ListItemIcon>
                <ArchiveIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Archive</ListItemText>
            </MenuItem>
          </Menu>
        </TabPanel>
        <TabPanel value={value} index={2}>
          {DownTime ? <ComingSoon /> : <ResumeUploader id={false} />}
        </TabPanel>
        <TabPanel value={value} index={3}>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  backgroundColor: theme?.palette?.primary?.toolbar,
                  alignItems: "center",
                  padding: "6px 10px 6px 24px",
                }}
              >
                <Typography variant="h6"> Quick Search</Typography>
                <FormControl sx={{ width: "30%", margin: "5px" }}>
                  <Autocomplete
                    value={searchtags}
                    onChange={handleTagChange}
                    clearIcon={false}
                    size="small"
                    options={[]}
                    freeSolo
                    multiple={true}
                    limitTags={3}
                    id="search"
                    name="search"
                    renderTags={(value, props) =>
                      value.map((option, index) => (
                        <Chip
                          size="small"
                          label={option}
                          {...props({ index })}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        size="small"
                        label="Search"
                        placeholder="Type Your Keywords Here...."
                        {...params}
                        variant="standard"
                      />
                    )}
                  />
                </FormControl>
              </Box>

              <MaterialTable
                onRowClick={(event, rowData) => hanldeEdit(rowData)}
                isLoading={isLoading}
                columns={[
                  {
                    title: "First Name",
                    cellStyle: { padding: "10px 10px 10px 13px" },
                    field: "first_name",
                    grouping: false,
                  },
                  {
                    title: "Last Name",
                    cellStyle: { padding: "10px 10px 10px 13px" },
                    field: "last_name",
                    grouping: false,
                  },
                  {
                    title: "Contact",
                    cellStyle: { padding: "10px 10px 10px 13px" },
                    field: "contact_no",
                    grouping: false,
                  },
                  {
                    title: "Email",
                    cellStyle: { padding: "10px 10px 10px 13px" },
                    field: "email_id",
                    grouping: false,
                  },
                  {
                    title: "Designation",
                    cellStyle: { padding: "10px 10px 10px 13px" },
                    field: "designation",
                    grouping: false,
                  },
                  {
                    title: "Last Modified By",
                    cellStyle: { padding: "10px 10px 10px 13px" },
                    field: "modifiedby",
                    grouping: false,
                  },
                  {
                    title: "Resume",
                    cellStyle: { padding: "10px 10px 10px 13px" },
                    sorting: false,
                    grouping: false,
                    render: rowData => (
                      <div>
                        {rowData.resume ? (
                          <IconButton
                            onClick={(event) => handleView(event, rowData)}
                          >
                            <VisibilityIcon />
                          </IconButton>
                        ) : (
                          <IconButton disabled>
                            <VisibilityOffIcon />
                          </IconButton>
                        )}
                      </div>
                    ),
                  },
                ]}
                data={atsQuickSearchCandidateData || []}
                options={{
                  maxBodyHeight: "69vh",
                  minBodyHeight: "69vh",
                  rowStyle: { fontSize: "13px" },
                  headerStyle: {
                    backgroundColor:
                      theme?.palette?.mode === "light"
                        ? theme.palette.grey[0]
                        : theme.palette.grey[500],
                    color:
                      theme?.palette?.mode === "light"
                        ? theme.palette.primary.contrastText
                        : theme.palette.grey[800],
                    fontWeight: "bold",
                    padding: "10px 10px 10px 13px",
                  },
                  sorting: false,
                  search: false,
                  searchText: "",
                  header: true,
                  searchFieldAlignment: "right",
                  searchAutoFocus: true,
                  searchFieldVariant: "standard",
                  filtering: false,
                  paging: true,
                  pageSizeOptions: [25, 50],
                  pageSize: 25,
                  paginationType: "stepped",
                  showFirstLastPageButtons: false,
                  paginationPosition: "bottom",
                  exportButton: false,
                  exportAllData: true,
                  exportFileName: "Candidate",
                  addRowPosition: "first",
                  selection: false,
                  actionsColumnIndex: -1,
                  showSelectAllCheckbox: true,
                  showTextRowsSelected: true,
                  grouping: false,
                  columnsButton: false,
                  toolbar: false,
                }}
                title=""
              />
            </Box>
          </Box>
        </TabPanel>
      </Paper>
    </>
  );
};

export default AtsCandidate;
