import React from "react";
import { useEffect } from "react";
import NavBar from "../views/Header/Nav";
import OurProduct from "../views/OurProducts/OurProduct";
import Product from "../views/Product/ProductPage";
import HowWork from "../views/HowWorks/HowWork";
import Whyus from "../views/WhyUs/WhyUs";
import Manage from "../views/ManageTime/ManageTime";
import Features from "../views/Features/Feature";
// import People from "../views/PeopleSay/PeopleSaySlider";
import Pricing from "../views/Pricing/Pricing";
import FAQ from "../views/FAQ/Faq";
import FooterSec from "../views/Footer/Footer";
import "../css/CommonCss.css"
import { getSessionData } from "../../Base/helper/baseHelper";
import history from "../../../../history";

const LandingPage = () => {
        const region = getSessionData({ key: "region" });
        useEffect(() => {
                if (region) {
                        history.replace(`/${region}`);
                }
        }, [region]);
        return (
                <>
                        <div className="main_website">
                                <NavBar />
                                <OurProduct />
                                <Product />
                                <HowWork />
                                <Whyus />
                                <Manage />
                                <Features />
                                {/* <People /> */}
                                <Pricing />
                                <FAQ />
                                <FooterSec />
                        </div>
                </>

        );

}
export default LandingPage;