import React, { useEffect, useRef, useState } from "react";
import Webcam from "react-webcam";
import * as faceapi from "face-api.js";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Box, Typography, Button, IconButton } from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import ArrowBackButton from "../Base/views/ArrowBackButton";
import history from "../../../history";
import { GetEmployeesAction } from "../../../store/Employee/Employee/Action";
import { addFaceDataHelper } from "./helper/FaceRecognizationHelper";
import { getSignedUrl } from "@aws-sdk/s3-request-presigner";
import { GetObjectCommand } from "@aws-sdk/client-s3";
import { s3Client } from "../Base/helper/baseFunctions";
import SuccessDialog from "./SuccessDialog";
import face_icon3 from "./images/face3.png";

const Alert = ({ message, flag }) => {
  const containerStyle = {
    backgroundColor:
      flag === "success"
        ? "#b8dfc6"
        : flag === "detect"
          ? "#c4e8fa"
          : "#fde3de",
    borderRadius: "5px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "10px 15px",
    color: "#000",
    position: "relative",
    borderBottom:
      flag === "success"
        ? "1px solid #24965f"
        : flag === "detect"
          ? "1px solid #476b96"
          : "1px solid #c34638",
    marginBottom: "20px",
  };

  const infoIconStyle = {
    color:
      flag === "success"
        ? "#1b9857"
        : flag === "detect"
          ? "#3b649f"
          : "#bf4638",
    marginRight: "10px",
    fontSize: "18px",
  };

  const textStyle = {
    margin: 0,
    flex: 1,
    color: "#375640",
  };

  return (
    <div
      style={{
        width: "100%",
        textAlign: "center",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <div style={containerStyle}>
        <span style={infoIconStyle}>
          {flag === "success" ? (
            <CheckCircleIcon />
          ) : flag === "detect" ? (
            <AccessTimeFilledIcon />
          ) : (
            <ErrorIcon />
          )}
        </span>
        <p style={textStyle}>{message}</p>
      </div>
    </div>
  );
};

const FaceAttendanceTracker = () => {
  const webcamRef = useRef(null);
  const [flag, setFlag] = useState("detect");
  const [facingMode, setFacingMode] = useState("user");
  const [imageUrl, setImageUrl] = useState("");
  const [attendanceMarked, setAttendanceMarked] = useState(false);
  const [message, setMessage] = useState("Detecting face...");
  const [detectedUser, setDetectedUser] = useState(null);
  const dispatch = useDispatch();
  const [openDialog, setOpenDialog] = useState(true);
  const [attendanceType, setAttendanceType] = useState("");
  const [successDialog, setSuccessDialog] = useState(false);
  const [employeeTime, setEmployeeTime] = useState(null);
  const [retry, setRetry] = useState(false);
  const { employees_options } = useSelector(
    (state) => state?.EmployeeReducer.EmployeesReducer
  );
  
  useEffect(() => {
    const loadModels = async () => {
      const MODEL_URL = process.env.PUBLIC_URL + "/models";
      await faceapi.loadTinyFaceDetectorModel(MODEL_URL);
      await faceapi.loadFaceLandmarkModel(MODEL_URL);
      await faceapi.loadFaceRecognitionModel(MODEL_URL);
    };
    loadModels();
    dispatch(GetEmployeesAction({ is_active: true }));
  }, []);
  const getImageSignedUrl = async (currentData) => {
    let filename = currentData?.image;
    const bucketParams = {
      Bucket: process.env.REACT_APP_DO_BUCKET_NAME,
      Key: filename,
    };
    try {
      const url = await getSignedUrl(
        s3Client,
        new GetObjectCommand(bucketParams),
        { expiresIn: 3 * 60 }
      ); // Adjustable expiration.
      setImageUrl(url);
      return url;
    } catch (err) { }
  };
  const detectFace = async () => {
    setMessage("Detecting face...");
    setFlag("detect");
    const registeredUsers = employees_options || [];
    if (webcamRef.current && webcamRef.current.video.readyState === 4) {
      const video = webcamRef.current.video;
      const detections = await faceapi
        .detectSingleFace(video, new faceapi.TinyFaceDetectorOptions())
        .withFaceLandmarks()
        .withFaceDescriptor();
      if (detections) {
        let matchedUser = null;
        registeredUsers.forEach((user) => {
          if (user.descriptor) {
            const userDescriptorArray = new Float32Array(
              JSON.parse(user.descriptor)
            );
            const detectionDescriptorArray = new Float32Array(
              detections.descriptor
            );
            if (
              userDescriptorArray.length === detectionDescriptorArray.length
            ) {
              const distance = faceapi.euclideanDistance(
                userDescriptorArray,
                detectionDescriptorArray
              );
              if (distance < 0.5) {
                matchedUser = user;
              }
            }
          }
        });
        if (matchedUser) {
          getImageSignedUrl(matchedUser);
          setDetectedUser(matchedUser);
          markAttendance(matchedUser);
        } else {
          setMessage("User is unknown");
          setFlag("error");
          setRetry(!retry);
          setTimeout(() => {
          setAttendanceType("");
          setOpenDialog(true);
          }, 1000);
        }
      } else {
        setRetry(!retry);
      }
    } else {
      setRetry(!retry);
    }
  };

  const markAttendance = (data) => {
    const getLocalISOTime = () => {
      const now = new Date();
      const offsetInMinutes = now.getTimezoneOffset(); // Offset in minutes
      const localTime = new Date(now.getTime() - offsetInMinutes * 60000);
      return localTime.toISOString().slice(0, -1); // Remove the "Z" at the end
    };
    const record_time = getLocalISOTime();
    addFaceDataHelper({
      ...data,
      record_time: record_time,
      entry_type: attendanceType,
    }).then((res) => {
      if (res?.data?.statuscode === 200) {
        setAttendanceMarked(false);
        setEmployeeTime(res.data?.data?.[0]);
        // setMessage(`Attendance marked for ${data?.name}`);
        setSuccessDialog(true);
        setTimeout(() => {
          setAttendanceType("");
          setOpenDialog(true);
          setSuccessDialog(false);
        }, 3000);
      }
    });
  };

  const handleDialogClose = (type) => {
    dispatch(GetEmployeesAction({ is_active: true }));
    setAttendanceType(type);
    setOpenDialog(false);
    setAttendanceMarked(true);
  };

  useEffect(() => {
    if (
      employees_options?.length > 0 &&
      (attendanceType === "Check In" || attendanceType === "Check Out")
    ) {
      const interval = setInterval(() => {
        if (webcamRef.current && webcamRef.current.video.readyState === 4) {
          clearInterval(interval);
          detectFace();
        }
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [employees_options, attendanceType, retry]);
  // const toggleCamera = () => {
  //   setFacingMode((prev) => (prev === "user" ? "environment" : "user"));
  // };
  return (
    <>
      <Box
        className="bg_form_main"
        sx={{
          minHeight: "100vh",
          paddingTop: "20px",
          pb: "20px",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            backgroundColor: "rgba(255, 255, 255, 0.2)",
            // backgroundColor: "red",
            backdropFilter: "blur(10px)",
            borderRadius: "10px",
            padding: "5px 20px",
            pb: attendanceMarked ? "80px" : "15px",
            width: "100%",
            maxWidth: "660px",
            margin: "auto",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              margin: { xs: "10px auto", md: "0 auto" },
            }}
          >
            <Box sx={{ width: "1px" }}>
              <IconButton onClick={() => history.goBack()} edge="start">
                <ArrowBackButton />
              </IconButton>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", margin: "auto" }}>
              <Typography
                variant="h6"
                sx={{
                  fontSize: { xs: "20px", sm: "36px", md: "28px" },
                  fontWeight: "bold",
                }}
              >
                Face Recognition Attendance
              </Typography>
            </Box>
          </Box>

          {openDialog && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 2,
                mt: 4,
                mb: 4,
              }}
            >
              <Box
                component="img"
                src={face_icon3}
                alt="face_icon"
                sx={{
                  width: { xs: "150px", sm: "220px", md: "150px" },
                  height: { xs: "150px", sm: "220px", md: "150px" },
                  borderRadius: "10px",
                  mb: { xs: "40px", sm: "50px", md: "40px" },
                }}
              />
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: { xs: 4, sm: 6, md: 4 },
                  flexDirection: { xs: "column", md: "row" },
                }}
              >
                <Button
                  variant="contained"
                  size="large"
                  sx={{
                    width: { xs: "300px", sm: "460px", md: "260px" },
                    height: { xs: "90px", sm: "130px", md: "55px" },
                    fontSize: { xs: "32px", sm: "50px", md: "30px" },
                    padding: 4,
                    color: "black",
                    fontWeight: "bold",
                    background: "#fecb03",
                    borderRadius: "10px",
                    border: "1px solid #fecb03",
                    transition: "all 0.3s ease",
                    "&:hover": {
                      cursor: "pointer",
                      backgroundColor: "#fecb03",
                      boxShadow: "0px 4px 10px rgba(254,203,3, 0.3)",
                    },
                  }}
                  onClick={() => handleDialogClose("Check In")}
                >
                  Check In
                </Button>
                <Button
                  variant="contained"
                  sx={{
                    width: { xs: "300px", sm: "460px", md: "260px" },
                    height: { xs: "90px", sm: "130px", md: "55px" },
                    fontSize: { xs: "32px", sm: "50px", md: "30px" },
                    padding: 4,
                    color: "#fecb03",
                    fontWeight: "bold",
                    background: "black",
                    borderRadius: "10px",
                    border: "1px solid black",
                    transition: "all 0.3s ease",
                    "&:hover": {
                      cursor: "pointer",
                      backgroundColor: "black",
                      boxShadow: "0px 4px 10px rgba(254,203,3, 0.3)",
                    },
                  }}
                  onClick={() => handleDialogClose("Check Out")}
                >
                  Check Out
                </Button>
              </Box>
            </Box>
          )}
          {attendanceMarked && attendanceType && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  textAlign: "center",
                  position: "relative",
                  width: "100%",
                  maxWidth: "640px",
                  height: "440px",
                  margin: "0 auto",
                }}
              >
                <div
                  style={{
                    position: "relative",
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Webcam
                    ref={webcamRef}
                    audio={false}
                    screenshotFormat="image/jpeg"
                    videoConstraints={{
                      width: 640,
                      height: 480,
                      facingMode:facingMode,
                    }}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />
                 
                  <div
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      pointerEvents: "none",
                    }}
                  >
                    <div
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "70px",
                        height: "70px",
                        borderTop: "5px solid #00ff00",
                        borderLeft: "5px solid #00ff00",
                        borderRadius: "16px 0 0 0px",
                      }}
                    />
                    <div
                      style={{
                        position: "absolute",
                        top: 0,
                        right: 0,
                        width: "70px",
                        height: "70px",
                        borderTop: "5px solid #00ff00",
                        borderRight: "5px solid #00ff00",
                        borderRadius: "0 16px 0 0",
                      }}
                    />
                    <div
                      style={{
                        position: "absolute",
                        bottom: 0,
                        left: 0,
                        width: "70px",
                        height: "70px",
                        borderBottom: "5px solid #00ff00",
                        borderLeft: "5px solid #00ff00",
                        borderRadius: "0 0 0 16px",
                      }}
                    />
                    <div
                      style={{
                        position: "absolute",
                        bottom: 0,
                        right: 0,
                        width: "70px",
                        height: "70px",
                        borderBottom: "5px solid #00ff00",
                        borderRight: "5px solid #00ff00",
                        borderRadius: "0 0 16px 0",
                      }}
                    />
                  </div>
                </div>
                {/* <Button variant="contained" onClick={toggleCamera} sx={{ mt: 2 }}>
                    Switch Camera
                  </Button> */}
                <div style={{ margin: "15px" }}>
                  {!attendanceMarked ? (
                    <Alert message={message} flag={flag} />
                  ) : (
                    <Alert message={message} flag={flag} />
                  )}
                </div>
              </div>
            </Box>
          )}

          {successDialog && (
            <SuccessDialog
              attendanceType={attendanceType}
              handleClose={() => {
                setOpenDialog(true);
                setSuccessDialog(false);
              }}
              user={detectedUser}
              imageUrl={imageUrl}
              employeeTime={employeeTime}
            />
          )}
        </Box>
      </Box>
    </>
  );
};

export default FaceAttendanceTracker;