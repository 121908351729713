import { Box, Grid } from "@mui/material";
import MTable from "material-table";
import SelectableSearch from "../../../Base/views/SelectableSearch";
import { useState } from "react";
import { GetWorkLoadAction } from "../../../../../store/ATS/Report/Action";
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getUserData } from "../../../Base/helper/baseFunctions";
import CustomPagination from "../../../Base/views/CustomPagination";
const WorkloadReports = () => {
    const user = getUserData();
    const dispatch = useDispatch();
    const isGroupOrgFlowTrue = user?.grouporg_flow === true;
    const [userData, setUserData] = useState({ load: true, values: [] })
    const [selectedRows, setSelectedRows] = useState([]);
    const [filters, setFilters] = useState({ primary_recruiter_filter: "" });
    const { atsWorkLoadData } = useSelector(state => state.AtsReducer?.AtsReportReducer);
    const { atsRecruiterData } = useSelector(state => state.AtsReducer?.AtsReportReducer);
    const parseAndSum = (field) => atsWorkLoadData?.reduce((acc, curr) => acc + parseInt(curr[field] || '0', 10), 0);
    const sumno_of_jobs = parseAndSum('no_of_jobs');
    const sumno_of_positions = parseAndSum('no_of_positions');
    const hasNonZeroSum = sumno_of_jobs || sumno_of_positions
    const displayableKeys = hasNonZeroSum ? [
        ["Number of Jobs", sumno_of_jobs],
        ["Number of Position", sumno_of_positions],
    ] : [];



    useEffect(() => {
        dispatch(GetWorkLoadAction({
            from_date: null,
            to_date: null,
            primary_recruiter: filters?.primary_recruiter_filter ? `{${filters?.primary_recruiter_filter?.ats_users_id}}` : null,
        }))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters])

    const handleFilter = (event, value) => {
        if (["primary_recruiter_filter"].includes(event)) {
            if (value) setFilters({ ...filters, [event]: value })
            else setFilters({ ...filters, [event]: "" })
        }
    }
    const handleExportSelected = () => {
        const headers = [
            { label: "Recruiter", key: "recruiter_name" },
            { label: isGroupOrgFlowTrue ? "Group Org" : "Client", key: "company_name" },
            { label: "Number of Jobs", key: "no_of_jobs" },
            { label: "Number of Position", key: "no_of_positions" }
        ];

        const csvData = [headers.map(header => header.label)];
        selectedRows.forEach(row => {
            const rowData = headers.map(header => row[header.key]);
            csvData.push(rowData);
        });
        const csv = csvData.map(row => row.join(",")).join("\n");
        const blob = new Blob([csv], { type: "text/csv" });
        const url = URL.createObjectURL(blob);
        const downloadLink = document.createElement("a");
        downloadLink.href = url;
        downloadLink.download = "WorkLoad.csv";
        downloadLink.setAttribute("target", "_blank");
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
        URL.revokeObjectURL(url);
    };
    return (
        <>
            <Box sx={{ mt: 1 }}>
                <Grid container columnSpacing={1} mb={1}>
                    <Grid key="primary_recruiter_id" item xs={12} sm={5}>
                        <SelectableSearch
                            isOptionEqualToValue={(option, value) =>
                                +option.ats_users_id === +value.ats_users_id
                            }
                            label="Recruiter"
                            value={filters.primary_recruiter_filter}
                            loading={() => setUserData({ load: false, values: atsRecruiterData })}
                            fieldLabel="first_name"
                            required={false}
                            grouping={true}
                            variant={"outlined"}
                            name="primary_recruiter_filter"
                            onChangeEvent={handleFilter}
                            data={userData}
                        />
                    </Grid>
                </Grid>
            </Box>
            <Box>
                <MTable
                    columns={[
                        { title: "Recruiter", cellStyle: { padding: "10px", textAlign: "left" }, headerStyle: { padding: "10px", textAlign: "left" }, field: "recruiter_name", editable: "never" },
                        { title: isGroupOrgFlowTrue ? "Group Org" : "Client", cellStyle: { padding: "10px", textAlign: "left" }, headerStyle: { padding: "10px", textAlign: "left" }, field: "company_name", editable: "never" },
                        { title: "Number of Jobs", cellStyle: { padding: "10px", textAlign: "center" }, headerStyle: { padding: "10px", textAlign: "center" }, field: "no_of_jobs", editable: "never" },
                        { title: "Number of Position", cellStyle: { padding: "10px", textAlign: "center" }, headerStyle: { padding: "10px", textAlign: "center" }, field: "no_of_positions", editable: "never" },
                    ]}

                    data={atsWorkLoadData || []}
                    options={{
                        maxBodyHeight: "54vh",
                        minBodyHeight: "54vh",
                        sorting: true,
                        search: true,
                        searchFieldAlignment: "right",
                        searchAutoFocus: true,
                        searchFieldVariant: "standard",
                        filtering: false,
                        exportButton: false,
                        exportAllData: true,
                        paging: true,
                        pageSizeOptions: [25, 50],
                        pageSize: 25,
                        showTextRowsSelected: true,
                        selectionProps: rowData => ({
                            disabled: [null, '', 0, '0'].includes(rowData.commission_percentage),
                        }),
                        showSelectAllCheckbox: true,
                        selection: true,
                        actionsColumnIndex: -1,
                        grouping: false,
                        addRowPosition: "first",
                        columnsButton: false,
                        draggable: false
                    }}
                    actions={[
                        {
                            icon: () => <CloudDownloadIcon />,
                            tooltip: 'Export Selected Rows',
                            onClick: () => handleExportSelected(),
                            hidden: selectedRows.length === 0,
                        },
                    ]}
                    components={{
                        Pagination: props => <CustomPagination {...props} displayableKeys={displayableKeys} />
                    }}
                    onSelectionChange={(rows) => setSelectedRows(rows)}
                    title="WorkLoad"
                />
            </Box>
        </>
    )
}

export default WorkloadReports;