import React from 'react';
import { Button, FormControl, Grid, TextField } from "@mui/material";
import { Form } from "../../../Base/views/ModalPopUpForm"
import { useState } from 'react';
import { grey } from '@mui/material/colors';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { AddShiftTimeConfigureAction, UpdateShiftTimeConfigureAction } from '../../../../../store/Leave/ShiftConfigure/Action';
import CommonTimePicker from '../../../Base/views/CommonTimePicker';

const ShitTimeConfigurePopup = ({ editedRecord, setOpenPopup }) => {
    const dispatch = useDispatch();
    const [shifttime, setShiftTime] = useState({
        shift_name: "",
        time_in: "",
        time_out: "",
        pay: ""
    });

    const [errors, setErrors] = useState({});
   

    useEffect(() => {
        if (editedRecord != null) {
            setShiftTime({
                ...editedRecord,
                time_in: editedRecord.in_time ? ConvertToDate(editedRecord.in_time) : "",
                time_out: editedRecord.out_time ? ConvertToDate(editedRecord.out_time) : ""
            });
        }
    }, [editedRecord])
    const ConvertToDate = (timeString) => {
        if (!timeString) return null;
        const [hours, minutes, seconds] = timeString.split(":");
        const date = new Date();
        date.setHours(parseInt(hours, 10));
        date.setMinutes(parseInt(minutes, 10));
        date.setSeconds(parseInt(seconds, 10));
        return date; // Returns a Date object
    };
    const validate = (fieldValues) => {
        let temp = { ...errors };

        if ("shift_name" in fieldValues) {
            temp.shift_name = fieldValues.shift_name === "" ? "Shift name is required" : "";
        }
        if ("time_in" in fieldValues) {
            temp.time_in = fieldValues.time_in === "" ? "Timein is required" : "";
        }
        if ("time_out" in fieldValues) {
            temp.time_out = fieldValues.time_out === "" ? "Timeout is required" : "";
        }
        if ("pay" in fieldValues) {
            temp.pay = fieldValues.pay === "" ? "pay is required" : "";
        }
        setErrors({
            ...temp,
        });

        return Object.values(temp).every((x) => x === "");
    };

    const handleInputChange = (event, value) => {
        if (['time_in', 'time_out'].includes(event)) {
            setShiftTime({ ...shifttime, [event]: value });
            Object.values?.(errors)?.find((res) => res !== "")?.length > 0 &&
                validate({ ...shifttime, [event]: value });
        }
        else {
            setShiftTime({ ...shifttime, [event.target.name]: event.target.value });
            Object.values?.(errors)?.find(res => res !== "")?.length > 0 &&
                validate({ ...shifttime, [event.target.name]: event.target.value })
        }

    }

    const handleSubmit = (event) => {
        event.preventDefault();
        if (event.type === "submit" || event.which === 13) {
            if (validate(shifttime)) {
                const formatTime = (time) => {
                    if (!time) return "";
                    const date = new Date(time);
                    return date.toLocaleTimeString("en-GB", { hour12: false });
                };
                const formattedShiftTime = {
                    ...shifttime,
                    time_in: formatTime(shifttime.time_in),
                    time_out: formatTime(shifttime.time_out),
                };
                if (shifttime?.shift_roster_configuration_id)
                    dispatch(UpdateShiftTimeConfigureAction(formattedShiftTime));
                else
                    dispatch(AddShiftTimeConfigureAction(formattedShiftTime));

                setOpenPopup(false);
            }
        }
    };

    return (
        <>
            <Form onSubmit={(e) => handleSubmit(e)}>
                <Grid container>
                    <Grid container>
                        <Grid key="shift_name" item xs={12} md={6} sx={{ marginTop: "1rem", paddingRight: "15px", paddingLeft: "15px" }}>
                            <FormControl fullWidth >
                                <TextField
                                    size="small"
                                    required
                                    value={shifttime?.shift_name}
                                    onChange={handleInputChange}
                                    variant="outlined"
                                    fullWidth
                                    id="shift_name"
                                    label="Shift Name"
                                    name="shift_name"
                                    {...(errors.shift_name && {
                                        error: true,
                                        helperText: errors.shift_name,
                                    })}
                                />
                            </FormControl>
                        </Grid>
                        <Grid key="time_in" item xs={12} md={6} sx={{ marginTop: "1rem", paddingRight: "15px", paddingLeft: "15px" }}>
                            <FormControl fullWidth >
                                <CommonTimePicker
                                    size="small"
                                    required
                                    name="time_in"
                                    label="Time In"
                                    onKeyDown={(e) => e.preventDefault()}
                                    onKeyPress={(e) => e.preventDefault()}
                                    DefaultDTvalue={shifttime?.time_in}
                                    handleChange={(value) => handleInputChange("time_in", value)}
                                    {...(errors.time_in && {
                                        error: true,
                                        helperText: errors.time_in,
                                    })}
                                />
                            </FormControl>
                        </Grid>
                        <Grid key="time_out" item xs={12} md={6} sx={{ marginTop: "1rem", paddingRight: "15px", paddingLeft: "15px" }}>
                            <FormControl fullWidth >
                                <CommonTimePicker
                                    size="small"
                                    required
                                    name="time_out"
                                    label="Time Out"
                                    onKeyDown={(e) => e.preventDefault()}
                                    onKeyPress={(e) => e.preventDefault()}
                                    DefaultDTvalue={shifttime?.time_out}
                                    handleChange={(value) => handleInputChange("time_out", value)}
                                    {...(errors.time_out && {
                                        error: true,
                                        helperText: errors.time_out,
                                    })}
                                />
                            </FormControl>
                        </Grid>
                        <Grid key="pay" item xs={12} md={6} sx={{ marginTop: "1rem", paddingRight: "15px", paddingLeft: "15px" }}>
                            <FormControl fullWidth >
                                <TextField
                                    required
                                    size="small"
                                    value={shifttime?.pay}
                                    onChange={handleInputChange}
                                    variant="outlined"
                                    fullWidth
                                    id="pay"
                                    label="Pay"
                                    name="pay"
                                    {...(errors.pay && {
                                        error: true,
                                        helperText: errors.pay,
                                    })}
                                />
                            </FormControl>
                        </Grid>

                    </Grid>
                    <Grid container sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                    }}>
                        <Button
                            sx={{ marginTop: "1rem", marginRight: "1rem", ":hover": { color: grey[50] } }}
                            variant="outlined"
                            onClick={() => setOpenPopup(false)}
                            className="mpl-secondary-btn"
                        >
                            cancel
                        </Button>
                        <Button variant="contained" color="primary" type="submit" className="mpl-primary-btn" sx={{ marginTop: "1rem", marginRight: "1rem" }}>
                            {editedRecord ? "Update" : "Save"}
                        </Button>
                    </Grid>
                </Grid>
            </Form>
        </>
    );
}

export default ShitTimeConfigurePopup;
