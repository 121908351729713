import { useEffect, useState } from "react";
import MaterialTable from "material-table";
import { Box, Grid, FormControl } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import CommonDatePicker from "../../../Base/views/CommonDatePicker";
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { GetEmployeeFaceTimeAction } from "../../../../../store/Finance/Profile/Action";

const EmployeeFaceTime = () => {
    const dispatch = useDispatch();
    const { employee_face_time_data } = useSelector(
        (state) => state?.FinanceReducer.EmployeeProfileReducer
    );

    const date = new Date();
    const [filters, setFilters] = useState({
        month_year: `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
    });
    const [name, setName] = useState(date.toLocaleString('default', { month: 'short', year: 'numeric' }));

    useEffect(() => {
        const [f_year, month] = filters.month_year.split("-");
        const f_month = month.padStart(2, "0");
        dispatch(GetEmployeeFaceTimeAction({ month: f_month, year: f_year }));
        const selectedDate = new Date(filters.month_year);
        setName(selectedDate.toLocaleString('default', { month: 'short', year: 'numeric' }));
    }, [filters]);

    const handleFilter = (event) => {
        if (["month_year"].includes(event?.target?.name)) {
            setFilters({ ...filters, [event?.target?.name]: event?.target?.value });
        }
    };

    const formatTime = (time) => {
        if (!time) return "-";
        const date = new Date(`1970-01-01T${time}`);
        return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });
    };

    const formattedData = employee_face_time_data.map(entry => ({
        ...entry,
        checkin: formatTime(entry.checkin),
        checkout: formatTime(entry.checkout),
        total_hours: entry.total_hours
            ? `${entry.total_hours.toFixed(2)}`
            : "-"
    }));

    const generateCSV = () => {
        const headers = ["Name", "Mail", "Date", "In Time", "Out Time", "Total Hours(HH:MM)"];
        const csvData = [headers, ...formattedData.map(row => [
            row.name, row.email, row.date, row.checkin, row.checkout, row.total_hours
        ])];
        const csv = csvData.map(row => row.join(",")).join("\n");
        const blob = new Blob([csv], { type: "text/csv" });
        const url = URL.createObjectURL(blob);
        const downloadLink = document.createElement("a");
        downloadLink.href = url;
        downloadLink.download = `EmployeeFaceTime_${name}.csv`;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
        URL.revokeObjectURL(url);
    };

    return (
        <>
            <Box sx={{ m: 1 }}>
                <Grid container sx={{ display: "flex", justifyContent: "space-between" }}>
                    <FormControl>
                        <CommonDatePicker
                            name="month_year"
                            openTo="month"
                            views={['year', 'month']}
                            label="Year and Month"
                            size="small"
                            onKeyDown={(e) => e.preventDefault()}
                            pickerFormat="MM/yyyy"
                            DefaultDTvalue={filters.month_year}
                            maxDate={new Date()}
                            handleChange={(value) =>
                                handleFilter({
                                    target: {
                                        name: "month_year",
                                        value: value ? `${value.getFullYear()}-${("0" + (value.getMonth() + 1)).slice(-2)}-${("0" + value.getDate()).slice(-2)}` : "",
                                    },
                                })
                            }
                        />
                    </FormControl>
                </Grid>
            </Box>
            <MaterialTable
                columns={[
                    { title: "Name", field: "name" },
                    { title: "Email", field: "email" },
                    { title: "Date", field: "date" },
                    { title: "In Time", field: "checkin" },
                    { title: "Out Time", field: "checkout" },
                    { title: "Total Hours(HH:MM)", field: "total_hours" },
                ]}
                data={employee_face_time_data?.length ? formattedData : []}
                options={{
                    maxBodyHeight: "54vh",
                    minBodyHeight: "54vh",
                    rowStyle: { fontSize: "13px" },
                    headerStyle: { padding: "10px", fontWeight: "bold" },
                    sorting: false,
                    search: true,
                    paging: true,
                    pageSizeOptions: [25, 50],
                    pageSize: 25,
                }}
                actions={[
                    {
                        icon: () => <CloudDownloadIcon />,
                        tooltip: 'Download CSV',
                        onClick: generateCSV,
                        isFreeAction: true,
                        hidden: employee_face_time_data.length === 0,
                    },
                ]}
                title="Employee Face Time"
            />
        </>
    );
};

export default EmployeeFaceTime;
