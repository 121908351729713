import axiosInstance from "../../../../axiosInstance";
import { HRMS_UTILITY_API_URL } from "../../../Constants/baseConstant";
import { getUserData } from "../../Base/helper/baseFunctions";
import { toast } from "react-toastify";




export const sendOnboardRequestMessageHelper = async (params) => {
    var data = getUserData();
    return await axiosInstance(`/whatsapp/sendOnboardRequestMessage`, {
        method: "POST",
        baseURL: HRMS_UTILITY_API_URL,
        data: {
            domain_name: params?.domain || data.domain,
            number: `91${params?.number}`,
            message:params?.message,
            user_empid: data.employee_id,
            emp_id: params?.emp_id,
            info_type: params?.info_type,
            info: "employee"

        }
    })
        .then(res => {
            toast.dismiss();
            toast.success("Request Send Successfully")
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            toast.error(err?.response?.data?.error?.[0]?.message);
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}
