import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Tab, Box } from "@mui/material";
import { useState } from "react";

import ArrowBackButton from "../../../Base/views/ArrowBackButton";
import AdminUsersTabs from "./AdminUsersTabs";

const AdminUsers = ({ setAdminUsers, countryCode }) => {
    const [value, setValue] = useState("1");
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
    
  return (
    <>
      { countryCode === "IND" ? (
        <TabContext value={value}>
          <Box>
            <TabList
              sx={{
                minHeight: "40px",
                margin: "10px 0px 0px 10px",
                ".MuiTab-root": { minHeight: "20px" },
                "& .MuiTabs-indicator": {
                  width: "100%",
                  display: "flex",
                  justifyContent: "center !important",
                },
              }}
              onChange={handleChange}
              aria-label="lab API tabs example"
            >
              <Box
                sx={{
                  display: "flex",
                  WebkitBoxPack: "justify",
                  justifyContent: "flex-start",
                  padding: "8px 16px 0px 16px",
                }}
              >
                <ArrowBackButton onClick={() => setAdminUsers(false)} />
              </Box>
              <Tab label="IND" value="1" />
              <Tab label="ANZ" value="2" />
            </TabList>
          </Box>
          <TabPanel value="1" sx={{ padding: "0px", minHeight: "45px" }}>
            <AdminUsersTabs countryCode={"IND"} setAdminUsers={setAdminUsers} />
          </TabPanel>
          <TabPanel value="2" sx={{ padding: "0px", minHeight: "45px" }}>
            <AdminUsersTabs countryCode={"AUS"} setAdminUsers={setAdminUsers} />
          </TabPanel>
        </TabContext>
      ) : ( 
        <AdminUsersTabs countryCode={countryCode} setAdminUsers={setAdminUsers} />
      ) }
    </>
  );
};

export default AdminUsers;
