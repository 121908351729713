import { toast } from "react-toastify";
import axiosInstance from "../../../../axiosInstance";
import { HRMS_LMS_API_URL } from "../../../Constants/baseConstant";
import { getUserData } from "../../Base/helper/baseFunctions";


export const GetShiftTimeConfigureHelper = async () => {
    var data = getUserData();
    return await axiosInstance(`/ancillary/lms/getShiftTimeConfigure`, {
        method: "POST",
        baseURL: HRMS_LMS_API_URL,
        data: {
            domain_name: data.domain,
        }
    })
        .then((res) => {
            toast.dismiss();
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}

export const AddShiftTimeConfigureHelper = async (params) => {
    var data = getUserData();
    toast.info("Saving...")
    return await axiosInstance(`/ancillary/lms/addShiftTimeConfigure`, {
        method: "POST",
        baseURL: HRMS_LMS_API_URL,
        data: {
            domain_name: data?.domain,
            user_id: data?.employee_id,
            shift_name: params?.shift_name,
            time_in: params?.time_in,
            time_out: params?.time_out,
            pay: params?.pay,
        }
    })
        .then((res) => {
            toast.dismiss();
            toast.success("Shift added successfully")
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            toast.error(err?.response?.data?.error?.[0]?.message)
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}

export const UpdateShiftTimeConfigureHelper = async (params) => {
    var data = getUserData();
    toast.info("Saving...")
    return await axiosInstance(`/ancillary/lms/updateShiftTimeConfigure`, {
        method: "PUT",
        baseURL: HRMS_LMS_API_URL,
        data: {
            domain_name: data?.domain,
            user_id: data?.employee_id,
            shift_name: params?.shift_name,
            time_in: params?.time_in,
            time_out: params?.time_out,
            pay: params?.pay,
            shift_roster_configuration_id: params?.shift_roster_configuration_id
        }
    })
        .then((res) => {
            toast.dismiss();
            toast.success("Shift updated successfully")
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            toast.error(err?.response?.data?.error?.[0]?.message)
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}

export const DeleteShiftTimeConfigureHelper = async (params) => {
    var data = getUserData();
    toast.info("Saving...")
    return await axiosInstance(`/ancillary/lms/deleteShiftTimeConfigure`, {
        method: "DELETE",
        baseURL: HRMS_LMS_API_URL,
        data: {
            domain_name: data?.domain,
            shift_roster_configuration_id: params?.shift_roster_configuration_id
        }
    })
        .then((res) => {
            toast.dismiss();
            toast.success("Shift deleted successfully")
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            toast.error(err?.response?.data?.error?.[0]?.message)
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}