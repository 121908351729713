import MaterialTable, { MTableToolbar } from "material-table";
import { useSelector } from "react-redux";
import { useState } from "react";
import { useDispatch } from "react-redux";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { GetEmployeesAction } from "../../../../store/Employee/Employee/Action";
import { useEffect } from "react";
import {
  Box,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
  useMediaQuery,
  CircularProgress 
} from "@mui/material";
import { ConvertDateTime, getUserData } from "../../Base/helper/baseFunctions";
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import ArchiveIcon from "@mui/icons-material/Archive";
import { GetATSModuleFeaturesAction } from "../../../../store/RoleAccess/ATSModuleFeatures/Action";
import history from "../../../../history";
import ConfirmationDialog from "../../Base/views/ConfirmationDialog";
import CustomToggle from "../../Base/views/CustomToggle";
import { UpdateEmployeeStatusHelper } from "../../Employee/helper/EmployeeHelper";
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Popup from "../../Base/views/Popup";
import AddEmployeePopup from "./AddEmployeePopup";
import EmployeePayroleTemplate from "../../Finance/views/Employee/EmployeePayroleTemplate";
import AddIcon from '@mui/icons-material/Add';
import { getExportEmployeeDataHelper } from "../../Finance/helper/ProfileHelper";

const MyEmployeeGrid = ({ setCurrentMode, setData }) => {
  const userData = getUserData();
  const dispatch = useDispatch();
  const [openDialog, setOpenDialog] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [inactive, setInactive] = useState(false);
  const [currentData, setCurrentData] = useState({});
  const [selectedRows, setSelectedRows] = useState([]);
  const [openPopup, setOpenPopup] = useState(false);
  const [openTemplate, setOpenTemplate] = useState(false);
  const [isExporting, setIsExporting] = useState(false);
  const open = Boolean(anchorEl);
  const { employees_options } = useSelector(
    (state) => state?.EmployeeReducer.EmployeesReducer
  );
  const isSmallScreen = useMediaQuery("(max-width: 600px)");
  useEffect(() => {
    dispatch(
      GetATSModuleFeaturesAction({ module_name: "ATS Employees Onboarding" })
    );
  }, [dispatch]);
  useEffect(() => {
    if (inactive === true) {
      dispatch(GetEmployeesAction({ is_active: false }));
    } else dispatch(GetEmployeesAction({ is_active: true }));
    
  }, [dispatch, inactive]);

  const handleAdd = () => {
    setCurrentData({})
    setOpenPopup(true)
  };
  const handleEdit = (event, row) => {
    setCurrentData(row)
    setOpenPopup(true);
  };
  const handleView = (event, row) => {
    history.push({
      pathname: "/Onboardemployee",
      state: { defaultValue: currentData, Mode: "editemployee" },
    });
  };

  const handlePayrollSetupPopup = (data) => {
    setOpenTemplate(true);
    setCurrentData(data);
  };
  const handleClick = (event, data) => {
    setCurrentData(data);
    setAnchorEl(event.currentTarget);
  };
  const handleConfirmArchive = () => {
    setOpenDialog(true);
    setAnchorEl(null);
  };
  const handleToggle = (e) => {
    setInactive(!inactive);
  };
  const handleUnArchive = () => {
    UpdateEmployeeStatusHelper({
      employee_id: currentData?.employee_id,
      is_active: true,
      successMessage: "Employee made Active Successfully",
    }).then((res) => {
      if (res?.data?.statuscode === 200) {
        dispatch(GetEmployeesAction({ is_active: false }));
      }
    });
    setOpenDialog(false);
  };

  const handleArchive = () => {
    UpdateEmployeeStatusHelper({
      employee_id: currentData?.employee_id,
      is_active: false,
      successMessage: "Employee made Inactive Successfully",
    }).then((res) => {
      if (res?.data?.statuscode === 200) {
        dispatch(GetEmployeesAction({ is_active: true }));
      }
    });
    setOpenDialog(false);
  };

  const getSelectedEmployeeIds = async() => {
    try{
      const selectedEmployeeIds = `{${selectedRows?.map((item) => item.employee_id).join(",")}}`;
      const res = await getExportEmployeeDataHelper({ employees_id: selectedEmployeeIds !== '{}' ? selectedEmployeeIds : null });
      if (res?.data?.statuscode === 200) {
          return res?.data?.data; 
      }
    } finally {
      setIsExporting(false);
    }
  }
  
  const handleExportSelected = async() => {
    setIsExporting(true);
    const selectedEmp =  await getSelectedEmployeeIds();
        const headers = [
          { label: "#", key: "serial_number" }, 
          { label: "Emp No", key: "emp_no" }, 
          { label: "First Name", key: "first_name" }, 
          { label: "Last Name", key: "last_name" },
          { label: "Gender", key: "gender" },
          { label: "Father's Name", key: "fathers_name" }, 
          { label: "Designation", key: "designation" },
          { label: "Department", key: "department" }, 
          { label: "Reporting Manager", key: "reporting_manager" },
          { label: "Work Location", key: "work_location" },
          { label: "Date of Joining", key: "doj" },
          { label: "Years of Experience", key: "years_of_experience" }, 
          { label: "Date of Birth", key: "dob" }, 
          { label: "Age", key: "age" },
          { label: "Date of Marraige", key: "marriage_date" },
          { label: "Current Address", key: "current_address" }, 
          { label: "Permanent Address", key: "permanent_address" }, 
          { label: "Mobile Number", key: "mobile_no" },
          { label: "PAN No", key: "pan" }, 
          {label: "Aadhar No", key: "aadhar" },
          { label: "Qualification", key: "qualif_1" },  
          { label: "Emergency Contact", key: "emergency_contact_person" }, 
          { label: "Emergency Number", key: "emergency_contact_no" },
          { label: "Blood Group", key: "blood_group" },
          { label: "Email", key: "email" } 
      ];
      
    const csvData = [headers.map(header => header.label)];
    selectedEmp?.forEach((row, index) => {
    const rowData = headers.map(header => {
        let value = row?.employee_master_report?.[0]?.[header.key] ?? "";
        if (typeof value === "string" && value.includes(",")) {
            value = `"${value}"`; 
        }
        if (header.key === "serial_number") return index + 1; 
        if (header.key === "aadhar") {
          value = `="${value}"`;
      }
        return value;
    });
      csvData.push(rowData);
    });
    const csv = csvData.map(row => row.join(",")).join("\n");
    const blob = new Blob([csv], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const downloadLink = document.createElement("a");
    downloadLink.href = url;
    downloadLink.download = "Employees Master Report.csv";
    downloadLink.setAttribute("target", "_blank");
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
    URL.revokeObjectURL(url);
    setIsExporting(false);
  };

  return (
    <>
      <Popup
        title={currentData?.employee_id ? "Edit Employee" : "Add Employee"}
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
        fullWidth={true}
        maxWidth="sm"
      >
        <AddEmployeePopup
          editRecord={currentData}
          setOpenPopup={setOpenPopup}
        />
      </Popup>
      <Popup
        maxWidth="md"
        fullWidth={true}
        title={`Payroll Configuration - ${currentData?.name}`}
        openPopup={openTemplate}
        setOpenPopup={setOpenTemplate}
      >
        <EmployeePayroleTemplate emp={true} createEmp={false} setOpenTemplate={setOpenTemplate} openTemplate={openTemplate} currentData={currentData} />
      </Popup>
      <Box sx={{ maxHeight: "87vh", overflowY: "auto" }}>
        <MaterialTable
          onRowClick={(event, rowData) => {
            handleEdit(event, rowData);
          }}
          columns={[
            {
              title: "Prev.Emp ID",
              cellStyle: { padding: "10px 10px 10px 13px" },
              field: "previous_employee_id",
              grouping: false,
              render: (rowData) => rowData.previous_employee_id || "NA",
            },
            {
              title: "Emp No",
              cellStyle: { padding: "10px 10px 10px 13px" },
              field: "employee_no",
              grouping: false,
            },
            {
              title: "Name",
              cellStyle: { padding: "10px 10px 10px 13px" },
              field: "name",
              grouping: false,
            },
            {
              title: "Email",
              cellStyle: { padding: "10px 10px 10px 13px" },
              field: "office_email",
              grouping: false,
            },
            {
              title: "D.O.J",
              headerStyle: { textAlign: "center" }, 
              cellStyle: { padding: "10px", textAlign: "center" },
              field: "createdon",
              render: (rowData) => {
                return ConvertDateTime(rowData.doj);
              },
            },
            {
              title: "Onboarding", field: "empform_onboarding_status",sorting:false, 
              headerStyle: { textAlign: "left" }, 
              cellStyle: { padding: "10px", textAlign: "center" },
              render: rowData => {
                return rowData.empform_onboarding_status ? <TaskAltIcon sx={{ color: "rgb(86, 202, 0)" }} />
                  : <HighlightOffIcon sx={{ color: "rgb(255, 76, 81)" }} />
              }
            },

            {
              title: "Payroll Setup", field: "payroll_setup_flag",sorting: false, hidden: !['cvtmeepl', "xpohrmeepl", "altiio", "adobemeepl", "kmkmeepl", "paycmeepl", "bpmeepl", "nirahameepl", "abmeepl", "uatkmmeepl","sogbimeepl"].includes(userData?.domain) || !(userData?.f_country_code === "IND"), 
              headerStyle: { textAlign: "left" }, 
              cellStyle: { padding: "10px", textAlign: "center" },
              render: rowData => {
                return rowData.payroll_setup_flag ? <TaskAltIcon sx={{ color: "rgb(86, 202, 0)", cursor: "pointer" }}
                  onClick={(e) => {
                    e.stopPropagation();
                    handlePayrollSetupPopup(rowData);
                  }}
                />
                  : <HighlightOffIcon sx={{ color: "rgb(255, 76, 81)", cursor: "pointer" }} onClick={(e) => {
                    e.stopPropagation();
                    handlePayrollSetupPopup(rowData);
                  }} />
              }
            },
            {
              title: "Action",
              field: "action",
              width:"8%",
              grouping: false,
              hidden: inactive, 
              sorting: false,
              headerStyle: {  textAlign: "center" }, 
              cellStyle: { padding: "10px", textAlign: "center", marginTop:"-14px" },
              render: (rowData) => (
                <Tooltip title="Settings">
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      handleClick(e, rowData);
                    }}
                    sx={{
                      padding: "0px",width: "30px", height: "30px"
                    }}
                  >
                    <MoreVertIcon fontSize="small" sx={{ fontWeight: "bold" }} />
                  </IconButton>
                </Tooltip>
              ),
            }
            
          ]}
          data={employees_options}
          options={{
            maxBodyHeight: "65vh",
            minBodyHeight: "65vh",
            rowStyle: { fontSize: "13px" },
            headerStyle: { padding: "10px 10px 10px 13px", fontWeight: "bold" },
            sorting: true,
            search: true,
            searchText: "",
            searchFieldAlignment: "right",
            searchAutoFocus: true,
            searchFieldVariant: "standard",
            filtering: false,
            paging: true,
            pageSizeOptions: [25, 50],
            pageSize: 25,
            paginationType: "stepped",
            showFirstLastPageButtons: false,
            paginationPosition: "bottom",
            exportButton: false,
            exportAllData: false,
            exportFileName: "Employees",
            addRowPosition: "first",
            selection: true,
            actionsColumnIndex: -1,
            showSelectAllCheckbox: true,
            showTextRowsSelected: true,
            grouping: false,
            columnsButton: false,
          }}
          
          onSelectionChange={(rows) => setSelectedRows(rows)}
          components={{
            Toolbar: (props) => (
              <Box sx={{ width: "full", display: "flex", flexDirection: { xs: "column", sm: "row" }, flexGrow: 1 }}>
              <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    backgroundColor: "#f3edfd",
                    padding: "0px 20px",
                    flexGrow: 1
                    
                  }}
                >
                  <Box 
                    sx={{
                      fontWeight: "bold",
                      fontSize: { xs: "20px", sm: "18px" }, 
                    }}
                  >
                    Employees
                  </Box>
                  {isSmallScreen && <Box
                  sx={{
                    display: "flex",
                    alignItems: "center"
                  }}
                >
                  <Box>
                    <Tooltip
                      title={inactive ? "Toggle to view active users" : "Toggle to view archived users"}
                    >
                      <div>
                        <CustomToggle
                          isChecked={inactive}
                          setIsChecked={handleToggle}
                          handleToggleChange={handleToggle}
                          Yestext={"Inactive"}
                          NoText={"Active"}
                          sx={{
                            height: "32px",
                            padding: "0px",
                            width: "145px",
                            labelPadding: "1px",
                          }}
                        />
                      </div>
                    </Tooltip>
                  </Box>
                  <Box>
                    <Tooltip title="Add Employee">
                    <IconButton onClick={handleAdd} color="primary">
                      <AddIcon />
                    </IconButton>
                  </Tooltip>
                  </Box>
                    {selectedRows.length !== 0 &&
                      <Box>
                        <Tooltip title="Export">
                          <IconButton onClick={handleExportSelected}>
                            <CloudDownloadIcon />
                          </IconButton>
                        </Tooltip>
                </Box>}
                </Box>
                  }
                </Box>
                 <MTableToolbar {...props} />
                {!isSmallScreen && <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    backgroundColor: "#f3edfd",
                    pr: 2
                  }}
                >
                  <Box>
                    <Tooltip
                      title={inactive ? "Toggle to view active users" : "Toggle to view archived users"}
                    >
                      <div>

                        <CustomToggle
                          isChecked={inactive}
                          setIsChecked={handleToggle}
                          handleToggleChange={handleToggle}
                          Yestext={"Inactive"}
                          NoText={"Active"}
                          sx={{
                            height: "32px",
                            padding: "0px",
                            width: "145px",
                            labelPadding: "1px",
                          }}
                        />
                      </div>
                    </Tooltip>
                  </Box>
                  <Box>
                  <Tooltip title="Add Employee">
                    <IconButton onClick={handleAdd} color="primary">
                      <AddIcon />
                    </IconButton>
                  </Tooltip>
                  </Box>
                  
                  {selectedRows.length !== 0 &&
                    <Box>
                     <Tooltip title="Export">
                        <IconButton onClick={handleExportSelected} disabled={isExporting}>
                          {isExporting ? <CircularProgress size={24} /> : <CloudDownloadIcon />}
                        </IconButton>
                      </Tooltip>
                    </Box>}
                </Box>}
                </Box>
            ),
          }}  
          title=""
          />
        <Menu
          id="more-options-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={() => setAnchorEl(null)}
          MenuListProps={{
            "aria-labelledby": "more-options-button",
          }}
        >
          <MenuItem onClick={(event, rowData) => {
            handleView(event, rowData);
          }}>
            <ListItemIcon>
              <VisibilityIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>
              View Employee Detail
            </ListItemText>
          </MenuItem>
          {(currentData?.employee_id === userData?.employee_id) || (currentData?.core_hr_user_type === "Owner") ? (
            <MenuItem disabled>
              <ListItemIcon>
                <ArchiveIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>
                {inactive ? "Make Active" : "Make Inactive"}
              </ListItemText>
            </MenuItem>
          ) : (
            <MenuItem onClick={handleConfirmArchive}>
              <ListItemIcon>
                <ArchiveIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>
                {inactive ? "Make Active" : "Make Inactive"}
              </ListItemText>
            </MenuItem>
          )}

        </Menu>
        <ConfirmationDialog
          title={inactive ? "Make Active" : "Make Inactive"}
          content={
            inactive
              ? "Are you sure want to make active ?"
              : "Are you sure want to make Inactive ?"
          }
          openDialog={openDialog}
          closeDialog={setOpenDialog}
          popupTitle="Users"
          OkButtonText={inactive ? "Make Active" : "Make Inactive"}
          onSubmit={inactive ? handleUnArchive : handleArchive}
        />
      </Box>
    </>
  );
};

export default MyEmployeeGrid;
