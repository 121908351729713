import { Box, Card, Divider, Grid, IconButton, Skeleton, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { NoData } from '../../Base/views/NotAuthorized';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { GetOTAction, GetPayrollTemplateAction } from '../../../../store/Finance/PayRollTemplate/Action';
import { GetPayDateAction } from '../../../../store/Finance/PayDate/Action';
import { GetPayrollSettingsHelper } from '../helper/PayRollTemplateHelper';

const Dashboard = ({ setValue, setAccordian }) => {
    const { payroll_template_data, isLoading } = useSelector(
        (state) => state?.FinanceReducer.PayrollTemplateReducer
    );
    const { pay_date_data } = useSelector(
        (state) => state.FinanceReducer?.PayDateReducer
    );
    const { ot_data } = useSelector(state => state.FinanceReducer?.PayrollTemplateReducer);
    const createUniqueState = (component_data) => {
        const uniqueState = Array.from(
            new Set(component_data?.[0]?.fn_get_payroll_settings_components?.map((item) => item.state_name))
        );
        return uniqueState
    }

    const dispatch = useDispatch();
    const [componentPT, setComponentPT] = useState([]);
    const [componentLWF, setComponentLWF] = useState([]);

    useEffect(() => {
        dispatch(GetOTAction());
        dispatch(GetPayrollTemplateAction());
        dispatch(GetPayDateAction());
        GetPayrollSettingsHelper({ component_type: "PT" }).then((res) => {
            const PT_data = createUniqueState(res?.data?.data);
            setComponentPT(PT_data)
        })
        GetPayrollSettingsHelper({ component_type: "LWF" }).then((res) => {
            const LWF_data = createUniqueState(res?.data?.data)
            setComponentLWF(LWF_data)
        })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const handleClick = (value, accordian) => {
        setValue(value);
        setAccordian(accordian)
    }

    const data = [
        { title: "Templates", data: payroll_template_data || [], field_name: "template_name" },
    ]
    const profTax = [
        { title: "Professional Tax", data: componentPT || [], field_name: "" },
    ]
    const labourWelfund = [
        { title: "Labour Welfare Fund", data: componentLWF || [], field_name: "" },
    ]
    const payPeriod = [
        { title: "Pay Period", data: pay_date_data || [], field_name: "pay_day" },
    ]
    const ot = [
        { title: "Over Time", data: ot_data || [], field_name: "overtime_policy" },
    ]

    return (
        <>
            <Grid container sx={{ padding: "0px" }} className="hr-settings">
                {
                    data?.map((res, index) => {
                        return (
                            <Grid key={index} item xs={12} sm={6} md={3} lg={3} sx={{ padding: "10px" }}>
                                <Card className="shadow" sx={{ border: "1px solid #dbdada", borderRadius: "10px" }}>
                                    <Box sx={{ display: "flex", justifyContent: "space-between", padding: "5px" }} className="card-title-dashboard">
                                        {
                                            isLoading ?
                                                <Skeleton width="85%">
                                                    <Typography>.</Typography>
                                                </Skeleton>
                                                : <Typography variant="h6" sx={{ marginLeft: "5px" }}>{res.title}</Typography>
                                        }
                                        <IconButton size="small" onClick={() => handleClick("2", res.title)} sx={{ color: "white" }}>
                                            <OpenInNewIcon />
                                        </IconButton>
                                    </Box>
                                    <Divider sx={{ backgroundColor: "#8534b9" }} />
                                    <Box sx={{ overflowY: "auto", maxHeight: "12rem", minHeight: "12rem", padding: "10px" }}>
                                        {
                                            isLoading ?
                                                <Skeleton variant="rectangular" width="90%" sx={{ margin: "10px" }}>
                                                    <div style={{ paddingTop: '60%' }} />
                                                </Skeleton>
                                                : res.data?.length > 0 ?
                                                    res.data?.map((item, id) => {
                                                        return (
                                                            <>
                                                                <Typography sx={{ padding: "2px" }} key={id} variant="body1">{item?.[res?.field_name]}</Typography>
                                                            </>
                                                        )
                                                    })
                                                    : <NoData title="No data found" />
                                        }
                                    </Box>
                                </Card>
                            </Grid>
                        )
                    })
                }
                {
                    profTax?.map((res, index) => {
                        return (
                            <Grid key={index} item xs={12} sm={6} md={3} lg={3} sx={{ padding: "10px" }}>
                                <Card className="shadow" sx={{ border: "1px solid #dbdada", borderRadius: "10px" }}>
                                    <Box sx={{ display: "flex", justifyContent: "space-between", padding: "5px" }} className="card-title-dashboard">
                                        {
                                            isLoading ?
                                                <Skeleton width="85%">
                                                    <Typography>.</Typography>
                                                </Skeleton>
                                                : <Typography variant="h6" sx={{ marginLeft: "5px" }}>{res.title}</Typography>
                                        }
                                        <IconButton size="small" onClick={() => handleClick("3", res.title)} sx={{ color: "white" }}>
                                            <OpenInNewIcon />
                                        </IconButton>
                                    </Box>
                                    <Divider sx={{ backgroundColor: "#8534b9" }} />
                                    <Box sx={{ overflowY: "auto", maxHeight: "12rem", minHeight: "12rem", padding: "10px" }}>
                                        {
                                            isLoading ?
                                                <Skeleton variant="rectangular" width="90%" sx={{ margin: "10px" }}>
                                                    <div style={{ paddingTop: '60%' }} />
                                                </Skeleton>
                                                : res.data?.length > 0 ?
                                                    res.data?.map((item, id) => {
                                                        return (
                                                            <>
                                                                <Typography sx={{ padding: "2px" }} key={id} variant="body1">{item}</Typography>
                                                            </>
                                                        )
                                                    })
                                                    : <NoData title="No data found" />
                                        }
                                    </Box>
                                </Card>
                            </Grid>
                        )
                    })
                }
                {
                    labourWelfund?.map((res, index) => {
                        return (
                            <Grid key={index} item xs={12} sm={6} md={3} lg={3} sx={{ padding: "10px" }}>
                                <Card className="shadow" sx={{ border: "1px solid #dbdada", borderRadius: "10px" }}>
                                    <Box sx={{ display: "flex", justifyContent: "space-between", padding: "5px" }} className="card-title-dashboard">
                                        {
                                            isLoading ?
                                                <Skeleton width="85%">
                                                    <Typography>.</Typography>
                                                </Skeleton>
                                                : <Typography variant="h6" sx={{ marginLeft: "5px" }}>{res.title}</Typography>
                                        }
                                        <IconButton size="small" onClick={() => handleClick("4", res.title)} sx={{ color: "white" }}>
                                            <OpenInNewIcon />
                                        </IconButton>
                                    </Box>
                                    <Divider sx={{ backgroundColor: "#8534b9" }} />
                                    <Box sx={{ overflowY: "auto", maxHeight: "12rem", minHeight: "12rem", padding: "10px" }}>
                                        {
                                            isLoading ?
                                                <Skeleton variant="rectangular" width="90%" sx={{ margin: "10px" }}>
                                                    <div style={{ paddingTop: '60%' }} />
                                                </Skeleton>
                                                : res.data?.length > 0 ?
                                                    res.data?.map((item, id) => {
                                                        return (
                                                            <>
                                                                <Typography sx={{ padding: "2px" }} key={id} variant="body1">{item}</Typography>
                                                            </>
                                                        )
                                                    })
                                                    : <NoData title="No data found" />
                                        }
                                    </Box>
                                </Card>
                            </Grid>
                        )
                    })
                }
                {
                    payPeriod?.map((res, index) => {
                        return (
                            <Grid key={index} item xs={12} sm={6} md={3} lg={3} sx={{ padding: "10px" }}>
                                <Card className="shadow" sx={{ border: "1px solid #dbdada", borderRadius: "10px" }}>
                                    <Box sx={{ display: "flex", justifyContent: "space-between", padding: "5px" }} className="card-title-dashboard">
                                        {
                                            isLoading ?
                                                <Skeleton width="85%">
                                                    <Typography>.</Typography>
                                                </Skeleton>
                                                : <Typography variant="h6" sx={{ marginLeft: "5px" }}>{res.title}</Typography>
                                        }
                                        <IconButton size="small" onClick={() => handleClick("5", res.title)} sx={{ color: "white" }}>
                                            <OpenInNewIcon />
                                        </IconButton>
                                    </Box>
                                    <Divider sx={{ backgroundColor: "#8534b9" }} />
                                    <Box sx={{ overflowY: "auto", maxHeight: "12rem", minHeight: "12rem", padding: "10px" }}>
                                        {
                                            isLoading ?
                                                <Skeleton variant="rectangular" width="90%" sx={{ margin: "10px" }}>
                                                    <div style={{ paddingTop: '60%' }} />
                                                </Skeleton>
                                                : res.data?.length > 0 ?
                                                    res.data?.map((item, id) => {
                                                        return (
                                                            <>
                                                                <Typography sx={{ padding: "2px" }} key={id} variant="body1">{item?.[res?.field_name]}</Typography>
                                                            </>
                                                        )
                                                    })
                                                    : <NoData title="No data found" />
                                        }
                                    </Box>
                                </Card>
                            </Grid>
                        )
                    })
                }
                {
                    ot?.map((res, index) => {
                        return (
                            <Grid key={index} item xs={12} sm={6} md={3} lg={3} sx={{ padding: "10px" }}>
                                <Card className="shadow" sx={{ border: "1px solid #dbdada", borderRadius: "10px" }}>
                                    <Box sx={{ display: "flex", justifyContent: "space-between", padding: "5px" }} className="card-title-dashboard">
                                        {
                                            isLoading ?
                                                <Skeleton width="85%">
                                                    <Typography>.</Typography>
                                                </Skeleton>
                                                : <Typography variant="h6" sx={{ marginLeft: "5px" }}>{res.title}</Typography>
                                        }
                                        <IconButton size="small" onClick={() => handleClick("5", res.title)} sx={{ color: "white" }}>
                                            <OpenInNewIcon />
                                        </IconButton>
                                    </Box>
                                    <Divider sx={{ backgroundColor: "#8534b9" }} />
                                    <Box sx={{ overflowY: "auto", maxHeight: "12rem", minHeight: "12rem", padding: "10px" }}>
                                        {
                                            isLoading ?
                                                <Skeleton variant="rectangular" width="90%" sx={{ margin: "10px" }}>
                                                    <div style={{ paddingTop: '60%' }} />
                                                </Skeleton>
                                                : res.data?.length > 0 ?
                                                    res.data?.map((item, id) => {
                                                        return (
                                                            <>
                                                                <Typography sx={{ padding: "2px" }} key={id} variant="body1">{item?.[res?.field_name]}</Typography>
                                                            </>
                                                        )
                                                    })
                                                    : <NoData title="No data found" />
                                        }
                                    </Box>
                                </Card>
                            </Grid>
                        )
                    })
                }
            </Grid>
        </>
    );
}

export default Dashboard;
