import { Box, Grid, TextField, Typography, Button, Paper } from "@mui/material";
import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { createStyles, makeStyles } from "@mui/styles";
import { UpdatePayDateAction } from "../../../../../store/Finance/PayDate/Action";
import { useTheme } from "@mui/material/styles";

const Payperiod = () => {
  const theme = useTheme();
  const useStyles = makeStyles((theme) =>
    createStyles({
      Paper: {
        boxShadow:
          theme.palette.mode === "dark"
            ? "rgb(0 0 0 / 20%) 0px 0px 2px 0px, rgb(0 0 0 / 12%) 0px 12px 24px -4px"
            : "0px 3px 3px -2px #cbc3c3, 0px 3px 4px 0px rgb(242 242 242), 0px 1px 8px 0px rgb(242 242 242)",
        padding: "20px",
        borderRadius: "10px",
        backgroundColor: "#e6d9fb7a",
      },
    })
  );
  const classes = useStyles();
  const dispatch = useDispatch();
  const { pay_date_data } = useSelector(
    (state) => state.FinanceReducer?.PayDateReducer
  );
  const initialPayDay = pay_date_data?.[0]?.pay_day || "";
  const [payDay, setPayDay] = useState(initialPayDay);
  const [error, setError] = useState("");
  const getOrdinal = (n) => {
    if (n > 3 && n < 21) return `${n}th`;
    const lastDigit = n % 10;
    return `${n}${["th", "st", "nd", "rd"][lastDigit] || "th"}`;
  };
  const handleChange = (event) => {
    const value = event.target.value;
    if (value === "" || (Number(value) >= 1 && Number(value) <= 31)) {
      setPayDay(value);
      setError("");
    } else {
      setError("Please enter a valid number between 1 and 31.");
    }
  };
  const getPreviewText = () => {
    if (!payDay) return "Please enter a pay period date.";
    const startDay = Number(payDay);
    const endDay = startDay === 1 ? 31 : startDay - 1;
    const endMonthText = startDay === 1 ? "this month" : "next month";
    return `Your pay period runs from ${getOrdinal(startDay)} of this month to ${getOrdinal(endDay)} of ${endMonthText}.`;
};

  const handleSubmit = () => {
    if (!payDay) {
      setError("This field is required.");
      return;
    }
    dispatch(UpdatePayDateAction({ pay_day: Number(payDay) }));
  };

  return (
    <Grid container spacing={2} style={{ padding: "10px 0px" }}>
      <Grid item xs={12} md={12}>
        <Typography
          variant="h6"
          gutterBottom
          sx={{
            paddingLeft: "15px",
            color: theme.palette.primary.contrastText,
          }}
        >
          Pay Period
        </Typography>
        <Grid item xs={12} md={12} sx={{ display: "flex", justifyContent: "center" }}>
          <Paper
            className={classes.Paper}
            sx={{
              padding: "80px 24px 40px", borderRadius: "16px", maxWidth: "600px",
              width: "100%",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <TextField
                label="Enter Pay Period (1-31)"
                variant="standard"
                type="number"
                value={payDay}
                onChange={handleChange}
                error={!!error}
                helperText={error}
                InputProps={{ inputProps: { min: 1, max: 31 } }}
                sx={{ flex: 1 }}
              />
              <Button
                variant="contained"
                className="mpl-primary-btn"
                onClick={handleSubmit}
                sx={{ textTransform: "none", fontSize: "14px", padding: "4px 10px" }}
              >
                Submit
              </Button>
            </Box>
            <Typography
              variant="body1"
              sx={{
                marginTop: "15px",
                padding: "10px",
                backgroundColor: theme.palette.grey[200],
                borderRadius: "5px",
              }}
            >
              {getPreviewText()}
            </Typography>
          </Paper>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Payperiod;
