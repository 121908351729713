import { Collapse, Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { grey } from '@mui/material/colors';

const SettingsMenu = ({ collapse, setCollapse, open, sideBarData2, userData, selectedMenu, handleMenuClick, theme }) => {
    return (
        <Collapse in={collapse} timeout="auto" unmountOnExit sx={{ padding: "0px 10px 0px 10px", position: "fixed", bottom: 50, width: "inherit" }}>
            <Divider />
            <ListItem disablePadding sx={{ display: 'block', fontSize: "12px", pb: 1 }}>
                <ListItemButton sx={{ borderRadius: "8px", fontWeight: 600, px: 1.5 }} onClick={() => setCollapse(!collapse)} disableRipple selected={collapse}>
                    <ListItemIcon sx={{ minWidth: "35px" }}>
                        <span style={{ color: collapse && `${theme?.palette?.primary?.main}` }}><SettingsSuggestIcon /></span>
                    </ListItemIcon>
                    <ListItemText primaryTypographyProps={{
                        variant: "body2",
                        fontWeight: selectedMenu === "Settings" ? 600 : 550,
                        fontSize: "0.8rem",
                        color: collapse ? "primary" : grey[600]
                    }} primary="Settings" sx={{ opacity: open ? 1 : 0 }} />
                    {open && collapse ? <ExpandMore /> : open && <ExpandLess />}
                </ListItemButton>
            </ListItem>
            <List sx={{ padding: "0px" }}>
                {
                    sideBarData2.map((res, id) => {
                        return (

                            <Link key={id} to={{ pathname: res?.route, state: res?.state }} style={{ color: theme.palette.mode === "dark" ? "white" : "black" }}>
                                {res?.show &&
                                    <ListItem
                                        disablePadding
                                        sx={{ display: 'block', fontSize: "12px" }}
                                        onClick={() => handleMenuClick(res?.label)}
                                    >
                                        <ListItemButton
                                            selected={selectedMenu === res?.label}
                                            key={id}
                                            sx={{
                                                minHeight: 10,
                                                justifyContent: open ? 'initial' : 'center',
                                                px: 1.5,
                                                borderRadius: "8px", fontWeight: 600,
                                            }}
                                        >
                                            <ListItemIcon
                                                key={id}
                                                sx={{
                                                    minWidth: 0,
                                                    mr: open ? 2 : 'auto',
                                                    justifyContent: 'center',
                                                }}
                                            >
                                                <span style={{ color: selectedMenu === res?.label && `${theme?.palette?.primary?.main}` }}>{res?.icon}</span>
                                            </ListItemIcon>
                                            <ListItemText primaryTypographyProps={{
                                                variant: "body2",
                                                fontWeight: selectedMenu === res?.label ? 600 : 550,
                                                fontSize: "0.8rem",
                                                color: selectedMenu === res?.label ? "primary" : grey[600]
                                            }} primary={res?.label} sx={{ opacity: open ? 1 : 0 }} />
                                        </ListItemButton>
                                    </ListItem>
                                }
                            </Link>
                        )
                    })
                }
            </List>
        </Collapse>
    )
}

export default SettingsMenu