import * as ActionTypes from './ActionTypes';

const initialState = {
    isLoading: false,
};

const BulkUploadReducer = function (state = initialState, action) {
    switch (action.type) {
        case ActionTypes.FINANCE_BULK_LOAD:
            return {
                ...state,
                isLoading: action.params.isLoading
            };

        default:
            return state;
    }
}

export default BulkUploadReducer;