import { Grid, Typography } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import YearType from '../../../Leave/views/YearType/YearType';
import { useTheme } from "@mui/material/styles";
import CompanyDetail from '../../../Ancillary/CompanyDetails/views/CompanyDetail';
import WeekOff from '../../../Leave/views/WeekOff/WeekOff';
import WorkingHours from '../../../Leave/views/WorkHours/WorkingHours';
import EmployeeID from '../Employee/EmployeeID';
import { getUserData } from '../../../Base/helper/baseFunctions';

const Others = () => {
    const userData = getUserData();
    const theme = useTheme();
    const { leaveYearTypeData } = useSelector(state => state?.LeaveReducer?.LeaveYearTypeReducer);
    const { week_off_data } = useSelector(state => state.LeaveReducer?.WeekOffConfigureReducer);
    const { work_hours_data } = useSelector(state => state.LeaveReducer?.WeekOffConfigureReducer);


    const values = [
        { name: "Employee ID Configuration", comp: <EmployeeID />, count: leaveYearTypeData?.length || 0 },
        { name: "Company Logo", comp: <CompanyDetail /> },
        { name: "Business Year Type", comp: <YearType />, count: leaveYearTypeData?.length || 0 },
        // { name: "Projects", comp: <MyTime />, count: mytime_options?.length || 0 },
        { name: "Week Off Configuration", comp: <WeekOff />, count: week_off_data?.length || 0 },
        { name: "Working Hours", comp: <WorkingHours />, count: work_hours_data?.length || 0 },
    ];
    const filteredValues = values?.filter((value) => {
        if (userData?.f_country_code !== "IND") {
            return value.name !== "Business Year Type" &&
                value.name !== "Week Off Configuration"
        }
        return true; // Show all items if the country code is "IND"
    });
    return (
        <Grid container spacing={2} style={{ padding: "10px 0px" }}>
            {filteredValues?.map((value, id) => (
                <Grid item xs={12} md={12} key={id}>

                    <Typography
                        variant="h6"
                        gutterBottom
                        sx={{ paddingLeft: "15px", color: theme.palette.primary.contrastText }}
                    >
                        {value.name}
                    </Typography>
                    {value.comp}

                </Grid>
            ))}
        </Grid>
    );
};

export default Others;
