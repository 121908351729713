import * as ActionTypes from '../ActionTypes';

const ShiftTimeConfigureInitialState = {
    isLoading: false,
    success: false,
    error: false,
    successMessage: "",
    errorMessage: ""
}

export const ShiftTimeConfigureReducer = function (state = ShiftTimeConfigureInitialState, action) {
    switch (action.type) {
        case ActionTypes.SHIFT_TIME_CONFIGURE_GET_REQUEST:
            return {
                ...state,
                isLoading: true,
                success: false,
                successMessage: "",
                error: false,
                errorMessage: "",
                shift_time_options: []
            };
        case ActionTypes.SHIFT_TIME_CONFIGURE_GET_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: true,
                successMessage: action.payload?.message,
                shift_time_options: action.payload?.data?.data
            };
        case ActionTypes.SHIFT_TIME_CONFIGURE_GET_ERROR:
            return {
                ...state,
                isLoading: false,
                error: true,
                success: false,
                errorMessage: action.payload?.data?.error?.[0]?.message
            }
        case ActionTypes.SHIFT_TIME_CONFIGURE_ADD_REQUEST:
            return {
                ...state,
                isLoading: true,
                success: false,
                error: false,
            };
        case ActionTypes.SHIFT_TIME_CONFIGURE_ADD_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: true,
                successMessage: action.payload?.message,
                shift_time_options: action.payload?.data?.data
            };
        case ActionTypes.SHIFT_TIME_CONFIGURE_ADD_ERROR:
            return {
                ...state,
                isLoading: false,
                error: true,
                success: false,
                errorMessage: action.payload?.data?.error?.[0]?.message
            }
        case ActionTypes.SHIFT_TIME_CONFIGURE_UPDATE_REQUEST:
            return {
                ...state,
                isLoading: true,
                success: false,
                error: false,
            };
        case ActionTypes.SHIFT_TIME_CONFIGURE_UPDATE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: true,
                successMessage: action.payload?.message,
                shift_time_options: action.payload?.data?.data
            };
        case ActionTypes.SHIFT_TIME_CONFIGURE_UPDATE_ERROR:
            return {
                ...state,
                isLoading: false,
                error: true,
                success: false,
                errorMessage: action.payload?.data?.error?.[0]?.message
            }
        case ActionTypes.SHIFT_TIME_CONFIGURE_DELETE_REQUEST:
            return {
                ...state,
                isLoading: true,
                success: false,
                error: false,
            };
        case ActionTypes.SHIFT_TIME_CONFIGURE_DELETE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: true,
                successMessage: action.payload?.message,
                shift_time_options: action.payload?.data?.data
            };
        case ActionTypes.SHIFT_TIME_CONFIGURE_DELETE_ERROR:
            return {
                ...state,
                isLoading: false,
                error: true,
                success: false,
                errorMessage: action.payload?.data?.error?.[0]?.message
            }
        default:
            return state;
    }
}