import MaterialTable from "material-table";
import { useEffect } from "react";
import {
    Box,
    Button,
    Select,
    Typography,
    MenuItem,
    Grid,
    CircularProgress,
    IconButton,
    FormControl,
    TextField,
    FormLabel,
    InputLabel,
    Menu,
    Tooltip
} from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import { useState } from "react";
import Base from "../../../Base/views/Base";
import { getUserData } from "../../../Base/helper/baseFunctions";
import Popup from "../../../Base/views/Popup";
import * as XLSX from "xlsx";
import { toast } from "react-toastify";
import axiosInstance from "../../../../../axiosInstance";
import { HRMS_LMS_API_URL } from "../../../../Constants/baseConstant";
import { AddBioMetricDataStagetoMainHelper, AddTimeDataStagetoMainHelper, DeleteBioMetricDataStageHelper, DeleteTimeDataStageHelper, GetBioMetricDataStageHelper, GetTimeDataStageHelper, UpdateBioMetricDataStageHelper, UpdateTimeDataStageHelper } from "../../../Finance/helper/BulkUpload/BulkUploadHelper";
import ConfirmationDialog from "../../../Base/views/ConfirmationDialog";
import CommonDatePicker from "../../../Base/views/CommonDatePicker";
import Papa from "papaparse";
import Warning from "../../image/report.png";
const BioMetricUpload = () => {
    let userData = getUserData();
    const date = new Date();
    const [inputData, setInputData] = useState({ type: "Check In - Check Out" });
    const [input, setInput] = useState({ month_year: `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}` });
    const [showConfirmPopup, setShowConfirmPopup] = useState(false);
    const [Import, setImport] = useState(false);
    const [show, setShow] = useState(false);
    const [hours, setHours] = useState(false);
    const [mode, setMode] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [editedRecord, setEditedRecord] = useState(null);
    const [id, setId] = useState();
    const [openPopup, setOpenPopup] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [currentData, setCurrentData] = useState({});
    const [empdata, setEmpData] = useState([]);
    const [hoursdata, setHoursData] = useState([]);
    const [file, setFile] = useState(null);
    const initialColMapping = [
        { excel: "", db: "previous_employee_id", label: "Prev.Emp ID", required: true },
        { excel: "", db: "in_date", label: "In Date", required: true },
        { excel: "", db: "out_date", label: "Out Date", required: true },
        { excel: "", db: "in_time", label: "In Time", required: true },
        { excel: "", db: "out_time", label: "Out Time", required: true },
    ];
    const [excelDbColMapping, setExcelDbColMapping] = useState(initialColMapping);
    const [rowHeaders, setRowHeaders] = useState([]);
    const [uploadProgress, setUploadProgress] = useState(0);
    useEffect(() => {
        let bioMetricData = null;
        let timeData = null;
        GetBioMetricDataStageHelper().then((res) => {
            bioMetricData = res?.data?.data?.[0]?.biometric_details;
            setId(res?.data?.data?.[0]?.stage_biometric_pk);
            setMode(res?.data?.data?.[0]?.mode)
        });
        GetTimeDataStageHelper().then((res) => {
            timeData = res?.data?.data?.[0]?.time_details;
            if (timeData) {
                setEmpData(timeData);
                setHours(true);
                setId(res?.data?.data?.[0]?.time_detail_stage_pk);
                setMode(res?.data?.data?.[0]?.mode)
            } else if (bioMetricData) {
                setEmpData(bioMetricData);
                setHours(false);
            }
        });
    }, []);
    const handleImport = () => {
        if (empdata?.length > 0) {
            setShowConfirmPopup(true); // Show confirmation popup if data exists
        } else {
            setImport(true); // Directly trigger file selection if no data exists
        }
    };
    const fileSelected = (e) => {
        const selectedFile = e.target.files[0];
        if (!selectedFile) return;
        if (!selectedFile.name.endsWith(".csv")) {
            setShow(true);
            return;
        }
        // processFile(selectedFile, e);
        processCSVFile(selectedFile, e);
    };
    // const processFile = (selectedFile, event) => {
    //     setFile(selectedFile);
    //     setExcelDbColMapping(initialColMapping);
    //     setUploadProgress(0);
    //     const fileReader = new FileReader();
    //     toast.info("Reading File. Please wait");
    //     fileReader.onload = (e) => {
    //         const arrayBuffer = e.target.result;
    //         const workbook = XLSX.read(arrayBuffer, { type: "array" });
    //         const worksheet = workbook.Sheets[workbook.SheetNames[0]];
    //         const firstRowData = XLSX.utils.sheet_to_json(worksheet, {
    //             header: 1,
    //             range: 0,
    //         });
    //         setRowHeaders(firstRowData[0]);
    //         toast.success("File imported");
    //     };
    //     fileReader.readAsArrayBuffer(selectedFile);
    //     if (event) event.target.value = ""; // Reset the input
    // };
    // const processCSVFile = (selectedFile, event) => {
    //     setFile(selectedFile);
    //     setUploadProgress(0);
    //     toast.info("Reading CSV File. Please wait...");

    //     Papa.parse(selectedFile, {
    //         header: true,
    //         skipEmptyLines: true,
    //         complete: (result) => {
    //             const headers = Object.keys(result.data[0]); // Extract CSV headers
    //             setRowHeaders(headers);
    //             toast.success("File imported successfully!");
    //         },
    //         error: (error) => {
    //             toast.error(`Error reading CSV file: ${error.message}`);
    //         }
    //     });

    //     if (event) event.target.value = ""; // Reset the input
    // };
    const processCSVFile = (selectedFile, event) => {
        setFile(selectedFile);
        setUploadProgress(0);
        toast.info("Reading CSV File. Please wait...");
        Papa.parse(selectedFile, {
            header: true,
            skipEmptyLines: true,
            complete: (result) => {
                if (hours) {
                    const processedData = processDailyHoursCSV(result.data);
                    setHoursData(processedData);
                }
                const headers = Object.keys(result.data[0]);
                setRowHeaders(headers);
                toast.success("File imported successfully!");
            },
            error: (error) => {
                toast.error(`Error reading CSV file: ${error.message}`);
            }
        });

        if (event) event.target.value = "";
    };
    const processDailyHoursCSV = (data) => {
        if (data.length === 0) return [];
        const firstRow = data[0];
        const empIdKey = Object.keys(firstRow).find(
            key => key.toLowerCase().includes("emp id")
        );
        return data.map(row => {
            const timeJson = {};
            for (let day = 1; day <= 31; day++) {
                if (row[day] !== undefined && row[day] !== '') {
                    timeJson[day] = row[day].trim().toUpperCase() === "LOP" ? "0" : row[day].trim();
                }
            }
            return {
                previous_employee_id: empIdKey ? row[empIdKey] : null,
                time_json: timeJson
            };
        });
    };
    function setColMapping(value, dbCol) {
        setExcelDbColMapping((prev) => {
            prev.forEach((col) => {
                if (col["db"] === dbCol) col.excel = value;
            });
            return [...prev];
        });
    }
    function clearColMapping(dbCol) {
        setExcelDbColMapping((prev) => {
            prev.forEach((col) => {
                if (col["db"] === dbCol) col.excel = "";
            });
            return [...prev];
        });
    }
    const validate = () => {
        const temp = {};
        if (!file) temp.file = "File is required";
        const unmappedRequiredFields = excelDbColMapping.filter(
            (col) => col.required && !col.excel
        );
        if (unmappedRequiredFields.length > 0) {
            toast.error("Please map all required fields.")
            temp.columnMapping = "Please map all required fields.";
        }
        if (hours && (!input?.month_year)) {
            toast.error("Please choose Year and Month.");
        }
        return Object.keys(temp).length === 0; // Return true if no errors
    };
    async function addEmployeeStage(e) {
        if (!validate()) return;
        e.preventDefault();
        try {
            const formData = new FormData();
            formData.append("file", file);
            formData.append("user_id", userData?.employee_id);
            formData.append("mode", inputData?.type);
            formData.append("domain_name", userData?.domain);

            if (hours) {
                const [f_year, month] = input.month_year.split("-");
                const f_month = parseInt(month, 10).toString();
                formData.append("month", f_month);
                formData.append("year", f_year);
                formData.append("column_mapping", JSON.stringify(hoursdata));
            }
            else {
                formData.append("column_mapping", JSON.stringify(excelDbColMapping));
            }
            toast.info("Uploading file");
            try {
                setUploadProgress(0);
                await axiosInstance
                    .post("/lms/uploadBioMetricFile", formData, {
                        baseURL: HRMS_LMS_API_URL,
                        headers: {
                            Accept: "multipart/form-data",
                            "Content-Type": "multipart/form-data",
                        },
                        onUploadProgress: (progress) => {
                            const percentCompleted = Math.round(
                                (progress.loaded * 90) / progress.total
                            );
                            setUploadProgress(percentCompleted);
                            if (percentCompleted > 89) {
                                toast.info("Processing...");
                            }
                        },
                    })
                    .then((response) => {
                        const result = response?.data?.statuscode;
                        setEmpData(response?.data?.data?.[0]?.biometric_details || response?.data?.data?.[0]?.time_details)
                        setId(response?.data?.data?.[0]?.stage_biometric_pk || response?.data?.data?.[0]?.time_detail_stage_pk)
                        if (result) {
                            toast.success("File processed successfully!");
                        }
                    });

                setUploadProgress(100);
                setFile(null);
                setImport(false);
                setFile(null);
                setRowHeaders([]);
                setInputData({ type: "Check In - Check Out" });

            } catch (error) {
                setUploadProgress(0);
                toast.warning(error.response?.data?.message || error.message);
            }
        } catch (error) {
            console.error("Unexpected error", error);
        }

    }
    async function handleSubmit(e) {
        if (hours) {
            AddTimeDataStagetoMainHelper().then((res) => {
                if (res.data.statuscode === 200) {
                    toast.success("Time bulk upload completed successfully.");
                    setEmpData([]);
                }
                else {
                    toast.error("The bulk upload failed. Please try again");
                }

            });
        }
        else {
            AddBioMetricDataStagetoMainHelper().then((res) => {
                if (res.data.statuscode === 200) {
                    toast.success("Time bulk upload completed successfully.");
                    setEmpData([]);
                }
                else {
                    toast.error("The bulk upload failed. Please try again");
                }

            });
        }

    }
    const handleCancel = () => {
        setShowConfirmPopup(false);
        if (hours) {
            DeleteTimeDataStageHelper({ time_detail_stage_id: id }).then((res) => {
                setHours(false);
                setEmpData([]); // Clear table data
                setFile(null); // Allow new file selection
                setImport(true);
                setExcelDbColMapping(initialColMapping);
                setRowHeaders([]);
            });
        }
        else {
            DeleteBioMetricDataStageHelper({ stage_biometric_id: id }).then((res) => {
                setHours(false);
                setEmpData([]); // Clear table data
                setFile(null); // Allow new file selection
                setImport(true);
                setExcelDbColMapping(initialColMapping);
                setRowHeaders([]);
            });
        }

    };
    const handleEdit = (rowData) => {
        setEditedRecord(rowData);
        setOpenPopup(true);
    };
    const handleConfirmDelete = (rowData) => {
        setOpenDialog(true);
        setCurrentData(rowData)
    };
    const handleDelete = () => {
        const updatedData = empdata?.filter(emp => emp?.previous_employee_id !== currentData?.previous_employee_id); // Use unique field
        setEmpData(updatedData); // Update state
        toast.success("Record deleted successfully!");
        setOpenDialog(false);
        UpdateBioMetricDataStageHelper({ stage_biometric_id: id, biometric_details: JSON.stringify(updatedData), mode: mode }).then((res) => {
        });
    };
    const validateFields = () => {
        const tempErrors = {};

        // Always validate previous_employee_id
        if (!editedRecord?.previous_employee_id) {
            tempErrors.previous_employee_id = "Prev.Emp ID is required.";
        }

        // If hours is false, validate the date & time fields
        if (!hours) {
            if (!editedRecord?.in_date) tempErrors.in_date = "In Date is required.";
            if (!editedRecord?.out_date) tempErrors.out_date = "Out Date is required.";
            if (!editedRecord?.in_time) tempErrors.in_time = "In Time is required.";
            if (!editedRecord?.out_time) tempErrors.out_time = "Out Time is required.";
        }
        return Object.keys(tempErrors).length === 0; // Return true if no errors
    };
    const handleEditSubmit = (updatedRecord) => {
        if (!validateFields()) return;

        if (hours) {
            let cleanedRecord = updatedRecord;
            const { total_hours, ...filteredRecord } = updatedRecord;
            cleanedRecord = filteredRecord;
            const updatedData = empdata?.map(emp =>
                emp?.tableData?.id === cleanedRecord?.tableData?.id ? cleanedRecord : emp
            )
            UpdateTimeDataStageHelper({
                time_detail_stage_id: id,
                time_details: JSON.stringify(updatedData),
                mode: mode
            }).then((res) => {
                setEmpData(res?.data?.data?.[0]?.time_details)
                toast.success("Record updated successfully!");
            });
        } else {
            const updatedData = empdata?.map(emp =>
                emp?.tableData?.id === updatedRecord?.tableData?.id ? updatedRecord : emp
            )
            setEmpData(updatedData); // Update state
            UpdateBioMetricDataStageHelper({
                stage_biometric_id: id,
                biometric_details: JSON.stringify(updatedData),
                mode: mode
            }).then((res) => {
                toast.success("Record updated successfully!");
            });
        }
        setOpenPopup(false); // Close popup
    };
    const handleInputChange = async (event, value) => {
        if (event.target.value === "Daily Hours") {
            setHours(true);
            setExcelDbColMapping([]);
        }
        else {
            setHours(false);
            setExcelDbColMapping(initialColMapping);
        }
        setInputData({ ...inputData, [event.target.name]: event.target.value });
        setFile(null);
    };
    const handleInput = (event, value) => {
        if (["month_year"].includes(event?.target?.name)) {
            setInput({ ...input, [event?.target?.name]: event?.target?.value });
        }
    }
    const sampleData = [
        ["Prev.Emp ID", "In Date", "Out Date", "In Time", "Out Time",],
        ["EMP00123", "2023-06-01", "2023-06-01", "08:00:00", "20:00:00"],
    ];
    const dailyHoursData = [
        ["Prev.Emp ID", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30", "31"],
        ["EMP00123", "8", "8", "8", "0", "8", "7.5", "8", "8", "8", "0", "8", "8", "8", "7", "8", "0", "8", "8", "8", "8", "8", "6", "8", "0", "8", "8", "7.5", "8", "0", "8"],
        ["EMP00124", "7.5", "7.5", "7.5", "0", "7", "7.5", "7", "7.5", "8", "0", "7.5", "7.5", "7.5", "7", "7.5", "0", "7.5", "7.5", "7.5", "7.5", "7.5", "6.5", "7.5", "0", "7.5", "7.5", "7", "7.5", "0", "7.5"]
    ];

    const handleOpenMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseMenu = () => {
        setAnchorEl(null);
    };
    const handleDownload = (type) => {
        let data, fileName;
        if (type === "in") {
            data = sampleData;
            fileName = "Check In - Check Out.csv";
        } else if (type === "daily") {
            data = dailyHoursData;
            fileName = "Daily_Hours.csv";
        }
        const ws = XLSX.utils.aoa_to_sheet(data);
        const csv = XLSX.utils.sheet_to_csv(ws);
        const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        handleCloseMenu();
    };
    const handleClose = () => {
        setImport(false);
        setFile(null);
        setRowHeaders([]);
        setHours(false);
        setInputData({ type: "Check In - Check Out" });
        setExcelDbColMapping(initialColMapping);
    };
    return (
        <>
            <Base>
                <Popup
                    maxWidth="xs"
                    openPopup={show}
                    setOpenPopup={setShow}
                    close={false}
                    hideDividers={true}
                >
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            textAlign: "center",
                            alignItems: "center",
                        }}
                    >
                        <Box sx={{ mt: 1, mb: 1, textAlign: "center !important" }}>
                            <img src={Warning} alt="UnSupport File" width={"25%"} />
                        </Box>
                        <FormControl>
                            <FormLabel id="gender-label">
                                <Typography variant="h5" className="blink">
                                    Invalid File Type, Accepted file type is .csv
                                </Typography>
                            </FormLabel>
                        </FormControl>
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                paddingTop: "10px",
                            }}
                        >
                            <Button
                                onClick={() => {
                                    setShow(false);
                                }}
                                variant="contained"
                                type="submit"
                                className="mpl-primary-btn profile-btn"
                                size="small"
                                sx={{
                                    margin: "10px",
                                }}
                            >
                                Close
                            </Button>
                        </Box>
                    </Box>
                </Popup>
                <Box sx={{ maxHeight: "87vh", overflowY: "auto" }}>
                    {/* <Box>
                        <form
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                gap: "16px",
                            }}
                        >
                            <div style={{ flex: 1, display: "flex", alignItems: "center", gap: "10px" }}>
                                <p
                                    style={{
                                        cursor: "pointer",
                                        color: "blue",
                                    }}
                                    onClick={handleOpenMenu}
                                >
                                    Click Here to Get Sample CSV
                                </p>
                                <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleCloseMenu}>
                                    <MenuItem onClick={() => handleDownload("in")}>Check In - Check Out</MenuItem>
                                    <MenuItem onClick={() => handleDownload("daily")}>Daily Hours</MenuItem>
                                </Menu>

                            </div>
                            <div style={{ display: "flex", alignItems: "center", gap: "16px" }}>
                                <Button
                                    variant="contained"
                                    className="mpl-primary-btn"
                                    component="span"
                                    sx={{ marginBottom: "5px" }}
                                    onClick={handleImport}
                                >
                                    Import
                                </Button>
                                <Button
                                    variant="contained"
                                    className="mpl-primary-btn"
                                    sx={{ marginBottom: "5px", marginRight: "5px" }}
                                    disabled={empdata?.length === 0 || empdata === undefined}
                                    onClick={handleSubmit}
                                >
                                    Submit
                                </Button>
                            </div>
                        </form>
                    </Box> */}
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            gap: 2,
                            padding: "20px",
                            backgroundColor: "#f9f9f9",
                            borderRadius: "10px",
                        }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                gap: 1,
                                cursor: "pointer",
                                color: "#1976d2",
                                fontSize: "16px",
                                fontWeight: 500,
                                "&:hover": { textDecoration: "underline" },
                            }}
                            onClick={handleOpenMenu}
                        >
                            Click Here to Get Sample CSV
                        </Box>

                        <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleCloseMenu}>
                            <MenuItem onClick={() => handleDownload("in")}>Check In - Check Out</MenuItem>
                            <MenuItem onClick={() => handleDownload("daily")}>Daily Hours</MenuItem>
                        </Menu>
                        <Box sx={{ display: "flex", gap: 2 }}>
                            <Button
                                variant="contained"
                                className="mpl-primary-btn"
                                component="span"
                                sx={{ textTransform: "none", fontSize: "14px", padding: "8px 20px" }}
                                onClick={handleImport}
                            >
                                Import
                            </Button>
                            <Button
                                variant="contained"
                                className="mpl-primary-btn"
                                disabled={empdata?.length === 0 || empdata === undefined}
                                onClick={handleSubmit}
                                sx={{
                                    textTransform: "none",
                                    fontSize: "14px",
                                    padding: "8px 20px",
                                    boxShadow: empdata?.length > 0 ? 3 : 0,
                                }}
                            >
                                Submit
                            </Button>
                        </Box>
                    </Box>
                    {(empdata?.length > 0) && (
                        <MaterialTable
                            columns={
                                hours
                                    ? [
                                        {
                                            title: "Prev.Emp ID",
                                            field: "previous_employee_id",
                                            cellStyle: { padding: "10px 10px 10px 13px" },
                                            grouping: false,
                                        },
                                        {
                                            title: "Total Work Hours",
                                            field: "total_hours",
                                            cellStyle: { padding: "10px 10px 10px 13px" },
                                        },
                                    ]
                                    : [
                                        {
                                            title: "Prev.Emp ID",
                                            field: "previous_employee_id",
                                            cellStyle: { padding: "10px 10px 10px 13px" },
                                            grouping: false,
                                        },
                                        {
                                            title: "In Date",
                                            field: "in_date",
                                            cellStyle: { padding: "10px 10px 10px 13px" },
                                        },
                                        {
                                            title: "Out Date",
                                            field: "out_date",
                                            cellStyle: { padding: "10px 10px 10px 13px" },
                                        },
                                        {
                                            title: "In Time",
                                            field: "in_time",
                                            cellStyle: { padding: "10px 10px 10px 13px" },
                                        },
                                        {
                                            title: "Out Time",
                                            field: "out_time",
                                            cellStyle: { padding: "10px 10px 10px 13px" },
                                        },
                                    ]
                            }
                            data={empdata}
                            options={{
                                maxBodyHeight: "65vh",
                                minBodyHeight: "65vh",
                                rowStyle: { fontSize: "13px" },
                                headerStyle: { padding: "10px 10px 10px 13px", fontWeight: "bold" },
                                sorting: true,
                                search: true,
                                searchText: "",
                                searchFieldAlignment: "right",
                                searchAutoFocus: true,
                                searchFieldVariant: "standard",
                                filtering: false,
                                paging: true,
                                pageSizeOptions: [25, 50],
                                pageSize: 25,
                                paginationType: "stepped",
                                showFirstLastPageButtons: false,
                                paginationPosition: "bottom",
                                exportButton: false,
                                exportAllData: false,
                                exportFileName: "Bio Metric",
                                addRowPosition: "first",
                                selection: false,
                                actionsColumnIndex: -1,
                                showSelectAllCheckbox: true,
                                showTextRowsSelected: true,
                                grouping: false,
                                columnsButton: false,
                            }}
                            actions={[
                                {
                                    icon: () => <IconButton sx={{ padding: "0px" }}><EditIcon /></IconButton>,
                                    tooltip: "Edit",
                                    onClick: (_event, rowData) => { handleEdit(rowData) },
                                },
                                {
                                    icon: () => <IconButton sx={{ padding: "0px" }}><DeleteIcon /></IconButton>,
                                    tooltip: "Delete",
                                    onClick: (_event, rowData) => { handleConfirmDelete(rowData) },
                                },
                            ]}
                            title="Time Bulk Upload"
                        />
                    )}
                    <Popup
                        maxWidth="xs"
                        fullWidth={true}
                        title="Attention Needed!"
                        openPopup={showConfirmPopup}
                        close={false}
                        setOpenPopup={setShowConfirmPopup}
                    >
                        <Typography variant="h5"> You will lose the imported data. Do you want to submit it before proceeding? </Typography>
                        <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
                            <Button
                                onClick={handleCancel}
                                className="mpl-primary-btn"
                            >
                                No
                            </Button>

                            <Button
                                onClick={() => {
                                    setShowConfirmPopup(false); // Close popup and retain existing data
                                }}
                                className="mpl-primary-btn"
                            >
                                Yes
                            </Button>

                        </Box>
                    </Popup>
                    <Popup
                        title={"Edit Employee Time"}
                        openPopup={openPopup}
                        setOpenPopup={setOpenPopup}
                    >
                        <form
                            onSubmit={(e) => {
                                e.preventDefault();
                                handleEditSubmit(editedRecord);
                            }}
                        >
                            <TextField
                                variant="standard"
                                size="small"
                                label="Previous Employee ID"
                                value={editedRecord?.previous_employee_id || ""}
                                onChange={(e) =>
                                    setEditedRecord({ ...editedRecord, previous_employee_id: e.target.value })
                                }
                                fullWidth
                                sx={{ mb: 3 }}
                                required
                            />
                            {!hours && (
                                <>
                                    <FormControl fullWidth sx={{ mb: 3 }}>
                                        <CommonDatePicker
                                            variant="standard"
                                            size="small"
                                            name="in_date"
                                            label="In Date"
                                            pickerType="date"
                                            onKeyPress={(e) => e.preventDefault()}
                                            onKeyDown={(e) => e.preventDefault()}
                                            pickerFormat="dd/MM/yyyy"
                                            DefaultDTvalue={editedRecord?.in_date}
                                            handleChange={(value) =>
                                                setEditedRecord({
                                                    ...editedRecord,
                                                    in_date: value
                                                        ? `${value.getFullYear()}-${("0" + (value.getMonth() + 1)).slice(-2)}-${("0" + value.getDate()).slice(-2)}`
                                                        : "",
                                                })
                                            }
                                            required
                                        />
                                    </FormControl>
                                    <FormControl fullWidth sx={{ mb: 3 }}>
                                        <CommonDatePicker
                                            variant="standard"
                                            size="small"
                                            name="out_date"
                                            label="Out Date"
                                            pickerType="date"
                                            onKeyPress={(e) => e.preventDefault()}
                                            onKeyDown={(e) => e.preventDefault()}
                                            pickerFormat="dd/MM/yyyy"
                                            DefaultDTvalue={editedRecord?.out_date}
                                            handleChange={(value) =>
                                                setEditedRecord({
                                                    ...editedRecord,
                                                    out_date: value
                                                        ? `${value.getFullYear()}-${("0" + (value.getMonth() + 1)).slice(-2)}-${("0" + value.getDate()).slice(-2)}`
                                                        : "",
                                                })
                                            }
                                            required
                                        />
                                    </FormControl>
                                    <TextField
                                        variant="standard"
                                        size="small"
                                        label="In Time"
                                        value={editedRecord?.in_time || ""}
                                        onChange={(e) =>
                                            setEditedRecord({ ...editedRecord, in_time: e.target.value })
                                        }
                                        fullWidth
                                        sx={{ mb: 3 }}
                                        required
                                    />
                                    <TextField
                                        variant="standard"
                                        size="small"
                                        label="Out Time"
                                        value={editedRecord?.out_time || ""}
                                        onChange={(e) =>
                                            setEditedRecord({ ...editedRecord, out_time: e.target.value })
                                        }
                                        fullWidth
                                        sx={{ mb: 3 }}
                                        required
                                    />
                                </>
                            )}
                            <Grid container spacing={2}>
                                {hours &&
                                    editedRecord?.time_json &&
                                    Object.keys(editedRecord.time_json).map((day, index) => (
                                        <Grid item xs={4} key={day}>
                                            <Grid container spacing={1} alignItems="center">
                                                <Grid item xs={5} sx={{ textAlign: "left" }}>
                                                    <Typography variant="body2" fontWeight="bold">{`Date ${day}`}</Typography>
                                                </Grid>
                                                <Grid item xs={7}>
                                                    <TextField
                                                        variant="standard"
                                                        size="small"
                                                        value={editedRecord.time_json[day] || ""}
                                                        onChange={(e) =>
                                                            setEditedRecord({
                                                                ...editedRecord,
                                                                time_json: {
                                                                    ...editedRecord.time_json,
                                                                    [day]: e.target.value,
                                                                },
                                                            })
                                                        }
                                                        sx={{
                                                            width: "60px",
                                                            "& .MuiInputBase-root": {
                                                                height: 30,
                                                                textAlign: "center",
                                                                display: "flex",
                                                                alignItems: "center",
                                                                justifyContent: "center",
                                                            },
                                                            "& .MuiOutlinedInput-input": {
                                                                padding: "5px",
                                                                textAlign: "center",
                                                            },
                                                        }}
                                                        inputProps={{ style: { textAlign: "center" } }}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    ))}
                            </Grid>
                            <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
                                <Button onClick={() => setOpenPopup(false)} className="mpl-primary-btn">
                                    Cancel
                                </Button>
                                <Button type="submit" variant="contained" className="mpl-primary-btn">
                                    Save
                                </Button>
                            </Box>
                        </form>
                    </Popup>
                    <Popup
                        maxWidth="sm"
                        fullWidth={true}
                        title="Time Bulk Import"
                        openPopup={Import}
                        close={false}
                        setOpenPopup={setImport}
                    >
                        <Box sx={{ m: 1 }}>
                            <FormControl sx={{ marginBottom: "20px" }} fullWidth size="small">
                                <InputLabel sx={{ marginLeft: "-15px" }} id="status">Select type of time bulk upload</InputLabel>
                                <Select
                                    name="type"
                                    labelId="type"
                                    id="type"
                                    value={inputData.type}
                                    label="Select type of time bulk upload"
                                    variant="standard"
                                    onChange={handleInputChange}
                                >
                                    <MenuItem value={'Check In - Check Out'}>Check In - Check Out</MenuItem>
                                    <MenuItem value={'Daily Hours'}>Daily Hours</MenuItem>
                                </Select>
                            </FormControl>
                            {hours && (

                                <FormControl fullWidth size="small">
                                    <CommonDatePicker
                                        name="month_year"
                                        openTo="month"
                                        required
                                        views={['year', 'month']}
                                        label="Year and Month"
                                        pickerType="date"
                                        variant="standard"
                                        onKeyDown={(e) => e.preventDefault()}
                                        onKeyPress={(e) => e.preventDefault()}
                                        pickerFormat="MM/yyyy"
                                        DefaultDTvalue={input.month_year}
                                        minDate={new Date().setFullYear(new Date().getFullYear() - 3)}
                                        maxDate={new Date()}
                                        handleChange={(value) =>
                                            handleInput({
                                                target: {
                                                    name: "month_year",
                                                    value: value
                                                        ? `${value?.getFullYear()}-${("0" + (value?.getMonth() + 1)).slice(-2)}-${("0" + value?.getDate()).slice(-2)}`
                                                        : "",
                                                },
                                            })
                                        }
                                    />
                                </FormControl>

                            )}
                            <>
                                <div style={{ display: "flex", alignItems: "center", gap: "16px", justifyContent: "center", margin: "20px 0px 20px 0px" }}>
                                    <label
                                        htmlFor="contained-button-file"
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            border: "2px dashed #883bd5",  // Dashed border like a drop area
                                            padding: "50px",
                                            borderRadius: "8px",
                                            cursor: "pointer",
                                            backgroundColor: "#fff", // Light blue background
                                            width: "300px",
                                            textAlign: "center",
                                            fontSize: "14px",
                                            color: "#883bd5",
                                        }}
                                    >
                                        {file ? file?.name?.substring(0, 20) + "..." : "Select File"}
                                        <input
                                            accept=".csv"
                                            id="contained-button-file"
                                            type="file"
                                            style={{ display: "none" }}
                                            multiple={false}
                                            onChange={fileSelected}
                                        />
                                    </label>
                                </div>
                            </>
                            {(!hours && file) && (
                                <>
                                    <Box
                                        style={{
                                            display: "grid",
                                            gridTemplateColumns: "1fr 1fr",
                                            alignItems: "center",
                                            padding: 5,
                                            borderBottom: "1px solid gray",
                                        }}
                                    >
                                        <Typography variant="h6" style={{ textAlign: "center" }}>
                                            Imported Headers
                                        </Typography>
                                        <Typography variant="h6" style={{ textAlign: "center" }}>
                                            Meepl Fields
                                        </Typography>
                                    </Box>
                                    {excelDbColMapping.map((col) => (
                                        <Grid
                                            key={col.db}
                                            container
                                            spacing={2}
                                            style={{
                                                padding: 5,
                                                alignItems: "center",
                                            }}
                                        >
                                            <Grid sx={{ mt: 2 }} item xs={6} container alignItems="center" spacing={1}>
                                                <Grid item xs>
                                                    <Select
                                                        onChange={(e) => setColMapping(e.target.value, col.db)}
                                                        value={col.excel}
                                                        fullWidth
                                                        required={col.required}
                                                        variant="standard"
                                                    >
                                                        {rowHeaders.map((header) => (
                                                            <MenuItem key={header} value={header}>
                                                                {header}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </Grid>
                                                {col.excel && (
                                                    <Grid item>
                                                        <IconButton onClick={() => clearColMapping(col.db)}>
                                                            <CloseIcon />
                                                        </IconButton>
                                                    </Grid>
                                                )}
                                            </Grid>
                                            <Grid sx={{ mt: 2 }} item xs={6}>
                                                <Typography>
                                                    {col.label || col.db}
                                                    {col.required && " *"}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    ))}
                                </>
                            )}
                            <Box sx={{ display: "flex", justifyContent: "space-between", m: 2 }}>
                                <Button
                                    onClick={() => handleClose()}
                                    className="mpl-primary-btn"
                                    disabled={uploadProgress > 0 && uploadProgress < 100}
                                >
                                    Close
                                </Button>

                                <div>
                                    {uploadProgress > 0 && uploadProgress < 100 ? (
                                        <CircularProgress disableShrink />
                                    ) : (
                                        <Tooltip title={file ? "" : "Please select a file"}>
                                            <span>
                                                <Button
                                                    disabled={!file}
                                                    variant="contained"
                                                    type="submit"
                                                    className="mpl-primary-btn"
                                                    loading={uploadProgress > 0 && uploadProgress < 100}
                                                    onClick={addEmployeeStage}
                                                >
                                                    Upload
                                                </Button>
                                            </span>
                                        </Tooltip>
                                    )}
                                </div>

                            </Box>

                        </Box>
                    </Popup>
                    <ConfirmationDialog
                        title="Do you wish to proceed?"
                        openDialog={openDialog}
                        closeDialog={setOpenDialog}
                        OkButtonText="Delete"
                        onSubmit={handleDelete}
                    />
                </Box>


            </Base >
        </>
    );
};

export default BioMetricUpload;