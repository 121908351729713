import Box from "@mui/material/Box";
import { Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import Logout from "@mui/icons-material/Logout";
import { Divider, ListItemIcon, Tooltip, Typography } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { getSessionData } from "../../Base/helper/baseHelper";
import LockResetIcon from "@mui/icons-material/LockReset";
import { signOut } from "../../SignIn/helper/SigninHelper";
import MenuIcon from "@mui/icons-material/Menu";
import VideoCameraFrontIcon from '@mui/icons-material/VideoCameraFront';
import { useSelector, useDispatch } from "react-redux";
import { getUserData, s3Client } from "../../Base/helper/baseFunctions";
import { Logo } from "../../../../svg/Logo";
import Popup from "../../Base/views/Popup";
import history from "../../../../history";
import HomeIcon from "@mui/icons-material/Home";
import MaleAvatar from "../../Home/images/Male.jpg";
import FemaleAvatar from "../../Home/images/Female.jpg";
import TransgenderAvatar from "../../Home/images/Trans.jpg";
import { AddMenuHeadingAction } from "../../../../store/Menu/Action";
import ChangePasswordPopup from "../../SignIn/views/ChangePasswordPopup";
import { PowerUserDisabledAction } from "../../../../store/RoleAccess/Permission/Action";
import { getSignedUrl } from "@aws-sdk/s3-request-presigner";
import { GetObjectCommand } from "@aws-sdk/client-s3";
import AllNotification from "../../Base/views/AllNotification";
import NotificationsIcon from '@mui/icons-material/Notifications';
import Badge from '@mui/material/Badge';

const AppBar = ({ theme, isMobile, setOpenMobileMenu }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorE2, setAnchorE2] = useState(null);
  const [imageUrl, setImageUrl] = useState("");
  const { fixedMenu, MenuHeading } = useSelector((state) => state.MenuReducer);
  const dispatch = useDispatch();
  const [selectedMenu, setSelectedMenu] = useState(
    getSessionData({ key: "selectedMenu" })
  );
  const { featuresData } = useSelector(
    (state) => state?.RoleAndAccessReducer?.ATSModuleFeaturesReducer
  );
  const { atsModuleData } = useSelector(
    (state) => state?.RoleAndAccessReducer?.ATSModuleReducer
  );
  const { notificationData, allNotificationData } = useSelector(
    state => state?.AncillaryReducer?.NotificationReducer);
  const { homeData } = useSelector((state) => state?.EmployeeDashboardReducer);
  // Total notification count
  const approvalTimeSheet = allNotificationData?.[0]?.notifications?.[0]?.approval_timesheet > 0 ? 1 : 0;
  const approvalTimeOff = allNotificationData?.[0]?.notifications?.[0]?.approval_timeoff > 0 ? 1 : 0;
  const notifiedUsersCount = notificationData?.[0]?.notifiedusers?.length || 0;
  const totalNotificationCount = notifiedUsersCount + approvalTimeSheet + approvalTimeOff

  var data = getUserData();
  const [openPopup, setOpenPopup] = useState(false);
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleNotification = (event) => {
    setAnchorE2(event.currentTarget)
  }
  const ATSAccess = atsModuleData?.find((item) => item.module_name === "ATS");
  const atsDashboarAccess = atsModuleData?.find(
    (item) => item.module_name === "ATS Dashboard"
  );
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClose2 = () => {
    setAnchorE2(null);
  }
  const handleSignout = () => {
    dispatch({ type: "USER_LOGOUT" });
    signOut();
  };
  const handleHomeClick = (menu) => {
    sessionStorage.setItem("selectedMenu", menu);
    sessionStorage.removeItem("tagsSearch");
    dispatch(AddMenuHeadingAction({ heading: menu }));
    history.push("/home");
  };
  const handleFaceClick = () => {
    history.push("/face-attendance-tracker");
  };
  const handleDisabled = () => {
    let value = featuresData?.filter(
      (row) => row?.permission === "dashboard_add_jobpost"
    );
    return value?.length > 0 ? true : false;
  };
  useEffect(() => {
    if (homeData?.[0]?.image) {
      getImageSignedUrl(homeData[0].image);
    }
  }, [homeData]);
  const getImageSignedUrl = async (currentData) => {
    let filename = currentData;
    const bucketParams = {
      Bucket: process.env.REACT_APP_DO_BUCKET_NAME,
      Key: filename,
    };
    try {
      const url = await getSignedUrl(
        s3Client,
        new GetObjectCommand(bucketParams),
        { expiresIn: 3 * 60 }
      ); // Adjustable expiration.
      setImageUrl(url);
      return url;
    } catch (err) {
      toast.error(err);
    }
  };
  return (
    <>
      {isMobile ? (
        <>
          <Box
            sx={{
              zIndex: "999",
              display: { md: "inline", xs: "flex", sm: "flex" },
              justifyContent: "space-between",
              position: "fixed",
              alignItems: "center",
              top: 0,
              width: "100%",
              backgroundColor: `${theme === "dark"
                ? theme.palette?.dark?.main
                : theme.palette?.root?.main
                }`,
            }}
          >
            <Box sx={{ height: "50px", width: "40px", marginLeft: "5px" }}>
              <Logo />
            </Box>
            <Box className="menu_heading">
              <Typography variant="h4">
                {MenuHeading}
              </Typography>
            </Box>
            <Toolbar style={{ padding: "0px 5px 0px 0px" }} className="bar_section">
              <Box
                sx={{
                  display: { md: "flex", sm: "flex", xs: "flex" },
                  justifyContent: "flex-end",
                  alignItems: "center"
                }}
              >
                {atsDashboarAccess &&
                  ATSAccess &&
                  ![
                    "Dashboard",
                    "It's Me",
                    "My Employee",
                    "My Leave",
                    "Employee",
                    "Employee Access Control",
                    "My Timesheet",
                    "Team",
                    "Home",
                    "Employee Bulk Upload",
                    "Time Bulk Upload",
                    "Employee Payroll",
                    "Employee Time",
                    "Employee Reports",
                    "Payroll Bulk Upload",
                    "Tax Declaration"
                  ].includes(selectedMenu) && (
                    <Button
                      variant="contained"
                      sx={{
                        backgroundColor: "#f5f5f5",
                        color: "black",
                        textTransform: "none",
                        fontSize: "10px",
                        borderRadius: "20px",
                        padding: "0px 0px",
                        boxShadow: "none",
                        width: "50px",
                        height: "30px",

                        "&:hover": {
                          backgroundColor: "#e0e0e0",
                        },
                      }}
                      onClick={() =>
                        handleDisabled()
                          ? dispatch(PowerUserDisabledAction())
                          : (sessionStorage.setItem("selectedMenu", "Add Job"),
                            sessionStorage.removeItem("tagsSearch"),
                            dispatch(AddMenuHeadingAction({ heading: "Add Job" })),
                            history.push({
                              pathname: "/ats/add-job/",
                              state: { from: "dashboard" },
                            }))
                      }
                    >
                      Add Job
                    </Button>
                  )}

                {
                  ["owner", "Owner", "Admin"].includes(data?.user_type) && (data?.facerecognition_flow) && (
                    <Tooltip
                      title="Face Attendance Tracker"
                      onClick={() => handleFaceClick()}
                      sx={{ mr: 1 }}
                    >
                      <IconButton sx={{ "&:hover": { color: "#ba54f5 " } }}>
                        <VideoCameraFrontIcon sx={{ transform: "true",fontSize: "21px"  }} />
                      </IconButton>
                    </Tooltip>
                  )}
                <Tooltip
                  title="Home"
                  onClick={() => handleHomeClick("Home")}
                  sx={{ mr: 1 }}
                >
                  <IconButton sx={{ "&:hover": { color: "#ba54f5 " } }}>
                    <HomeIcon sx={{ transform: "true",fontSize: "21px"  }} />
                  </IconButton>
                </Tooltip>
                {data?.image ? (
                  <img
                    alt="Anonymous User"
                    width="20%"
                    onClick={handleMenu}
                    style={{
                      marginLeft: "5px",
                      marginRight: "5px",
                      cursor: "pointer",
                      borderRadius: "50%",
                      width: "30px",
                      height: "30px",
                    }}
                    src={imageUrl}
                  />
                ) : (
                  <img
                    alt="Anonymous User"
                    width="20%"
                    onClick={handleMenu}
                    style={{
                      marginLeft: "5px",
                      marginRight: "5px",
                      cursor: "pointer",
                      borderRadius: "50%",
                      width: "30px",
                      height: "30px",
                    }}
                    src={
                      data?.gender === "Female"
                        ? FemaleAvatar
                        : data?.gender === "Non-Binary"
                          ? TransgenderAvatar
                          : MaleAvatar
                    }
                  />
                )}
                <div>
                  <Menu
                    id="menu-appbar"
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    sx={{ marginTop: "2.5rem" }}
                  >
                    <div className="appbar-user-name" style={{ padding: 2 }}>
                      <div className="appbar-user-img">
                        {data?.image ? (
                          <img
                            style={{ borderRadius: "50%" }}
                            alt="Nothing"
                            src={imageUrl}
                          />
                        ) : (
                          <img
                            style={{ borderRadius: "50%" }}
                            alt="Nothing"
                            src={
                              data?.gender === "Female"
                                ? FemaleAvatar
                                : data?.gender === "Non-Binary"
                                  ? TransgenderAvatar
                                  : MaleAvatar
                            }
                          />
                        )}
                      </div>
                      <div className="appbar-user-details">
                        <p className="appbar-user-name">
                          {data?.name === null || data?.name === ""
                            ? data?.user_name
                            : data?.name}
                        </p>
                        <p className="appbar-user-role">{data?.designation}</p>
                      </div>
                    </div>
                    <Divider orientation="horizontal" />
                    {ATSAccess && (
                      <MenuItem
                        onClick={() => {
                          sessionStorage.setItem("selectedMenu", "My Task");
                          sessionStorage.removeItem("tagsSearch")
                          dispatch(AddMenuHeadingAction({ heading: "My Task" }));
                          history.push("/ats/tasks/");
                        }}
                      >
                        <ListItemIcon>
                          <NotificationsActiveIcon fontSize="small" />
                        </ListItemIcon>
                        Notifications
                      </MenuItem>
                    )}
                    <MenuItem onClick={() => setOpenPopup(true)}>
                      <ListItemIcon>
                        <LockResetIcon fontSize="small" />
                      </ListItemIcon>
                      Change Password
                    </MenuItem>
                    <MenuItem onClick={handleSignout}>
                      <ListItemIcon>
                        <Logout fontSize="small" />
                      </ListItemIcon>
                      Signout
                    </MenuItem>
                  </Menu>
                </div>
              </Box>
              <MenuIcon onClick={() => setOpenMobileMenu(true)} />
            </Toolbar>
          </Box>
        </>
      ) : (
        <>
          <Box
            sx={{
              zIndex: "999",
              display: { md: "inline", xs: "flex", sm: "flex" },
              justifyContent: `${!isMobile ? "flex-end" : "space-between"}`,
              position: "fixed",
              top: 0,
              width: !fixedMenu ? "calc(100vw - 65px)" : "calc(100vw - 245px)",
              maxWidth: "100%",// Ensures full width across all screen sizes
              backgroundColor: `${theme === "dark"
                ? theme.palette?.dark?.main
                : theme.palette?.root?.main
                }`,
            }}
          >
            <Toolbar style={{ padding: "0px 10px 0px 10px" }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  alignItems: "center",
                }}
              >
                <Box>
                  <Typography sx={{ fontSize:"17px !important"}} variant="h4">
                    {MenuHeading}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "center",
                  }}
                >
                  {atsDashboarAccess &&
                    ATSAccess &&
                    ![
                      "Dashboard",
                      "It's Me",
                      "My Employee",
                      "My Leave",
                      "Employee",
                      "My Timesheet",
                      "Team",
                      "Employee Access Control",
                      "Home",
                      "Employee Bulk Upload",
                      "Time Bulk Upload",
                      "Employee Payroll",
                      "Employee Time",
                      "Employee Reports",
                      "Payroll Bulk Upload",
                      "Tax Declaration"
                    ].includes(selectedMenu) && (


                      <Button
                        variant="contained"
                        sx={{
                          backgroundColor: "#cc73ff1c",
                          color: "black",
                          textTransform: "none",
                          fontWeight: 600,
                          fontSize: "13px",
                          borderRadius: "20px",
                          padding: "4px 12px",
                          boxShadow: "none",
                          "&:hover": {
                            color: "#ba54f5",
                            backgroundColor: "#cc73ff1c",
                          },
                        }}
                        onClick={() =>
                          handleDisabled()
                            ? dispatch(PowerUserDisabledAction())
                            : (sessionStorage.setItem("selectedMenu", "Add Job"),
                              sessionStorage.removeItem("tagsSearch"),
                              dispatch(AddMenuHeadingAction({ heading: "Add Job" })),
                              history.push({
                                pathname: "/ats/add-job/",
                                state: { from: "dashboard" },
                              }))
                        }
                      >
                        <AddIcon sx={{ fontSize: "17px", marginRight: "8px" }} />
                        Add Job
                      </Button>

                    )}
                  {
                    ["owner", "Owner", "Admin"].includes(data?.user_type) && (data?.facerecognition_flow) && (
                      <Tooltip
                        title="Face Attendance Tracker"
                        onClick={() => handleFaceClick()}
                        sx={{ mr: 1 }}
                      >
                        <IconButton sx={{ "&:hover": { color: "#ba54f5 " } }}>
                          <VideoCameraFrontIcon sx={{ transform: "true", fontSize: "21px" }} />
                        </IconButton>
                      </Tooltip>
                    )}
                  <Tooltip
                    title="Home"
                    onClick={() => handleHomeClick("Home")}
                    sx={{ mr: 1 }}
                  >
                    <IconButton sx={{ "&:hover": { color: "#ba54f5 " } }}>
                      <HomeIcon sx={{ transform: "true", fontSize: "21px" }} />
                    </IconButton>
                  </Tooltip>


                  {/* {!isMobile && <SearchBar />} */}

                  <Tooltip
                    title="Notifications"
                    onClick={handleNotification}
                    sx={{ mr: 1 }}
                  >
                    <IconButton sx={{ "&:hover": { color: "#ba54f5" } }}>
                      <Badge
                        badgeContent={totalNotificationCount || 0}
                        color="default"
                        sx={{
                          "& .MuiBadge-dot, .MuiBadge-standard": {
                            backgroundColor: "#ba54f5",
                            color: "#fff",
                            fontSize: "12px"
                          },
                        }}
                        max={99}
                        overlap="circular"
                      >
                        <NotificationsIcon sx={{ transform: "true",fontSize: "21px" }} />
                      </Badge>
                    </IconButton>
                  </Tooltip>

                  {data?.image ? (
                    <img
                      alt="Anonymous User"
                      width="20%"
                      onClick={handleMenu}
                      style={{
                        marginLeft: "5px",
                        marginRight: "5px",
                        cursor: "pointer",
                        borderRadius: "50%",
                        width: "34px",
                        height: "34px",
                      }}
                      src={imageUrl}
                    />
                  ) : (
                    <img
                      alt="Anonymous User"
                      width="20%"
                      onClick={handleMenu}
                      style={{
                        marginLeft: "5px",
                        marginRight: "5px",
                        cursor: "pointer",
                        borderRadius: "50%",
                        width: "34px",
                        height: "34px",
                      }}
                      src={
                        data?.gender === "Female"
                          ? FemaleAvatar
                          : data?.gender === "Non-Binary"
                            ? TransgenderAvatar
                            : MaleAvatar
                      }
                    />
                  )}
                  <div>
                    <Menu
                      id="menu-notification"
                      anchorEl={anchorE2}
                      open={Boolean(anchorE2)}
                      onClose={handleClose2}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      keepMounted
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      sx={{ marginTop: "2.5rem" }}
                    >
                      < AllNotification />
                    </Menu>
                  </div>

                  <div>
                    <Menu
                      id="menu-appbar"
                      anchorEl={anchorEl}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      keepMounted
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      open={Boolean(anchorEl)}
                      onClose={handleClose}
                      sx={{ marginTop: "2.5rem" }}
                    >
                      <div className="appbar-user-name" style={{ padding: 2 }}>
                        <div className="appbar-user-img">
                          {data?.image ? (
                            <img
                              alt="Anonymous User"
                              style={{
                                borderRadius: "50%",
                              }}
                              src={imageUrl}
                            />
                          ) : (
                            <img
                              alt="Anonymous User"
                              style={{
                                borderRadius: "50%",
                              }}
                              src={
                                data?.gender === "Female"
                                  ? FemaleAvatar
                                  : data?.gender === "Non-Binary"
                                    ? TransgenderAvatar
                                    : MaleAvatar
                              }
                            />
                          )}
                        </div>
                        <div className="appbar-user-details">
                          <p className="appbar-user-name">
                            {data?.name === null || data?.name === ""
                              ? data?.user_name
                              : data?.name}
                          </p>
                          <p className="appbar-user-role">
                            {data?.designation}
                          </p>
                        </div>
                      </div>
                      <Divider orientation="horizontal" />
                      {ATSAccess && (
                        <MenuItem onClick={() => {
                          sessionStorage.setItem("selectedMenu", "My Task");
                          sessionStorage.removeItem("tagsSearch")
                          dispatch(AddMenuHeadingAction({ heading: "My Task" }));
                          history.push("/ats/tasks/");
                        }}>
                          <ListItemIcon>
                            <NotificationsActiveIcon fontSize="small" />
                          </ListItemIcon>
                          Notifications
                        </MenuItem>
                      )}
                      <MenuItem onClick={() => setOpenPopup(true)}>
                        <ListItemIcon>
                          <LockResetIcon fontSize="small" />
                        </ListItemIcon>
                        Change Password
                      </MenuItem>
                      <MenuItem onClick={handleSignout}>
                        <ListItemIcon>
                          <Logout fontSize="small" />
                        </ListItemIcon>
                        Signout
                      </MenuItem>
                    </Menu>
                  </div>
                </Box>
              </Box>
            </Toolbar>
          </Box>
        </>
      )}
      <Popup
        title={"Change Password"}
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
        maxWidth="xs"
        fullWidth={true}
      >
        <ChangePasswordPopup
          openPopup={openPopup}
          setOpenPopup={setOpenPopup}
        />
      </Popup>
    </>
  );
};

export default AppBar;
