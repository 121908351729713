import { useState } from "react";
import MaterialTable from "material-table";
import {
    Box,
    Grid,
    CircularProgress,
    Button
} from "@mui/material";
import { GenerateEPFOReportHelper } from "../../helper/Reports/GetWageReportHelper";
import CustomToggle from "../../../Base/views/CustomToggle";
import { CSVLink } from "react-csv";

const EPFOLandingPage = () => {
    const [isLoadingbtn, setIsLoadingbtn] = useState(false);
    const [data, setData] = useState([]);
    const [inactive, setInactive] = useState(false);
    const transformData = (epfoData = [], ecrData = []) => {
        return epfoData?.map((epfoItem, index) => {
            const ecrItem = ecrData?.[index] || {};
            return {
                name: epfoItem?.["employee name"] || "N/A",
                uan: epfoItem?.uan || "N/A",
                gross: Number(epfoItem?.["gross wages"] || 0).toFixed(2),
                epfcr: Number(epfoItem?.["epf contribution remitted"] || 0).toFixed(2),
                epscr: Number(epfoItem?.["eps contribution remitted"] || 0).toFixed(2),
                epfeps: Number(epfoItem?.["epf and eps diff remitted"] || 0).toFixed(2),
                ncp: Number(epfoItem?.["ncp days"] || 0).toFixed(2),
                epf: Number(epfoItem?.["epf wages"] || 0).toFixed(2),
                eps: Number(epfoItem?.["eps wages"] || 0).toFixed(2),
                edli: Number(epfoItem?.["edli wages"] || 0).toFixed(2),
                paidDays: Number(ecrItem?.["paid days"] || 0).toFixed(2),
                employeeId: ecrItem?.["employee id"] || "N/A",
                esi_no: ecrItem?.["esi_no"] || "N/A",
                wages: Number(ecrItem?.["wages"] || 0).toFixed(2),
                employeecontribution: Number(ecrItem?.["employee contribution"] || 0).toFixed(2),
                employercontribution: Number(ecrItem?.["employer contribution"] || 0).toFixed(2),
            };
        }) || [];
    };

    const handleGenerate = () => {
        setIsLoadingbtn(true);
        GenerateEPFOReportHelper({
        }).then((res) => {
            const epfoData = res?.data?.data?.[0]?.epfo || [];
            const ecrData = res?.data?.data?.[0]?.ecr || [];
            const transformedData = transformData(epfoData, ecrData);
            setData(transformedData);
            setIsLoadingbtn(false);
        });
    };
    const handleToggle = (e) => {
        setInactive(!inactive);
    };
    const headers = [
        { label: "Name", key: "name" },
        { label: "UAN", key: "uan" },
        { label: "ESI No", key: "esi_no" },
        { label: "Gross", key: "gross" },
        { label: "EPF Contribution Remitted", key: "epfcr" },
        { label: "EPS Contribution Remitted", key: "epscr" },
        { label: "EPF and EPS Diff Remitted", key: "epfeps" },
        { label: "NCP Days", key: "ncp" },
        { label: "Paid Days", key: "paidDays" },
        { label: "Wages", key: "wages" },
        { label: "Employee Contribution", key: "employeecontribution" },
        { label: "Employer Contribution", key: "employercontribution" }
    ];

    const formatDate = () => {
        const date = new Date();
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = String(date.getFullYear()).slice(-2);
        return `${day}_${month}_${year}`;
    };
    const csvFileName = `EPFO_ECR_${formatDate()}.csv`;
    const exportFileName = inactive ? `ESI_${formatDate()}` : `EPF_${formatDate()}`;
    return (
        <>
            <Box >
                <Box sx={{ m: 1 }}>
                    <Grid sx={{ display: "flex", justifyContent: "flex-end" }} container columnSpacing={1} mb={1}>
                        <div>
                            {isLoadingbtn ? (
                                <CircularProgress disableShrink />
                            ) : (
                                <Button
                                    onClick={handleGenerate}
                                    className="mpl-primary-btn"
                                >
                                    Generate
                                </Button>
                            )}
                        </div>
                        {data?.length > 0 && (
                            <Button sx={{ ml: 2 }} className="mpl-primary-btn">
                                <CSVLink className="ats-csv-btn" data={data} headers={headers} filename={csvFileName}>
                                    Export All
                                </CSVLink>
                            </Button>
                        )}
                    </Grid>
                </Box>

                <MaterialTable
                    columns={[
                        {
                            title: "Name",
                            cellStyle: { padding: "10px 10px 10px 13px" },
                            field: "name",
                            grouping: false,
                        },
                        {
                            title: "UAN",
                            cellStyle: { padding: "10px 10px 10px 13px" },
                            field: "uan",
                            grouping: false,
                            hidden: inactive,
                        },

                        {
                            title: "Gross",
                            cellStyle: { padding: "10px 10px 10px 13px" },
                            field: "gross",
                            grouping: false,
                            hidden: inactive,
                        },
                        {
                            title: "EPF Contribution remitted",
                            cellStyle: { padding: "10px 10px 10px 13px" },
                            field: "epfcr",
                            grouping: false,
                            hidden: inactive,
                        },
                        {
                            title: "EPS Contribution remitted ",
                            cellStyle: { padding: "10px 10px 10px 13px" },
                            field: "epscr",
                            hidden: inactive,
                            grouping: false,
                        }, {
                            title: "EPF and EPS Diff remitted",
                            cellStyle: { padding: "10px 10px 10px 13px" },
                            field: "epfeps",
                            grouping: false,
                            hidden: inactive,
                        },
                        {
                            title: "NCP Days",
                            cellStyle: { padding: "10px 10px 10px 13px" },
                            field: "ncp",
                            grouping: false,
                            hidden: inactive,
                        },
                        {
                            title: "ESI No",
                            cellStyle: { padding: "10px 10px 10px 13px" },
                            field: "esi_no",
                            grouping: false,
                            hidden: !inactive,
                        },
                        {
                            title: "Paid Days",
                            cellStyle: { padding: "10px 10px 10px 13px" },
                            field: "paidDays",
                            grouping: false,
                            hidden: !inactive,
                        },
                        {
                            title: "Wages",
                            cellStyle: { padding: "10px 10px 10px 13px" },
                            field: "wages",
                            grouping: false,
                            hidden: !inactive,
                        },
                        {
                            title: "Employee Contribution",
                            cellStyle: { padding: "10px 10px 10px 13px" },
                            field: "employeecontribution",
                            grouping: false,
                            hidden: !inactive,
                        }, {
                            title: "Employer Contribution",
                            cellStyle: { padding: "10px 10px 10px 13px" },
                            field: "employercontribution",
                            grouping: false,
                            hidden: !inactive,
                        },
                    ]}
                    data={data}
                    options={{
                        maxBodyHeight: "54vh",
                        minBodyHeight: "54vh",
                        rowStyle: { fontSize: "13px" },
                        headerStyle: { padding: "10px 10px 10px 13px", fontWeight: "bold" },
                        sorting: true,
                        search: true,
                        searchText: "",
                        searchFieldAlignment: "right",
                        searchAutoFocus: true,
                        searchFieldVariant: "standard",
                        filtering: false,
                        paging: true,
                        pageSizeOptions: [25, 50],
                        pageSize: 25,
                        paginationType: "stepped",
                        showFirstLastPageButtons: false,
                        paginationPosition: "bottom",
                        exportButton: data?.length > 0 && {
                            csv: true,
                            pdf: false,
                        },
                        exportAllData: true,
                        exportFileName: exportFileName,
                        addRowPosition: "first",
                        selection: false,
                        actionsColumnIndex: -1,
                        showSelectAllCheckbox: false,
                        showTextRowsSelected: false,
                        grouping: false,
                        columnsButton: false,
                    }}
                    actions={[
                        {
                            icon: () => (
                                <CustomToggle
                                    isChecked={inactive}
                                    setIsChecked={handleToggle}
                                    handleToggleChange={handleToggle}
                                    Yestext={"ESI"}
                                    NoText={"EPF"}
                                    style={{
                                        height: "32px",
                                        padding: "0px",
                                        width: "150px",
                                        labelPadding: "4px",
                                        "&:hover": {
                                            backgroundColor: "#e6d9fb7a !important",
                                        },
                                    }}
                                />
                            ),
                            position: "row",
                            isFreeAction: true,
                        },

                    ]}
                    title={inactive ? "ESI" : "EPF"}
                />
            </Box>
        </>
    );
};

export default EPFOLandingPage;