import UnauthorizedBase from "../../../Base/views/UnauthorizedBase";
import { Box, Grid, Typography, Tooltip, Skeleton } from "@mui/material";
import { useEffect, useState } from "react";
import { GetAtsJobPostByIdHelper } from "../../helper/JobPostHelper";
import { useParams } from "react-router-dom";
import NotFound from "../../../Base/views/NotFound";
import { BASE_URL } from "../../../../Constants/baseConstant";
import { GetCompanyLogoHelper } from "../../../Ancillary/CompanyDetails/helper/CompanyDetailsHelper";
import { GetObjectCommand } from "@aws-sdk/client-s3";
import { getSignedUrl } from "@aws-sdk/s3-request-presigner";
import { s3Client } from "../../../Base/helper/baseFunctions";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import WorkIcon from "@mui/icons-material/Work";
const CareePortalJobs = () => {
  const [jobdata, setJobData] = useState([]);
  const { domain, jobid } = useParams();
  const decDomain = atob(domain);
  const domainname = decDomain?.split("=")?.[1];
  const decjobId = atob(jobid);
  const decjobsId = decjobId.replace(/[[\]']/g, "");
  const [isLoading, setisLoading] = useState(true);

  const [imageUrl, setImageUrl] = useState("");
  const [notfound, setNotfound] = useState(false);
  useEffect(() => {
    setisLoading(true);
    GetAtsJobPostByIdHelper({
      domain: domainname,
      ats_jobpost_id: `{${decjobsId}}`,
    }).then((res) => {
      if (res.hasError === true) {
        setisLoading(true);
        setNotfound(true);
      } else {
        setNotfound(false);
        setisLoading(false);
        setJobData(res.data.data);
      }
    });
    GetCompanyLogoHelper({ domain: domainname })
      .then((res) => {
        res?.data?.data?.[0]?.companylogo &&
          getImageSignedUrl(res?.data?.data?.[0]?.companylogo);
      })
      .catch((err) => {
        console.log(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getImageSignedUrl = async (currentData) => {
    let filename = currentData;
    const bucketParams = {
      Bucket: process.env.REACT_APP_DO_BUCKET_NAME,
      Key: filename,
    };
    try {
      const url = await getSignedUrl(
        s3Client,
        new GetObjectCommand(bucketParams),
        { expiresIn: 3 * 60 }
      ); // Adjustable expiration.
      setImageUrl(url);
      return url;
    } catch (err) {}
  };
  const handleLinkClick = (id) => {
    const url = `${BASE_URL}/ats/career-portal-job/${btoa(id)}/${btoa(
      decDomain
    )}`;
    window.open(url, "_blank");
  };
  return (
    <>
      {notfound === true && <NotFound />}
      <UnauthorizedBase
        imageUrl={imageUrl}
        companyName={jobdata?.[0]?.company_full_name}
      >
        {isLoading === true ? (
          <>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignContent: "center",
                justifyContent: "center",
                alignItems: "center",
                mt: 1,
              }}
            >
              <Grid container item xs={12} sm={10} lg={10} spacing={4} mb>
                {Array(4)
                  .fill()
                  .map((d, i) => (
                    <Grid key={i} item xs={6}>
                      <Skeleton
                        animation="wave"
                        variant="rectangular"
                        width={570}
                        height={275}
                      />
                    </Grid>
                  ))}
              </Grid>
            </Box>
          </>
        ) : (
          <>
            <Box
              sx={{
                padding: "16px",
                background: "#f6f6f4",
                width: "100%",
                minHeight: "92vh",
              }}
            >
              <Grid
                container
                direction="row"
                alignItems="center"
                width={"95%"}
                bgcolor={"#ffffff"}
                borderRadius={"10px"}
                boxShadow={"0px 0px 10px 0px #0000001A"}
                margin={"auto"}
                p={3}
              >
                <Grid
                  item
                  xs={12}
                  display="flex"
                  flexDirection={"column"}
                  justifyContent="start"
                  pl={2}
                >
                  <Typography className="workspace_name" variant="h3">
                    Job Board
                  </Typography>
                  <Typography>
                    Thanks for checking out our job openings. See something that
                    interests you? Apply here.
                  </Typography>
                </Grid>
                {jobdata?.map((job, index) => (
                  <Grid
                    className="Card-Job"
                    onClick={() => handleLinkClick(job?.ats_jobpost_id)}
                    key={index}
                    item
                  >
                    <Box key={index}>
                      <Grid
                        container
                        bgcolor={"#ffffff"}
                        sx={{ display: "flex", flexDirection: {xs:"column", md: "row"}, gap: "4%", padding: "12px 4px",alignItems:"start",
                       }}
                      >
                        <Box
                          sx={{
                            width: {xs: "100%", md: "28%"},
                            textAlign:"start",
                            gap: "2px",
                            paddingRight:"25px",
                            marginBottom: {xs: "10px", md: "0px"},
                          }}
                        >
                          <Tooltip
                                title={job?.title}>
                          <Typography className="Job_title" variant="h5"
                            sx={{
                              whiteSpace: "nowrap",     
                              overflow: "hidden",      
                              textOverflow: "ellipsis", 
                            }}
                          >
                            {job?.title}
                          </Typography>
                        </Tooltip>
                        </Box>

                        <Box 
                        sx={{ width: {xs: "100%", md: "66%"}}}
                          >
                          <Box
                            sx={{
                              width: "100%",
                              display: "flex",
                              overflow: "hidden",
                              flexDirection: { xs: "column", md: "row" },
                              justifyContent: "flex-start",
                              alignItems: { xs: "start", md: "center" },
                              gap: 2,
                              
                            }}
                          >
                            {/* Location Section */}
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                flex: 1,
                              }}
                            >
                              <LocationOnIcon color="primary" sx={{ mr: 1, fontSize:"22px" }} />
                              <Typography
                                className="Sub-header-job"
                                variant="body1"
                                sx={{fontSize:"18px" }}
                              >
                                Location:&nbsp;
                              </Typography>
                              <Tooltip
                                title={
                                  job?.job_location === null
                                    ? "Not Available"
                                    : ""
                                }
                              >
                                <Typography variant="body1" sx={{fontSize:"18px" }}>
                                  {job?.job_location || "NA"}
                                </Typography>
                              </Tooltip>
                            </Box>
                            <Box
                              className="sub-content-values"
                              sx={{
                                display: "flex", 
                                alignItems: "center",
                                flex: 1,
                                minWidth: 0,
                                overflow: "hidden"
                              }}
                            >
                              <WorkIcon color="primary" sx={{ mr: 1, fontSize:"22px" }} />
                              <Tooltip title={`${job?.industry_type} - ${job?.job_type || "Full Time"}`}>
                              <Typography
                                className="Sub-header-job"
                                variant="body1"
                                sx={{
                                  maxWidth: {xs:"50%", sm:"80%", md: "100%"},
                                  whiteSpace:"nowrap",     
                                  overflow: "hidden",      
                                  textOverflow: "ellipsis",
                                  fontSize:"18px" 
                                }}
                              >
                                {job?.industry_type}
                              </Typography>
                              </Tooltip>
                              <Typography
                                variant="body1"
                                sx={{ whiteSpace: "nowrap", fontSize:"18px" }}
                              >
                                &nbsp;-&nbsp;{job?.job_type || "Full Time"}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      </Grid>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </>
        )}
      </UnauthorizedBase>
    </>
  );
};

export default CareePortalJobs;
