import axiosInstance from "../../../../axiosInstance";
import history from "../../../../history";
import {
  SIGN_IN_SECRET,
  SIGN_IN_INITIAL_VECTOR,
  HRMS_UTILITY_API_URL,
  ADMIN_WORKSPACE,
} from "../../../Constants/baseConstant";
import { addSessionData } from "../../Base/helper/baseHelper";
import * as CryptoJS from "crypto-js";
import { toast } from "react-toastify";
import NProgress from "nprogress";

function EncryptPassword(data) {
  let password = CryptoJS.AES.encrypt(
    data,
    CryptoJS.enc.Utf8.parse(SIGN_IN_SECRET),
    {
      iv: CryptoJS.enc.Utf8.parse(SIGN_IN_INITIAL_VECTOR),
      mode: CryptoJS.mode.CBC,
    }
  );
  return password.toString();
}

export const AdminSigninHelper = async (signinData) => {
  NProgress.start();
  return await axiosInstance("/user/signIn", {
    method: "POST",
    baseURL: HRMS_UTILITY_API_URL,
    data: {
      userid: signinData?.emailid,
      password:
        signinData?.step !== 1
          ? EncryptPassword(signinData?.password)
          : "password",
      domain: ADMIN_WORKSPACE,
    },
  })
    .then((res) => {
      NProgress.done();
      history.push("/meepl-admin/");
      addSessionData({ key: "userManagement", value: ADMIN_WORKSPACE });
      addSessionData({ key: "AdminEmail", value: signinData?.emailid });
      addSessionData({ key: "isSignedIn", value: true });
      addSessionData({ key: "is_ats_user", value: res.data?.data?.is_ats_user });
      addSessionData({ key: "userid", value: res.data?.data?.userid });
      addSessionData({ key: "user_type", value: btoa(res.data?.data?.user_type) });
      addSessionData({ key: "country_code", value: res.data?.data?.country_code });
      return {
        message: res,
        payload: {
          userid: res.data?.data?.userid,
          domain: signinData?.domain?.toLowerCase(),
          is_ats_user: res.data?.data?.is_ats_user,
        },
      };
    })
    .catch((err) => {
      NProgress.done();
      err?.response?.data?.error?.[0]?.message &&
        toast.error(err?.response?.data?.error?.[0]?.message);
    });
};
