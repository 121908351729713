import {
  Button,
  Typography,
  Avatar,
  Box,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const SuccessDialog = ({
  handleClose,
  attendanceType,
  user,
  imageUrl,
  employeeTime,
}) => {

  const formatTime = (date) => {
    const time = new Date(date);
    const options = {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
    return time.toLocaleTimeString("en-US", {
      ...options,
      timeZone: "UTC",
    });
  };


  const theme = createTheme({
    typography: {
      fontSize: 20,
      h6: {
        fontSize: 20,
        "@media (min-width:600px)": {
          fontSize: 35,
        },
        "@media (min-width:960px)": {
          fontSize: 26,
        },
      },
      body1: {
        fontSize: 20,
        "@media (min-width:600px)": {
          fontSize: 30,
        },
        "@media (min-width:960px)": {
          fontSize: 24,
        },
        display: "flex",
      },
    },
  });
  return (
    <ThemeProvider theme={theme}>
      <Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: 1,
            margin: "10px 0px",
          }}
        >
          <CheckCircleIcon
            sx={{ color: "green", fontSize: { xs: 30, sm: 40, md: 35 } }}
          />
          <Typography variant="h6">{`${attendanceType} Successful`}</Typography>
        </Box>

        <Box
          sx={{
            width: { xs: "90%", sm: "70%" },
            margin: "auto",
            textAlign: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: 1,
              textAlign: "start",
              "& strong": { color: "#883bd5", ml: 1 },
              "& span": { width: { xs: "150px", sm: "210px", md: "170px" } },
            }}
          >
            <Avatar
              src={imageUrl}
              alt={user?.name}
              sx={{
                width: { xs: 100, sm: 150, md: 140 },
                height: { xs: 100, sm: 150, md: 140 },
              }}
            />

            <Typography variant="h6" color={"#883bd5"} fontWeight={"bold"}>
              {user?.name}
            </Typography>
            {attendanceType === "Check Out" && (
              <Box textAlign="left">
                <Typography variant="body1">
                  <span>Employee ID</span>:<strong>{user?.employee_no}</strong>
                </Typography>

                <Typography variant="body1">
                  <span>Check-In</span>:
                  <strong>
                    {employeeTime?.last_check_in
                      ? formatTime(employeeTime?.last_check_in)
                      : "NA"}
                  </strong>
                </Typography>

                <Typography variant="body1">
                  <span>Check-Out</span>:
                  <strong>
                    {employeeTime?.last_check_out
                      ? formatTime(employeeTime?.last_check_out)
                      : "NA"}
                  </strong>
                </Typography>
              </Box>
            )}
          </Box>
          {attendanceType === "Check Out" && (
            <Button
              variant="contained"
              size="medium"
              sx={{
                width: { xs: "150px", sm: "180px", md: "150px" },
                fontWeight: "bold",
                margin: "20px 0px",
                borderRadius: "10px",
                color: "black",
                background: "#fecb03",
                border: "1px solid #fecb03",
                transition: "all 0.3s ease",
                "&:hover": {
                  cursor: "pointer",
                  backgroundColor: "#fecb03",
                  boxShadow: "0px 4px 10px rgba(254,203,3, 0.3)",
                },
              }}
              onClick={handleClose}
            >
              OK
            </Button>
          )}
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default SuccessDialog;
