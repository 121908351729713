import { put, call } from 'redux-saga/effects';
import { GetLeaveHistoryHelper } from "../../app/Components/Leave/helper/LeaveHistoryHelper";
import {
    GetLeaveTypesHelper,
    AddLeaveTypesHelper,
    UpdateLeaveTypesHelper,
    DeleteLeaveTypesHelper
} from '../../app/Components/Leave/helper/LeaveTypeHelper';
import {
    GetLeaveRequestHelper,
    AddLeaveRequestHelper,
    UpdateLeaveRequesteHelper,
    DeleteLeaveRequestHelper
} from "../../app/Components/Leave/helper/LeaveRequestHelper";
import {
    GetUpcomingHolidaysHelper,
    GetHolidayCalendarHelper,
    AddHolidayCalendarHelper,
    UpdateHolidayCalendarHelper,
    DeleteHolidayCalendarHelper
} from "../../app/Components/Leave/helper/HolidayCalendarHelper";
import { LeaveStatusHelper } from "../../app/Components/Leave/helper/LeaveStatusHelper";
import {
    GetLeaveOverallBalanceHelper,
    GetPendingLeaveApprovalHelper
} from "../../app/Components/Leave/helper/LeaveDashboardHelper";
import * as ActionTypes from './ActionTypes';
import { GetLeaveReportHelper, GetLeaveSummaryHelper, GetLeaveLedgerHelper } from '../../app/Components/Leave/helper/LeaveReports';
import { GetLeaveApprovalHelper } from '../../app/Components/Leave/helper/LeaveApproval';
import { AddYearlyHolidayCalendarHelper, DeleteYearlyHolidayCalendarHelper, GetYearlyHolidayCalendarHelper, UpdateYearlyHolidayCalendarHelper } from '../../app/Components/Leave/helper/YearlyHolidayCalendarHelper';
import { AddLeaveReasonHelper, DeleteLeaveReasonHelper, GetLeaveReasonHelper, UpdateLeaveReasonHelper } from '../../app/Components/Leave/helper/LeaveReasonHelper';
import { GetLeaveYearTypeHelper, UpdateLeaveYearTypeHelper } from '../../app/Components/Leave/helper/LeaveYearTypeHelper';
import { GetMyTimeConfigureHelper, AddMyTimeConfigureHelper, UpdateMyTimeConfigureHelper, DeleteMyTimeConfigureHelper  } from '../../app/Components/Leave/helper/MyTimeConfigureHelper';
import { GetCurrentTimeHelper  } from '../../app/Components/Leave/helper/CurrentTimeHelper';
import { GetMyTimeSheetHelper, GetMyTimeSheetLeaveInfoHelper, AddMyTimeSheetHelper, UpdateMyTimeSheetHelper } from '../../app/Components/MyTime/helper/TimeSheet/MyTimeSheetHelper';
import { GetMyTimeSheetApprovalHelper, ApproveTimeSheetHelper, ApproveTimeOffHelper } from '../../app/Components/MyTime/helper/Team/TeamHelper';
import { getFaceDataHelper } from '../../app/Components/FaceAttendance/helper/FaceRecognizationHelper';
import { AddWeekOffConfigureHelper, GetWeekOffConfigureHelper, GetWorkHoursHelper, UpdateWeekOffConfigureHelper, UpdateWorkHoursHelper,AddWorkHoursHelper, DeleteWorkHoursHelper, DeleteWeekOffConfigureHelper } from '../../app/Components/Leave/helper/WeekOffHelper';
import { GetTeamBalanceHelper, ReportingEmployeeHelper } from '../../app/Components/MyTime/helper/TimeOff/TimeOffHelper';
import { AddShiftTimeConfigureHelper, DeleteShiftTimeConfigureHelper, GetShiftTimeConfigureHelper, UpdateShiftTimeConfigureHelper } from '../../app/Components/Leave/helper/ShiftTimeConfigureHelper';

// Leave Types
export function* GetLeaveTypesSaga() {
    const response = yield call(GetLeaveTypesHelper)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.LEAVE_TYPE_GET_SUCCESS, payload: response })
    } else {
        yield put({ type: ActionTypes.LEAVE_TYPE_GET_ERROR, payload: response })
    }
}

export function* AddLeaveTypesSaga(payload) {
    const response = yield call(AddLeaveTypesHelper, payload.params)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.LEAVE_TYPE_ADD_SUCCESS, payload: response })
    } else {
        yield put({ type: ActionTypes.LEAVE_TYPE_ADD_ERROR, payload: response })
    }
}

export function* UpdateLeaveTypesSaga(payload) {
    const response = yield call(UpdateLeaveTypesHelper, payload.params)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.LEAVE_TYPE_UPDATE_SUCCESS, payload: response })
    } else {
        yield put({ type: ActionTypes.LEAVE_TYPE_UPDATE_ERROR, payload: response })
    }
}

export function* DeleteLeaveTypesSaga(payload) {
    const response = yield call(DeleteLeaveTypesHelper, payload.params)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.LEAVE_TYPE_DELETE_SUCCESS, payload: response })
    } else {
        yield put({ type: ActionTypes.LEAVE_TYPE_DELETE_ERROR, payload: response })
    }
}

// Leave Request
export function* GetLeaveRequestSaga(payload) {
    const response = yield call(GetLeaveRequestHelper, payload?.params)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.LEAVE_REQUEST_GET_SUCCESS, payload: response })
    } else {
        yield put({ type: ActionTypes.LEAVE_REQUEST_GET_ERROR, payload: response })
    }
}

export function* AddLeaveRequestSaga(payload) {
    const response = yield call(AddLeaveRequestHelper, payload.leaveRequestDetails)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.LEAVE_REQUEST_ADD_SUCCESS, payload: response })
    } else {
        yield put({ type: ActionTypes.LEAVE_REQUEST_ADD_ERROR, payload: response })
    }
}

export function* UpdateLeaveRequestSaga(payload) {
    const response = yield call(UpdateLeaveRequesteHelper, payload.leaveRequestDetail, payload.reqDays)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.LEAVE_REQUEST_UPDATE_SUCCESS, payload: response })
    } else {
        yield put({ type: ActionTypes.LEAVE_REQUEST_UPDATE_ERROR, payload: response })
    }
}

export function* DeleteLeaveRequestSaga(payload) {
    const response = yield call(DeleteLeaveRequestHelper, payload.leaveRequestDetails)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.LEAVE_REQUEST_DELETE_SUCCESS, payload: response })
    } else {
        yield put({ type: ActionTypes.LEAVE_REQUEST_DELETE_ERROR, payload: response })
    }
}

// Leave History
export function* GetLeaveHistorySaga(payload) {
    const response = yield call(GetLeaveHistoryHelper, payload.leaveHistoryDetails)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.LEAVE_HISTORY_GET_SUCCESS, payload: response })
    } else {
        yield put({ type: ActionTypes.LEAVE_HISTORY_GET_ERROR, payload: response })
    }
}

// Leave Status
export function* LeaveStatusSaga(payload) {
    const response = yield call(LeaveStatusHelper, payload.leaveDetails, payload.bulkApprovalData)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.LEAVE_STATUS_UPDATE_SUCCESS, payload: response })
    } else {
        yield put({ type: ActionTypes.LEAVE_STATUS_UPDATE_ERROR, payload: response })
    }
}


// Leave Overall Balance
export function* GetLeaveOverallBalanceSaga(payload) {
    const response = yield call(GetLeaveOverallBalanceHelper, payload.show)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.LEAVE_OVERALL_BALANCE_GET_SUCCESS, payload: response })
    } else {
        yield put({ type: ActionTypes.LEAVE_OVERALL_BALANCE_GET_ERROR, payload: response })
    }
}

// Pending Leave Approval
export function* GetPendingLeaveApprovalSaga() {
    const response = yield call(GetPendingLeaveApprovalHelper)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.PENDING_LEAVE_APPROVAL_GET_SUCCESS, payload: response })
    } else {
        yield put({ type: ActionTypes.PENDING_LEAVE_APPROVAL_GET_ERROR, payload: response })
    }
}

// Holiday Calendar
export function* GetUpcomingHolidaysSaga(payload) {
    const response = yield call(GetUpcomingHolidaysHelper, payload.params)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.UPCOMING_HOLIDAYS_GET_SUCCESS, payload: response })
    } else {
        yield put({ type: ActionTypes.UPCOMING_HOLIDAYS_GET_ERROR, payload: response })
    }
}
export function* GetHolidayCalendarSaga(payload) {
    const response = yield call(GetHolidayCalendarHelper, payload.params)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.HOLIDAY_CALENDAR_GET_SUCCESS, payload: response })
    } else {
        yield put({ type: ActionTypes.HOLIDAY_CALENDAR_GET_ERROR, payload: response })
    }
}

export function* AddHolidayCalendarSaga(payload) {
    const response = yield call(AddHolidayCalendarHelper, payload.leaveRequestDetails, payload.reqDays)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.HOLIDAY_CALENDAR_ADD_SUCCESS, payload: response })
    } else {
        yield put({ type: ActionTypes.HOLIDAY_CALENDAR_ADD_ERROR, payload: response })
    }
}

export function* UpdateHolidayCalendarSaga(payload) {
    const response = yield call(UpdateHolidayCalendarHelper, payload.leaveRequestDetail, payload.reqDays)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.HOLIDAY_CALENDAR_UPDATE_SUCCESS, payload: response })
    } else {
        yield put({ type: ActionTypes.HOLIDAY_CALENDAR_UPDATE_ERROR, payload: response })
    }
}

export function* DeleteHolidayCalendarSaga(payload) {
    const response = yield call(DeleteHolidayCalendarHelper, payload.leaveRequestDetails)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.HOLIDAY_CALENDAR_DELETE_SUCCESS, payload: response })
    } else {
        yield put({ type: ActionTypes.HOLIDAY_CALENDAR_DELETE_ERROR, payload: response })

    }
}
// Leave Summary
export function* GetLeaveSummary(payload) {
    const response = yield call(GetLeaveSummaryHelper, payload.show)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.LEAVE_SUMMARY_GET_SUCCESS, payload: response })
    } else {
        yield put({ type: ActionTypes.LEAVE_SUMMARY_GET_ERROR, payload: response })
    }
}

// Leave Report
export function* GetLeaveReport(payload) {
    const response = yield call(GetLeaveReportHelper, payload?.report)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.LEAVE_REPORT_GET_SUCCESS, payload: response })
    } else {
        yield put({ type: ActionTypes.LEAVE_REPORT_GET_ERROR, payload: response })
    }
}

// Leave Ledger
export function* GetLeaveLedger(payload) {
    const response = yield call(GetLeaveLedgerHelper)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.LEAVE_LEDGER_GET_SUCCESS, payload: response })
    } else {
        yield put({ type: ActionTypes.LEAVE_LEDGER_GET_ERROR, payload: response })
    }
}

// Leave Approval
export function* GetLeaveApproval(payload) {
    const response = yield call(GetLeaveApprovalHelper)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.LEAVE_APPROVAL_GET_SUCCESS, payload: response })
    } else {
        yield put({ type: ActionTypes.LEAVE_APPROVAL_GET_ERROR, payload: response })
    }
}

// Yearly Holiday Calendar
export function* GetYearlyHolidayCalendar(payload) {
    const response = yield call(GetYearlyHolidayCalendarHelper, payload?.params)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.YEARLY_HOLIDAY_CALENDAR_GET_SUCCESS, payload: response })
    } else {
        yield put({ type: ActionTypes.YEARLY_HOLIDAY_CALENDAR_GET_ERROR, payload: response })
    }
}

export function* AddYearlyHolidayCalendar(payload) {
    const response = yield call(AddYearlyHolidayCalendarHelper, payload?.params)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.YEARLY_HOLIDAY_CALENDAR_ADD_SUCCESS, payload: response })
    } else {
        yield put({ type: ActionTypes.YEARLY_HOLIDAY_CALENDAR_ADD_ERROR, payload: response })
    }
}

export function* UpdateYearlyHolidayCalendar(payload) {
    const response = yield call(UpdateYearlyHolidayCalendarHelper, payload?.params)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.YEARLY_HOLIDAY_CALENDAR_UPDATE_SUCCESS, payload: response })
    } else {
        yield put({ type: ActionTypes.YEARLY_HOLIDAY_CALENDAR_UPDATE_ERROR, payload: response })
    }
}

export function* DeleteYearlyHolidayCalendar(payload) {
    const response = yield call(DeleteYearlyHolidayCalendarHelper, payload?.params)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.YEARLY_HOLIDAY_CALENDAR_DELETE_SUCCESS, payload: response })
    } else {
        yield put({ type: ActionTypes.YEARLY_HOLIDAY_CALENDAR_DELETE_ERROR, payload: response })
    }
}

export function* GetLeaveReason() {
    const response = yield call(GetLeaveReasonHelper)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.LEAVE_REASON_GET_SUCCESS, payload: response })
    }
}

export function* AddLeaveReason(payload) {
    const response = yield call(AddLeaveReasonHelper, payload.params)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.LEAVE_REASON_ADD_SUCCESS, payload: response })
    } else {
        yield put({ type: ActionTypes.LEAVE_REASON_ADD_ERROR, payload: response })
    }
}

export function* UpdateLeaveReason(payload) {
    const response = yield call(UpdateLeaveReasonHelper, payload.params)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.LEAVE_REASON_UPDATE_SUCCESS, payload: response })
    } else {
        yield put({ type: ActionTypes.LEAVE_REASON_UPDATE_ERROR, payload: response })
    }
}

export function* DeleteLeaveReason(payload) {
    const response = yield call(DeleteLeaveReasonHelper, payload.params)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.LEAVE_REASON_DELETE_SUCCESS, payload: response })
    } else {
        yield put({ type: ActionTypes.LEAVE_REASON_DELETE_ERROR, payload: response })
    }
}

export function* GetLeaveYearType(payload) {
    const response = yield call(GetLeaveYearTypeHelper, payload.params)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.LEAVE_YEAR_TYPE_GET_SUCCESS, payload: response })
    }
}


export function* UpdateLeaveYearType(payload) {
    const response = yield call(UpdateLeaveYearTypeHelper, payload.params)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.LEAVE_YEAR_TYPE_UPDATE_SUCCESS, payload: response })
    } else {
        yield put({ type: ActionTypes.LEAVE_YEAR_TYPE_UPDATE_ERROR, payload: response })
    }
}

export function* GetMyTimeConfigure() {
    const response = yield call(GetMyTimeConfigureHelper)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.MY_TIME_CONFIGURE_GET_SUCCESS, payload: response })
    }
}

export function* AddMyTimeConfigure(payload) {
    const response = yield call(AddMyTimeConfigureHelper, payload.params)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.MY_TIME_CONFIGURE_ADD_SUCCESS, payload: response })
    } else {
        yield put({ type: ActionTypes.MY_TIME_CONFIGURE_ADD_ERROR, payload: response })
    }
}

export function* UpdateMyTimeConfigure(payload) {
    const response = yield call(UpdateMyTimeConfigureHelper, payload.params)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.MY_TIME_CONFIGURE_UPDATE_SUCCESS, payload: response })
    } else {
        yield put({ type: ActionTypes.MY_TIME_CONFIGURE_UPDATE_ERROR, payload: response })
    }
}

export function* DeleteMyTimeConfigure(payload) {
    const response = yield call(DeleteMyTimeConfigureHelper, payload.params)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.MY_TIME_CONFIGURE_DELETE_SUCCESS, payload: response })
    } else {
        yield put({ type: ActionTypes.MY_TIME_CONFIGURE_DELETE_ERROR, payload: response })
    }
}


export function* GetCurrentTime(payload) {
    const response = yield call(GetCurrentTimeHelper, payload.params)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.CURRENT_TIME_GET_SUCCESS, payload: response })
    }
}

//MyTimeSheet
export function* AddMyTimeSheet(payload) {
    const response = yield call(AddMyTimeSheetHelper, payload.params)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.MY_TIME_SHEET_ADD_SUCCESS, payload: response })
    } else {
        yield put({ type: ActionTypes.MY_TIME_SHEET_ADD_ERROR, payload: response })
    }
}

export function* UpdateMyTimeSheet(payload) {
    const response = yield call(UpdateMyTimeSheetHelper, payload.params)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.MY_TIME_SHEET_UPDATE_SUCCESS, payload: response })
    } else {
        yield put({ type: ActionTypes.MY_TIME_SHEET_UPDATE_ERROR, payload: response })
    }
}


export function* GetMyTimeSheet(payload) {
    const response = yield call(GetMyTimeSheetHelper,payload.params)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.MY_TIME_SHEET_GET_SUCCESS, payload: response })
    }
}
export function* GetMyTimeSheetLeaveInfo(payload) {
    const response = yield call(GetMyTimeSheetLeaveInfoHelper,payload.params)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.MY_TIME_SHEET_LEAVE_INFO_GET_SUCCESS, payload: response })
    }
}
//MyTimeSheet Approval

export function* GetMyTimeSheetApproval(payload) {
    const response = yield call(GetMyTimeSheetApprovalHelper,payload.params)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.TIME_SHEET_APPROVAL_GET_SUCCESS, payload: response })
    }
}

export function* ApproveTimeSheet(payload) {
    const response = yield call(ApproveTimeSheetHelper, payload.params)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.APPROVE_TIME_SHEET_SUCCESS, payload: response })
    } else {
        yield put({ type: ActionTypes.APPROVE_TIME_SHEET_ERROR, payload: response })
    }
}


export function* ApproveTimeOff(payload) {
    const response = yield call(ApproveTimeOffHelper, payload.params)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.APPROVE_TIME_OFF_SUCCESS, payload: response })
    } else {
        yield put({ type: ActionTypes.APPROVE_TIME_OFF_ERROR, payload: response })
    }
}

export function* GetFaceDataSaga(payload) {
    const response = yield call(getFaceDataHelper, payload.params)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.GET_FACE_DATA_SUCCESS, payload: response })
    } else {
        yield put({ type: ActionTypes.GET_FACE_DATA_ERROR, payload: response })
    }
}

export function* GetWeekOffConfigure() {
    const response = yield call(GetWeekOffConfigureHelper)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.WEEK_OFF_CONFIGURE_GET_SUCCESS, payload: response })
    }
}

export function* AddWeekOffConfigure(payload) {
    const response = yield call(AddWeekOffConfigureHelper, payload.params)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.WEEK_OFF_CONFIGURE_ADD_SUCCESS, payload: response })
    } else {
        yield put({ type: ActionTypes.WEEK_OFF_CONFIGURE_ADD_ERROR, payload: response })
    }
}

export function* UpdateWeekOffConfigure(payload) {
    const response = yield call(UpdateWeekOffConfigureHelper, payload.params)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.WEEK_OFF_CONFIGURE_UPDATE_SUCCESS, payload: response })
    } else {
        yield put({ type: ActionTypes.WEEK_OFF_CONFIGURE_UPDATE_ERROR, payload: response })
    }
}

export function* DeleteWeekOffConfigure(payload) {
    const response = yield call(DeleteWeekOffConfigureHelper, payload.params)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.WEEK_OFF_CONFIGURE_DELETE_SUCCESS, payload: response })
    } else {
        yield put({ type: ActionTypes.WEEK_OFF_CONFIGURE_DELETE_ERROR, payload: response })
    }
}

export function* GetTeamBalanceSaga(payload) {
    const response = yield call(GetTeamBalanceHelper, payload.params)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.TEAM_BALANCE_GET_SUCCESS, payload: response })
    } else {
        yield put({ type: ActionTypes.TEAM_BALANCE_GET_ERROR, payload: response })
    }
}

export function* GetReportingEmployeeSaga(payload) {
    const response = yield call(ReportingEmployeeHelper, payload.params)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.REPORTING_EMPLOYEE_GET_SUCCESS, payload: response })
    } else {
        yield put({ type: ActionTypes.REPORTING_EMPLOYEE_GET_ERROR, payload: response })
    }
}

export function* GetWorkHours() {
    const response = yield call(GetWorkHoursHelper)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.WORK_HOURS_GET_SUCCESS, payload: response })
    }
}

export function* UpdateWorkHours(payload) {
    const response = yield call(UpdateWorkHoursHelper, payload.params)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.WORK_HOURS_UPDATE_SUCCESS, payload: response })
    } else {
        yield put({ type: ActionTypes.WORK_HOURS_UPDATE_ERROR, payload: response })
    }
}


export function* AddWorkHours(payload) {
    const response = yield call(AddWorkHoursHelper, payload.params)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.WORK_HOURS_ADD_SUCCESS, payload: response })
    } else {
        yield put({ type: ActionTypes.WORK_HOURS_ADD_ERROR, payload: response })
    }
}

export function* DeleteWorkHours(payload) {
    const response = yield call(DeleteWorkHoursHelper, payload.params)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.WORK_HOURS_DELETE_SUCCESS, payload: response })
    } else {
        yield put({ type: ActionTypes.WORK_HOURS_DELETE_ERROR, payload: response })
    }
}

export function* GetShiftTimeConfigure() {
    const response = yield call(GetShiftTimeConfigureHelper)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.SHIFT_TIME_CONFIGURE_GET_SUCCESS, payload: response })
    }
}

export function* AddShiftTimeConfigure(payload) {
    const response = yield call(AddShiftTimeConfigureHelper, payload.params)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.SHIFT_TIME_CONFIGURE_ADD_SUCCESS, payload: response })
    } else {
        yield put({ type: ActionTypes.SHIFT_TIME_CONFIGURE_ADD_ERROR, payload: response })
    }
}

export function* UpdateShiftTimeConfigure(payload) {
    const response = yield call(UpdateShiftTimeConfigureHelper, payload.params)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.SHIFT_TIME_CONFIGURE_UPDATE_SUCCESS, payload: response })
    } else {
        yield put({ type: ActionTypes.SHIFT_TIME_CONFIGURE_UPDATE_ERROR, payload: response })
    }
}

export function* DeleteShiftTimeConfigure(payload) {
    const response = yield call(DeleteShiftTimeConfigureHelper, payload.params)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.SHIFT_TIME_CONFIGURE_DELETE_SUCCESS, payload: response })
    } else {
        yield put({ type: ActionTypes.SHIFT_TIME_CONFIGURE_DELETE_ERROR, payload: response })
    }
}