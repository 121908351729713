import { useEffect, useState } from "react";
import MaterialTable from "material-table";
import {
  Box,
  Button,
  Grid,
  TextField,
  FormControl,
  Typography,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import { MeeplAdminAction } from "../../../../store/MeeplAdmin/AdminUser/Action";
import { useDispatch, useSelector } from "react-redux";
import ArchiveIcon from "@mui/icons-material/Archive";
import Popup from "../../Base/views/Popup";
import { AddExpirePlansHelper } from "../helper/AddExpirePlansHelper";
import { ConvertDateTime } from "../../Base/helper/baseFunctions";
import { UserStatusHelper } from "../helper/WorkspaceHelper";
import CommonDatePicker from "../../Base/views/CommonDatePicker";

const SignUpRequest = ({ countryCode, setworkspaceManage }) => {
  const theme = useTheme();
  const initialValue = { plan_day: "", access_grp_org: false, access_face_recg: false };
  const Value = { reason: "" };
  const [formData, setFormData] = useState(initialValue);
  const [reasonData, setReasonData] = useState(Value);
  const [tableData, setTableData] = useState({});
  const [data, setData] = useState({});
  const [errors, setErrors] = useState({});
  const [archive, setArchive] = useState(false);
  const dispatch = useDispatch();
  const [openPopup, setOpenPopup] = useState(false);
  const [expiryDays, setExpiryDays] = useState(0);
  const { AdminTrackStatusdata, Loading } = useSelector(
    (state) => state?.MeeplAdminReducer?.AdminUserReducer
  );
  useEffect(() => {
    dispatch(MeeplAdminAction({countryCode}));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleEnable = (rowData) => {
    setOpenPopup(true);
    setTableData(rowData);
  };
  const handleArchive = (rowData) => {
    setArchive(true);
    setData(rowData);
  };
  const handleConfirmArchive = () => {
    if (validate(reasonData)) {
      UserStatusHelper({
        update_id: data?.id,
        status: "false",
        reason: reasonData?.reason,
      })
        .then((res) => {
          setReasonData(Value);
          setArchive(false);
          dispatch(MeeplAdminAction({countryCode}));
        })
        .catch((err) => {
          setReasonData(Value);
        });
    }
  };
  const handleSubmit = () => {
    if (validate(formData)) {
      AddExpirePlansHelper({
        request_demo_id: parseInt(tableData?.id),
        planid: parseInt(tableData?.plan_id),
        plan_day: formData?.plan_day,
        full_name: tableData?.full_name,
        emailid: tableData?.emailid,
        expiry_days: expiryDays.toString(),
        access_grp_org: formData?.access_grp_org,
        access_face_recg: formData?.access_face_recg
      })
        .then((res) => {
            setOpenPopup(false);
            setFormData(initialValue);
            setExpiryDays(0);
            dispatch(MeeplAdminAction({countryCode}));
          })
        .catch((err) => {
          setFormData(initialValue);
          setExpiryDays(0);
        });
    }
  };
  const handleInputChange = (event, value) => {
    if (["access_grp_org","access_face_recg"].includes(event?.target?.name)) {
      setFormData({
        ...formData,
        [event.target.name]: event.target.checked,
      });
    } else {
      setFormData({
        ...formData,
        [event.target.name]: event.target.value,
      });
      setReasonData({
        ...reasonData,
        [event.target.name]: event.target.value,
      });
    }
  };
  const validate = (fieldValues) => {
    let temp = { ...errors };
    if ("plan_day" in fieldValues) {
      temp.plan_day =
        fieldValues.plan_day === "" ? "Expiry Date is required" : "";
    }
    if ("reason" in fieldValues) {
      temp.reason = fieldValues.reason === "" ? "Reason is required" : "";
    }
    setErrors({
      ...temp,
    });
    return Object.values(temp).every((x) => x === "");
  };
  const handleclose = () => {
    setOpenPopup(false);
    setFormData(initialValue);
    setExpiryDays(0);
    setErrors([]);
  };

  const handleArchiveclose = () => {
    setReasonData(Value);
    setArchive(false);
    setErrors([]);
  };

  return (
    <>
      <Box
        sx={{
          margin: 2,
          boxShadow:
            "0px 2px 4px -1px rgba(158, 158, 158, 0.2), 0px 4px 5px 0px rgba(158, 158, 158, 0.14), 0px 1px 10px 0px rgba(158, 158, 158, 0.12)",
        }}
      >
        <MaterialTable
          isLoading={Loading}
          columns={[
            {
              title: "Name",
              cellStyle: { padding: "10px 10px 10px 13px" },
              field: "full_name",
              grouping: false,
            },
            {
              title: "Mobile Number",
              cellStyle: { padding: "10px 10px 10px 13px" },
              field: "contactno",
              grouping: false,
            },
            {
              title: "Email",
              cellStyle: { padding: "10px 10px 10px 13px" },
              field: "emailid",
              grouping: false,
            },
            {
              title: "Company Name",
              cellStyle: { padding: "10px 10px 10px 13px" },
              field: "company_name",
              grouping: false,
            },
            {
              title: "Team Size",
              cellStyle: { padding: "10px 10px 10px 13px" },
              field: "teamsize",
              grouping: false,
            },
            {
              title: "Plan Type",
              cellStyle: { padding: "10px 10px 10px 13px" },
              field: "plan_type",
              grouping: false,
            },
            {
              title: "Requested Date",
              cellStyle: { padding: "10px 10px 10px 13px" },
              field: "createdon",
              grouping: false,
              render: (rowData) => {
                return rowData?.createdon
                  ? ConvertDateTime(rowData?.createdon)
                  : "    ";
              },
            },
          ]}
          data={AdminTrackStatusdata || []}
          options={{
            maxBodyHeight: "62vh",
            minBodyHeight: "62vh",
            rowStyle: { fontSize: "13px" },
            headerStyle: {
              backgroundColor:
                theme?.palette?.mode === "light"
                  ? "#fff"
                  : theme.palette.grey[500],
              color:
                theme?.palette?.mode === "light"
                  ? "#000"
                  : theme.palette.grey[800],
              fontWeight: "bold",
              padding: "10px 10px 10px 13px",
            },
            sorting: true,
            search: false,
            searchText: "",
            searchFieldAlignment: "right",
            searchAutoFocus: true,
            searchFieldVariant: "standard",
            filtering: false,
            paging: true,
            pageSizeOptions: [25, 50],
            pageSize: 25,
            paginationType: "stepped",
            showFirstLastPageButtons: false,
            paginationPosition: "bottom",
            exportButton: false,
            exportAllData: true,
            exportFileName: "Client",
            addRowPosition: "first",
            selection: false,
            actionsColumnIndex: -1,
            showSelectAllCheckbox: true,
            showTextRowsSelected: true,
            grouping: false,
            columnsButton: false,
            toolbar: false,
          }}
          actions={[
            {
              icon: () => <TaskAltIcon fontSize="small" color="success" />,
              tooltip: "Enable Workspace",
              onClick: (_event, rowData) => {
                handleEnable(rowData);
              },
            },
            {
              icon: () => <ArchiveIcon fontSize="small" color="error" />,
              tooltip: "Archive",
              onClick: (_event, rowData) => {
                handleArchive(rowData);
              },
            },
          ]}
          title=""
        />
      </Box>
      <Popup
        title={"Choose Plans"}
        openPopup={openPopup}
        setOpenPopup={handleclose}
      >
        <Box sx={{ minWidth: "300px" }}>
          <Grid container sx={{ flexGrow: 1 }}>
            <Grid item xs={12} md={12} p={1}>
              <FormControl fullWidth>
                <CommonDatePicker
                  size="small"
                  name="plan_day"
                  label="Expiry Date"
                  pickerType="date"
                  onKeyPress={(e) => e.preventDefault()}
                  onKeyDown={(e) => e.preventDefault()}
                  pickerFormat="dd/MM/yyyy"
                  DefaultDTvalue={formData?.plan_day}
                  required
                  minDate={new Date()}
                  handleChange={(value) => {
                    const selectedDate = value;
                    const currentDate = new Date();
                    const timeDifference = selectedDate - currentDate;
                    const daysDifference = Math.ceil(
                      timeDifference / (1000 * 60 * 60 * 24)
                    );
                    setExpiryDays(daysDifference);
                    handleInputChange({
                      target: {
                        name: "plan_day",
                        value: value
                          ? `${
                              value?.getFullYear() +
                              "-" +
                              ("0" + (value?.getMonth() + 1)).slice(-2) +
                              "-" +
                              ("0" + value?.getDate()).slice(-2)
                            }`
                          : "",
                      },
                    });
                  }}
                  {...(errors.plan_day && {
                    error: true,
                    helperText: errors.plan_day,
                  })}
                />
              </FormControl>
              <FormControl fullWidth sx={{ pt: 2 }}>
                <TextField
                  disabled
                  name="plan_type"
                  label="Plans"
                  value={tableData?.plan_type}
                  size="small"
                  fullWidth
                  variant="outlined"
                  type={"text"}
                />
              </FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    name="access_grp_org"
                    checked={formData?.access_grp_org || false}
                    onChange={handleInputChange}
                  />
                }
                label="Enable Group Organisation flow?"
              />
                 <FormControlLabel
                control={
                  <Checkbox
                    name="access_face_recg"
                    checked={formData?.access_face_recg || false}
                    onChange={handleInputChange}
                  />
                }
                label="Enable Face Recognition?"
              />
            </Grid>
          </Grid>
          <Grid
            container
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              position: "sticky",
              bottom: -20,
              zIndex: 1,
              backgroundColor: "#ffffff",
              p: 1,
            }}
          >
            <Typography
              variant="p"
              sx={{
                marginRight: 2,
                fontSize: "13px",
                color: theme?.palette?.primary?.main,
              }}
            >
              Expiry Days : {expiryDays} Days
            </Typography>
            <Button size="small" onClick={handleSubmit} variant="outlined">
              Submit
            </Button>
          </Grid>
        </Box>
      </Popup>
      <Popup
        fullWidth={true}
        maxWidth="xs"
        title="Request User"
        openPopup={archive}
        setOpenPopup={handleArchiveclose}
      >
        <Box>
          <TextField
            label={"Reason for Archive"}
            name="reason"
            id="reason"
            multiline
            rows={4}
            variant="outlined"
            value={reasonData?.reason}
            fullWidth
            onChange={handleInputChange}
            {...(errors?.reason && {
              error: true,
              helperText: errors?.reason,
            })}
          />
          <Grid display="flex" justifyContent="flex-end" container>
            <Button
              sx={{ marginTop: "1.75rem", marginRight: "1rem" }}
              variant="outlined"
              onClick={() => {
                handleArchiveclose();
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                handleConfirmArchive();
              }}
              variant="contained"
              color="primary"
              type="submit"
              className="mpl-primary-btn"
              sx={{ marginTop: "1.75rem" }}
            >
              Archive
            </Button>
          </Grid>
        </Box>
      </Popup>
    </>
  );
};

export default SignUpRequest;
