import { Collapse, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'
import WorkIcon from "@mui/icons-material/Work";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { grey } from '@mui/material/colors';

const FinanceMenu = ({ financeCollapse, setFinanceCollapse, payrollCollapse, setPayrollCollapse, open, financeOptions, selectedMenu, handleMenuClick, isMobile, theme, payrollOptions }) => {
    return (
        <>
            <ListItem disablePadding sx={{ display: 'block', fontSize: "12px" }}>
                <ListItemButton sx={{ px: 1.5, borderRadius: "8px", fontWeight: 600,"&.Mui-selected:hover": { backgroundColor: "rgb(254 255 187 / 48%)" }, ":hover": { backgroundColor: "rgb(254 255 187 / 48%)" } }} onClick={() => setFinanceCollapse()} disableRipple selected={financeCollapse}>
                    <ListItemIcon sx={{ minWidth: "35px" }}>
                        <span style={{ color: financeCollapse && `${theme?.palette?.primary?.main}` }}><WorkIcon fontSize='small' /></span>
                    </ListItemIcon>
                    <ListItemText primaryTypographyProps={{
                        variant: "body2",
                        fontWeight: financeCollapse ? 600 : 550,
                        fontSize: "0.8rem",
                        color: financeCollapse ? "primary" : grey[600]
                    }} primary={"Payroll"} sx={{ opacity: open ? 1 : 0 }} />

                    {open && financeCollapse ? <ExpandLess /> : open && <ExpandMore />}
                </ListItemButton>
            </ListItem>
            <Collapse in={financeCollapse} timeout="auto" unmountOnExit>
                <ListItem disablePadding sx={{ display: 'block', fontSize: "12px", pl: 0, pt: 1, pb: 1 }}>
                    
                    <List sx={{ padding: "0px" }}>
                        {
                            payrollOptions.map((res, i) => {
                                return (
                                    <Link key={i} to={res?.route} style={{ color: theme.palette.mode === "dark" ? "white" : "black" }}>
                                      {res?.show && 
                                        <ListItem
                                            disablePadding
                                            sx={{ display: 'block', fontSize: "12px", marginLeft: `${isMobile ? "-4px" : ''}`}}
                                            onClick={(e) => (handleMenuClick(res?.label))}

                                        >
                                            <ListItemButton sx={{ borderRadius: "8px", fontWeight: 600, minHeight: 10, justifyContent: open ? 'initial' : 'center', px: 1.5, }} selected={selectedMenu === res?.label}>
                                                <ListItemIcon sx={{ minWidth: 0, mr: open ? 2 : 'auto', justifyContent: 'center', }} >
                                                    <span style={{ color: selectedMenu === res?.label && `${theme?.palette?.primary?.main}` }}>{res?.icon}</span>
                                                </ListItemIcon>
                                                <ListItemText primaryTypographyProps={{
                                                    variant: "body2",
                                                    fontWeight: selectedMenu === res?.label ? 600 : 550,
                                                    fontSize: "0.8rem",
                                                    color: selectedMenu === res?.label ? "primary" : grey[600]
                                                }} primary={res?.label} sx={{ opacity: open ? 1 : 0 }} />
                                            </ListItemButton>
                                        </ListItem>
                                      }
                                    </Link>
                                )
                            })
                        }
                    </List>
                    </ListItem>
            </Collapse>
            {/* <Collapse in={financeCollapse} timeout="auto" unmountOnExit>
                <ListItem disablePadding sx={{ display: 'block', fontSize: "12px", pl: 2, pt: 1, pb: 1 }}>
                    <ListItemButton onClick={() => setPayrollCollapse(!payrollCollapse)} sx={{ borderRadius: "8px", fontWeight: 600 }} disableRipple selected={payrollCollapse}>
                        <ListItemIcon sx={{ minWidth: "35px" }}>
                            <span style={{ color: payrollCollapse && `${theme?.palette?.primary?.main}` }}><WorkIcon fontSize='small' /></span>

                        </ListItemIcon>

                        <ListItemText primaryTypographyProps={{
                            variant: "body2",
                            fontWeight: selectedMenu === "Payroll" ? 600 : 550,
                            fontSize: "0.8rem",
                            color: selectedMenu === "Payroll" ? "primary" : grey[600]
                        }} primary={"Payroll"} sx={{ opacity: open ? 1 : 0 }} />



                        {open && payrollCollapse ? <ExpandLess /> : open && <ExpandMore />}
                    </ListItemButton>
                </ListItem>
                <Collapse in={payrollCollapse} timeout="auto" unmountOnExit>
                    <List sx={{ padding: "0px" }}>
                        {
                            financeOptions.map((res, i) => {
                                return (
                                    <Link key={i} to={res?.route} style={{ color: theme.palette.mode === "dark" ? "white" : "black" }}>
                                        <ListItem
                                            disablePadding
                                            sx={{ display: 'block', fontSize: "12px", marginLeft: `${isMobile ? "-4px" : ''}`, pl: 4 }}
                                            onClick={(e) => (handleMenuClick(res?.label))}

                                        >
                                            <ListItemButton sx={{ borderRadius: "8px", fontWeight: 600, minHeight: 10, justifyContent: open ? 'initial' : 'center', px: 2.5, }} selected={selectedMenu === res?.label}>
                                                <ListItemIcon sx={{ minWidth: 0, mr: open ? 2 : 'auto', justifyContent: 'center', }} >
                                                    <span style={{ color: selectedMenu === res?.label && `${theme?.palette?.primary?.main}` }}>{res?.icon}</span>
                                                </ListItemIcon>
                                                <ListItemText primaryTypographyProps={{
                                                    variant: "body2",
                                                    fontWeight: selectedMenu === res?.label ? 600 : 550,
                                                    fontSize: "0.8rem",
                                                    color: selectedMenu === res?.label ? "primary" : grey[600]
                                                }} primary={res?.label} sx={{ opacity: open ? 1 : 0 }} />


                                            </ListItemButton>
                                        </ListItem>
                                    </Link>
                                )
                            })
                        }
                    </List>
                </Collapse>
            </Collapse> */}
        </>
    )
}

export default FinanceMenu
