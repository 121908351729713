
import { toast } from 'react-toastify';
import axiosInstance from '../../../../../axiosInstance';
import { getUserData } from '../../../Base/helper/baseFunctions';
import { HRMS_LMS_API_URL } from '../../../../Constants/baseConstant';
import { sendLeaveApprovalEmail } from '../../../Leave/helper/EmailHelper';

export const GetMyTimeSheetApprovalHelper = async (params) => {
    var data = getUserData();
    return await axiosInstance(`/lms/GetTimeSheetApproval`, {
        method: "POST",
        baseURL: HRMS_LMS_API_URL,
        data: {
            domain_name: data.domain,
            status: params?.status,
            emp_id: data?.employee_id,
        }
    })
        .then((res) => {
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}


export const ApproveTimeSheetHelper = async (params) => {
    var data = getUserData();
    return await axiosInstance(`/lms/ApproveTimeSheet`, {
        method: "POST",
        baseURL: HRMS_LMS_API_URL,
        data: {
            domain_name: data?.domain,
            user_id: data?.emp_users_pk,
            flag: params?.flag,
            week_no: params?.week_no,
            weekly_working_days: params?.weekly_working_days,
            timesheet_id: params?.timesheet_id,
            comments: params?.comments,
            employee_id: params?.employee_id
        }
    })
        .then((res) => {
            toast.dismiss();
            toast.success(`TimeSheet ${params?.flag} successfully`);
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}


export const ApproveTimeOffHelper = async (params) => {
    var data = getUserData();
    return await axiosInstance(`/lms/ApproveTimeOff`, {
        method: "POST",
        baseURL: HRMS_LMS_API_URL,
        data: {
            domain_name: data?.domain,
            user_id: data?.emp_users_pk,
            flag: params?.flag,
            comments: params?.comments,
            leaverequest_id: params?.leaverequest_id
        }
    })
        .then((res) => {
            toast.dismiss();
            toast.success(`Leave ${params?.flag} successfully`);
            sendLeaveApprovalEmail(params)
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}


export const GetBioMetricDataHelper = async (params) => {
    var data = getUserData();
    return await axiosInstance(`/lms/getBioMetricData`, {
        method: "POST",
        baseURL: HRMS_LMS_API_URL,
        data: {
            domain_name: data.domain,
            from_date: params?.from_date,
            to_date: params?.to_date,
            emp_id: params?.emp_id,
            reporting_manager_emp_id: data?.employee_id
        }
    })
        .then((res) => {
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}
export const GetTimeDataHelper = async (params) => {
    var data = getUserData();
    return await axiosInstance(`/lms/getTimeData`, {
        method: "POST",
        baseURL: HRMS_LMS_API_URL,
        data: {
            domain_name: data.domain,
            from_date: params?.from_date,
            to_date: params?.to_date,
            emp_id: params?.emp_id,
            reporting_manager_emp_id: data?.employee_id
        }
    })
        .then((res) => {
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}

// Team Assign Shift Time

export const GetAssignShiftTimeHelper = async (params) => {
    var data = getUserData();
    return await axiosInstance(`/lms/getAssignShiftTime`, {
        method: "POST",
        baseURL: HRMS_LMS_API_URL,
        data: {
            domain_name: data.domain,
            reporting_manager_id: data.employee_id,
            month_id: params?.month_id,
            week_id: params?.week_id,
            year: params?.year
        }
    })
        .then((res) => {
            toast.dismiss();
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}

export const AddAssignShiftTimeHelper = async (params) => {
    var data = getUserData();
    toast.info("Saving...")
    return await axiosInstance(`/lms/addAssignShiftTime`, {
        method: "POST",
        baseURL: HRMS_LMS_API_URL,
        data: {
            domain_name: data?.domain,
            user_id: data?.emp_users_pk,
            employee_id: params?.employee_id,
            week_off_custom_json: params?.week_off_custom_json,
            month_id: params?.month_id,
            week_id: params?.week_id,
            day_shift_mapping_json: params?.day_shift_mapping_json,
            year: params?.year,
            shift_configuration_id: params?.shift_configuration_id
        }
    })
        .then((res) => {
            toast.dismiss();
            toast.success("Shift assign successfully")
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            toast.error(err?.response?.data?.error?.[0]?.message)
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}

export const UpdateAssignShiftTimeHelper = async (params) => {
    var data = getUserData();
    toast.info("Saving...")
    return await axiosInstance(`/lms/updateAssignShiftTime`, {
        method: "PUT",
        baseURL: HRMS_LMS_API_URL,
        data: {
            domain_name: data?.domain,
            user_id: data?.emp_users_pk,
            shift_roster_id: params?.shift_roster_id,
            employee_id: params?.employee_id,
            week_off_custom_json: params?.week_off_custom_json,
            month_id: params?.month_id,
            week_id: params?.week_id,
            day_shift_mapping_json: params?.day_shift_mapping_json,
            year: params?.year,
            shift_configuration_id: params?.shift_configuration_id
        }
    })
        .then((res) => {
            toast.dismiss();
            toast.success("Shift assign updated successfully")
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            toast.error(err?.response?.data?.error?.[0]?.message)
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}

export const DeleteAssignShiftTimeHelper = async (params) => {
    var data = getUserData();
    toast.info("Saving...")
    return await axiosInstance(`/lms/deleteAssignShiftTime`, {
        method: "DELETE",
        baseURL: HRMS_LMS_API_URL,
        data: {
            domain_name: data?.domain,
            shift_roster_id: params?.shift_roster_id,
            month_id: params?.month_id,
            week_id: params?.week_id,
            year: params?.year,
        }
    })
        .then((res) => {
            toast.dismiss();
            toast.success("Shift assign deleted successfully")
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            toast.error(err?.response?.data?.error?.[0]?.message)
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}

export const GetWeekHelper = async (params) => {
    var data = getUserData();
    return await axiosInstance(`/lms/getWeek`, {
        method: "POST",
        baseURL: HRMS_LMS_API_URL,
        data: {
            domain_name: data.domain,
            month_id: params?.month_id,
            year: params?.year
        }
    })
        .then((res) => {
            toast.dismiss();
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}