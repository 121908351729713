import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel'
import { Box, Paper, Tab } from '@mui/material';
import React from 'react'
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from "react-router-dom";
import Base from '../../../Base/views/Base';
import TeamApproveLandingPage from './TeamApproveLandingPage';
import { GetLeaveApprovalAction } from '../../../../../store/Leave/ManageApproval/Action';
import TeamTimeOff from './TimeOff/TeamTimeoff';
import TeamTimeSheet from './TimeSheet/TeamTimeSheet';
import { getUserData } from '../../../Base/helper/baseFunctions';
import { GetEmployeeDashboardAction } from '../../../../../store/EmployeeDashboard/Action';
// import TeamFaceGrid from './TeamFaceGrid';
import TeamBalanceGrid from './TeamBalanceGrid';
import BioMetricGrid from './BioMetricGrid';
import AssignShift from './AssignShift';

const TeamLandingPage = () => {
    const location = useLocation();
    const defaultValue = location.state?.defaultValue || "";
    const dispatch = useDispatch();
    const empData = getUserData();
    const [value, setValue] = useState('1');
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };


    useEffect(() => {
        dispatch(GetEmployeeDashboardAction({ domain: empData?.domain, emp_id: empData?.employee_id }));
        dispatch(GetLeaveApprovalAction())
        //eslint-disable-next-line
    }, [])
    return (
        <Base>
            <Box sx={{ maxHeight: "87vh", overflowY: 'auto' }}>
                <Paper sx={{ borderRadius: "10px", minHeight: "87vh" }}>
                    <Box sx={{ width: '100%' }}>
                        <TabContext value={value}>
                            <Box>
                                <TabList 
                                sx={{
                                    minHeight: "40px", ".MuiTab-root": { minHeight: "20px" },
                                    '& .MuiTabs-indicator': {
                                        width: '100%',
                                        display: 'flex',
                                        justifyContent: "center !important",
                                    },
                                    }} 
                                    onChange={handleChange} 
                                    aria-label="lab API tabs example"
                                    variant="scrollable"
                                    orientation="horizontal"
                                    allowScrollButtonsMobile={true}
                                    >
                                    <Tab label="Team Approvals" value="1" />
                                    <Tab label="Team Time Sheets" value="2" />
                                    <Tab label="Team Time Off" value="3" />
                                    {/* {empData?.facerecognition_flow && <Tab label="Face Attendance" value="4" />} */}
                                    <Tab label="Team Time" value="5" />
                                    <Tab label="Team Balance" value="6" />
                                    <Tab label="Assign Shift" value="7" />
                                </TabList>
                            </Box>
                            <TabPanel value="1" sx={{ padding: "0px", minHeight: "45px" }}>
                                <TeamApproveLandingPage setValue={setValue} defaultValue={defaultValue} />
                            </TabPanel>
                            <TabPanel value="2" sx={{ padding: "0px" }}>
                                <TeamTimeSheet />
                            </TabPanel>
                            <TabPanel value="3" sx={{ padding: "0px" }}>
                                <TeamTimeOff setValueParent={setValue} />
                            </TabPanel>
                            {/* <TabPanel value="4" sx={{ padding: "0px" }}>
                                <TeamFaceGrid />
                            </TabPanel> */}
                            <TabPanel value="5" sx={{ padding: "0px" }}>
                                <BioMetricGrid />
                            </TabPanel>
                            <TabPanel value="6" sx={{ padding: "0px" }}>
                                <TeamBalanceGrid />
                            </TabPanel>
                            <TabPanel value="7" sx={{ padding: "0px" }}>
                                <AssignShift />
                            </TabPanel>
                        </TabContext>
                    </Box>
                </Paper>
            </Box>
        </Base>
    )
}

export default TeamLandingPage;