import { toast } from "react-toastify";
import { getUserData } from "../../../Base/helper/baseFunctions";
import axiosInstance from "../../../../../axiosInstance";
import { HRMS_FINANCE_API_URL } from "../../../../Constants/baseConstant";

export const AddEmployeeTemplateHelper = async (params) => {
    const data = getUserData();
    toast.dismiss()
    toast.info("Saving...")
    return await axiosInstance(`/finance/addEmployeeTemplate`, {
        method: "POST",
        baseURL: HRMS_FINANCE_API_URL,
        data: {
            domain_name: data?.domain,
            user_id: data?.employee_id,
            earnings: params?.earnings,
            deductions: params?.deductions,
            employee_id: params?.employee_id,
            overtime_applicable_flag: params?.overtime_applicable_flag ? params?.overtime_applicable_flag : false,
        }
    })
        .then((res) => {
            toast.dismiss()
            toast.success("Template Added Successfully")
            return { message: "Template Added Successfully", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss()
            toast.error(err?.response?.data?.error?.[0]?.message)
            return { message: "Unable to add Template", hasError: false, data: err?.response?.data }
        })
}
export const UpdateEmployeeTemplateHelper = async (params) => {
    const data = getUserData();
    toast.dismiss()
    toast.info("Saving...")
    return await axiosInstance(`/finance/updateEmployeeTemplate`, {
        method: "PUT",
        baseURL: HRMS_FINANCE_API_URL,
        data: {
            domain_name: data?.domain,
            user_id: data?.employee_id,
            employee_id: params?.employee_id,
            earnings: params?.earnings,
            deductions: params?.deductions,
            overtime_applicable_flag: params?.overtime_applicable_flag ? params?.overtime_applicable_flag : false,

        }
    })
        .then((res) => {
            toast.dismiss()
            toast.success("Template Updated Successfully")
            return { message: "Template Updated Successfully", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss()
            toast.error(err?.response?.data?.error?.[0]?.message)
            return { message: "Unable to update Template", hasError: false, data: err?.response?.data }
        })
}
export const GetEmployeeTemplateHelper = async (params) => {
    const data = getUserData();
    return await axiosInstance(`/finance/getEmployeeTemplate`, {
        method: "POST",
        baseURL: HRMS_FINANCE_API_URL,
        data: {
            domain_name: data?.domain,
            employee_id: params?.employee_id
        }
    })
        .then((res) => {
            return { message: "Template retrived successfully", hasError: false, data: res?.data }
        }).catch(err => {
            return { message: "Unable to retrived Template", hasError: false, data: err?.response?.data }
        })
}
export const CalculateEmployeeSalaryTemplateHelper = async (params) => {
    const data = getUserData();
    return await axiosInstance(`/finance/CalculateEmployeeSalaryTemplate`, {
        method: "POST",
        baseURL: HRMS_FINANCE_API_URL,
        data: {
            domain_name: data?.domain,
            ctc: +params?.ctc,
            payroll_worklocation_id: params?.payroll_worklocation_id?.state_id || params?.payroll_worklocation_id?.payroll_worklocation_id,
            basic: +params?.basic,
            hra: +params?.hra,
            conveyance_allowance: params?.conveyance_allowance_flag ? +params?.conveyance_allowance : 0,
            medical_allowance: params?.medical_allowance_flag ? +params?.medical_allowance : 0,
            educational_allowance: params?.educational_allowance_flag ? +params?.educational_allowance : 0,
            lta: params?.lta_flag ? +params?.lta: 0,
            esic: params?.esic ? params?.esic : false,
            pt: params?.pt ? params?.pt : false,
            country: "india"
        }
    })
        .then((res) => {
            return { message: "success", hasError: false, data: res?.data }
        }).catch(err => {
            return { message: "Unable", hasError: false, data: err?.response?.data }
        })
}
export const AddCalculateEmployeeSalaryTemplateHelper = async (params) => {
    const data = getUserData();
    return await axiosInstance(`/finance/AddCalculateEmployeeSalaryTemplate`, {
        method: "POST",
        baseURL: HRMS_FINANCE_API_URL,
        data: {
            domain_name: data?.domain,
            payroll_worklocation_id: params?.payroll_worklocation_id,
            ctc: params?.ctc,
            basic_percentage: params?.basic_percentage,
            hra_percentage: params?.hra_percentage,
            conveyance_allowance: params?.conveyance_allowance,
            medical_allowance: params?.medical_allowance,
            lta_percentage: params?.lta,
            basic: params?.basic,
            epf_earning: params?.epf_earning,
            esi_earning: params?.esi_earning,
            hra: params?.hra,
            lta: params?.lta,
            educational_allowance: params?.educational_allowance,
            special_allowance: params?.special_allowance,
            epf_deduction: params?.epf_deduction,
            pt_deduction: params?.pt_deduction,
            esi_deduction: params?.esi_deduction,
            user_id: data?.employee_id,
            employee_id: params?.employee_id,
            conveyance_allowance_flag: params?.conveyance_allowance_flag ? params?.conveyance_allowance_flag : false,
            medical_allowance_flag: params?.medical_allowance_flag ? params?.medical_allowance_flag : false,
            educational_allowance_flag: params?.educational_allowance_flag ? params?.educational_allowance_flag : false,
            lta_flag: params?.lta_flag ? params?.lta_flag : false,
            esic_flag: params?.esic_flag ? params?.esic_flag : false,
            pt_flag: params?.pt_flag ? params?.pt_flag : false,
            overtime_applicable_flag: params?.overtime_applicable_flag ? params?.overtime_applicable_flag : false,
        }
    })
        .then((res) => {
            return { message: "success", hasError: false, data: res?.data }
        }).catch(err => {
            return { message: "Unable", hasError: false, data: err?.response?.data }
        })
}
export const CalculateEmployeeAdvanceSalaryTemplateHelper = async (params) => {
    const data = getUserData();
    return await axiosInstance(`/finance/CalculateEmployeeAdvanceSalaryTemplate`, {
        method: "POST",
        baseURL: HRMS_FINANCE_API_URL,
        data: {
            domain_name: data?.domain,
            ctc: +params?.ctc,
            payroll_worklocation_id: params?.payroll_worklocation_id?.state_id || params?.payroll_worklocation_id?.payroll_worklocation_id,
            basic: +params?.basic,
            hra: +params?.hra,
            conveyance_allowance: params?.conveyance_allowance_flag ? +params?.conveyance_allowance : 0,
            medical_allowance: params?.medical_allowance_flag ? +params?.medical_allowance : 0,
            educational_allowance: params?.educational_allowance_flag ? +params?.educational_allowance : 0,
            lta: params?.lta_flag ? +params?.lta: 0,
            esic: params?.esic ? params?.esic : false,
            pt: params?.pt ? params?.pt : false,
            country: "india",
            variable_percentage: +params?.variable_percentage,
            pf: params?.pf ? params?.pf : false,
            pf_limit_applicable: params?.pf ? params?.pf_limit_applicable : false,
            pf_limit_amount: (params?.pf && params?.pf_limit_applicable) ? params?.pf_limit_amount : null,
            earning_component_json: params?.earning_component_json
        }
    })
        .then((res) => {
            return { message: "success", hasError: false, data: res?.data }
        }).catch(err => {
            return { message: "Unable", hasError: false, data: err?.response?.data }
        })
}
export const AddCalculateEmployeeAdvanceSalaryTemplateHelper = async (params) => {
    const data = getUserData();
    return await axiosInstance(`/finance/AddCalculateEmployeeAdvanceSalaryTemplate`, {
        method: "POST",
        baseURL: HRMS_FINANCE_API_URL,
        data: {
            domain_name: data?.domain,
            payroll_worklocation_id: params?.payroll_worklocation_id,
            ctc: params?.ctc,
            basic_percentage: params?.basic_percentage,
            hra_percentage: params?.hra_percentage,
            conveyance_allowance: params?.conveyance_allowance,
            medical_allowance: params?.medical_allowance,
            lta_percentage: params?.lta,
            basic: params?.basic,
            epf_earning: params?.epf_earning,
            esi_earning: params?.esi_earning,
            hra: params?.hra,
            lta: params?.lta,
            educational_allowance: params?.educational_allowance,
            special_allowance: params?.special_allowance,
            epf_deduction: params?.epf_deduction,
            pt_deduction: params?.pt_deduction,
            esi_deduction: params?.esi_deduction,
            user_id: data?.employee_id,
            employee_id: params?.employee_id,
            conveyance_allowance_flag: params?.conveyance_allowance_flag ? params?.conveyance_allowance_flag : false,
            medical_allowance_flag: params?.medical_allowance_flag ? params?.medical_allowance_flag : false,
            educational_allowance_flag: params?.educational_allowance_flag ? params?.educational_allowance_flag : false,
            lta_flag: params?.lta_flag ? params?.lta_flag : false,
            esic_flag: params?.esic_flag ? params?.esic_flag : false,
            pt_flag: params?.pt_flag ? params?.pt_flag : false,
            overtime_applicable_flag: params?.overtime_applicable_flag ? params?.overtime_applicable_flag : false,
            pf_flag: params?.pf_flag ? params?.pf_flag : false,
            pf_limit_applicable_flag: params?.pf_limit_applicable_flag ? params?.pf_limit_applicable_flag : false,
            pf_limit_amount: params?.pf_flag ? params?.pf_limit_amount : null,
            variable_percentage: params?.variable_percentage,
            variable_pay: params?.variable_pay,
            earning_component_json: params?.earning_component_json
        }
    })
        .then((res) => {
            return { message: "success", hasError: false, data: res?.data }
        }).catch(err => {
            return { message: "Unable", hasError: false, data: err?.response?.data }
        })
}
export const GetCalculateEmployeeSalaryTemplateHelper = async (params) => {
    const data = getUserData();
    return await axiosInstance(`/finance/GetCalculateEmployeeSalaryTemplate`, {
        method: "POST",
        baseURL: HRMS_FINANCE_API_URL,
        data: {
            domain_name: data?.domain,
            employee_id: params?.employee_id
        }
    })
        .then((res) => {
            return { message: "Template retrived successfully", hasError: false, data: res?.data }
        }).catch(err => {
            return { message: "Unable to retrived Template", hasError: false, data: err?.response?.data }
        })
}
export const GetPayrollWorkLocationHelper = async (params) => {
    const data = getUserData();
    return await axiosInstance(`/finance/GetPayrollWorkLocation`, {
        method: "POST",
        baseURL: HRMS_FINANCE_API_URL,
        data: {
            country: "india"
        }
    })
        .then((res) => {
            return { message: "Template retrived successfully", hasError: false, data: res?.data }
        }).catch(err => {
            return { message: "Unable to retrived Template", hasError: false, data: err?.response?.data }
        })
}
export const BulkUploadCalculateHelper = async (params) => {
    const data = getUserData();
    return await axiosInstance(`/finance/BulkUploadCalculate`, {
        method: "POST",
        baseURL: HRMS_FINANCE_API_URL,
        data: {
            domain_name: data?.domain,
            user_id: data?.employee_id,
        }
    })
        .then((res) => {
            return { message: "successfully", hasError: false, data: res?.data }
        }).catch(err => {
            return { message: "Unable to retrived", hasError: false, data: err?.response?.data }
        })
}
export const BulkUploadCalculateAdvanceHelper = async (params) => {
    const data = getUserData();
    return await axiosInstance(`/finance/BulkUploadAdvanceCalculate`, {
        method: "POST",
        baseURL: HRMS_FINANCE_API_URL,
        data: {
            domain_name: data?.domain,
            user_id: data?.employee_id,
        }
    })
        .then((res) => {
            return { message: "successfully", hasError: false, data: res?.data }
        }).catch(err => {
            return { message: "Unable to retrived", hasError: false, data: err?.response?.data }
        })
}