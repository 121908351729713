import { TextField } from "@mui/material";
import React from "react";
import { TimePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

const CommonTimePicker = ({
  name,
  size,
  label,
  pickerFormat = "hh:mm aa",
  DefaultDTvalue,
  handleChange,
  error,
  helperText,
  variant = "outlined",
  minTime,
  maxTime,
  disabled,
  required,
  onKeyPress,
  onKeyDown,
}) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <TimePicker
        name={name}
        label={label}
        ampm
        inputFormat={pickerFormat}
        value={DefaultDTvalue}
        onChange={handleChange}
        disabled={disabled}
        minTime={minTime}
        maxTime={maxTime}
        renderInput={(params) => (
          <TextField
            {...params}
            variant={variant}
            onKeyDown={onKeyDown}
            onKeyPress={onKeyPress}
            disabled={disabled}
            size={size}
            error={error}
            required={required}
            helperText={helperText}
          />
        )}
      />
    </LocalizationProvider>
  );
};

export default CommonTimePicker;
