import { Box, Button, IconButton } from '@mui/material';
import MaterialTable from 'material-table';
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import Popup from "../../../Base/views/Popup"
import { useState } from 'react';
import ConfirmationDialog from '../../../Base/views/ConfirmationDialog';
import "../../css/styles.css"
import { DeleteShiftTimeConfigureAction } from '../../../../../store/Leave/ShiftConfigure/Action';
import ShitTimeConfigurePopup from './ShiftTimeConfigurePopUp';
import { ConvertTimeOnly } from '../../../Base/helper/baseFunctions';

const ShitTimeConfigure = () => {
    const dispatch = useDispatch();
    const { shift_time_options } = useSelector(state => state?.LeaveReducer?.ShiftTimeConfigureReducer);
    const [editedRecord, setEditedRecord] = useState(null);
    const [openPopup, setOpenPopup] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [currentData, setCurrentData] = useState({});
    const handleEdit = (rowData) => {
        setEditedRecord(rowData);
        setOpenPopup(true);
    };
    const handleConfirmDelete = (rowData) => {
        setOpenDialog(true);
        setCurrentData(rowData)
    };
    const handleAdd = () => {
        setEditedRecord()
        setOpenPopup(true);
    };
    const handleDelete = () => {
        setOpenDialog(false);
        dispatch(DeleteShiftTimeConfigureAction({ shift_roster_configuration_id: currentData?.shift_roster_configuration_id }))
    };
    const addMode = (resetForm) => {
        resetForm();
        setEditedRecord(null);
        setOpenPopup(false);
    };

    const addButton = <Button onClick={handleAdd} variant="contained" className="mpl-primary-btn" startIcon={<AddIcon />} >
        Add
    </Button>

    return (
        <Box>
            <MaterialTable
                columns={[
                    { title: "Shift Name", field: "shift_name", cellStyle: { padding: "0px 10px 0px 10px" } },
                    { title: "Time In", field: "in_time", cellStyle: { padding: "0px 10px 0px 10px" }, render: rowData => { return rowData?.in_time ? ConvertTimeOnly(rowData?.in_time) : "    " } },
                    { title: "Time Out", field: "out_time", cellStyle: { padding: "0px 10px 0px 10px" }, render: rowData => { return rowData?.out_time ? ConvertTimeOnly(rowData?.out_time) : "    " } },
                    { title: "Pay", field: "pay", cellStyle: { padding: "0px 10px 0px 10px" } },

                ]}
                data={shift_time_options || []}
                options={{
                    maxBodyHeight: 385,
                    rowStyle: { fontSize: "13px" },
                    headerStyle: { padding: "0px 10px 0px 10px", fontWeight: "bold" },
                    sorting: true,
                    search: true,
                    searchText: "",
                    searchFieldAlignment: "right",
                    searchAutoFocus: true,
                    searchFieldVariant: "standard",
                    filtering: false,
                    paging: true,
                    pageSizeOptions: [25, 50],
                    pageSize: 25,
                    paginationType: "stepped",
                    showFirstLastPageButtons: false,
                    paginationPosition: "bottom",
                    exportButton: false,
                    exportAllData: true,
                    addRowPosition: "first",
                    selection: false,
                    actionsColumnIndex: -1,
                    showSelectAllCheckbox: true,
                    showTextRowsSelected: true,
                    grouping: false,
                    columnsButton: false,
                }}
                onSelectionChange={(rows) => { return (rows.filter(row => row?.status === "Submitted")) }}
                actions={[
                    {
                        icon: () => <IconButton sx={{ padding: "0px" }}><EditIcon /></IconButton>,
                        tooltip: "Edit",
                        onClick: (_event, rowData) => { handleEdit(rowData) },
                    },
                    {
                        icon: () => <IconButton sx={{ padding: "0px" }}><DeleteIcon /></IconButton>,
                        tooltip: "Delete",
                        onClick: (_event, rowData) => { handleConfirmDelete(rowData) },
                    },
                ]}
                title={addButton}
            />
            <Popup
                title={editedRecord ? "Edit Shift Time" : "Add Shift Time"}
                openPopup={openPopup}
                setOpenPopup={setOpenPopup}
            >
                <ShitTimeConfigurePopup editedRecord={editedRecord} addMode={addMode} setOpenPopup={setOpenPopup} />
            </Popup>
            <ConfirmationDialog
                title="Do you wish to proceed?"
                openDialog={openDialog}
                closeDialog={setOpenDialog}
                popupTitle="Country Management"
                OkButtonText="Delete"
                onSubmit={handleDelete}
            />
        </Box>
    );
}

export default ShitTimeConfigure;
