import React from "react";
import Container from "@mui/material/Container";
import MenuLandingPage from "../../Menu/views/MenuLandingPage";
import ThemeProvider from "../../theme";

export default function Base({ children }) {

  return (
    <ThemeProvider>
      <MenuLandingPage>
        <Container maxWidth="xxl" style={{ padding: "0px 5px 0px 5px", marginTop: "4rem", }}>
          {children}
        </Container>
      </MenuLandingPage>
    </ThemeProvider>
  );
}
