import { put, call } from "redux-saga/effects";
import { AddMeeplAdminUsersHelper, GetMeeplAdminHelper, GetMeeplAdminUsersHelper, UpdateMeeplAdminUsersHelper } from "../../app/Components/MeeplAdmin/helper/AdminUserHelper";
import { DisableWorkspaceHelper, EnableWorkspaceHelper, GetCreatedSchemastHelper, UserStatusHelper,getWorkspaceExpiryRequestHelper, requestAdminHelper, updateWorkspaceExpiryRequestHelper, updatePlanHelper, AddRequestHelper, GetRequestHelper } from "../../app/Components/MeeplAdmin/helper/WorkspaceHelper";
import * as ActionTypes from "./ActionTypes";

export function* MeeplAdminSaga(payload) {
  const response = yield call(GetMeeplAdminHelper, payload?.params);
  if (response?.data?.statuscode === 200) {
    yield put({
      type: ActionTypes.MEEPL_ADMIN_GET_SUCCESS,
      payload: response,
    });
  } else {
    yield put({
      type: ActionTypes.MEEPL_ADMIN_GET_ERROR,
      payload: response?.message?.error?.[0]?.message,
    });
  }
}

export function* GetCreatedSchemasSaga(payload) {
  const response = yield call(GetCreatedSchemastHelper, payload?.params);
  if (response?.data?.statuscode === 200) {
    yield put({
      type: ActionTypes.CREATED_SCHEMAS_GET_SUCCESS,
      payload: response,
    });
  } else {
    yield put({
      type: ActionTypes.CREATED_SCHEMAS_GET_ERROR,
      payload: response?.message?.error?.[0]?.message,
    });
  }
}

export function* EnableWorkspaceSaga(payload) {
  const response = yield call(EnableWorkspaceHelper, payload?.params);
  if (response?.data?.statuscode === 200) {
    yield put({
      type: ActionTypes.ENABLE_WORKSPACE_SUCCESS,
      payload: response,
    });
  } else {
    yield put({
      type: ActionTypes.ENABLE_WORKSPACE_ERROR,
      payload: response?.message?.error?.[0]?.message,
    });
  }
}

export function* DisableWorkspaceSaga(payload) {
  const response = yield call(DisableWorkspaceHelper, payload?.params);
  if (response?.data?.statuscode === 200) {
    yield put({
      type: ActionTypes.DISABLE_WORKSPACE_SUCCESS,
      payload: response,
    });
  } else {
    yield put({
      type: ActionTypes.DISABLE_WORKSPACE_ERROR,
      payload: response?.message?.error?.[0]?.message,
    });
  }
}

export function* UserArchiveSaga(payload) {
  const response = yield call(UserStatusHelper, payload?.params);
  if (response?.data?.statuscode === 200) {
    yield put({
      type: ActionTypes.USER_ARCHIVE_GET_SUCCESS,
      payload: response,
    });
  } else {
    yield put({
      type: ActionTypes.USER_ARCHIVE_GET_ERROR,
      payload: response?.message?.error?.[0]?.message,
    });
  }
}


export function* GetMeeplAdminUserSaga(payload) {
  const response = yield call(GetMeeplAdminUsersHelper, payload?.params);
  if (response?.data?.statuscode === 200) {
    yield put({ type: ActionTypes.MEEPL_ADMIN_GET_USER_SUCCESS, payload: response, });
  } else {
    yield put({ type: ActionTypes.MEEPL_ADMIN_GET_USER_ERROR, payload: response?.message?.error?.[0]?.message });
  }
}

export function* AddMeeplAdminUserSaga(payload) {
  const response = yield call(AddMeeplAdminUsersHelper, payload?.params);
  if (response?.data?.statuscode === 200) {
    yield put({ type: ActionTypes.MEEPL_ADMIN_ADD_USER_SUCCESS, payload: response, });
  } else {
    yield put({ type: ActionTypes.MEEPL_ADMIN_ADD_USER_ERROR, payload: response?.message?.error?.[0]?.message });
  }
}

export function* UpdateMeeplAdminUserSaga(payload) {
  const response = yield call(UpdateMeeplAdminUsersHelper, payload?.params);
  if (response?.data?.statuscode === 200) {
    yield put({ type: ActionTypes.MEEPL_ADMIN_UPDATE_USER_SUCCESS, payload: response, });
  } else {
    yield put({ type: ActionTypes.MEEPL_ADMIN_UPDATE_USER_ERROR, payload: response?.message?.error?.[0]?.message });
  }
}


export function* getWorkspaceExpiryRequestSaga(payload) {
  const response = yield call(getWorkspaceExpiryRequestHelper, payload?.params);
  if (response?.data?.statuscode === 200) {
    yield put({
      type: ActionTypes.GET_WORKSPACE_EXPIRY_SUCCESS,
      payload: response,
    });
  } else {
    yield put({
      type: ActionTypes.GET_WORKSPACE_EXPIRY_ERROR,
      payload: response?.message?.error?.[0]?.message,
    });
  }
}


export function* requestAdminSaga(payload) {
  const response = yield call(requestAdminHelper, payload?.params);
  if (response?.data?.statuscode === 200) {
    yield put({
      type: ActionTypes.ADMIN_REQUEST,
      payload: response,
    });
  } else {
    yield put({
      type: ActionTypes.ADMIN_ERROR,
      payload: response?.message?.error?.[0]?.message,
    });
  }
}

export function* updateWorkspaceExpiryRequestSaga(payload) {
  const response = yield call(updateWorkspaceExpiryRequestHelper, payload?.params);
  if (response?.data?.statuscode === 200) {
    yield put({
      type: ActionTypes.UPDATE_WORKSPACE_EXPIRE_SUCCESS,
      payload: response,
    });
  } else {
    yield put({
      type: ActionTypes.UPDATE_WORKSPACE_EXPIRE_ERROR,
      payload: response?.message?.error?.[0]?.message,
    });
  }
}

export function* updatePlanRequestSaga(payload) {
  const response = yield call(updatePlanHelper, payload?.params);
  if (response?.data?.statuscode === 200) {
    yield put({
      type: ActionTypes.UPDATE_PLAN_SUCCESS,
      payload: response,
    });
  } else {
    yield put({
      type: ActionTypes.UPDATE_PLAN_ERROR,
      payload: response?.message?.error?.[0]?.message,
    });
  }
}

export function* AddRequestSaga(payload) {
  const response = yield call(AddRequestHelper, payload?.params);
  if (response?.data?.statuscode === 200) {
    yield put({
      type: ActionTypes.ADD_SUCCESS,
      payload: response,
    });
  } else {
    yield put({
      type: ActionTypes.ADD_ERROR,
      payload: response?.message?.error?.[0]?.message,
    });
  }
}

export function* GetRequestSaga(payload) {
  const response = yield call(GetRequestHelper, payload?.params);
  if (response?.data?.statuscode === 200) {
    yield put({
      type: ActionTypes.GET_SUCCESS,
      payload: response,
    });
  } else {
    yield put({
      type: ActionTypes.GET_ERROR,
      payload: response?.message?.error?.[0]?.message,
    });
  }
}