import { toast } from "react-toastify";
import axiosInstance from "../../../../axiosInstance";
import { HRMS_ATS_API_URL } from "../../../Constants/baseConstant";
import { getUserData } from "../../Base/helper/baseFunctions";
import NProgress from 'nprogress';

export const GetAtsExternalCandidateHelper = async (params) => {
    var user = getUserData();
    NProgress.start()
    return await axiosInstance(`/ats/getExternalCandidate`, {
        method: "POST",
        baseURL: HRMS_ATS_API_URL,
        data: {
            workspace: user.domain,
            user_emp_id: +user?.ats_user_id,
            primary_recruiter: params?.primary_recruiter || null
        }
    })
        .then((res) => {
            NProgress.done()
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            NProgress.done()
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
};

export const AddAtsExternalCandidateHelper = async (params) => {
    var user = getUserData();
    toast.info("Saving...")
    return await axiosInstance(`/ats/addExternalCandidate`, {
        method: "POST",
        baseURL: HRMS_ATS_API_URL,
        data: {
            workspace: params?.domain ? params?.domain : user?.domain,
            user_emp_id: +user?.ats_user_id,
            first_name: params?.first_name,
            last_name: params?.last_name,
            contact_no: params?.contact_no || null,
            email_id: params?.email_id,
            dob: params?.dob || null,
            designation: params?.designation|| null,
            years_of_experience: params?.years_of_experience || null,
            current_ctc: params?.current_ctc || null,
            expected_pay: params?.expected_pay || null,
            current_employer: params?.current_employer,
            notice_period: params?.notice_period || null,
            gender: params?.gender,
            primary_skill: params?.primary_skill,
            secondary_skill: params?.secondary_skill,
            status: "Active",
            resume: params?.resume || "",
            resume_name: params?.resume_name,
            identity_id: params?.identity_id?.identity_id || null,
            proof_number: params?.proof_number,
            is_external: params?.is_external || false,
            mode: params?.mode,
            is_verified: params?.is_verified || false,
            ats_jobpost_id: params?.ats_jobpost_id || null,
            location: params?.location,
            uan_number: params?.uan_number || null,
            alternative_mobile: params?.alternative_mobile || null,
            github_profile: params?.github_profile || null,
            linkedin_profile: params?.linkedin_profile || null,
            certificate_details: params?.certificate_details || null,
            content_type: params?.content_type || "",
            consultancy_fk: params?.consultancy_fk,
            ats_company_fk: params?.ats_company_fk
        }
    })
        .then((res) => {
            toast.dismiss();
            toast.success(params?.message || "Candidate added successfully")
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            toast.error(err?.response?.data?.error?.[0]?.message)
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}

export const UpdateAtsExternalCandidateHelper = async (params) => {
    var user = getUserData();
    toast.info("Saving...")
    return await axiosInstance(`/ats/updateExternalCandidate`, {
        method: "PUT",
        baseURL: HRMS_ATS_API_URL,
        data: {
            workspace: params?.domain ? params?.domain : user?.domain,
            user_emp_id: +user?.ats_user_id || 0,
            first_name: params?.first_name,
            candidate_id: params?.candidate_id,
            last_name: params?.last_name,
            contact_no: params?.contact_no || null,
            email_id: params?.email_id,
            dob: params?.dob || null,
            designation: params?.designation || null,
            years_of_experience: params?.years_of_experience || null,
            current_ctc: params?.current_ctc || null,
            expected_pay: params?.expected_pay || null,
            current_employer: params?.current_employer,
            notice_period: params?.notice_period || null,
            gender: params?.gender,
            primary_skill: params?.primary_skill,
            secondary_skill: params?.secondary_skill,
            status: "Active",
            resume: params?.resume || "",
            resume_name: params?.resume_name,
            old_resume_name: params?.old_resume_name,
            identity_id: params?.identity_id?.identity_id || null,
            proof_number: params?.proof_number,
            is_external: params?.is_external || false,
            mode: params?.mode,
            is_verified: params?.is_verified || false,
            location: params?.location,
            uan_number: params?.uan_number || null,
            alternative_mobile: params?.alternative_mobile || null,
            github_profile: params?.github_profile || null,
            linkedin_profile: params?.linkedin_profile || null,
            certificate_details: params?.certificate_details || null,
            content_type: params?.content_type || ""
        }
    })
        .then((res) => {
            toast.dismiss();
            toast.success(params?.message || "Candidate Updated successfully")
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            toast.error(err?.response?.data?.error?.[0]?.message)
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}


export const DeleteExternalCandidateCertificateHelper = async (params) => {
    let user = getUserData()
    toast.dismiss();
    toast.info("Saving...")
    return await axiosInstance(`/ats/deleteExternalCandidateCertificate`, {
        method: "POST",
        baseURL: HRMS_ATS_API_URL,
        data: {
            workspace: user?.domain,
            certificate_id: params?.certificate_id
        }
    })
        .then((res) => {
            toast.dismiss();
            toast.success("Certificate Deleted Successfully")
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            toast.error(err?.response?.data?.error?.[0]?.message)
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}