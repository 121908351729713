import { useEffect, useState } from "react";
import MaterialTable from "material-table";
import {
    Box,
    Grid,
    FormControl,
} from "@mui/material";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { GetEmployeesAction } from "../../../../../store/Employee/Employee/Action";
import CommonDatePicker from "../../../Base/views/CommonDatePicker";
import SelectableSearch from '../../../Base/views/SelectableSearch';
import { InEligibleReportHelper } from "../../helper/Reports/GetWageReportHelper";

const InEligibleGrid = () => {
    const dispatch = useDispatch();
    const [empdata, setEmpData] = useState([]);
    const date = new Date();
    const default_date = `${date?.getFullYear() + '-' + ("0" + (date?.getMonth() + 1)).slice(-2) + '-' + ("0" + date?.getDate()).slice(-2)}`;
    const [filters, setFilters] = useState({
        employee_id: "",
        month_year: default_date
    });
    const [employeesData, setEmployeesData] = useState({
        load: true,
        values: [],
    });
    const { employees_options } = useSelector(
        (state) => state?.EmployeeReducer.EmployeesReducer
    );
    const handleEmployees = () => {
        employees_options &&
            setEmployeesData({ load: false, values: employees_options });
    };
    useEffect(() => {
        dispatch(GetEmployeesAction({ is_active: true }));
        const selectedDate = new Date(filters?.month_year);
        const firstDay = new Date(selectedDate.getFullYear(), selectedDate.getMonth(), 1);
        const lastDay = new Date(selectedDate.getFullYear(), selectedDate.getMonth() + 1, 0);
        const formattedFirstDay = `${firstDay.getFullYear()}-${("0" + (firstDay.getMonth() + 1)).slice(-2)}-${("0" + firstDay.getDate()).slice(-2)}`;
        const formattedLastDay = `${lastDay.getFullYear()}-${("0" + (lastDay.getMonth() + 1)).slice(-2)}-${("0" + lastDay.getDate()).slice(-2)}`;
        InEligibleReportHelper({
            from_date: formattedFirstDay,
            to_date: formattedLastDay,
            emp_id: filters?.employee_id?.employee_id || null,
        }).then((res) => {
            setEmpData(res?.data?.data);
        });
    }, [filters]);
    const handleFilter = (event, value) => {
        if (["employee_id"].includes(event)) {
            setFilters({ ...filters, [event]: value });
        }
        if (["month_year"].includes(event?.target?.name)) {
            setFilters({ ...filters, [event?.target?.name]: event?.target?.value })
        }
    };


    return (
        <>
            <Box sx={{ maxHeight: "89vh", overflowY: "auto" }}>
                <Box sx={{ m: 1 }}>
                    <Grid pb={2} container>
                        <Grid pr={1} item xs={12} sm={3.5}>
                            <FormControl fullWidth>
                                <SelectableSearch
                                    label="Employee"
                                    value={filters?.employee_id}
                                    loading={() => handleEmployees()}
                                    fieldLabel="name"
                                    variant={"outlined"}
                                    name="employee_id"
                                    size="small"
                                    onChangeEvent={handleFilter}
                                    data={employeesData}

                                />
                            </FormControl>
                        </Grid>
                        <Grid pr={1} item xs={12} sm={3.5}>
                            <FormControl fullWidth>
                                <CommonDatePicker
                                    name="month_year"
                                    openTo="month"
                                    views={['year', 'month']}
                                    label="Year and Month"
                                    pickerType="date"
                                    size="small"
                                    onKeyDown={(e) => e.preventDefault()}
                                    onKeyPress={(e) => e.preventDefault()}
                                    pickerFormat="MM/yyyy"
                                    DefaultDTvalue={filters.month_year}
                                    maxDate={new Date()}
                                    handleChange={(value) =>
                                        handleFilter({
                                            target: {
                                                name: "month_year",
                                                value: value
                                                    ? `${value?.getFullYear() +
                                                    "-" +
                                                    ("0" + (value?.getMonth() + 1)).slice(-2) +
                                                    "-" +
                                                    ("0" + value?.getDate()).slice(-2)
                                                    }`
                                                    : "",
                                            },
                                        })
                                    }

                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </Box>

                <MaterialTable
                    columns={[
                        {
                            title: "Name",
                            cellStyle: { padding: "10px 10px 10px 13px" },
                            field: "employee_name",
                            grouping: false,
                        },
                        {
                            title: "Comments",
                            cellStyle: { padding: "10px 10px 10px 13px" },
                            field: "comments",
                            grouping: false,
                        },
                    ]}
                    data={empdata}
                    options={{
                        maxBodyHeight: "54vh",
                        minBodyHeight: "54vh",
                        rowStyle: { fontSize: "13px" },
                        headerStyle: { padding: "10px 10px 10px 13px", fontWeight: "bold" },
                        sorting: true,
                        search: true,
                        searchText: "",
                        searchFieldAlignment: "right",
                        searchAutoFocus: true,
                        searchFieldVariant: "standard",
                        filtering: false,
                        paging: true,
                        pageSizeOptions: [25, 50],
                        pageSize: 25,
                        paginationType: "stepped",
                        showFirstLastPageButtons: false,
                        paginationPosition: "bottom",
                        exportButton: false,
                        exportAllData: false,
                        exportFileName: "Employees",
                        addRowPosition: "first",
                        selection: false,
                        actionsColumnIndex: -1,
                        showSelectAllCheckbox: true,
                        showTextRowsSelected: true,
                        grouping: false,
                        columnsButton: false,
                    }}

                    title="Onhold employees"
                />
            </Box>
        </>
    );
};

export default InEligibleGrid;