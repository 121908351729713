import React from "react";
import { Tooltip } from "@mui/material";
import { useState, useEffect } from "react";
import SelectableSearch from "../../../Base/views/SelectableSearch";
import { PriceHelper } from "../../helper/PriceHelper";
import history from "../../../../../history";
import { FeatureHelper } from "../../helper/FeatureHelper";
import { getSessionData } from "../../../Base/helper/baseHelper";
function Pricing() {
  const region = getSessionData({ key: "region" });
  const [priceData, setPriceData] = useState({});
  const [featuresData, setFeaturesData] = useState([]);
  const [inputData, setInputData] = useState({
    team_size: { value: "Upto 10", label: "Upto 10" },
  });
  const [filterOptions] = useState({
    load: false,
    values: [
      { value: "Upto 10", label: "Upto 10" },
      { value: "11 to 25", label: "11 to 25" },
      { value: "26 to 50", label: "26 to 50" },
      { value: "51 to 100", label: "51 to 100" },
      { value: "101 to 250", label: "101 to 250" },
      { value: "251 to 500", label: "251 to 500" },
    ],
  });
  useEffect(() => {
    PriceHelper({
      team_size: inputData?.team_size?.value,
    }).then((res) => {
      if (res?.data?.statuscode === 200) {
        setPriceData(res?.data?.data?.[0]);
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputData]);

  useEffect(() => {
    FeatureHelper({}).then((res) => {
      if (res?.data?.statuscode === 200) {
        setFeaturesData(res?.data?.data);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleInputChange = (e, value) => {
    if (e === "team_size") {
      if (value) setInputData({ [e]: value });
      else setInputData({ ...inputData, [e]: "" });
    } else setInputData({ ...inputData, [e.target.name]: e.target.value });
  };

  const generateLabel = (minCount, maxCount) => {
    if (minCount === "1" && maxCount === "10") {
      return "Upto 10";
    } else {
      return `${minCount} to ${maxCount}`;
    }
  };
  const ChoosePlan = (plan, Price) => {
    const { min_count, max_count } = priceData;
    const label = generateLabel(min_count, max_count);
    history.push({
      pathname: `/${region}/requestDemo`,
      state: {
        defaultPlan: { value: plan, label: plan },
        defaultteamsize: { value: label, label: label },
        defaultdisable: true,
      },
    });
  };
  //Monthly Yearly Change Section
  return (
    <>
      <div id="Pricing" className="section">
        <div className="container">
          <div className="title-container">
            <div className="center-text">
              <div className="w-layout-grid grid-8-col">
                <div
                  id="w-node-_5a2d0534-ac8b-35bf-25dd-c7821f795ab1-3f715fba"
                  className="content"
                >
                  <div className="title-with-emoji">
                    <div className="fade-in-move-on-scroll">
                      <h1 className="title">Simple, Transparent Pricing</h1>
                    </div>
                    <div className="emoji-holder _05">
                      <div>🤍</div>
                      <div className="bg-transparent"></div>
                    </div>
                  </div>
                  <div className="fade-in-move-on-scroll">
                    <p className="black-text">
                      No contracts. No surprise fees.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              data-current="Tab 1"
              data-easing="ease"
              data-duration-in="300"
              data-duration-out="100"
              className="tabs w-tabs"
            >
              <div></div>
              <div
                data-w-id="3601d95e-1ed1-1e02-6c94-a942cd77a224"
                className="tabs-content-price w-tab-content fade-in"
              >
                <div
                  data-w-tab="Tab 1"
                  className="w-tab-pane w--tab-active"
                  id="w-tabs-1-data-w-pane-0"
                  role="tabpanel"
                  aria-labelledby="w-tabs-1-data-w-tab-0"
                >
                  <div className="pricing-tab-holder">
                    <div className="pricing-tab-continaer">
                      <div
                        id="w-node-bfce872a-61ed-e983-66d9-0c928054c2a1-3f715fba"
                        className="pricing-mobile-container"
                      >
                        <div
                          id="w-node-bfce872a-61ed-e983-66d9-0c928054c2a2-3f715fba"
                          className="pricing-grid-header"
                        >
                          <div
                            id="w-node-bfce872a-61ed-e983-66d9-0c928054c2a3-3f715fba"
                            className="contact-us-holder"
                          >
                            <div className="card-title">
                              Contact us to Get a Quote
                            </div>
                            <div className="team_size">
                              <SelectableSearch
                                label="Team Size"
                                value={inputData?.team_size}
                                fieldLabel="label"
                                required={false}
                                variant={"contained"}
                                name="team_size"
                                size="small"
                                data={filterOptions}
                                onChangeEvent={handleInputChange}
                              />
                            </div>
                          </div>
                          <div
                            id="w-node-bfce872a-61ed-e983-66d9-0c928054c2b3-3f715fba"
                            className="pricing-header-container"
                          >
                            <div className="pricing-text-content">
                              <div className="card-title">Starter</div>
                              <div className="price-holder">
                                {priceData?.starter_price_min !== null &&
                                priceData?.starter_price_max !== null ? (
                                  <div className="pricing-intro">
                                    {priceData?.min_count === "1" &&
                                    priceData?.max_count === "10" ? (
                                      <span>
                                        {priceData?.starter_price_max}
                                      </span>
                                    ) : (
                                      <>
                                        <span>
                                          {priceData?.starter_price_min}
                                        </span>
                                        <span> - </span>
                                        <span>
                                          {priceData?.starter_price_max}
                                        </span>
                                      </>
                                    )}
                                  </div>
                                ) : (
                                  <div className="pricing-intro">NA</div>
                                )}
                                {priceData?.starter_price_min !== null &&
                                  priceData?.starter_price_max !== null && (
                                    <p className="paragraph-extra-small no-margins">
                                      / Month
                                    </p>
                                  )}
                              </div>
                              {priceData?.starter_price_min !== null &&
                              priceData?.starter_price_max !== null ? (
                                <button
                                  onClick={() => ChoosePlan("Starter", priceData)}
                                  href="#"
                                  className="button price-button w-button"
                                >
                                  Choose Plan
                                </button>
                              ) : (
                                <Tooltip title="Plan N.A for selected team size">
                                  <button
                                    disabled
                                    href="#"
                                    className="button price-button w-button disable"
                                  >
                                    Choose Plan
                                  </button>
                                </Tooltip>
                              )}
                            </div>
                          </div>
                          <div
                            id="w-node-bfce872a-61ed-e983-66d9-0c928054c2c9-3f715fba"
                            className="pricing-header-container"
                          >
                            <div className="pricing-text-content">
                              <div className="card-title">Growth</div>
                              <div className="price-holder">
                                <div className="pricing-intro">
                                  {priceData?.min_count === "1" &&
                                  priceData?.max_count === "10" ? (
                                    <span>{priceData?.growth_price_max}</span>
                                  ) : (
                                    <>
                                      <span>{priceData?.growth_price_min}</span>
                                      <span> - </span>
                                      <span>{priceData?.growth_price_max}</span>
                                    </>
                                  )}
                                </div>
                                <p className="paragraph-extra-small no-margins">
                                  / Month
                                </p>
                              </div>
                              <button
                                onClick={() => ChoosePlan("Growth", priceData)}
                                href="#"
                                className="button price-button w-button"
                              >
                                Choose Plan
                              </button>
                            </div>
                          </div>
                          <div
                            id="w-node-bfce872a-61ed-e983-66d9-0c928054c2c9-3f715fba"
                            className="pricing-header-container"
                          >
                            <div className="pricing-text-content">
                              <div className="card-title">Premium</div>
                              <div className="price-holder">
                                <div className="pricing-intro">
                                  {priceData?.min_count === "1" &&
                                  priceData?.max_count === "10" ? (
                                    <span>{priceData?.premier_price_max}</span>
                                  ) : (
                                    <>
                                      <span>
                                        {priceData?.premier_price_min}
                                      </span>
                                      <span> - </span>
                                      <span>
                                        {priceData?.premier_price_max}
                                      </span>
                                    </>
                                  )}
                                </div>
                                <p className="paragraph-extra-small no-margins">
                                  / Month
                                </p>
                              </div>
                              <button
                                onClick={() => ChoosePlan("Premium", priceData)}
                                href="#"
                                className="button price-button w-button"
                              >
                                Choose Plan
                              </button>
                            </div>
                          </div>
                        </div>
                        <div
                          id="w-node-bfce872a-61ed-e983-66d9-0c928054c2d4-3f715fba"
                          className="pricing-tabs-holder"
                        >
                          <div className="pricing-grid">
                            {featuresData.map((item) => (
                              <>
                                <div
                                  key={item.pfm_pk}
                                  id="w-node-bfce872a-61ed-e983-66d9-0c928054c2d6-3f715fba"
                                  className="pricing-detail-holder"
                                >
                                  <div>{item.feature}</div>
                                </div>
                                <div
                                  id="w-node-bfce872a-61ed-e983-66d9-0c928054c2d9-3f715fba"
                                  className="tabel-white-background"
                                >
                                  <div className="white-tabel-grid">
                                    <div
                                      id="w-node-bfce872a-61ed-e983-66d9-0c928054c2f5-3f715fba"
                                      className="tabel-checkmark-holder"
                                    >
                                      <div className="checkmark-holder">
                                        {item.starter_plan}
                                      </div>
                                      <div className="purple-line dark"></div>
                                    </div>
                                    <div
                                      id="w-node-bfce872a-61ed-e983-66d9-0c928054c2f9-3f715fba"
                                      className="tabel-checkmark-holder"
                                    >
                                      <div className="checkmark-holder">
                                        {item.growth_plan}
                                      </div>
                                      <div className="purple-line dark"></div>
                                    </div>
                                    <div
                                      id="w-node-bfce872a-61ed-e983-66d9-0c928054c2fd-3f715fba"
                                      className="tabel-checkmark-holder"
                                    >
                                      <div className="checkmark-holder">
                                        {item.premier_plan}
                                      </div>
                                      <div className="purple-line dark"></div>
                                    </div>
                                  </div>
                                </div>
                              </>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Pricing;
