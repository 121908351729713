import { combineReducers } from "redux";
import SignInReducer from "./Signin/Reducer";
import SlideReducer from "./Slide/Reducer";
import EmployeeReducer from "./Employee/EmployeeReducer";
import AncillaryReducer from './Ancillary/AncillaryReducer';
import MenuReducer from './Menu/Reducer';
import LeaveReducer from "./Leave/LeaveReducer";
import { UserAccessReducer, UserInfoReducer } from "./Accesses/Reducer";
import RoleAndAccessReducer from "./RoleAccess/RoleAndAccessReducer";
import EmployeeDashboardReducer from "./EmployeeDashboard/Reducer";
import MeeplAdminReducer from "./MeeplAdmin/MeeplAdminReducer";
import FinanceReducer from "./Finance/FinanceReducer";
import AtsReducer from "./ATS/AtsReducer";
import TourReducer from "./Tour/Reducer";
import OnboardingReducer from "./Onboarding/OnboardingReducer";
import PlansReducer from "./Plans/Reducer";
import BulkUploadReducer from "./Finance/BulkUpload/Reducer";

const appReducer = combineReducers({
    SlideReducer,
    SignInReducer,
    EmployeeReducer,
    AncillaryReducer,
    MenuReducer,
    LeaveReducer,
    UserAccessReducer,
    UserInfoReducer,
    RoleAndAccessReducer,
    EmployeeDashboardReducer,
    FinanceReducer,
    AtsReducer,
    TourReducer,
    MeeplAdminReducer,
    OnboardingReducer,
    PlansReducer,
    BulkUploadReducer
})

const rootReducer = (state, action) => {
    if (action.type === 'USER_LOGOUT') {
        return appReducer(undefined, action)
    }
    return appReducer(state, action)
}

export default rootReducer;
