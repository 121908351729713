import { Box, Button, Divider, InputBase, Typography } from "@mui/material";
import React, { useState } from "react";
import { useEffect } from "react";
import { Mention, MentionsInput } from "react-mentions";
import MentionInputStyles from "./MentionStyles/MentionInputStyles";
import { grey } from "@mui/material/colors";
import { GetAtsUsersEmailHelper } from "../../helper/AtsUserHelper";
import { ShareBulkHelper } from "../../helper/ATSEmailHeper";

const BulkResumeSharingForm = ({ setOpenPopup, currentJobData, zipFileData }) => {
    const initialValues = {
        subject: "",
        content: "",
        attachments: [
            {
                // use URL as an attachment
                filename: "",
                path: "",
            },
        ],
    };
    const [showCc, setShowCc] = useState(false);
    const [showBcc, setShowBcc] = useState(false);
    const [mentionTo, setMentionTo] = useState("");
    const [mentionCc, setMentionCc] = useState("");
    const [mentionBcc, setMentionBcc] = useState("");
    const [mentionUsers, setMentionUsers] = useState([]);
    const [inputData, setInputData] = useState(initialValues);
    const [errors, setErrors] = useState({});

    const validate = (fieldValues) => {
        let temp = { ...errors };

        if ("mentionTo" in fieldValues) {
            temp.mentionTo = fieldValues.mentionTo?.trim() === "" ? "To Email is required" : "";
        }
        if ("subject" in fieldValues) {
            temp.subject =
                fieldValues.subject?.trim() === "" ? "Subject is required" : "";
        }
        if ("responser_name" in fieldValues) {
            temp.responser_name =
                fieldValues.responser_name?.trim() === ""
                    ? "Responser Name is required"
                    : "";
        }

        setErrors({
            ...temp,
        });

        return Object.values(temp).every((x) => x === "");
    };

    useEffect(() => {
        setInputData({
            ...inputData,
            content: "Please find the Attachment",
            subject: `Sharing resume's of ${currentJobData?.title}`,
        });
        GetAtsUsersEmailHelper().then((res) => {
            let arr = [];
            res?.data?.data?.forEach((temp) =>
                arr.push({ ...temp, id: temp?.email_id, display: temp?.email_id })
            );
            setMentionUsers(arr);
        });
    }, []);

    const handleShowCc = () => {
        setMentionCc("");
        setShowCc(!showCc);
    };
    const handleShowBcc = () => {
        setMentionBcc("");
        setShowBcc(!showBcc);
    };
    const handleInputChange = (event) => {
        setInputData({ ...inputData, [event?.target?.name]: event?.target?.value });
        Object.values?.(errors)?.find((res) => res !== "")?.length > 0 &&
            validate({ ...inputData, [event.target.name]: event.target.value });
    };
    const handleChange = (e, name) => {
        const inputValue = e.target.value;
        const endsWithSpace = inputValue.endsWith(" ");
        if (endsWithSpace) {
            const trimmedValue = inputValue.trim();
            const updatedValue = trimmedValue + ",";
            if (name === "mentionCc") {
                setMentionCc(updatedValue);
            } else if (name === "mentionBcc") {
                setMentionBcc(updatedValue);
            } else if (name === "mentionTo") {
                setMentionTo(updatedValue);
            }
        } else {
            if (name === "mentionCc") {
                setMentionCc(inputValue);
            } else if (name === "mentionBcc") {
                setMentionBcc(inputValue);
            } else if (name === "mentionTo") {
                setMentionTo(inputValue);
            }
        }
    };

    const handleSend = async () => {
        const processEmails = (emailString) => {
            const emailArray = emailString.split(",");
            const uniqueEmailSet = new Set(emailArray);
            const uniqueEmailArray = Array.from(uniqueEmailSet);
            return uniqueEmailArray.join(",");
        };
        const to = processEmails(mentionTo);
        const cc = processEmails(mentionCc);
        const bcc = processEmails(mentionBcc);
        if (validate({ ...inputData, mentionTo })) {
            ShareBulkHelper({
                ...inputData,
                to: to,
                cc: cc,
                bcc: bcc,
                bulk_file: zipFileData.base64,
                file_name: zipFileData.fileName,
                message: "Email Sent Successfully",
            });
            setOpenPopup(false);
        }
    };
    const handleResumeDownload = () => {
        const link = document.createElement("a");
        link.href = `data:application/zip;base64,${zipFileData.base64}`;
        link.download = zipFileData.fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };
    return (
        <div>
            <Divider sx={{ borderStyle: "dashed" }} />
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
            >
                <Box sx={{ width: "100%" }}>
                    <MentionsInput
                        fullWidth
                        style={MentionInputStyles}
                        value={mentionTo}
                        name="mentionTo"
                        onChange={(e, name) => handleChange(e, "mentionTo")}
                        placeholder={"TO"}
                    >
                        <Mention
                            markup="__id__,"
                            displayTransform={(email_id) => `${email_id}, `}
                            data={mentionUsers}
                            displayName="email_id"
                        />
                    </MentionsInput>
                </Box>
                <Typography variant="body1">
                    <Box
                        onClick={handleShowCc}
                        sx={{ cursor: "pointer" }}
                        component={"span"}
                    >
                        CC
                    </Box>
                    <Box
                        sx={{ marginLeft: "0.5rem", marginRight: "0.5rem" }}
                        component={"span"}
                    >
                        |
                    </Box>
                    <Box
                        onClick={handleShowBcc}
                        sx={{ cursor: "pointer" }}
                        component={"span"}
                    >
                        BCC
                    </Box>
                </Typography>
            </Box>
            <Divider sx={{ borderStyle: "dashed" }} />
            <Typography color="error">{errors.mentionTo}</Typography>
            {showCc && (
                <>
                    <MentionsInput
                        fullWidth
                        style={MentionInputStyles}
                        value={mentionCc}
                        name="mentionCc"
                        onChange={(e, name) => handleChange(e, "mentionCc")}
                        placeholder={"CC"}
                    >
                        <Mention
                            markup="__id__,"
                            displayTransform={(email_id) => `${email_id}, `}
                            data={mentionUsers}
                            displayName="email_id"
                        />
                    </MentionsInput>
                    <Divider sx={{ borderStyle: "dashed" }} />
                </>
            )}
            {showBcc && (
                <>
                    <MentionsInput
                        fullWidth
                        style={MentionInputStyles}
                        value={mentionBcc}
                        name="mentionBcc"
                        onChange={(e, name) => handleChange(e, "mentionBcc")}
                        placeholder={"BCC"}
                    >
                        <Mention
                            markup="__id__,"
                            displayTransform={(email_id) => `${email_id}, `}
                            data={mentionUsers}
                            displayName="email_id"
                        />
                    </MentionsInput>
                    <Divider sx={{ borderStyle: "dashed" }} />
                </>
            )}
            <InputBase
                fullWidth
                name="subject"
                value={inputData?.subject}
                onChange={handleInputChange}
                sx={{ padding: "5px 0px" }}
                placeholder="Subject"
            />
            <Divider sx={{ borderStyle: "dashed" }} />
            <Typography color="error">{errors.subject}</Typography>
            <InputBase
                fullWidth
                name="content"
                value={inputData?.content}
                onChange={handleInputChange}
                minRows={4}
                multiline
                sx={{ padding: "5px 0px" }}
                placeholder="Content"
            />

            <Box marginBottom={2} display="flex" alignItems={"center"}>
                <Typography sx={{ fontWeight: "bold", textAlign: "left" }}>
                    Resume:&nbsp;
                </Typography>
                <Typography
                    className="file-name"
                    sx={{ color: "#1155cc" }}
                    onClick={handleResumeDownload}
                    style={{ cursor: "pointer" }}
                >
                    {zipFileData.fileName}
                </Typography>
            </Box>

            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Button className="mpl-secondary-btn" sx={{ ":hover": { color: grey[50] } }} size="small" variant="text" onClick={() => setOpenPopup(false)}>
                    Cancel
                </Button>
                <Button
                    sx={{
                        ml: 1,
                        color: grey[50],
                        ":hover": { backgroundColor: "#f9c307", color: "#000000" },
                    }}
                    size="small"
                    variant="contained"
                    className="mpl-primary-btn"
                    onClick={handleSend}
                >
                    Send
                </Button>
            </Box>
        </div>
    );
};

export default BulkResumeSharingForm;
