import { Box, Button, FormControl, Grid, TextField } from "@mui/material";
import Popup from "../../../../Base/views/Popup";
import SelectableSearch from "../../../../Base/views/SelectableSearch";

const AcceptPopup = ({ hide, accept, setAccept, handleStageDataLoading, errors, values, setErrors, handleValues, setValues, handleRejectClick, stagesData }) => {
    return (
        <>
            <Popup
                fullWidth={true}
                maxWidth="xs"
                title="Accept Candidate"
                openPopup={accept}
                setOpenPopup={setAccept}
            >
                <Box>
                    {!hide && (
                        <FormControl fullWidth sx={{ pb: 1 }}>
                            <SelectableSearch
                                isOptionEqualToValue={(option, value) =>
                                    option?.name === "Rejected"
                                }
                                label="Move to"
                                value={values?.stage_id?.ats_job_stages_id}
                                loading={() => handleStageDataLoading()}
                                fieldLabel="name"
                                required={true}
                                variant={"contained"}
                                name="stage_id"
                                size="small"
                                data={stagesData}
                                onChangeEvent={handleValues}
                                {...(errors?.stage_id && {
                                    error: true,
                                    helperText: errors?.stage_id,
                                })}

                            />
                        </FormControl>
                    )}
                    <TextField
                        label={"Reverting the Rejection Reason"}
                        name='revert_reason'
                        id="revert_reason"
                        required
                        multiline
                        rows={4}
                        variant="outlined"
                        value={values?.revert_reason}
                        fullWidth
                        onChange={handleValues}
                        {...(errors?.revert_reason && {
                            error: true,
                            helperText: errors?.revert_reason,
                        })}

                    />
                    <Grid display="flex" justifyContent="flex-end" container>
                        <Button
                            sx={{ marginTop: "1.75rem", marginRight: "1rem" }}
                            variant="outlined"
                            onClick={() => { setValues({}); setAccept(false); setErrors([]) }}
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={() => {
                                handleRejectClick()
                            }}
                            variant="contained"
                            color="primary"
                            type="submit"
                            className="mpl-primary-btn"
                            sx={{ marginTop: "1.75rem" }}
                        >
                            Accept
                        </Button>
                    </Grid>
                </Box>
            </Popup>
        </>
    )
}

export default AcceptPopup