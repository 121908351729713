import { useEffect } from "react";
import MaterialTable from "material-table";
import {
    Box,
    Button,
    Select,
    Typography,
    MenuItem,
    Grid,
    CircularProgress,
    IconButton,
    FormControl,
    TextField,
    Tooltip,
    Menu,
    FormControlLabel,
    Checkbox
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useSelector } from "react-redux";
import { useState } from "react";
import { useDispatch } from "react-redux";
import Base from "../../../Base/views/Base";
import { getUserData } from "../../../Base/helper/baseFunctions";
import Popup from "../../../Base/views/Popup";
import * as XLSX from "xlsx";
import { toast } from "react-toastify";
import axiosInstance from "../../../../../axiosInstance";
import { HRMS_FINANCE_API_URL } from "../../../../Constants/baseConstant";
import SelectableSearch from "../../../Base/views/SelectableSearch";
import { GetPayrollTemplateAction } from "../../../../../store/Finance/PayRollTemplate/Action";
import ConfirmationDialog from "../../../Base/views/ConfirmationDialog";
import { AddPayrollStagetoMainHelper, DeletePayrollStageHelper, GetPayrollStageHelper, UpdatePayrollStageHelper } from "../../helper/BulkUpload/BulkUploadHelper";
import CloseIcon from '@mui/icons-material/Close';
import { GetEarningAction } from "../../../../../store/Finance/Component/Action";
import { BulkUploadCalculateAdvanceHelper, BulkUploadCalculateHelper } from "../../helper/Employee/EmployeeTemplateHelper";
import { BulkPayrollLoadAction } from "../../../../../store/Finance/BulkUpload/Action";

const PayrollBulkUpload = () => {
    const dispatch = useDispatch();
    let userData = getUserData();
    const [, setOpenBulk] = useState(true);
    const [anchorEl, setAnchorEl] = useState(null);
    const [showConfirmPopup, setShowConfirmPopup] = useState(false);
    const [editedRecord, setEditedRecord] = useState(null);
    const [id, setId] = useState();
    const [openPopup, setOpenPopup] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [currentData, setCurrentData] = useState({});
    const [editerrors, setEditErrors] = useState({});
    const [empdata, setEmpData] = useState([]);
    const [errors, setErrors] = useState({});
    const [file, setFile] = useState(null);
    const [selecttemp, setSelectTemp] = useState("Meepl Salary Template");
    const templateEarningsValues = {
        earning_key: "",
        earning_value: "0",
    };
    const [earningData, setEarningData] = useState({
        load: true,
        values: [],
    });
    const [templateEarningData, setTemplateEarningData] = useState([]);
    const isLoading = useSelector((state) => state.BulkUploadReducer.isLoading);
    const { payroll_template_data } = useSelector(
        (state) => state?.FinanceReducer.PayrollTemplateReducer
    );
    const { earning_data } = useSelector(
        (state) => state?.FinanceReducer.ComponentReducer
    );
    const selectedTemplateName = payroll_template_data?.find(t => t.template_name === "Meepl Salary Template");
    const initialColMapping = [
        { excel: "", db: "previous_employee_id", label: "Prev.Emp ID", required: true },
        { excel: "", db: "ctc", label: "CTC (Annually)", required: true },
        { excel: "", db: "basic", label: "Basic %", required: true },
        { excel: "", db: "hra", label: "HRA %", required: true },
        { excel: "", db: "conveyance_allowance", label: "Conveyance Allowance", required: false },
        { excel: "", db: "medical_allowance", label: "Medical Allowance", required: false },
        { excel: "", db: "educational_allowance", label: "Educational Allowance", required: false },
        { excel: "", db: "lta", label: "LTA", required: false },
        { excel: "", db: "esic", label: "ESIC Applicable", required: false },
        { excel: "", db: "pt", label: "PT Applicable", required: false },
        { excel: "", db: "overtime_applicable_flag", label: "OT Applicable", required: false },
    ];
    const [excelDbColMapping, setExcelDbColMapping] = useState(initialColMapping);
    const [isAdvancedTemplate, setIsAdvancedTemplate] = useState(false);
    const [excelDbCol, setExcelDbCol] = useState([{}]);
    const [rowHeaders, setRowHeaders] = useState([]);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [templateData, setTemplateData] = useState({ load: true, values: [] });
    const [inputData, setInputData] = useState({ template_id: "" });
    useEffect(() => {
        dispatch(GetPayrollTemplateAction());
        dispatch(GetEarningAction());
        setInputData({
            ...inputData,
            template_id: { template_id: selectedTemplateName?.template_id, template_name: "Meepl Salary Template" },
        });
        GetPayrollStageHelper().then((res) => {
            setEmpData(res?.data?.data?.[0]?.payroll_details);
            setId(res?.data?.data?.[0]?.stage_payroll_pk);

            if (res?.data?.data?.[0]?.template_name) {
                setSelectTemp(res?.data?.data?.[0]?.template_name);
                setIsAdvancedTemplate(res?.data?.data?.[0]?.template_name === "Advanced Meepl Salary Template");
            }
            else {
                setSelectTemp("Meepl Salary Template");
                setIsAdvancedTemplate(false);
            }
        });
    }, []);

    const handleTemplate = () => {
        payroll_template_data &&
            setTemplateData({ load: false, values: payroll_template_data });
    };
    const handleInput = (event, value) => {
        const selectedTemplate = payroll_template_data?.find(t => t.template_name === value?.template_name);
        setSelectTemp(selectedTemplate.template_name);
        setIsAdvancedTemplate(selectedTemplate?.template_name === "Advanced Meepl Salary Template");
        if (selectedTemplate) {
            if (selectedTemplate.template_name === "Meepl Salary Template") {
                setExcelDbColMapping(initialColMapping)
            }
            else if (selectedTemplate?.template_name === "Advanced Meepl Salary Template") {
                let newColMapping = [
                    { excel: "", db: "previous_employee_id", label: "Prev.Emp ID", required: true },
                    { excel: "", db: "ctc", label: "CTC (Annually)", required: true },
                    { excel: "", db: "basic", label: "Basic %", required: true },
                    { excel: "", db: "hra", label: "HRA %", required: true },
                    { excel: "", db: "variable_percentage", label: "Variable Pay %", required: false },
                    { excel: "", db: "conveyance_allowance", label: "Conveyance Allowance", required: false },
                    { excel: "", db: "medical_allowance", label: "Medical Allowance", required: false },
                    { excel: "", db: "educational_allowance", label: "Educational Allowance", required: false },
                    { excel: "", db: "lta", label: "LTA", required: false },
                    { excel: "", db: "esic", label: "ESIC Applicable", required: false },
                    { excel: "", db: "pt", label: "PT Applicable", required: false },
                    { excel: "", db: "pf", label: "PF Applicable", required: false },
                    { excel: "", db: "pf_limit_amount", label: "PF Limit Amount", required: false },
                    { excel: "", db: "overtime_applicable_flag", label: "OT Applicable", required: false },
                ];
                setExcelDbColMapping(newColMapping);
            }
            else {
                const newColMapping = [];
                const { earning_key = [], deduction_key = [] } = selectedTemplate.template_fields || {};
                newColMapping.push({
                    excel: rowHeaders.includes("previous_employee_id") ? "previous_employee_id" : "",
                    db: "previous_employee_id",
                    label: "Prev.Emp ID",
                    required: true,
                });
                [...earning_key, ...deduction_key].forEach((field) => {
                    newColMapping.push({
                        excel: "",
                        db: field.name,
                        label: field.label,
                        required: false,
                    });
                });
                setExcelDbColMapping(newColMapping);
            }

        } else {
            setExcelDbColMapping([]);
        }

        if (event === "template_id") {
            setInputData({ ...inputData, [event]: value });
        }
    };
    const handleSelectFileClick = () => {
        setInputData({
            ...inputData,
            template_id: { template_id: selectedTemplateName?.template_id, template_name: "Meepl Salary Template" },
        });
        if (empdata?.length > 0) {
            setShowConfirmPopup(true); // Show confirmation popup if data exists
        } else {
            document.getElementById("contained-button-file").click();

            // Directly trigger file selection if no data exists
        }
    };
    const fileSelected = (e) => {
        const selectedFile = e.target.files[0];
        if (!selectedFile) return;
        processFile(selectedFile, e);
    };
    const processFile = (selectedFile, event) => {
        setFile(selectedFile);
        setExcelDbColMapping(initialColMapping);
        setTemplateEarningData([]);
        setUploadProgress(0);
        const fileReader = new FileReader();
        toast.info("Reading File. Please wait");
        fileReader.onload = (e) => {
            const arrayBuffer = e.target.result;
            const workbook = XLSX.read(arrayBuffer, { type: "array" });
            const worksheet = workbook.Sheets[workbook.SheetNames[0]];
            const firstRowData = XLSX.utils.sheet_to_json(worksheet, {
                header: 1,
                range: 0,
            });
            setRowHeaders(firstRowData[0]);
            toast.success("File imported");
        };

        fileReader.readAsArrayBuffer(selectedFile);
        if (event) event.target.value = ""; // Reset the input
    };
    function setColMapping(value, dbCol) {
        setExcelDbColMapping((prev) => {
            prev.forEach((col) => {
                if (col["db"] === dbCol) col.excel = value;
            });
            return [...prev];
        });
        setExcelDbCol((prev) => {
            prev.forEach((col) => {
                if (col["db"] === dbCol) col.excel = value;
            });
            return [...prev];
        });
    }
    function clearColMapping(dbCol) {
        setExcelDbColMapping((prev) => {
            prev.forEach((col) => {
                if (col["db"] === dbCol) col.excel = "";
            });
            return [...prev];
        });
    }
    const validate = () => {
        const temp = {};
        if (!file) temp.file = "File is required";
        const unmappedRequiredFields = excelDbColMapping.filter(
            (col) => col.required && !col.excel
        );
        if (unmappedRequiredFields.length > 0) {
            toast.error("Please map all required fields.")
            temp.columnMapping = "Please map all required fields.";
        }
        setErrors(temp);
        return Object.keys(temp).length === 0; // Return true if no errors
    };
    async function addPayrollStage(e) {
        if (!validate()) return;
        e.preventDefault();
        try {
            const formData = new FormData();
            const EarningformattedData = templateEarningData?.map((item) => ({
                name: item.earning_key?.name,
                label: item.earning_key?.label,
                value: item.earning_value,
            }));
            const formattedDataToSend = EarningformattedData.length ? JSON.stringify(EarningformattedData) : null;
            const fullMapping = [
                ...excelDbColMapping.map(col => ({
                    ...col,
                    earning_component_json: formattedDataToSend
                })),
                ...templateEarningData.map((earning, index) => ({
                    excel: excelDbCol[index]?.excel || "",
                    db: earning.earning_key?.name || "",
                    label: earning.earning_key?.label || "",
                    required: false,
                    earning_component_json: formattedDataToSend
                }))
            ];
            formData.append("file", file);
            formData.append("user_id", userData?.employee_id);
            formData.append("template_id", inputData?.template_id?.template_id);
            formData.append("template_name", inputData?.template_id?.template_name);
            formData.append("domain_name", userData?.domain);
            formData.append("column_mapping", JSON.stringify(isAdvancedTemplate ? fullMapping : excelDbColMapping));
            toast.info("Uploading file");
            try {
                setUploadProgress(0);
                await axiosInstance
                    .post("/finance/uploadPayrollFile", formData, {
                        baseURL: HRMS_FINANCE_API_URL,
                        headers: {
                            Accept: "multipart/form-data",
                            "Content-Type": "multipart/form-data",
                        },
                        onUploadProgress: (progress) => {
                            const percentCompleted = Math.round((progress.loaded * 90) / progress.total);
                            setUploadProgress(percentCompleted);
                            if (percentCompleted > 89) {
                                toast.info("Processing...");
                            }
                        },
                    })
                    .then((response) => {
                        const result = response?.data?.statuscode;
                        setEmpData(response?.data?.data?.[0]?.payroll_details);
                        setId(response?.data?.data?.[0]?.stage_payroll_pk);
                        if (result) {
                            toast.success("File processed successfully!");
                        }
                    });

                setUploadProgress(100);
                setFile(null);
            } catch (error) {
                setUploadProgress(0);
                toast.warning(error.response?.data?.message || error.message);
            }
        } catch (error) {
            console.error("Unexpected error", error);
        }
    }
    async function handleSubmit(e) {
        if (selecttemp === "Meepl Salary Template") {
            await handleCalculate();
        }
        else if (selecttemp === "Advanced Meepl Salary Template") {
            await handleCalculateAdvance();
        }
        else {
            dispatch(BulkPayrollLoadAction(true));
            AddPayrollStagetoMainHelper().then((res) => {
                if (res?.data?.statuscode === 200) {
                    toast.success("Payroll bulk upload completed successfully.");
                    setEmpData([]);
                } else {
                    toast.error("The bulk upload failed. Please try again.");
                }
            }).finally(() => {
                dispatch(BulkPayrollLoadAction(false));
            });
        }

    }
    const handleCalculate = async () => {
        try {
            dispatch(BulkPayrollLoadAction(true));
            const finalResponse = await BulkUploadCalculateHelper();
            if (finalResponse?.data?.statuscode === 200) {
                toast.success("Payroll bulk upload completed successfully.");
                setEmpData([]);
            } else {
                toast.error("The bulk upload failed. Please try again.");
            }
        } catch (error) {
            toast.error("Error" + error.message);
        } finally {
            dispatch(BulkPayrollLoadAction(false));
        }
    };
    const handleCalculateAdvance = async () => {
        try {
            dispatch(BulkPayrollLoadAction(true));
            const finalResponse = await BulkUploadCalculateAdvanceHelper();
            if (finalResponse?.data?.statuscode === 200) {
                toast.success("Payroll bulk upload completed successfully.");
                setEmpData([]);
            } else {
                toast.error("The bulk upload failed. Please try again.");
            }
        } catch (error) {
            toast.error("Error" + error.message);
        } finally {
            dispatch(BulkPayrollLoadAction(false));
        }
    };
    const handleCancel = () => {
        setShowConfirmPopup(false);
        DeletePayrollStageHelper({ stage_payroll_id: id }).then((res) => {
            setEmpData([]); // Clear table data
            setFile(null); // Allow new file selection
            setIsAdvancedTemplate(false);
            document.getElementById("contained-button-file").click();
        });
    };
    const formatDecimal = (value) => {
        if (value === null || value === undefined) return "";
        const num = parseFloat(value);
        if (isNaN(num)) return "";
        return (Math.floor(num * 100) / 100).toFixed(2);
    };
    const handleEdit = (rowData) => {
        const formattedData = {};
        Object.keys(rowData).forEach((key) => {
            if (["previous_employee_id", "first_name", "last_name", "office_email"].includes(key)) {
                formattedData[key] = rowData[key];
            } else if (["esic", "pt", "pf", "overtime_applicable_flag"].includes(key)) {
                formattedData[key] = Boolean(rowData[key]); // Ensure boolean value
            }
            else {
                formattedData[key] = formatDecimal(rowData[key]);
            }
        });
        formattedData.earning_component_json = Array.isArray(rowData.earning_component_json)
            ? rowData.earning_component_json.map((earning) => ({
                name: earning.name,
                label: earning.label,
                value: formatDecimal(earning.value),
            }))
            : [];
        setEditedRecord(formattedData);
        setOpenPopup(true);
    };
    const handleConfirmDelete = (rowData) => {
        setOpenDialog(true);
        setCurrentData(rowData)
    };
    const handleDelete = () => {
        const updatedData = empdata?.filter(emp => emp?.previous_employee_id !== currentData?.previous_employee_id); // Use unique field
        setEmpData(updatedData); // Update state
        toast.success("Record deleted successfully!");
        setOpenDialog(false);
        UpdatePayrollStageHelper({ stage_payroll_id: id, payroll_details: JSON.stringify(updatedData) }).then((res) => {
        });
    };
    const validateFields = () => {
        const tempErrors = {};
        if (!editedRecord?.previous_employee_id) tempErrors.previous_employee_id = "Prev.Emp ID is required.";
        if (!editedRecord?.ctc) tempErrors.ctc = "CTC is required.";
        if (!editedRecord?.basic) tempErrors.basic = "Basic is required.";
        if (!editedRecord?.hra) tempErrors.hra = "HRA is required.";
        setEditErrors(tempErrors);
        return Object.keys(tempErrors).length === 0; // Return true if no errors
    };
    const handleEditSubmit = (updatedRecord) => {
        if (!validateFields()) return;
        updatedRecord.earning_component_json = Array.isArray(updatedRecord.earning_component_json)
            ? updatedRecord.earning_component_json
            : [];
        updatedRecord.esic = Boolean(updatedRecord.esic);
        updatedRecord.pt = Boolean(updatedRecord.pt);
        updatedRecord.pf = Boolean(updatedRecord.pf);
        updatedRecord.overtime_applicable_flag = Boolean(updatedRecord.overtime_applicable_flag);
        const updatedData = empdata?.map((emp) =>
            emp.previous_employee_id === updatedRecord.previous_employee_id ? updatedRecord : emp
        );
        setEmpData(updatedData);
        UpdatePayrollStageHelper({
            stage_payroll_id: id,
            payroll_details: JSON.stringify(updatedData),
        }).then((res) => { });
        setOpenPopup(false);
        toast.success("Record updated successfully!");
    };
    const handleAddEarningFields = () => {
        setTemplateEarningData((state) => [...state, templateEarningsValues]);
        setExcelDbCol([...excelDbCol, { db: "", excel: "" }]);
    };
    const handleEarningDelete = (deleteId) => {
        setTemplateEarningData((prevData) => prevData.filter((_, index) => index !== deleteId));
        setExcelDbCol((prevMappings) => prevMappings.filter((_, index) => index !== deleteId));
    };
    const handleEarnings = () => {
        const allowedEarnings = [
            "fixed_dearness_allowance",
            "variable_dearness_allowance",
            "other_allowance",
            "hardship_allowance",
            "city_compensatory_allowance",
            "special_compensatory_allowance",
            "uniform_allowance",
            "transport_allowance",
            "shift_allowance",
            "nightship_allowance",
            "attendance_bonus",
            "overtime_pay",
            "service_charges",
            "production_incentive",
            "sales_incentive",
            "coupons",
            "statutory_bonus",
            "medical_reimbursement",
            "gratuity"
        ];
        if (earning_data) {
            const filteredEarnings = earning_data?.[0]?.fn_get_earning_components?.filter(earning =>
                allowedEarnings.includes(earning.name)
            );
            setEarningData({ load: false, values: filteredEarnings });
        }

    };
    const getFilteredEarnings = (id) => {
        const selectedEarnings = templateEarningData.map((item, index) => index !== id && item.earning_key?.name).filter(Boolean);
        return earningData.values.filter(
            (option) => !selectedEarnings.includes(option.name)
        );
    };
    const handleEarningChange = (e, value, id) => {
        let newArr = [...templateEarningData];
        let item = newArr[id];
        item = { ...item, [e]: value };
        newArr[id] = item;
        setTemplateEarningData(newArr);

    }
    const handleEarningColumnChange = (index, value) => {
        const newExcelDbCol = [...excelDbCol];
        newExcelDbCol[index] = { ...newExcelDbCol[index], excel: value };
        setExcelDbCol(newExcelDbCol);
    };
    const meeplSalaryTemplate = [
        ["Prev.Emp ID", "CTC (Annual)", "Basic (% of CTC)", "HRA (% of Basic Pay)", "Conveyance Allowance (Fixed)", "Medical Allowance (Fixed)", "Educational Allowance (Fixed)", "LTA (Fixed)", "ESIC Applicable (Yes/No)", "PT Applicable (Yes/No)", "Over Time (Yes/No)"],
        ["EMP001", "100000", "20", "50", "1250", "1600", "200", "100", "Yes", "Yes", "Yes"],
    ];

    const advancedMeeplSalaryTemplate = [
        ["Prev.Emp ID", "CTC (Annual)", "Basic (% of CTC)", "HRA (% of Basic Pay)", "Variable (% of CTC)", "Conveyance Allowance (Fixed)", "Medical Allowance (Fixed)", "Educational Allowance (Fixed)", "LTA (Fixed)", "ESIC Applicable (Yes/No)", "PT Applicable (Yes/No)", "PF Applicable (Yes/No)", "PF Limit Amount (Fixed)", "Fixed Dearness Allowance", "Variable Dearness Allowance", "Over Time (Yes/No)"],
        ["EMP001", "100000", "20", "50", "10", "1250", "1600", "200", "100", "Yes", "Yes", "Yes", "100", "0", "0", "Yes"],
    ];

    const handleOpenMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseMenu = () => {
        setAnchorEl(null);
    };
    const handleDownload = (type) => {
        let data, fileName;
        if (type === "meepl") {
            data = meeplSalaryTemplate;
            fileName = "Meepl_Salary_Template.xlsx";
        } else if (type === "advanced") {
            data = advancedMeeplSalaryTemplate;
            fileName = "Advanced_Meepl_Salary_Template.xlsx";
        }

        const ws = XLSX.utils.aoa_to_sheet(data);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
        XLSX.writeFile(wb, fileName);

        handleCloseMenu();
    };
    return (
        <>
            <Base>
                {
                    (isLoading) &&
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100vh',
                        backgroundColor: '#f0f4f8'
                    }}>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            marginBottom: '10px'
                        }}>
                            <Typography sx={{ mb: 2 }} variant='h4'>Your file is being processed. We shall notify you once process is completed.</Typography>

                        </div>
                    </div>
                }
                <Box sx={{ maxHeight: "87vh", overflowY: "auto" }}>
                    <Box>
                        <form
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                gap: "16px",
                            }}
                        >
                            <div style={{ flex: 1, display: "flex", alignItems: "center", gap: "10px" }}>
                                <p
                                    style={{
                                        cursor: "pointer",
                                        color: "blue",
                                    }}
                                    onClick={handleOpenMenu}
                                >
                                    Click Here to Get Sample Excel
                                </p>
                                <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleCloseMenu}>
                                    <MenuItem onClick={() => handleDownload("meepl")}>Meepl Salary Template</MenuItem>
                                    <MenuItem onClick={() => handleDownload("advanced")}>Advanced Meepl Salary Template</MenuItem>
                                </Menu>
                            </div>
                            <div style={{ display: "flex", alignItems: "center", gap: "16px" }}>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    {file ? (
                                        <p style={{ margin: 0 }}>{file.name.substring(0, 20)}...</p>
                                    ) : errors?.selectedFile ? (
                                        <p style={{ color: "red", margin: 0 }}>No file chosen</p>
                                    ) : (
                                        <p style={{ margin: 0 }}>No file chosen</p>
                                    )}
                                </div>
                                <label htmlFor="contained-button-file" style={{ display: "none" }}>
                                    <input
                                        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                        id="contained-button-file"
                                        type="file"
                                        style={{ display: "none" }}
                                        multiple={false}
                                        onChange={fileSelected}
                                    />
                                </label>
                                <Button
                                    variant="contained"
                                    className="mpl-primary-btn"
                                    component="span"
                                    sx={{ marginBottom: "5px" }}
                                    onClick={handleSelectFileClick}
                                >
                                    Select File
                                </Button>
                                <Button
                                    variant="contained"
                                    className="mpl-primary-btn"
                                    sx={{ marginBottom: "5px", marginRight: "5px" }}
                                    disabled={empdata?.length === 0 || empdata === undefined || isLoading}
                                    onClick={handleSubmit}
                                >
                                    {isLoading ? <CircularProgress size={24} /> : "Submit"}
                                </Button>
                            </div>
                        </form>
                    </Box>
                    <MaterialTable
                        columns={[
                            {
                                title: "Prev.Emp ID",
                                cellStyle: { padding: "10px 10px 10px 13px" },
                                field: "previous_employee_id",
                                grouping: false,
                            },
                            {
                                title: "CTC (Annually)",
                                cellStyle: { padding: "10px 10px 10px 13px" },
                                render: rowData => rowData.ctc ? parseFloat(rowData.ctc).toFixed(2) : "",
                            },
                            {
                                title: "Basic",
                                cellStyle: { padding: "10px 10px 10px 13px" },
                                render: rowData => rowData.basic ? parseFloat(rowData.basic).toFixed(2) : ""
                            },
                        ]}
                        data={empdata}
                        options={{
                            maxBodyHeight: "65vh",
                            minBodyHeight: "65vh",
                            rowStyle: { fontSize: "13px" },
                            headerStyle: { padding: "10px 10px 10px 13px", fontWeight: "bold" },
                            sorting: true,
                            search: true,
                            searchText: "",
                            searchFieldAlignment: "right",
                            searchAutoFocus: true,
                            searchFieldVariant: "standard",
                            filtering: false,
                            paging: true,
                            pageSizeOptions: [25, 50],
                            pageSize: 25,
                            paginationType: "stepped",
                            showFirstLastPageButtons: false,
                            paginationPosition: "bottom",
                            exportButton: false,
                            exportAllData: false,
                            exportFileName: "Employees",
                            addRowPosition: "first",
                            selection: false,
                            actionsColumnIndex: -1,
                            showSelectAllCheckbox: true,
                            showTextRowsSelected: true,
                            grouping: false,
                            columnsButton: false,
                        }}
                        actions={[
                            {
                                icon: () => <IconButton sx={{ padding: "0px" }}><EditIcon /></IconButton>,
                                tooltip: "Edit",
                                onClick: (_event, rowData) => { handleEdit(rowData) },
                            },
                            {
                                icon: () => <IconButton sx={{ padding: "0px" }}><DeleteIcon /></IconButton>,
                                tooltip: "Delete",
                                onClick: (_event, rowData) => { handleConfirmDelete(rowData) },
                            },
                        ]}
                        title="Payroll Bulk Upload"
                    />
                    <Popup
                        maxWidth="sm"
                        fullWidth={true}
                        title="Pls map the column headers from your sheet & Meepl Fields"
                        openPopup={file}
                        close={false}
                        setOpenPopup={setOpenBulk}
                    >
                        <Box>
                            <Box sx={{ m: 1 }}>
                                <FormControl fullWidth>
                                    <SelectableSearch
                                        isOptionEqualToValue={(option, value) =>
                                            +option.template_id === +value.template_id
                                        }
                                        label="Select from Saved Templates"
                                        fullWidth
                                        value={inputData?.template_id}
                                        loading={() => handleTemplate()}
                                        fieldLabel="template_name"
                                        required={false}
                                        variantType={"standard"}
                                        name="template_id"
                                        size="small"
                                        onChangeEvent={handleInput}
                                        data={templateData}
                                    />
                                </FormControl>
                            </Box>
                            <Box
                                style={{
                                    display: "grid",
                                    gridTemplateColumns: "1fr 1fr",
                                    alignItems: "center",
                                    padding: 5,
                                    borderBottom: "1px solid gray",
                                }}
                            >
                                <Typography variant="h6" style={{ textAlign: "center" }}>
                                    Imported Headers
                                </Typography>
                                <Typography variant="h6" style={{ textAlign: "center" }}>
                                    Meepl Fields
                                </Typography>
                            </Box>
                            {isAdvancedTemplate && (
                                <>
                                    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mt: 2 }}>
                                        <Typography variant="h6">Earnings Components</Typography>
                                        <Tooltip title="Add Earning">
                                            <IconButton className="mpl-primary-btn" onClick={handleAddEarningFields}>
                                                <AddIcon fontSize="small" />
                                            </IconButton>
                                        </Tooltip>
                                    </Box>

                                    {templateEarningData.map((earning, index) => (
                                        <Grid container spacing={2} key={index} alignItems="center" sx={{ mt: 1 }}>
                                            <Grid item xs={5}>
                                                <FormControl fullWidth variant="standard">
                                                    <Select
                                                        value={excelDbCol[index]?.excel || ""}
                                                        onChange={(e) => handleEarningColumnChange(index, e.target.value)}
                                                        fullWidth
                                                    >
                                                        {rowHeaders.map((header) => (
                                                            <MenuItem key={header} value={header}>
                                                                {header}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={5}>
                                                <FormControl fullWidth sx={{ minHeight: "40px" }}>
                                                    <SelectableSearch
                                                        isOptionEqualToValue={(option, value) => option.label === value.label}
                                                        value={earning?.earning_key}
                                                        loading={() => handleEarnings()}
                                                        fieldLabel="label"
                                                        required={false}
                                                        variantType="standard"
                                                        name="earning_key"
                                                        size="small"
                                                        onChangeEvent={(e, newValue) => handleEarningChange("earning_key", newValue, index)}
                                                        data={{ load: earningData.load, values: getFilteredEarnings(index) }}
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <IconButton color="error" onClick={() => handleEarningDelete(index)}>
                                                    <DeleteIcon fontSize="small" />
                                                </IconButton>
                                            </Grid>
                                        </Grid>
                                    ))}
                                </>
                            )}
                            {excelDbColMapping.map((col) => (
                                <Grid
                                    key={col.db}
                                    container
                                    spacing={2}
                                    style={{
                                        padding: 5,
                                        alignItems: "center",
                                    }}
                                >
                                    <Grid sx={{ mt: 2 }} item xs={6} container alignItems="center" spacing={1}>
                                        <Grid item xs>
                                            <Select
                                                onChange={(e) => setColMapping(e.target.value, col.db)}
                                                value={col.excel}
                                                fullWidth
                                                required={col.required}
                                                variant="standard"
                                            >
                                                {rowHeaders.map((header) => (
                                                    <MenuItem key={header} value={header}>
                                                        {header}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </Grid>
                                        {col.excel && (
                                            <Grid item>
                                                <IconButton onClick={() => clearColMapping(col.db)}>
                                                    <CloseIcon />
                                                </IconButton>
                                            </Grid>
                                        )}
                                    </Grid>
                                    <Grid sx={{ mt: 2 }} item xs={6}>
                                        <Typography>
                                            {col.label || col.db}
                                            {col.required && " *"}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            ))}
                        </Box>
                        <Box sx={{ display: "flex", justifyContent: "space-between", m: 2 }}>
                            <Button
                                onClick={() => {
                                    setFile(null);
                                    setRowHeaders([]);
                                    setOpenBulk(false);
                                }}
                                className="mpl-primary-btn"
                                disabled={uploadProgress > 0 && uploadProgress < 100}
                            >
                                Close
                            </Button>
                            <div>
                                {uploadProgress > 0 && uploadProgress < 100 ? (
                                    <CircularProgress disableShrink />
                                ) : (
                                    <Button
                                        variant="contained"
                                        type="submit"
                                        className="mpl-primary-btn"
                                        loading={uploadProgress > 0 && uploadProgress < 100}
                                        onClick={addPayrollStage}
                                    >
                                        Upload
                                    </Button>
                                )}
                            </div>
                        </Box>
                    </Popup>
                    <Popup
                        maxWidth="xs"
                        fullWidth={true}
                        title="Attention Needed!"
                        openPopup={showConfirmPopup}
                        close={false}
                        setOpenPopup={setShowConfirmPopup}
                    >
                        <Typography variant="h5"> You will lose the imported data. Do you want to submit it before proceeding? </Typography>
                        <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
                            <Button
                                onClick={handleCancel}
                                className="mpl-primary-btn"
                            >
                                No
                            </Button>

                            <Button
                                onClick={() => {
                                    setShowConfirmPopup(false); // Close popup and retain existing data
                                }}
                                className="mpl-primary-btn"
                            >
                                Yes
                            </Button>

                        </Box>
                    </Popup>
                    <Popup
                        title={"Edit Employee"}
                        openPopup={openPopup}
                        setOpenPopup={setOpenPopup}
                    >
                        <form>
                            <Grid container spacing={2}>
                                {excelDbColMapping.map((field) => {
                                    if (!editedRecord?.hasOwnProperty(field.db)) return null; // Skip unmapped fields
                                    return (
                                        <Grid item xs={12} key={field.db}>
                                            <FormControl fullWidth>
                                                {!["esic", "pt", "pf", "overtime_applicable_flag"].includes(field.db) && (
                                                    <Typography variant="subtitle2" gutterBottom>
                                                        {field.label}
                                                    </Typography>
                                                )}
                                                {["esic", "pt", "pf", "overtime_applicable_flag"].includes(field.db) ? (
                                                    <FormControlLabel
                                                        label={field.label}
                                                        control={
                                                            <Checkbox
                                                                checked={!!editedRecord[field.db]} // Ensure boolean value
                                                                onChange={(e) => {
                                                                    setEditedRecord((prev) => ({
                                                                        ...prev,
                                                                        [field.db]: e.target.checked,
                                                                    }));
                                                                }}

                                                            />
                                                        }
                                                    />
                                                ) : (
                                                    <TextField
                                                        size="small"
                                                        type={["previous_employee_id"].includes(field.db) ? "text" : "number"}
                                                        variant="standard"
                                                        value={editedRecord[field.db] ?? ""}
                                                        onChange={(e) => {
                                                            let value = e.target.value;
                                                            const isNumberField = !["previous_employee_id"].includes(field.db);
                                                            if (isNumberField) {
                                                                const isValid = /^(\d+)?([.]?\d{0,2})?$/.test(value);
                                                                if (!isValid) return;

                                                                const parts = value.split('.');
                                                                if (parts.length > 1) {
                                                                    value = `${parts[0]}.${parts[1].slice(0, 2)}`;
                                                                }
                                                            }
                                                            setEditedRecord(prev => ({
                                                                ...prev,
                                                                [field.db]: value
                                                            }));
                                                        }}
                                                        placeholder={`Enter ${field.label}`}
                                                        error={Boolean(editerrors[field.db])}
                                                        helperText={editerrors[field.db] || ""}
                                                    />
                                                )}
                                            </FormControl>
                                        </Grid>
                                    );
                                })}
                                {isAdvancedTemplate &&
                                    Array.isArray(editedRecord?.earning_component_json) &&
                                    editedRecord?.earning_component_json.map((earning, index) => (
                                        <Grid item xs={12} key={`earning-${index}`}>
                                            <TextField
                                                label={earning.label}
                                                value={earning.value || ""}
                                                onChange={(e) => {
                                                    const updatedValue = e.target.value;
                                                    setEditedRecord((prev) => {
                                                        const updatedEarnings = [...prev.earning_component_json];
                                                        updatedEarnings[index] = { ...updatedEarnings[index], value: updatedValue };
                                                        return { ...prev, earning_component_json: updatedEarnings };
                                                    });
                                                }}
                                                fullWidth
                                                variant="standard"
                                                type="number"
                                            />
                                        </Grid>
                                    ))}
                            </Grid>
                            <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
                                <Button
                                    variant="outlined"
                                    onClick={() => setOpenPopup(false)}
                                    className="mpl-secondary-btn"
                                >
                                    Cancel
                                </Button>
                                <Button
                                    variant="contained"
                                    onClick={() => handleEditSubmit(editedRecord)}
                                    className="mpl-primary-btn"
                                >
                                    Save
                                </Button>
                            </Box>
                        </form>
                    </Popup>
                    <ConfirmationDialog
                        title="Do you wish to proceed?"
                        openDialog={openDialog}
                        closeDialog={setOpenDialog}
                        OkButtonText="Delete"
                        onSubmit={handleDelete}
                    />
                </Box>
            </Base>
        </>
    );
};

export default PayrollBulkUpload;