import { Box, FormControl, Grid } from "@mui/material";
import MTable from "material-table";
import SelectableSearch from "../../../Base/views/SelectableSearch";
import { useState } from "react";
import CommonDatePicker from "../../../Base/views/CommonDatePicker";
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { GetTargetAchievementAction } from "../../../../../store/ATS/Report/Action";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { ConvertDateTime, getUserData } from "../../../Base/helper/baseFunctions";
import CustomPagination from "../../../Base/views/CustomPagination";
const AtsTargetAchievementReports = () => {
    const user = getUserData();
    const dispatch = useDispatch();
    const date = new Date();
    const default_date = `${date?.getFullYear() + '-' + ("0" + (date?.getMonth() + 1)).slice(-2) + '-' + ("0" + date?.getDate()).slice(-2)}`;
    const isGroupOrgFlowTrue = user?.grouporg_flow === true;
    const [userData, setUserData] = useState({ load: true, values: [] })
    const [selectedRows, setSelectedRows] = useState([]);
    const [filters, setFilters] = useState({ primary_recruiter_filter: "", from_date: default_date, to_date: default_date });
    const { atsTargetAchievement } = useSelector(state => state.AtsReducer?.AtsReportReducer);
    const { atsRecruiterData } = useSelector(state => state.AtsReducer?.AtsReportReducer);
    const parseAndSum = (field) => atsTargetAchievement?.reduce((acc, curr) => acc + parseInt(curr[field] || '0', 10), 0);
    const sumshortlisted = parseAndSum('shortlisted');
    const sumrejected = parseAndSum('rejected');
    const suminterviews_scheduled = parseAndSum('interviews_scheduled');
    const sumselected = parseAndSum('selected');
    const hasNonZeroSum = sumshortlisted || sumrejected || suminterviews_scheduled || sumselected;
    const displayableKeys = hasNonZeroSum ? [
        ["Candidates Proposed", sumshortlisted],
        ["Candidates Rejected", sumrejected],
        ["Candidates Interviewed", suminterviews_scheduled],
        ["Candidates Selected", sumselected],
    ] : [];

    useEffect(() => {
        dispatch(GetTargetAchievementAction({
            from_date: filters?.from_date,
            to_date: filters?.to_date,
            primary_recruiter: filters?.primary_recruiter_filter ? `{${filters?.primary_recruiter_filter?.ats_users_id}}` : null,
        }))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters])

    const handleFilter = (event, value) => {
        if (["primary_recruiter_filter"].includes(event)) {
            if (value) setFilters({ ...filters, [event]: value })
            else setFilters({ ...filters, [event]: "" })
        }
        if (["from_date", "to_date"].includes(event?.target?.name)) {
            setFilters({ ...filters, [event?.target?.name]: event?.target?.value })
        }
    }
    const handleExportSelected = () => {
        const headers = [
            { label: "Date", key: "modified_date" },
            { label: "Recruiter", key: "recruiter_name" },
            { label: isGroupOrgFlowTrue ? "Group Org" : "Client", key: "company_name" },
            { label: "Job Title", key: "job_title" },
            { label: "Candidates Proposed", key: "shortlisted" },
            { label: "Candidates Rejected", key: "rejected" },
            { label: "Candidates Interviewed", key: "interviews_scheduled" },
            { label: "Candidates Selected", key: "selected" }
        ];
        const csvData = [headers.map(header => header.label)];
        selectedRows.forEach(row => {
            const rowData = headers.map(header => row[header.key]);
            csvData.push(rowData);
        });
        const csv = csvData.map(row => row.join(",")).join("\n");
        const blob = new Blob([csv], { type: "text/csv" });
        const url = URL.createObjectURL(blob);
        const downloadLink = document.createElement("a");
        downloadLink.href = url;
        downloadLink.download = "Achievement.csv";
        downloadLink.setAttribute("target", "_blank");
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
        URL.revokeObjectURL(url);
    };
    return (
        <>
            <Box sx={{ mt: 1 }}>
                <Grid container columnSpacing={1} mb={1}>
                    <Grid key="primary_recruiter_id" item xs={12} sm={5}>
                        <SelectableSearch
                            isOptionEqualToValue={(option, value) =>
                                +option.ats_users_id === +value.ats_users_id
                            }
                            label="Recruiter"
                            value={filters.primary_recruiter_filter}
                            loading={() => setUserData({ load: false, values: atsRecruiterData })}
                            fieldLabel="first_name"
                            required={false}
                            grouping={true}
                            variant={"outlined"}
                            name="primary_recruiter_filter"
                            onChangeEvent={handleFilter}
                            data={userData}
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <FormControl fullWidth>
                            <CommonDatePicker
                                name="from_date"
                                label="From Date"
                                pickerType="date"
                                onKeyDown={(e) => e.preventDefault()}
                                onKeyPress={(e) => e.preventDefault()}
                                pickerFormat="dd/MM/yyyy"
                                DefaultDTvalue={filters.from_date}
                                maxDate={new Date()}
                                handleChange={(value) =>
                                    handleFilter({
                                        target: {
                                            name: "from_date",
                                            value: value
                                                ? `${value?.getFullYear() +
                                                "-" +
                                                ("0" + (value?.getMonth() + 1)).slice(-2) +
                                                "-" +
                                                ("0" + value?.getDate()).slice(-2)
                                                }`
                                                : "",
                                        },
                                    })
                                }
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <FormControl fullWidth>
                            <CommonDatePicker
                                name="to_date"
                                label="To Date"
                                pickerType="date"
                                onKeyDown={(e) => e.preventDefault()}
                                onKeyPress={(e) => e.preventDefault()}
                                pickerFormat="dd/MM/yyyy"
                                DefaultDTvalue={filters.to_date}
                                maxDate={new Date()}
                                minDate={filters.from_date || null}
                                handleChange={(value) =>
                                    handleFilter({
                                        target: {
                                            name: "to_date",
                                            value: value
                                                ? `${value?.getFullYear() +
                                                "-" +
                                                ("0" + (value?.getMonth() + 1)).slice(-2) +
                                                "-" +
                                                ("0" + value?.getDate()).slice(-2)
                                                }`
                                                : "",
                                        },
                                    })
                                }
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </Box>
            <Box>
                <MTable
                    columns={[
                        { title: "Date", cellStyle: { padding: "10px", textAlign: "left" }, headerStyle: { padding: "10px", textAlign: "left" }, field: "modified_date", editable: "never", render: rowData => { return rowData?.modified_date ? ConvertDateTime(rowData?.modified_date) : "    " } },
                        { title: "Recruiter", cellStyle: { padding: "10px", textAlign: "left" }, headerStyle: { padding: "10px", textAlign: "left" }, field: "recruiter_name", editable: "never" },
                        { title: isGroupOrgFlowTrue ? "Group Org" : "Client", cellStyle: { padding: "10px", textAlign: "left" }, headerStyle: { padding: "10px", textAlign: "left" }, field: "company_name", editable: "never" },
                        { title: "Job Title", cellStyle: { padding: "10px", textAlign: "center" }, headerStyle: { padding: "10px", textAlign: "center" }, field: "job_title", editable: "never" },
                        { title: "Candidates Proposed", cellStyle: { padding: "10px", textAlign: "center" }, headerStyle: { padding: "10px", textAlign: "center" }, field: "shortlisted", editable: "never" },
                        { title: "Candidates Rejected", cellStyle: { padding: "10px", textAlign: "center" }, headerStyle: { padding: "10px", textAlign: "center" }, field: "rejected", editable: "never" },
                        { title: "Candidates Interviewed", cellStyle: { padding: "10px", textAlign: "center" }, headerStyle: { padding: "10px", textAlign: "center" }, field: "interviews_scheduled", editable: "never" },
                        { title: "Candidates Selected", cellStyle: { padding: "10px", textAlign: "center" }, headerStyle: { padding: "10px", textAlign: "center" }, field: "selected", editable: "never" },
                    ]}

                    data={atsTargetAchievement || []}
                    options={{
                        maxBodyHeight: "54vh",
                        minBodyHeight: "54vh",
                        sorting: true,
                        search: true,
                        searchFieldAlignment: "right",
                        searchAutoFocus: true,
                        searchFieldVariant: "standard",
                        filtering: false,
                        exportButton: false,
                        exportAllData: true,
                        paging: true,
                        pageSizeOptions: [25, 50],
                        pageSize: 25,
                        showTextRowsSelected: true,
                        selectionProps: rowData => ({
                            disabled: [null, '', 0, '0'].includes(rowData.commission_percentage),
                        }),
                        showSelectAllCheckbox: true,
                        selection: true,
                        actionsColumnIndex: -1,
                        grouping: false,
                        addRowPosition: "first",
                        columnsButton: false,
                        draggable: false
                    }}
                    actions={[
                        {
                            icon: () => <CloudDownloadIcon />,
                            tooltip: 'Export Selected Rows',
                            onClick: () => handleExportSelected(),
                            hidden: selectedRows.length === 0,
                        },
                    ]}
                    components={{
                        Pagination: props => <CustomPagination {...props} displayableKeys={displayableKeys} />
                    }}
                    onSelectionChange={(rows) => setSelectedRows(rows)}
                    title="Achievement"
                />
            </Box>
        </>
    )
}

export default AtsTargetAchievementReports;