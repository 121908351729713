import { Button, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { grey } from '@mui/material/colors';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { MeeplAdminAddUserAction, MeeplAdminUpdateUserAction } from '../../../../../store/MeeplAdmin/Users/Action';
import Popup from '../../../Base/views/Popup';
import PhoneInput from 'react-phone-input-2';
import { getSessionData } from '../../../Base/helper/baseHelper';


const AddAdminUserPopup = ({ setOpenPopup, currentData }) => {
    const [errors, setErrors] = useState({})
    const dispatch = useDispatch();
    let data = atob(sessionStorage.getItem("user_type"));
    const region = getSessionData({ key: "region" });
    const initialCountryCode = region === "au" ? "61" : region === "nz" ? "64" : "91";
    const [adminUserData, setAdminUserData] = useState({
        first_name: "",
        email_id: "",
        user_type: "",
        contact_no: "",
        last_name: "",
        country_code: initialCountryCode
    })
    const currentDataAvail = currentData && Object.keys(currentData).length > 0
    useEffect(() => {
        currentDataAvail && setAdminUserData(currentData)
        // eslint-disable-next-line
    }, []);

    const validate = (fieldValues) => {
        let temp = { ...errors };
        if ("first_name" in fieldValues) {
            temp.first_name = (fieldValues.first_name?.trim() === "" || fieldValues.first_name?.trim() === null) ? "First Name is required" : "";
        }
        if ("user_type" in fieldValues) {
            temp.user_type = (fieldValues.user_type === "" || fieldValues.user_type === null) ? "User Type is required" : "";
        }
        if ("email_id" in fieldValues) {
            temp.email_id =
                (fieldValues.email_id === "" || fieldValues.email_id === null)
                    ? "Email is required"
                    : /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(fieldValues.email_id)
                        ? ""
                        : "Invalid Email.";
        }
        if ("contact_no" in fieldValues) {
            temp.contact_no = (fieldValues.contact_no?.trim() === "" || fieldValues.contact_no?.trim() === null) ? "Contact Number is required" : "";
        }
        setErrors({
            ...temp,
        });
        return Object.values(temp).every((x) => x === "");
    };

    const handleInputChange = (event,value,name) => {
        if (name === "contact_no") {
            let splitMobile = event?.split(value?.dialCode);
            setAdminUserData({
                ...adminUserData,
                country_code: value?.dialCode,
                contact_no: splitMobile?.[1] || "" 
            })
            Object.values?.(errors)?.find((res) => res !== "")?.length > 0 &&
            validate({ ...adminUserData, [event]: value ? value : "" });
        }
        else {
            setAdminUserData({ ...adminUserData, [event.target.name]: event.target.value })
            Object.values?.(errors)?.find((res) => res !== "")?.length > 0 &&
            validate({ ...adminUserData, [event.target.name]: event.target.value });
        }
    }

    const HandleSubmit = () => {

        if (validate(adminUserData)) {
            if (currentData?.ats_users_id) dispatch(MeeplAdminUpdateUserAction(adminUserData))
            else dispatch(MeeplAdminAddUserAction(adminUserData))
            setOpenPopup(false);
        }
    }

    return (
        <>
            <Popup
                title={currentDataAvail ? "Edit User" : "Add User"}
                openPopup={true}
                setOpenPopup={setOpenPopup}
                isMasterProduct={true}
            >
                <Grid container>
                    <Grid key="user_type" item xs={12} md={6} sx={{ marginTop: "1rem", paddingRight: "15px", paddingLeft: "15px" }}>
                        <FormControl required fullWidth error={errors?.user_type ? true : false} size="small">
                            <InputLabel id="recruiter-type-select-label">User Type</InputLabel>
                            <Select
                                name='user_type'
                                labelId="recruiter-type-select-label"
                                id="recruiter-type-select"
                                value={adminUserData?.user_type}
                                label="User Type"
                                onChange={handleInputChange}
                            >
                                <MenuItem value={"Owner"}>Owner</MenuItem>
                                <MenuItem value={"Admin"}>Admin</MenuItem>
                            </Select>
                            <FormHelperText>{errors?.user_type}</FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid key="email_id" item xs={12} md={6} sx={{ marginTop: "1rem", paddingRight: "15px", paddingLeft: "15px" }}>
                        <FormControl fullWidth>
                            <TextField
                                size="small"
                                required
                                value={adminUserData.email_id}
                                onChange={handleInputChange}
                                variant="outlined"
                                fullWidth
                                id="email_id"
                                label="Email"
                                name="email_id"
                                disabled={adminUserData?.ats_users_id}
                                onKeyPress={(e) => e.target.value?.length >= 254 && e.preventDefault()}
                                autoComplete="email_id"
                                {...(errors.email_id && {
                                    error: true,
                                    helperText: errors.email_id,
                                })}
                            />
                        </FormControl>
                    </Grid>
                    <Grid key="first_name" item xs={12} md={6} sx={{ marginTop: "1rem", paddingRight: "15px", paddingLeft: "15px" }}>
                        <FormControl fullWidth >
                            <TextField
                                required
                                size="small"
                                value={adminUserData.first_name}
                                onChange={handleInputChange}
                                variant="outlined"
                                fullWidth
                                id="first_name"
                                label="First Name"
                                name="first_name"
                                onKeyPress={(e) => e.target.value?.length >= 35 && e.preventDefault()}
                                onPaste={(e) => e.target.value?.length >= 35 && e.preventDefault()}
                                autoComplete="first_name"
                                {...(errors.first_name && {
                                    error: true,
                                    helperText: errors.first_name,
                                })}
                            />
                        </FormControl>
                    </Grid>
                    <Grid key="last_name" item xs={12} md={6} sx={{ marginTop: "1rem", paddingRight: "15px", paddingLeft: "15px" }}>
                        <FormControl fullWidth >
                            <TextField
                                size="small"
                                value={adminUserData.last_name}
                                onChange={handleInputChange}
                                variant="outlined"
                                fullWidth
                                id="last_name"
                                onKeyPress={(e) => e.target.value?.length >= 35 && e.preventDefault()}
                                label="Last Name"
                                name="last_name"
                                autoComplete="last_name"
                            />
                        </FormControl>
                    </Grid>
                    <Grid key="contact_no" item xs={12} md={6} sx={{ marginTop: "1rem", paddingRight: "15px", paddingLeft: "15px" }}>
                    <FormControl fullWidth error={Boolean(errors?.contact_no)}>
                        <PhoneInput
                            inputProps={{
                                name: "contact_no",
                                required: true,
                                autoFocus: true,
                            }}
                            country={region === "au" ? "au" : region === "nz" ? "nz" : "in"}
                            onlyCountries={region === "au" ? ["au"] : region === "nz" ? ["nz"] : []}
                            value={`${adminUserData.country_code}${adminUserData.contact_no}`}
                            onChange={(e, phone) => handleInputChange(e, phone, "contact_no")}
                            inputStyle={{
                                height: "35px",
                                width: "100%",
                                paddingLeft: "45px",
                                borderRightRadius: "8px",
                                border: errors?.contact_no ? '1px solid red' : '1px solid #ccc',
                            }}
                        />
                        <FormHelperText>{errors?.contact_no}</FormHelperText>
                    </FormControl>
                    </Grid>


                    <Grid container sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        mt: -2
                    }}>
                        <Button
                            sx={{ ":hover": { color: grey[50] }, marginTop: "1.75rem", marginRight: "1rem" }}
                            variant="outlined"
                            className="mpl-secondary-btn"
                            onClick={() => setOpenPopup(false)}
                        >
                            cancel
                        </Button>
                        <Button
                            onClick={HandleSubmit}
                            variant="contained"
                            color="primary"
                            type="submit"
                            className="mpl-primary-btn"
                            sx={{ marginTop: "1.75rem", marginRight: "1rem" }}
                            disabled={data !== "Owner"}
                        >
                            Save
                        </Button>
                    </Grid>
                </Grid>
            </Popup>
        </>
    )
}

export default AddAdminUserPopup;