import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  ClickAwayListener,
  Divider,
  Drawer,
  Fade,
  Grid,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Modal,
  Pagination,
  Select,
  Skeleton,
  Snackbar,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import { ValidateAtsCompConsulStatusHelper } from "../../helper/AtsUserHelper";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import LibraryAddCheckOutlinedIcon from "@mui/icons-material/LibraryAddCheckOutlined";
import LibraryAddCheckIcon from "@mui/icons-material/LibraryAddCheck";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import EditIcon from "@mui/icons-material/Edit";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import ArchiveIcon from "@mui/icons-material/Archive";
import RestoreIcon from '@mui/icons-material/Restore';
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import PersonIcon from "@mui/icons-material/Person";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import VisibilityIcon from "@mui/icons-material/Visibility";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import DeleteIcon from "@mui/icons-material/Delete";
import SearchIcon from '@mui/icons-material/Search';
import ConfirmationDialog from "../../../Base/views/ConfirmationDialog";
import AtsProposeCandidateForm from "./AtsProposeCandidateForm";
import { NoData } from "../../../Base/views/NotAuthorized";
import AtsJobPostForm from "./AtsJobPostForm";
import { toast } from "react-toastify";
import AtsSuggestCandidates from "./AtsSuggestCandidates";
import AtsJobPostFilter from "./AtsJobPostFilter";
import { AddAtsJobPostFillterAction, GetAtsJobPostAction } from "../../../../../store/ATS/JobPost/Action";
import { BASE_URL } from "../../../../Constants/baseConstant";
import { getUserData } from "../../../Base/helper/baseFunctions";
import { PRIMARY } from "../../../theme/palette";
import { PowerUserDisabledAction } from "../../../../../store/RoleAccess/Permission/Action";
import { UpdateAtsJobPostStatusHelper } from "../../helper/JobPostHelper";
import { QRCodeCanvas } from "qrcode.react";
import ShareIcon from '@mui/icons-material/Share';
import GetAppIcon from '@mui/icons-material/GetApp';
import CloseIcon from '@mui/icons-material/Close';
import { GetAtsJobFilterAdminAction, GetAtsJobFilterCompanyAction, GetAtsJobFilterConsultancyAction, GetAtsJobFilterInternalAction,GetAtsJobFilterGroupOrgAction} from "../../../../../store/ATS/JobFilter/Action";
const AtsJobPost = ({ setCurrentMode, setCurrentData, currentData, archived, setArchived }) => {
  const dispatch = useDispatch();
  const userData = getUserData();
  const { atsJobPostData, isLoading,updateSuccess, atsJobPostMode, currentJobData } = useSelector(
    (state) => state.AtsReducer?.AtsJobPostReducer
  );
  const { featuresData } = useSelector(state => state?.RoleAndAccessReducer?.ATSModuleFeaturesReducer)
  const { atsCompanyData } = useSelector(state => state.AtsReducer?.AtsCompanyReducer);
  const { atsJobFilterData } = useSelector(state => state.AtsReducer?.AtsJobPostReducer);
  const [drawerType, setDrawerType] = useState();
  const [anchor, setAnchor] = useState(false);
  const [anchorE, setAnchorE] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [openInactiveDialog, setOpenInactiveDialog] = useState(false);
  const [openActiveDialog,setOpenActiveDialog] = useState(false);
  const [selected, setSelected] = useState([]);
  // Filter
  const [filterApplied, setFilterApplied] = useState(false);
  const [sortApplied, setSortApplied] = useState(false)
  const [companyValue, setCompanyValue] = useState(atsJobFilterData?.company_data || []);
  const [consultancyValue, setConsultancyValue] = useState(atsJobFilterData?.consultancy_data || []);
  const [primaryRecruiterValue, setPrimaryRecruiterValue] = useState(atsJobFilterData?.primary_recruiter || []);
  const [secondaryRecruiterValue, setSecondaryRecruiterValue] = useState(atsJobFilterData?.secondary_recruiter || []);
  const [grouporganisationValue, setgrouporganisationValue] = useState(atsJobFilterData?.grouporg || []);
  const [severityValue, setSeverityValue] = useState(atsJobFilterData?.severity_data || []);
  const [searchValue, setSearchValue] = useState("");
  const [debouncedSearchValue, setDebouncedSearchValue] = useState(searchValue);
  const [page, setPage] = useState(1);
  const [sortValue, setSortValue] = useState({
    value:  atsJobFilterData?.sort_data?.order || false,
    name: atsJobFilterData?.sort_data?.column || false,
    label: atsJobFilterData?.sort_data?.label || false,
    "Remaining Positions": false,
    order:  atsJobFilterData?.sort_data?.order || false,
  });
  const [jobPostFormMode, setJobPostFormMode] = useState("Card");
  const [copyLink, setCopyLink] = useState(false);
  const [suggestCandidates, setsuggestCandidates] = useState(false);
  const [company_full_name, setcompany_full_name] = useState("");
  const [tabTypeIndex, setTabTypeIndex] = useState(0);
  const [search, setSearch] = useState(false);
  const isGroupOrgFlowTrue = userData?.grouporg_flow === true;
  const isButtonDisabled = selected.length === 0;
  
  const [selectedOption, setSelectedOption] = useState(""); 
  const [isQRModalOpen, setQRModalOpen] = useState(false);
  const [jobLink, setJobLink] = useState("https://meepl.day"); 
  const qrRef = useRef(null);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchValue(searchValue);
    }, 700); 

    return () => clearTimeout(handler);
  }, [searchValue]);

  useEffect(() => {
    const CompanyFullName = sessionStorage.getItem("company_full_name");
    setcompany_full_name(CompanyFullName || "NA");
    if (!archived) getJobData();
    JobFilters();
    setSelected([]);
    //eslint-disable-next-line
  }, [archived, debouncedSearchValue, sortValue,companyValue, consultancyValue, severityValue, primaryRecruiterValue, secondaryRecruiterValue,grouporganisationValue]);

  useEffect(()=>{
    if(updateSuccess===200 && (sortApplied || filterApplied)){
      getJobData()
    }
 }, [ updateSuccess])
  useEffect(() => {
    if(jobPostFormMode === "Card"){
    switch (userData?.user_type) {
      case "Owner":
      case "Admin":
        dispatch(GetAtsJobFilterAdminAction());
        break;
      case "Internal":
        dispatch(GetAtsJobFilterInternalAction());
        break;
      case "Consultancy":
        dispatch(GetAtsJobFilterConsultancyAction());
        break;
      case "Company":
        dispatch(GetAtsJobFilterCompanyAction());
        break;
      case "Group User":
        dispatch(GetAtsJobFilterGroupOrgAction());
        break;
      default:
        break;
    }
  }
  },[jobPostFormMode, dispatch, userData?.user_type]);
  useEffect(() => {
    if (atsJobPostData.length > 0) {
      if (atsJobPostMode === "edit") {
        setCurrentData(currentJobData);
        setCurrentMode(atsJobPostMode);
      } else {
        setCurrentMode("grid");
      }
    }
    //eslint-disable-next-line
  }, [atsJobPostData, atsJobPostMode, currentJobData]);

  const handleSearch = () => {
    setSearch(!search)
  }

  const handleDisabled = (permission) => {
    let value = featuresData?.find(item => item?.permission === permission)
    return value ? false : true
  }

  const construcData = (value, label, key) => {
    var temp = [];
    value?.forEach((res) => {
      temp.push({ [label]: res[key] });
    });
    return JSON.stringify(temp);
  };

  const JobFilters = (e) => {
    dispatch(AddAtsJobPostFillterAction({
      company_data: e?.companyValue === null ? null : companyValue?.length > 0 ? companyValue : null,
      consultancy_data: e?.consultancyValue === null ? null : consultancyValue?.length > 0 ? consultancyValue : null,
      severity_data: e?.severityValue === null ? null : severityValue?.length > 0 ? severityValue : null,
      primary_recruiter: e?.primaryRecruiterValue === null ? null : primaryRecruiterValue?.length > 0 ? primaryRecruiterValue : null,
      secondary_recruiter: e?.secondaryRecruiterValue === null ? null : secondaryRecruiterValue?.length > 0 ? secondaryRecruiterValue : null,
      grouporg_data: e?.grouporganisationValue === null ? null : grouporganisationValue?.length > 0 ? grouporganisationValue : null,
      search: e?.target?.value || null,
      filter_by: null,
      pagenumber: typeof e === "number" ? e : 1,
      pagesize: 12,
      sort_data: {
        column: sortValue?.name || null,
        order: sortValue?.order || null,
        label: sortValue?.label || null,
      },
    }))
  }
  const [currentTabType, setCurrentTabType] = useState(0);
  const getJobData = (e, sortby = false, tabType) => {
    if (
      companyValue?.length > 0 ||
      consultancyValue?.length > 0 ||
      severityValue?.length > 0 ||
      primaryRecruiterValue?.length > 0 ||
      secondaryRecruiterValue?.length > 0  ||
      grouporganisationValue?.length > 0
    )  
    setFilterApplied(true); 
    else setFilterApplied(false);
    const previousTabType = currentTabType;
    if (!tabType) {
      tabType = previousTabType;
    }
    if (previousTabType !== tabType) {
      e = 1;
      setCurrentTabType(tabType);
    }
    const tabIndex = tabTypes.indexOf(tabType); 
    if (tabIndex !== -1) {
      setTabTypeIndex(tabIndex);
    }
    dispatch(
      GetAtsJobPostAction({
        company_data:
          companyValue?.length > 0 ?
            construcData(companyValue, "ats_company_id", "ats_company_id") : null,
        consultancy_data:
          consultancyValue?.length > 0 ?
            construcData(consultancyValue, "consultancy_id", "consultancy_id") : null,
        severity_data:
          severityValue?.length > 0 ?
            construcData(severityValue, "severity", "priority") : null,
        primary_recruiter:
          primaryRecruiterValue?.length > 0 ?
            construcData(
              primaryRecruiterValue,
              "primary_recruiter_id",
              "recruiter_id"
            )
            : null,
        secondary_recruiter:
          secondaryRecruiterValue?.length > 0 ?
            construcData(
              secondaryRecruiterValue,
              "secondary_recruiter_id",
              "recruiter_id"
            )
            : null,
            grouporg_data:
            grouporganisationValue?.length > 0 ?
              construcData(
                grouporganisationValue,
                "ats_grouporg_id", 
                "ats_grouporg_id", 
              )
              : null,
        search: e?.target?.value || debouncedSearchValue,
        filter_by: null,
        pagenumber: typeof e === "number" ? e : 1,
        pagesize: 12,
        sort_column: sortby
          ? sortby?.name
          : sortValue?.name
            ? sortValue?.name
            : null,
        sort_order: sortby
          ? sortby?.value
          : sortValue?.order
            ? sortValue?.order
            : null,
        status: "Active",
        tab_type: tabType
      })
    );

  };

  const open1 = Boolean(anchorE);
  const toggleDrawer = (open) => {
    if (!open) setDrawerType("");
    setAnchor(open);
  };
  const handleCardClick = (e, value,tabTypeIndex) => {
    setCurrentData({
      ...value,
  });
  sessionStorage.setItem("tabTypeIndex",tabTypeIndex);
    setCurrentMode("edit");
  };
  const handleProposeCandidate = (value) => {
    setCurrentData(value);
    setDrawerType("proposeCandidate");
    toggleDrawer(true);
  };

  const handleInactiveClick = () =>{
    setAnchorE(null);
    setOpenInactiveDialog(true)
  }

  const handleArchiveClick = () => {
    setAnchorE(null);
    setOpenDialog(true);
  };
  const handleConfirmArchive = (status_val) => {
    UpdateAtsJobPostStatusHelper({
      update_id: currentData?.ats_jobpost_id,
      status: status_val,
    }).then((res) => {
      getJobData()
    });
    if(status_val === "Archived"){
      setOpenDialog(false);
    }
    else{
      setOpenInactiveDialog(false)
    }
  };
  const handleHorMorevertClick = (event, data) => {
    setCurrentData(data);
    setAnchorE(event.currentTarget);
  };
  const handlePreview = (e, job) => {
    setCurrentData(job)
    setAnchorE(null);
    // setOpenPreview(true);
    setJobPostFormMode("Preview");
  };
  const [currentjobData, setCurrentJobData] = useState([]);


  const handleActive = (data) => {
    setCurrentJobData(data);
    setOpenActiveDialog(true);
  };

  const handleConfirmEnable = async () => {
    try {
      if (currentjobData?.ats_company_id) {
        const res = await ValidateAtsCompConsulStatusHelper({
          company_id: currentjobData?.ats_company_id,
          consul_id: null,
        });

        if (res?.data?.statuscode === 200 && res?.data?.data?.[0].fn_ats_comp_consul_status !== "Inactive") {
          await UpdateAtsJobPostStatusHelper({
            update_id: currentjobData?.ats_jobpost_id,
            status: "Active",
          });
          await getJobData();
        } else {
          toast.dismiss();
          toast.error("Job is associated with an inactive Client. Please make the Client active first.");
        }
      } else {
        await UpdateAtsJobPostStatusHelper({
          update_id: currentjobData?.ats_jobpost_id,
          status: "Active",
        });
        await getJobData();
      }
    } catch (error) {
      toast.dismiss();
      toast.error("An error occurred. Please try again.");
    } finally {
      setOpenActiveDialog(false);
      setTabTypeIndex(0)
    }
  };
  const handleGenereatelink = (option) => {
    let arr = selected;
    let b = JSON.stringify(arr);
    const encodedData = btoa(b); // encode a string
    const id = userData?.user_type === "Consultancy" ? userData?.consultancy_id : userData?.user_type === "Company" ? userData?.ats_company_id : "0";
    setCopyLink(true);
    let job_token = `${id}=${userData?.domain}=${userData?.user_type}`;
    if(arr.length === 1){
      let id = arr[0];
      if (option === "qr") {
        return `${BASE_URL}/ats/career-portal-job/${btoa(id)}/${btoa(job_token)}`
      }
      else {
        navigator.clipboard.writeText(`${BASE_URL}/ats/career-portal-job/${btoa(id)}/${btoa(job_token)}`);
      }
    }
    else{
      if (option === "qr") {
        return `${BASE_URL}/ats/career-portal-jobs/${encodedData}/${btoa(job_token)}`
      }
      else {
        navigator.clipboard.writeText(`${BASE_URL}/ats/career-portal-jobs/${encodedData}/${btoa(job_token)}`);
      }
    }
  };
  const MultipleJobs = (id) => {
    if (selected.includes(id)) {
      // If it exists, remove it from the array
      setSelected(selected.filter((jobId) => jobId !== id));
    } else {
      // If it doesn't exist, push it into the array
      setSelected([...selected, id]);
    }
  };
  const handleEditClick = () => {
    setAnchorE(null);
    setJobPostFormMode("Edit");
    sessionStorage.setItem("tabTypeIndex",tabTypeIndex);
  };
  const handleCompanyChange = (data, event) => {
    if (event?.target?.checked) {
      const isDuplicate = companyValue.some(element => element.name === data.name);
      if (!isDuplicate) {
        setCompanyValue((res) => [...res, data]);
      }
      else {
        const updatedSeverityValue = companyValue.filter(element => element.name !== data.name);
        setCompanyValue(updatedSeverityValue);
      }
    } else {
        let comp = companyValue?.findIndex(
          (element) => element?.ats_company_id === data?.ats_company_id
        );
        companyValue.splice(comp, 1);
        setCompanyValue((res) => [...res]);
    }

  };

  const handleGrouporgChange = (data, event) => {
    if (event?.target?.checked) {
      const isDuplicate = grouporganisationValue.some(element => element.name === data.name);
      if (!isDuplicate) {
        setgrouporganisationValue((res) => [...res, data]);
      }
      else {
        const updatedSeverityValue = grouporganisationValue.filter(element => element.name !== data.name);
        setgrouporganisationValue(updatedSeverityValue);
      }
    } else {
    
        let comp = grouporganisationValue?.findIndex(
          (element) => element?.ats_grouporg_id === data?.ats_grouporg_id
        );
        grouporganisationValue.splice(comp, 1);
        setgrouporganisationValue((res) => [...res]);
      
    }

  };


  const handleConsultancyChange = (data, event) => {
    if (event?.target?.checked) {
      setConsultancyValue((res) => [...res, data]);
    } else {
      let cons = consultancyValue?.findIndex(
        (element) => element?.consultancy_id === data?.consultancy_id
      );
      consultancyValue.splice(cons, 1);
      setConsultancyValue((res) => [...res]);
    }

  };
  const handlePrimaryRecruiterChange = (
    data,
    event,
  ) => {
    if (event?.target?.checked) {
      if (data?.recruiter_filter) {
        const isDuplicate = primaryRecruiterValue.some(element => element.name === data.name);
        if (!isDuplicate) {
          setPrimaryRecruiterValue((res) => [...res, data?.recruiter_filter]);
        }
        else {
          const updatedSeverityValue = primaryRecruiterValue.filter(element => element.name !== data.name);
          setPrimaryRecruiterValue(updatedSeverityValue);
        }
      }
      else {
        setPrimaryRecruiterValue((res) => [...res, data]);
      }
    } else {
      let cons = primaryRecruiterValue?.findIndex(
        (element) => element?.recruiter_id === data?.recruiter_id
      );
      primaryRecruiterValue.splice(cons, 1);
      setPrimaryRecruiterValue((res) => [...res]);
    }

  };
  const handleSecondaryRecruiterChange = (
    data,
    event,
  ) => {
    if (event?.target?.checked) {
      setSecondaryRecruiterValue((res) => [...res, data]);
    } else {
      let pr = secondaryRecruiterValue?.findIndex(
        (element) => element?.recruiter_id === data?.recruiter_id
      );
      secondaryRecruiterValue.splice(pr, 1);
      setSecondaryRecruiterValue((res) => [...res]);
    }

  };

  const handleSeverityChange = (data, event) => {
    if (event?.target?.checked) {
      const isDuplicate = severityValue.some(element => element.priority === data.priority);
      if (!isDuplicate) {
        setSeverityValue((res) => [...res, data]);
      }
      else {
        const updatedSeverityValue = severityValue.filter(element => element.priority !== data.priority);
        setSeverityValue(updatedSeverityValue);
      }
    } else {
      let sr = severityValue?.findIndex(
        (element) => element?.priority === data?.priority
      );
      severityValue.splice(sr, 1);
      setSeverityValue((res) => [...res]);
    }

  };

  const handleClearFilter = () => {
    dispatch(AddAtsJobPostFillterAction({}))
    setFilterApplied(false);
    setCompanyValue([]);
    setSelected([]);
    setConsultancyValue([]);
    setSeverityValue([]);
    setSortValue([]);
    setPrimaryRecruiterValue([]);
    setSecondaryRecruiterValue([]);
    setgrouporganisationValue([]);
    setPage(1)
  };

  const handlePagination = (event, value) => {
    setPage(value)
    getJobData(value);
  };
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

 

  // Sort By
  const sortByData = [
    { value: "asc", name: "priority", label: "Priority: Low - High" },
    { value: "desc", name: "createdon", label: "Created on: Newest - Oldest" },
    { value: "asc", name: "createdon", label: "Created on: Oldest - Newest" },
    {
      value: "desc",
      name: "Remaining Positions",
      label: "Remaining Positions: High - Low",
    },
    {
      value: "asc",
      name: "Remaining Positions",
      label: "Remaining Positions: Low - High",
    },
  ];
  const [filter, setFilter] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const tabTypes = ["Active", "Inactive", "Archived"];
  const open = Boolean(anchorEl);

  const handleClearSort = () =>{
    setFilter("")
    setSortApplied(false)
    setSortValue(
      {
        value: false,
        name: false,
        label: false,
        "Remaining Positions": false,
        order: false,
      }
    )
  }
 
  const handleFilterClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleTabChange = (event, newValue) => {
    setTabTypeIndex(newValue);
    const tabType = tabTypes[newValue];
    getJobData(newValue, false, tabType);
  };
  const handleFilterClose = (event, name) => {
    setAnchorEl(null);
    if (name !== "backdropClick") {
      setFilter(name);
      var found = sortByData.find((e) => e.label === name);
      if (found) {
        getJobData(event, found);
        setSortApplied(true)
        setSortValue({ name: found?.name, order: found?.value, label: found?.label });
      }
    }
  };

  const CardFilter = (e, value) => {
    e.stopPropagation();
    if (['High', 'Medium', 'Low'].includes(value)) {
      handleSeverityChange({ 'priority': value }, { 'target': { 'checked': true } })
    }
    if (value?.primary_recruiter) {
      handlePrimaryRecruiterChange({ 'recruiter_filter': { 'recruiter': value?.primary_recruiter?.[0]?.recruiter, 'recruiter_id': `${value?.primary_recruiter?.[0]?.recruiter_id}` } }, { 'target': { 'checked': true } })
    }
    if (value?.company) {
      const company = value?.company
      if (company === "Internal Job") {
        setDebouncedSearchValue("internal")
      }
      const foundCompany = Object.values(atsCompanyData).find(obj => obj.name === company);
      if (foundCompany) {
        handleCompanyChange(foundCompany, { 'target': { 'checked': true } })
      } else {
        handleCompanyChange('', { 'target': { 'checked': false } })
      }
    }
  }
  

  const handleSelectionChange = (event) => {
    setSelectedOption(event.target.value);
    if (event.target.value === "qr") {
     const link = handleGenereatelink("qr");
      setJobLink(link)
      setQRModalOpen(true); 
      setSelectedOption("")
    } else if (event.target.value === "link") {
      handleGenereatelink()
      setSelectedOption("")
    }
  };

 // Download QR Code as an image
const downloadQR = () => {
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  const qrCanvas = qrRef.current.querySelector("canvas");
  if (!qrCanvas) return;

  const width = 250;
  const height = 320;
  const scaleFactor = 3; 
  canvas.width = width * scaleFactor;
  canvas.height = height * scaleFactor;
  ctx.scale(scaleFactor, scaleFactor);

  ctx.fillStyle = "white";
  ctx.fillRect(0, 0, width, height);
  ctx.fillStyle = "black";
  ctx.font = "18px Arial";
  ctx.textAlign = "center";
  ctx.fillText("Scan to view career portal", width / 2, 40);
  ctx.drawImage(qrCanvas, 25, 70, 200, 200);

  ctx.fillStyle = "grey";
  ctx.font = "bold 12px Arial";
  ctx.textAlign = "center";
  ctx.fillText("powered by Meepl", width / 2, 300);
  const url = canvas.toDataURL("image/png");
  const link = document.createElement("a");
  link.href = url;
  link.download = "career-portal-job.png";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};


  // Share QR Code using Web Share API
  const shareQR = async () => {
    if (!qrRef.current) return;
    const qrCanvas = qrRef.current.querySelector("canvas");
    if (!qrCanvas) return;
  
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    const width = 250;
    const height = 320;
    const scaleFactor = 3; 
    canvas.width = width * scaleFactor;
    canvas.height = height * scaleFactor;
    ctx.scale(scaleFactor, scaleFactor);
    ctx.fillStyle = "white";
    ctx.fillRect(0, 0, width, height);
    ctx.fillStyle = "black";
    ctx.font = "18px Arial";
    ctx.textAlign = "center";
    ctx.fillText("Scan to view career portal", width / 2, 40);
  
    ctx.drawImage(qrCanvas, 25, 70, 200, 200);

    ctx.fillStyle = "grey";
    ctx.font = "bold 12px Arial";
    ctx.textAlign = "center";
    ctx.fillText("powered by Meepl", width / 2, 300);
    
    canvas.toBlob(async (blob) => {
      if (!blob) return;
  
      const file = new File([blob], "career-portal-job.png", { type: "image/png" });
  
      if (navigator.canShare && navigator.canShare({ files: [file] })) {
        navigator.share({
          title: "Career Portal Job",
          text: "Scan to view career portal job",
          files: [file],
        });
      } else {
        toast.error("Sharing is not supported on this device.");
      }
    }, "image/png");
  };
  
  return (
    <>
      {archived ?<div></div>
        :
        <>
          <Snackbar
            open={copyLink}
            onClose={() => setCopyLink(false)}
            autoHideDuration={2000}
            message="Copied to clipboard"
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {jobPostFormMode === "Card" && (
              <Typography variant="h4">{company_full_name}</Typography>
            )}
          </Box>
          <>
            {suggestCandidates === true ?
              <AtsSuggestCandidates setJobPostFormMode={setJobPostFormMode} suggestCandidates={suggestCandidates} setsuggestCandidates={setsuggestCandidates} editedRecord={currentData} currentData={currentData} /> :
              ["Add", "Edit", "Preview"].includes(jobPostFormMode) ? (
                <AtsJobPostForm
                  canEdit={handleDisabled("job_post_edit_job")}
                  editedRecord={currentData}
                  jobPostFormMode={jobPostFormMode}
                  setJobPostFormMode={setJobPostFormMode}
                />
              ) : (
                <Box sx={{ maxHeight: "85vh", overflowY: "auto", pb: 2 }}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >

                    <Grid p={1.5} item md={6} sm={6}>
                      <Box sx={{ display: "flex" }}>
                          <Box sx={{ mb: -1 }}>
                            <Tabs
                            value={tabTypeIndex}
                            onChange={handleTabChange}
                            aria-label="basic tabs example"
                            sx={{ mb: 1 }}
                            >
                            <Tab label="Active" {...a11yProps(0)} />
                            <Tab label="Inactive" {...a11yProps(1)} />
                            <Tab label="Archive" {...a11yProps(2)} />
                          </Tabs>
                          </Box>

                          <>
                        <Box sx={{ mt: 0.16 }}>
                          {search ?
                            <>
                              <ClickAwayListener onClickAway={() => { searchValue === '' && handleSearch() }}>
                                <Fade in={search} >
                                  <TextField
                                    variant="outlined"
                                    type={"text"}
                                    value={searchValue}
                                    onChange={(e) => {setSearchValue(e.target.value) }}
                                    id="note"
                                    size="small"
                                    label="Search Jobs"
                                  />
                                </Fade>
                              </ClickAwayListener>
                            </>
                            :
                            <Tooltip title="Search Jobs">
                              <IconButton onClick={handleSearch}>
                                <SearchIcon />
                              </IconButton>
                            </Tooltip>
                          }
                        </Box></>
                      </Box>
                    </Grid>
                    <Grid
                      p={1.5}
                      item
                      md={6}
                      sm={6}
                      display="flex"
                      justifyContent={"flex-end"}
                    >
                      
                      {!isButtonDisabled && (
                        <Tooltip title={"Get Link for Multiple Job Posting"} placement="left-start">
                          <Typography
                            size="small"
                            variant="span"
                            sx={{ display: "inline-grid" }}
                            >
                            <Select
                              value={selectedOption}
                              size="small"
                              className="mpl-primary-btn"
                              onChange={handleSelectionChange}
                              displayEmpty
                              sx={{ width: "115px", height:"35px", 
                                    mr: "4px", fontWeight: "bold","& .MuiSelect-select": {
                                    padding: "2px",
                                    paddingLeft: "4px"
                                  },
                                 }}

                            >
                              <MenuItem value="" disabled>Link Option</MenuItem>
                              <MenuItem value="link">Get Link</MenuItem>
                              <MenuItem value="qr">Get QR Code</MenuItem>
                            </Select>
                          </Typography>
                        </Tooltip>
                      )}

                      <>
                      <Button
                        sx={{ mr: 2 }}
                        endIcon={<FilterListIcon />}
                        onClick={() => toggleDrawer(true)}
                      >
                        Filter
                      </Button>
                      <>
                      <Button onClick={handleFilterClick}>
                        Sort By : &nbsp;{" "}
                        { sortValue?.name &&(
                            <span
                              style={{
                                WebkitTapHighlightColor: "transparent",
                                color: "rgb(99, 115, 129)",
                              }}
                              >
                              {sortValue?.label}
                            </span>
                        )}{" "}
                        &nbsp;
                        {open && (
                          <KeyboardArrowUpIcon
                            fontSize="small"
                            sx={{ color: "rgb(99, 115, 129)" }}
                          />
                        )}
                        {!open && (
                          <KeyboardArrowDownIcon
                            fontSize="small"
                            sx={{ color: "rgb(99, 115, 129)" }}
                          />
                        )}
                      </Button>
                        { sortValue?.name && ( 
                          <Tooltip title="Clear Sort" >
                            <IconButton 
                              color='error'
                              variant="outlined"
                              onClick={handleClearSort}
                              >
                                <DeleteIcon fontSize="small" />
                            </IconButton>
                         </Tooltip>
                        )}
                        </>
                      </>

                      <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleFilterClose}
                        MenuListProps={{
                          "aria-labelledby": "basic-button",
                        }}
                        PaperProps={{}}
                        transformOrigin={{ horizontal: "right", vertical: "top" }}
                        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                      >
                        {sortByData.map((option, index) => (
                          <MenuItem
                            key={option?.label}
                            selected={sortValue.label === option?.label}
                            onClick={(event) =>
                              handleFilterClose(event, option?.label)
                            }
                          >
                            {option?.label}
                          </MenuItem>
                        ))}
                      </Menu>
                    </Grid>
                  </Grid>
                  {filterApplied && (
                    <>
                      <Typography>
                        {atsJobPostData?.[0]?.totalcount} Job Found
                      </Typography>
                      <AppliedFilter
                        companyValue={companyValue}
                        handleCompanyChange={handleCompanyChange}
                        grouporganisationValue={grouporganisationValue}
                        handleGrouporgChange={handleGrouporgChange}
                        consultancyValue={consultancyValue}
                        handleConsultancyChange={handleConsultancyChange}
                        severityValue={severityValue}
                        handleSeverityChange={handleSeverityChange}
                        handleClearFilter={handleClearFilter}
                        primaryRecruiterValue={primaryRecruiterValue}
                        secondaryRecruiterValue={secondaryRecruiterValue}
                        handlePrimaryRecruiterChange={handlePrimaryRecruiterChange}
                        JobFilters={JobFilters}
                        handleSecondaryRecruiterChange={
                          handleSecondaryRecruiterChange
                        }
                      />
                    </>
                  )}

                  {isLoading ? (
                    <Grid container direction="row" alignItems="center">
                      {Array(8)
                        .fill()
                        .map((data, index) => (
                          <Grid key={index} item p={1.5} lg={3} md={6} sm={6} xs={12}>
                            <Card>
                              <CardHeader
                                action={null}
                                title={
                                  <Skeleton
                                    animation="wave"
                                    height={10}
                                    width="80%"
                                    style={{ marginBottom: 6 }}
                                  />
                                }
                                subheader={
                                  <Skeleton
                                    animation="wave"
                                    height={10}
                                    width="40%"
                                    style={{ marginBottom: 6 }}
                                  />
                                }
                              />
                              <Skeleton
                                sx={{ height: 130 }}
                                animation="wave"
                                variant="rectangular"
                              />
                              <CardContent>
                                <>
                                  <Skeleton
                                    animation="wave"
                                    height={10}
                                    style={{ marginBottom: 6 }}
                                  />
                                  <Skeleton
                                    animation="wave"
                                    height={10}
                                    width="80%"
                                  />
                                </>
                              </CardContent>
                            </Card>
                          </Grid>
                        ))}
                    </Grid>
                  ) : atsJobPostData?.length > 0 ? (
                    <Grid container direction="row" alignItems="center">
                      {atsJobPostData?.map((job, index) => (
                        <Grid key={index} item p={1.5} lg={3} md={6} sm={6} xs={12}>
                          <Card key={index}>
                            <CardActionArea
                              sx={{ ":hover": { backgroundColor: "#f1e3f3" } }}
                              onClick={(e) => {
                                handleCardClick(e, job, tabTypeIndex)
                              }}
                            >
                              <CardHeader
                                sx={{ p: 2 }}
                                action={
                                  job?.priority && (
                                    <Tooltip title="Severity" onClick={(e) => CardFilter(e, job?.priority)}>
                                      {job?.priority === "High" ? (
                                        <Chip
                                          label="High"
                                          name="Severity"
                                          color="error"
                                          size="small"
                                          icon={<KeyboardArrowUpIcon />}
                                        />
                                      ) : job?.priority === "Low" ? (
                                        <Chip
                                          label="Low"
                                          name="Severity"
                                          color="info"
                                          size="small"
                                          icon={<KeyboardArrowDownIcon />}
                                        />
                                      ) : job?.priority === "Medium" ? (
                                        <Chip
                                          label="Medium"
                                          name="Severity"
                                          color="warning"
                                          size="small"
                                          icon={<DragHandleIcon />}
                                        />
                                      ) : (
                                        ""
                                      )}
                                    </Tooltip>
                                  )
                                }
                                title={
                                  <Tooltip title={job?.title}>
                                    <Typography className="truncate" variant="h6">
                                      {job?.title}
                                    </Typography>
                                  </Tooltip>
                                }
                                subheader={
                                  <Tooltip title="Job ID">
                                    <Typography
                                      variant="body2"
                                      color="text.secondary"
                                    >
                                      {job?.job_id}
                                    </Typography>
                                  </Tooltip>
                                }
                              />
                              <CardContent sx={{ padding: "0px 5px 10px 5px" }}>
                                <Grid container sx={{ textAlign: "center" }}>
                                  <Grid item xs={3} md={6} lg={3}>
                                    <Typography variant="h4">
                                      {job?.vaccancies || 0}
                                    </Typography>
                                    <Typography
                                      fontWeight={600}
                                      fontSize="11px"
                                    >
                                      Required
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={3} md={6} lg={3}>
                                    <Typography variant="h4">
                                      {job?.proposed_cand || 0}
                                    </Typography>
                                    <Typography
                                      fontWeight={600}
                                      fontSize="11px"
                                    >
                                      Proposed
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={3} md={6} lg={3}>
                                    <Typography variant="h4" >
                                      {job?.inprog_cand || 0}
                                    </Typography>
                                    <Typography
                                      fontWeight={600}
                                      fontSize="11px"
                                    >
                                      InProgress
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={3} md={6} lg={3}>
                                    <Typography variant="h4">
                                      {job?.selected_cand || 0}
                                    </Typography>
                                    <Typography
                                      fontWeight={600}
                                      fontSize="11px"
                                    >
                                      Selected
                                    </Typography>
                                  </Grid>
                                </Grid>
                                <Box
                                  mt={1}
                                  mr={1}
                                  display="flex"
                                  justifyContent="space-between"
                                >
                                  <Box
                                    style={{ display: "flex", alignItems: "center" }}
                                  >
                                    <AccountBalanceIcon fontSize="small" />
                                    <Tooltip
                                      title={
                                        job?.is_internal
                                          ? "Internal Job"
                                          :isGroupOrgFlowTrue?job?.grouporg :job?.company
                                      }
                                      onClick={(e) => CardFilter(e, { 'company': job?.is_internal ? "Internal Job" : job?.company })}
                                    >
                                      <Typography
                                        color="#637381"
                                        variant="body2"
                                        pl={1}
                                      >
                                        {job?.is_internal
                                          ? "Internal Job"
                                          :isGroupOrgFlowTrue?job?.grouporg :job?.company}
                                      </Typography>
                                    </Tooltip>
                                  </Box>
                                  {job?.primary_recruiter && (
                                    <Box
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <PersonIcon fontSize="small" />
                                      <Tooltip title={job?.primary_recruiter || ""}>
                                        <Typography
                                          className="truncate"
                                          color="#637381"
                                          variant="body2"
                                          pl={1}
                                        >
                                          {job?.primary_recruiter}
                                        </Typography>
                                      </Tooltip>
                                    </Box>
                                  )}
                                </Box>
                                {job?.precruiter && (
                                  <Box
                                    style={{
                                      display: "flex",
                                      alignItems: "flex-start",
                                    }}
                                  >
                                    <PersonIcon fontSize="small" sx={{ mt: 1 }} />
                                    <Tooltip onClick={(e) => CardFilter(e, { 'primary_recruiter': job?.precruiter })} title={"Primary Recruiters"}>
                                      <Typography
                                        color="#637381"
                                        variant="body2"
                                        sx={{ mt: 1 }}
                                        pl={1}
                                      >
                                        {job?.precruiter
                                          .map((row) => row.recruiter)
                                          ?.join(", ")}
                                      </Typography>
                                    </Tooltip>
                                  </Box>
                                )}
                              </CardContent>
                            </CardActionArea>
                            <Divider variant="middle" />
                            {(tabTypeIndex === 1 || tabTypeIndex === 0) ? (
                            <>
                                <CardActions sx={{ padding: "0px" }}>
                                    <Box
                                        sx={{
                                            padding: "0px 20px 8px 20px",
                                            display: "flex",
                                            WebkitBoxPack: "justify",
                                            justifyContent: "space-between",
                                            width: "100%",
                                        }}
                                    >
                                        <Tooltip title="More Options">
                                            <IconButton onClick={(event) => handleHorMorevertClick(event, job)}>
                                                <MoreHorizIcon fontSize="small" color="error" />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title={`${job?.make_public ? "Select for Multiple Job Posting" : "This job can't be shared"}`}>
                                            <IconButton
                                                fontSize="small"
                                                onClick={() => job?.make_public && MultipleJobs(job?.ats_jobpost_id)}
                                            >
                                                {selected.includes(job?.ats_jobpost_id) ? (
                                                    <LibraryAddCheckIcon fontSize="small" color="secondary" />
                                                ) : (
                                                    <LibraryAddCheckOutlinedIcon fontSize="small" color="primary" />
                                                )}
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Preview">
                                            <IconButton onClick={(e) => handlePreview(e, job)}>
                                                <VisibilityIcon fontSize="small" color="primary" />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Propose Candidate">
                                            <IconButton
                                                onClick={() => !handleDisabled("job_post_propose_candidate") ? dispatch(PowerUserDisabledAction()) : handleProposeCandidate(job)}
                                            >
                                                <PersonAddAlt1Icon fontSize="small" color="success" />
                                            </IconButton>
                                        </Tooltip>
                                    </Box>
                                </CardActions>
                            </>
                        ) : (
                            <>
                                <CardActions sx={{ padding: "0px" }}>
                                    <Box
                                        sx={{
                                            padding: "0px 20px 8px 20px",
                                            display: "flex",
                                            WebkitBoxPack: "justify",
                                            justifyContent: "center",
                                            width: "100%",
                                        }}
                                    >
                                        <Tooltip  title="You cannot enable the Job">
                                            <IconButton >
                                                <CheckCircleOutlineIcon color="grey" fontSize="small" />
                                            </IconButton>
                                        </Tooltip>
                                    </Box>
                                </CardActions>
                            </>
                        )}
                          </Card>
                        </Grid>
                      ))}
                    </Grid>
                  ) : (
                    <NoData />
                  )}
                  {atsJobPostData?.[0]?.totalcount > 12 && (
                    <Pagination
                      sx={{ display: "flex", justifyContent: "flex-end", mb: 2 }}
                      showFirstButton
                      showLastButton
                      count={
                        Number.isInteger(atsJobPostData?.[0]?.totalcount / 12)
                          ? atsJobPostData?.[0]?.totalcount / 12
                          : Math.round(atsJobPostData?.[0]?.totalcount / 12 + 0.5) ||
                          0
                      }
                      page={page}
                      color="secondary"
                      onChange={(event, value) => handlePagination(event, value)}
                    />
                  )}

                </Box>
              )
            }
          </>
          {/* QR Code Modal for Job Posting*/}
          <Modal open={isQRModalOpen} onClose={() => {setQRModalOpen(false); setJobLink("https://meepl.day")}}>
                  <Box
                    sx={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      bgcolor: "background.paper",
                      borderRadius: 1,
                      p: 2,
                      boxShadow: 24,
                      maxWidth: 300,
                      width: "100%",
                      textAlign: "center",
                    }}
                  >
                    <IconButton
                      sx={{
                        position: "absolute",
                        top: 10,
                        right: 10,
                      }}
                      onClick={() => { setQRModalOpen(false) }}
                    >
                      <CloseIcon />
                    </IconButton>
                        <Typography mt={3} mb={1} variant="h4">Scan to view career portal</Typography>
                      <Box sx={{ mb: 1 }} ref={qrRef}>
                        <QRCodeCanvas value={jobLink} size={240} />
                      </Box>
                      <Box sx={{display: "flex", alignItems:"center", justifyContent: "center", gap: "25px"}}>
                        <IconButton
                          className="mpl-primary-btn"
                            onClick={shareQR}
                          >
                          <ShareIcon />
                        </IconButton> 
                        <IconButton
                          className="mpl-primary-btn"
                            onClick={downloadQR}
                          >
                          <GetAppIcon />
                        </IconButton>
                      </Box>             
                  </Box>
                </Modal>
            

          {/* Drawer */}
          <ClickAwayListener
            mouseEvent="onMouseDown"
            touchEvent="onTouchStart"
            // open={anchor}
            onClickAway={() => anchor && toggleDrawer(false)}
          >
            <Drawer
              variant={
                drawerType === "proposeCandidate" ? "temporary" : "persistent"
              }
              ModalProps={{
                keepMounted: false,
              }}
              PaperProps={{
                sx: { width: drawerType === "proposeCandidate" ? "25%" : "25%" },
              }}
              anchor={"right"}
              open={anchor}
              onClose={() => toggleDrawer(false)}
            >
              {/* FILTER CONTENT  or PROPOSE CANDIDATE CONTENT*/}
              {drawerType === "proposeCandidate" ? (
                <AtsProposeCandidateForm
                  setCurrentData={setCurrentData}
                  toggleDrawer={toggleDrawer}
                  currentData={currentData}
                />
              ) : (
                <AtsJobPostFilter
                  getJobData={getJobData}
                  handleClearFilter={handleClearFilter}
                  toggleDrawer={toggleDrawer}
                  companyValue={companyValue}
                  setCompanyValue={setCompanyValue}
                  handleCompanyChange={handleCompanyChange}
                  grouporganisationValue={grouporganisationValue}
                  setgrouporganisationValue={setgrouporganisationValue}
                  handleGrouporgChange={handleGrouporgChange}
                  consultancyValue={consultancyValue}
                  setConsultancyValue={setConsultancyValue}
                  handleConsultancyChange={handleConsultancyChange}
                  severityValue={severityValue}
                  setSeverityValue={setSeverityValue}
                  sortValue={sortValue}
                  setSortValue={setSortValue}
                  handleSeverityChange={handleSeverityChange}
                  primaryRecruiterValue={primaryRecruiterValue}
                  setPrimaryRecruiterValue={setPrimaryRecruiterValue}
                  secondaryRecruiterValue={secondaryRecruiterValue}
                  setSecondaryRecruiterValue={setSecondaryRecruiterValue}
                  handlePrimaryRecruiterChange={handlePrimaryRecruiterChange}
                  handleSecondaryRecruiterChange={handleSecondaryRecruiterChange}
                  JobFilters={JobFilters}
                />
              )}
            </Drawer>
          </ClickAwayListener>
          <ConfirmationDialog
            title="Inactive Job"
            content="Are you sure you want to Inactive this job?"
            openDialog={openInactiveDialog}
            closeDialog={setOpenInactiveDialog}
            popupTitle="Inactive Job"
            OkButtonText="Yes"
            CancelButtonText="No"
            onSubmit={() => handleConfirmArchive("Inactive")}
          />
          <ConfirmationDialog
            title="Archive"
            content="If you archive this job, you won’t be able to enable it again in the future. Are you sure you want to continue?"
            openDialog={openDialog}
            closeDialog={setOpenDialog}
            popupTitle="Job"
            OkButtonText="Archive"
            onSubmit={() => handleConfirmArchive("Archived")}
          />
          <ConfirmationDialog
            title="Unarchive"
            content="Are you sure you want to Activate this Job?"
            openDialog={openActiveDialog}
            closeDialog={setOpenActiveDialog}
            popupTitle="Job"
            OkButtonText="Unarchive"
            onSubmit={handleConfirmEnable}
          />       
          <Menu
            anchorEl={anchorE}
            open={open1}
            onClose={() => setAnchorE(null)}
            id="long-menu"
          >
            <MenuItem key="Archive" onClick={handleArchiveClick}>
              <ListItemIcon>
                <ArchiveIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Archive</ListItemText>
            </MenuItem>
           { tabTypeIndex===0 && (
             <MenuItem key="Inactive" onClick={handleInactiveClick}>
              <ListItemIcon>
                <RestoreIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Inactive</ListItemText>
            </MenuItem>
          )}
            {handleDisabled("job_post_edit_job") && (
              <MenuItem key="Edit" onClick={handleEditClick}>
                <ListItemIcon>
                  <EditIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>Edit</ListItemText>
              </MenuItem>
            )}
          </Menu>
        </>}
    </>
  );
};

const AppliedFilter = (props) => {
  const {
    companyValue,
    handleCompanyChange,
    grouporganisationValue,
    handleGrouporgChange,
    consultancyValue,
    handleConsultancyChange,
    severityValue,
    handleSeverityChange,
    primaryRecruiterValue,
    secondaryRecruiterValue,
    handlePrimaryRecruiterChange,
    handleSecondaryRecruiterChange,
    handleClearFilter,
    JobFilters,
  } = props;
  let filterStyle = {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    paddingLeft: "8px",
    paddingRight: "8px",
    lineHeight: 1.57143,
    fontSize: "0.875rem",
    color: " #000000",
    backgroundColor: PRIMARY.dark,
    borderRight: "1px solid rgba(145, 158, 171, 0.24)",
  };
  let filterStyle1 = {
    display: "flex",
    flexDirection: "row",
    alignItems: "stretch",
    margin: "4px",
    borderRadius: "8px",
    overflow: "hidden",
    border: "1px solid rgba(145, 158, 171, 0.24)",
  };

  return (
    <div
      style={{
        display: "flex",
        flexFlow: "row wrap",
        flexGrow: 1,
        alignItems: "center",
      }}
    >
      {companyValue?.length > 0 && (
        <div style={filterStyle1}>
          <Typography style={filterStyle}>Client</Typography>
          <div
            style={{ display: "flex", flexFlow: "row wrap", padding: "6px" }}
          >
            {companyValue?.map((comp, index) => (
              <Chip
                key={index}
                sx={{ margin: "4px" }}
                label={comp?.name}
                size="small"
                onDelete={(e) => { handleCompanyChange(comp, e); JobFilters() }}
              />
            ))}
          </div>
        </div>
      )}
       {grouporganisationValue?.length > 0 && (
        <div style={filterStyle1}>
          <Typography style={filterStyle}>Group Organisation</Typography>
          <div
            style={{ display: "flex", flexFlow: "row wrap", padding: "6px" }}
          >
            {grouporganisationValue?.map((comp, index) => (
              <Chip
                key={index}
                sx={{ margin: "4px" }}
                label={comp?.name}
                size="small"
                onDelete={(e) => { handleGrouporgChange(comp, e); JobFilters() }}
              />
            ))}
          </div>
        </div>
      )}

      {consultancyValue?.length > 0 && (
        <div style={filterStyle1}>
          <Typography style={filterStyle}>Hiring Partner</Typography>
          <div
            style={{ display: "flex", flexFlow: "row wrap", padding: "6px" }}
          >
            {consultancyValue?.map((cons, index) => (
              <Chip
                key={index}
                sx={{ margin: "4px" }}
                label={cons?.name}
                size="small"
                onDelete={(e) => { handleConsultancyChange(cons, e); JobFilters() }}
              />
            ))}
          </div>
        </div>
      )}
      {primaryRecruiterValue?.length > 0 && (
        <div style={filterStyle1}>
          <Typography style={filterStyle}>Primary</Typography>
          <div
            style={{ display: "flex", flexFlow: "row wrap", padding: "6px" }}
          >
            {primaryRecruiterValue?.map((pr, index) => (
              <Chip
                key={index}
                sx={{ margin: "4px" }}
                label={pr?.recruiter}
                size="small"

                onDelete={(e) => { handlePrimaryRecruiterChange(pr, e); JobFilters() }}
              />
            ))}
          </div>
        </div>
      )}
      {secondaryRecruiterValue?.length > 0 && (
        <div style={filterStyle1}>
          <Typography style={filterStyle}>Secondary</Typography>
          <div
            style={{ display: "flex", flexFlow: "row wrap", padding: "6px" }}
          >
            {secondaryRecruiterValue?.map((sr, index) => (
              <Chip
                key={index}
                sx={{ margin: "4px" }}
                label={sr?.recruiter}
                size="small"
                onDelete={(e) => { handleSecondaryRecruiterChange(sr, e); JobFilters() }}
              />
            ))}
          </div>
        </div>
      )}
      {severityValue?.length > 0 && (
        <div style={filterStyle1}>
          <Typography style={filterStyle}>Severity</Typography>
          <div
            style={{ display: "flex", flexFlow: "row wrap", padding: "6px" }}
          >
            {severityValue?.map((s, index) => (
              <Chip
                key={index}
                sx={{ margin: "4px" }}
                label={s?.priority}
                size="small"
                onDelete={(e) => { handleSeverityChange(s, e); JobFilters() }}
              />
            ))}
          </div>
        </div>
      )}
      {(companyValue?.length > 0 ||
        consultancyValue?.length > 0 ||
        severityValue?.length > 0 ||
        primaryRecruiterValue?.length > 0 ||
        secondaryRecruiterValue?.length > 0) && (
          <Button
            color="error"
            variant="text"
            startIcon={<DeleteIcon />}
            onClick={handleClearFilter}
          >
            Delete
          </Button>
        )}
    </div>
  );
};
export default AtsJobPost;
